import React, { Component } from 'react';
import { branch } from 'baobab-react/higher-order';
import _ from "lodash"
import { getIconCssClassFromStyleParams } from "../../utils/fontIcons";
import actions from "../../data/actions/actions";
import { hashHistory } from 'react-router';
import compositeActions from "../../data/actions/compositeActions";
import isDblTouchTap from "../deviceItems/isDblTouchTap"
import updateForm, { loadFormData } from "../../data/actions/formStateController";
import saveFormData from "../../data/actions/formActions";
import showHeaderNotification from "../../utils/showNotification"
import uiActions from "../../data/actions/ui";

import {requestData} from "../../data/newCommManager"

import baobabTree from '../../data/state';
var deviceType = {
    digitalOutput:0,
    analogOutput:1,
    digitalInput:2,
    analogInput:3,
    special:4,
    virtualSwitch:5
  }


class Widget extends Component {
    constructor(params) {
        super()
        this.debounceTimestamp = Date.now();

        this.state = {
            sliderValue: 0,
        }

    }
    hideDialog() {
        this.props.hideWarning()
    }

    changeStateOnSliderMove(e) {
        this.setState({
            sliderValue: parseInt(e.target.value, 10)
        })
    }

    toggleWindowDeviceState(compositeDevice){
        
            this.debounce(() => {
                if (!compositeDevice.lockState) {
                    if (compositeDevice.value === 0) {
                        compositeActions.toggleAnalogSwitch(compositeDevice.id, 255, compositeDevice.value);
                    } else {
                        compositeActions.toggleAnalogSwitch(compositeDevice.id, 0, compositeDevice.value);
                    }
                }
            });   
            
            
    }

    changeThermostatStateOnSliderMove(e){
        this.setState({
            sliderValue: parseFloat(e.target.value)
        })
    }

    getDeviceDesignType(dev){
        let renderType = null;
        if(dev.address){
          if(dev.address.plcDeviceType==="volumeControll"){
            renderType = "volume"
          } else if(dev.address.driver==="th01" || dev.address.driver==="th01_v2"){
            renderType = "singleThermostat"
          } else if(dev.address.driver==="sdm230"){
            renderType = "powermeter"
          }
        }
    
        if(renderType===null && dev){
          switch (dev.type) {
             case deviceType.digitalOutput:
              case deviceType.virtualSwitch:
                    renderType = "digitalOutput"
             break;
            case deviceType.analogOutput:
              renderType = "analogOutput"
            break;
            case deviceType.digitalInput: 
              renderType = "digitalInput"
            break;
            case deviceType.analogInput:
              renderType = "analogInput"
            break;
            default:break;
          }
        }
    
        return renderType;
      }

    componentDidUpdate(nextProps) {
        if (nextProps.devices !== this.props.devices) {
            _.forEach(nextProps.widget.params.selectedItems, item => {

                switch (item.model) {
                    case "Device":
                        let arrayToSearch = nextProps.devices;
                        let itemFromDb = _.find(arrayToSearch, { id: parseInt(item.id, 10) })

                        if (!_.isUndefined(itemFromDb)) {

                            switch (itemFromDb.type) {
                                case 1:
                                    let designType = this.getDeviceDesignType(itemFromDb)

                                    var device = itemFromDb
                                    let thState = _.clone(device.states);
                                    if (thState === undefined) {
                                        thState = {
                                            currentTemperature: 0,
                                            targetTemperature: 0,
                                            pirState: "closed"
                                        }
                                    }
    
                                    let sliderValue = designType !== "singleThermostat" ? 
                                    Math.round((itemFromDb.value * itemFromDb.address.analogOutputInfo.max) / 255): thState.targetTemperature
    
                                    this.setState({
                                        sliderValue: sliderValue
                                    })

                                    break;
                                default:
                                    break;
                            }
                        }
                        break;
                    case "CompositeDevice":
                        let arraySearch = nextProps.compositeDevices;

                        let myItemFromDb = _.find(arraySearch, { id: parseInt(item.id, 10) })
                        if (!_.isUndefined(myItemFromDb)) {

                        switch (myItemFromDb.type) {
                            case "shutters":
                                let sliderValue = Math.round((myItemFromDb.value * 4) / 255)

                                this.setState({
                                    sliderValue: sliderValue
                                })
                                break;
                            case "thermostat":
                                this.setState({
                                    sliderValue: myItemFromDb.value
                                })
                                break;
                            default:
                                break;
                        }
                    }
                        break;

                    default:
                        break;
                }
            })
        }

    }
    componentDidMount() {
        if(this.props.widget.params){
            _.forEach(this.props.widget.params.selectedItems, item => {

                switch (item.model) {
                    case "Device":
                        let arrayToSearch = this.props.devices;
                        let itemFromDb = _.find(arrayToSearch, { id: parseInt(item.id, 10) })
    
                        if (!_.isUndefined(itemFromDb)) {
    
                            switch (itemFromDb.type) {
                                case 1:
    
                                    let designType = this.getDeviceDesignType(itemFromDb)
    
                                    var device = itemFromDb
                                    let thState = _.clone(device.states);
                                    if (thState === undefined) {
                                        thState = {
                                            currentTemperature: 0,
                                            targetTemperature: 0,
                                            pirState: "closed"
                                        }
                                    }
    
                                    let sliderValue = designType !== "singleThermostat" ? 
                                    Math.round((itemFromDb.value * itemFromDb.address.analogOutputInfo.max) / 255): thState.targetTemperature
    
                                    this.setState({
                                        sliderValue: sliderValue
                                    })
    
                                    break;
                                default:
                                    break;
                            }
                        }
                        break;
                    case "CompositeDevice":
                        let arraySearch = this.props.compositeDevices;
                        let myItemFromDb = _.find(arraySearch, { id: parseInt(item.id, 10) })
                        if (!_.isUndefined(myItemFromDb)) {
                        switch (myItemFromDb.type) {
                            case "shutters":
                                let sliderValue = Math.round((myItemFromDb.value * 4) / 255)
    
                                this.setState({
                                    sliderValue: sliderValue
                                })
                                break;
                            case "thermostat":
                                this.setState({
                                    sliderValue: myItemFromDb.value
                                })
                                break;
                            default:
                                break;
                        }
                    }
                        break;
                    default:
                        break;
    
                }
            })
        }
        

    }

    setAnalogValueViaButton(device) {

        if(device.isExecuting !== true){
        switch (device.type) {
            case 1:
                if (_.isUndefined(device.lockState) || device.lockState === false) {
                    if (this.state.sliderValue !== null) {
                        let deviceFromDb = _.find(this.props.devices, { "id": parseInt(device.id, 10) })
                        let id = parseInt(device.id, 10);

                        let designType = this.getDeviceDesignType(deviceFromDb)
                        let stateVal = this.state.sliderValue === null ? 0 : this.state.sliderValue
                        let valueToSend = designType !== "singleThermostat" ? (255 / parseInt(deviceFromDb.address.analogOutputInfo.max, 10)) * parseInt(stateVal, 10) : parseInt(Math.round(this.state.sliderValue * 10), 10);
                        actions.setAnalogOutputState(id, Math.round(valueToSend));
                    }
                }
                else {
                    this.setState({
                        isWarningShown: true,
                        message: "Device is locked. Cannot change state at this moment"
                    })
                }
                break;
            case "shutters":
                if (_.isUndefined(device.lockState) || device.lockState === false) {
                    if (this.state.sliderValue !== null) {
                        let id = parseInt(device.id, 10);

                        let stateVal = this.state.sliderValue === null ? 0 : this.state.sliderValue
                        let valueToSend = (255 / parseInt(4, 10)) * parseInt(stateVal, 10);

                        // actions.setAnalogOutputState(id, parseInt(Math.round(valueToSend), 10));
                        compositeActions.setAnalogOutputState(id, Math.round(valueToSend));
                    }
                }
                else {
                    this.setState({
                        isWarningShown: true,
                        message: "Device is locked. Cannot change state at this moment"
                    })
                }
                break;
                case 4:
                if (!_.isUndefined(device.address)) {
                    if (!_.isUndefined(device.address.plcDeviceType)) {
                      if (device.address.plcDeviceType === "volumeControll") {
                        if (_.isUndefined(device.lockState) || device.lockState === false) {
                            if (this.state.sliderValue !== null) {
                                let id = parseInt(device.id, 10);
                                let stateVal = this.state.sliderValue === null ? 0 : this.state.sliderValue
                                // let valueToSend = (255 / parseInt(20, 10)) * parseInt(stateVal, 10);
                                let valueToSend = (255 / parseInt(device.address.analogOutputInfo.max, 10)) * parseInt(stateVal, 10);
                                actions.setAnalogOutputState(id, Math.round(valueToSend));
                            }
                        }
                        else {
                            this.setState({
                                isWarningShown: true,
                                message: "Device is locked. Cannot change state at this moment"
                            })
                        }                      }
                    }
                  }
               
                break;
            case "thermostat":
                if (_.isUndefined(device.lockState) || device.lockState === false) {
                    if (this.state.sliderValue !== null) {
                        let id = parseInt(device.id, 10);

                        let stateVal = this.state.sliderValue === null ? 0 : this.state.sliderValue
                        let valueToSend = stateVal                        
                        this.setState({
                            sliderValue:valueToSend
                        })
                        // actions.setAnalogOutputState(id, parseInt(Math.round(valueToSend), 10));
                        compositeActions.setAnalogOutputState(id, Math.round(valueToSend));
                    }
                }
                else {
                    this.setState({
                        isWarningShown: true,
                        message: "Device is locked. Cannot change state at this moment"
                    })
                }
                break;
            default:
                break;
        }
   }
    }
    debounce(fn) {
        if (Date.now() - this.debounceTimestamp > 300) {
            fn();
        } //else is debounced....
        this.debounceTimestamp = Date.now();
    }

    setDeviceValue(device, targetValue, e) {
        if(device.isExecuting !== true){
        switch (device.type) {
            case 0: //digital output
            case 5: //digital output
                this.debounce(() => {

                    actions.toggleSwitch(device.id, device.value);
                })
                break;
            case 1: //analog output
                this.debounce(() => {
                    actions.toggleAnalogSwitch(device.id, targetValue, device.value);
                })
                break;
            case 2:
                if (device.address.pushButton) {
                    if (device.address.pushButton === true) {
                        this.debounce(() => {
                            actions.toggleSwitch(device.id, device.value);
                        })
                    }
                }
                break;
            case 3:
            case 4:
            default:
                console.log("No action allowed");
                break;
        }
    }
    }

    handleSceneStopAndPlay(scene) {
        if (!_.isUndefined(scene.state)) {
            switch (scene.state) {
                default:
                case 0: // //0 - inactive
                    actions.playScene(scene.id)
                    break;
                case 1: //1 - active
                    actions.stopScene(scene.id)
                    break;
                case 2: //2 - activating,
                case 3: //3 - deactivating, 
                    //ignore
                    break;
                case 4: //4 - overrided
                    actions.playScene(scene.id)
                    break;
            }
        } else {
            actions.playScene(scene.id); //backward model compatibility
        }
    }

    goToGroup(groupId, e) {
        e.stopPropagation()
        hashHistory.push("/group/" + groupId)
    }

    goToScene(sceneId, e) {
        e.stopPropagation()
        hashHistory.push("/scene/" + sceneId)
    }

    goToWidgetEdit(widgetId, e) {
        e.stopPropagation()
        hashHistory.push("/widget/" + widgetId + "/edit")
    }

    onPushButtonPushed(device, targetValue, e){
        if (device.address.pushButton) {
            if (device.address.pushButton === true) {
              this.debounce(() => {
                actions.toggleSwitch(device.id, 1); //must be 1 to emulate push event...
              })
            }
          }
    }

    lockState(deviceId, lockState, e) {
        if (isDblTouchTap(e)) {
              let currentStateValue = _.isUndefined(lockState) ? true : !lockState
    
        loadFormData("device", parseInt(deviceId, 10));
    
        var obj = {};
        obj["lockState"] = currentStateValue;
        updateForm("device", obj);
    
        saveFormData("device").then(res => {
          showHeaderNotification(true, 3000, "Device state " + (currentStateValue === true ? "" : "un") + "locked")
          //bounceIn
          this.setState({
            animationName : "animated faster flip"
          })
        })
      }
      }

      lockCompositeDevice(compositeDeviceId, lockState, e){
        if (isDblTouchTap(e)) {
            let currentStateValue = _.isUndefined(lockState) ? true : !lockState
  
      loadFormData("compositedevice", parseInt(compositeDeviceId, 10));
  
      var obj = {};
      obj["lockState"] = currentStateValue;
      updateForm("compositedevice", obj);
  
      saveFormData("compositedevice").then(res => {
        showHeaderNotification(true, 3000, "Device state " + (currentStateValue === true ? "" : "un") + "locked")
        //bounceIn
        this.setState({
          animationName : "animated faster flip"
        })
      })
    }
      }

      turnThermostatOnOrOff(device) {
        let states = _.clone(device.states)
        let {toggleButtonBitMask} = states
        var mask = 1 << 2; //POWER button
        toggleButtonBitMask ^= mask;
    
        let deviceClone = _.cloneDeep(device)
        deviceClone.states.toggleButtonBitMask = toggleButtonBitMask;
    
        baobabTree.root.select(["model", "devices", {id:device.id}]).set(deviceClone)
        requestData("/setDeviceValue/"+device.id, "PUT", {value: {toggleButtonBitMask}  }).then(res=>{
          //console.log("RESULT:",res);
        }).catch(err=>{
          console.warn(err)
        })
      }


    render() {
        let widgets = []
        let arrayToSearch = []
        let itemsInWidget = []
        let itemFromDb = {}

        if(this.props.widget.params){
            _.forEach(this.props.widget.params.selectedItems, item => {
                let wrapperClassName = ""
                let actionOrStatus = ""
                 let icon = <i></i>
                switch (item.model) {
                    case "Device":
                        arrayToSearch = this.props.devices;
                        itemFromDb = _.find(arrayToSearch, { id: parseInt(item.id, 10) })
                       
                        if (!_.isUndefined(itemFromDb)) {
    
                            let isVolumeDevice = false;
                            let isModbusPowerDevice = false
    
                            if (!_.isUndefined(itemFromDb.address)) {
                                if (!_.isUndefined(itemFromDb.address.plcDeviceType)) {
                                  if (itemFromDb.address.plcDeviceType === "volumeControll") {
                                    isVolumeDevice = true
                                  }
                                }
                              }
    
                              if (!_.isUndefined(itemFromDb.address)) {
                                if (!_.isUndefined(itemFromDb.address.grp)) {
                                  if (itemFromDb.address.grp === "powermeter") {
                                    isModbusPowerDevice = true
                                  }
                                }
                              }
    
                            let lockedIcon = <div className="pointer" 
                            onClick={this.lockState.bind(this, itemFromDb.id,  itemFromDb.lockState)} >
                            <i className={"triple-font-size fa fa-lock " + this.state.animationName}  ></i></div>
    
                           let isDeviceDisabled = false
                           if (itemFromDb.connectionState === 2) {
                               isDeviceDisabled = true;
                             }                         
                            switch (itemFromDb.type) {
                                case 0:
                                case 5:
                                    wrapperClassName = "dashboard-digital-output";
                                    actionOrStatus = <input onChange={this.setDeviceValue.bind(this, itemFromDb, null)} checked={itemFromDb.value > 0}
                                        value={itemFromDb.value > 0 ? "on" : "off"} className="toggle" type="checkbox" />
                                    icon = itemFromDb.lockState === true ? lockedIcon :  <i onClick={this.lockState.bind(this, itemFromDb.id,  itemFromDb.lockState)}  
                                    style={{ color: !_.isUndefined(this.props.widget.params.style) ? this.props.widget.params.style.iconColor : "#f0f8ff85" }} 
                                    className={getIconCssClassFromStyleParams(itemFromDb.style, { isBig: true }) + " pointer"} />
                                    break;
                                case 1:
                                    let sliderValue = Math.round((itemFromDb.value * itemFromDb.address.analogOutputInfo.max) / 255)
                                    let designType = this.getDeviceDesignType(itemFromDb)
                                    let thermostatStates = {}
                                    let isPoweredOff = {}
                                    let isReady = false
                                    if (designType === "singleThermostat") {
                                        thermostatStates = itemFromDb.states ? itemFromDb.states : { targetTemperature: _.isUndefined(this.props.sceneParameter) ? 0 : this.props.device.value / 10, currentTemperature: 0, relaysBitMask: 0, toggleButtonBitMask: 4, pirState: "closed" };
                                        isPoweredOff = (thermostatStates.toggleButtonBitMask & (1 << 2)) === 0;
                                        if (thermostatStates.targetTemperature > 0) {
                                            isReady = true
                                        }                                    
                                        sliderValue = thermostatStates.targetTemperature
                                    }                                    
    
                                    wrapperClassName = "dashboard-analog-output"
                                    actionOrStatus =
                                        <div>
                                            {designType === "singleThermostat" ?
                                                (isReady ? <div>
                                                    
                                                    { <span className={thermostatStates.pirState === "open" ? "powermeter-device-data animated flash slow infinite" : "powermeter-device-data pointer "}
                                                        style={{ fontSize: "1em" }}>{thermostatStates.currentTemperature.toFixed(1) + "°C"} /</span>}
                                                    {isPoweredOff ? "OFF" : thermostatStates.targetTemperature.toFixed(1) + "°C"}
                                                    <div className=" text-white"></div></div> : "(not ready)") : null
                                            }
                                            <input disabled={itemFromDb.lockState === true} value={this.state.sliderValue} type="range" step={designType === "singleThermostat" ? "0.5" : "1"} min={itemFromDb.address.analogOutputInfo.min || "0"}
                                                max={itemFromDb.address.analogOutputInfo.max} className="analog" onChange={this.changeThermostatStateOnSliderMove.bind(this)} />
                                            {designType === "singleThermostat" ? <span style={{ position: "absolute", fontSize: "1rem", bottom: 0, left: "5px" }}>{this.state.sliderValue}</span> : null}
                            </div>
    
                                    icon = parseInt(sliderValue, 10) === parseInt(this.state.sliderValue, 10) ? <i onClick={this.lockState.bind(this, itemFromDb.id,  itemFromDb.lockState)} 
                                    style={{ color: !_.isUndefined(this.props.widget.params.style) ? this.props.widget.params.style.iconColor : "#f0f8ff85" }} className={getIconCssClassFromStyleParams(itemFromDb.style, { isBig: true })+ " pointer"} />
                                        : <i style={{ color: !_.isUndefined(this.props.widget.params.style) ? this.props.widget.params.style.iconColor : "#f0f8ff85" }} onClick={this.setAnalogValueViaButton.bind(this, itemFromDb)} className="fa fa-check-circle fa-3x infinite-animation animated faster pulse"></i>
                                    if(itemFromDb.lockState === true){icon = lockedIcon}
                                   
                                    if (designType === "singleThermostat") {
                                        icon = <div>
                                            <div className="text-center" style={{ position: "absolute", top: "-17px", left: "5px" }}
                                                onClick={this.turnThermostatOnOrOff.bind(this, itemFromDb)} >
                                                <input onChange={this.turnThermostatOnOrOff.bind(this, itemFromDb)}
                                                    checked={!isPoweredOff}
                                                    value={!isPoweredOff ? "on" : "off"} className="toggle" type="checkbox" />
                                            </div>
                                            {icon}
                                        </div>
                                    }
                                    break;
                                case 2:
                                    let trueFalseLabel = itemFromDb.value === 1 ?
                                        (_.isUndefined(itemFromDb.style.labelForTrue) ? "Open" : itemFromDb.style.labelForTrue) :
                                        (_.isUndefined(itemFromDb.style.labelForFalse) ? "Closed" : itemFromDb.style.labelForFalse);
                                    wrapperClassName = "dashboard-digital-input";
                                    actionOrStatus = <div onClick={itemFromDb.address.pushButton ? this.onPushButtonPushed.bind(this, itemFromDb, null) : ()=>{}} 
                                    className={itemFromDb.address.pushButton ? "btn btn-sm btn-info" : ""}>{trueFalseLabel}</div>
                                    icon = itemFromDb.lockState === true ? lockedIcon : <i onClick={this.lockState.bind(this, itemFromDb.id,  itemFromDb.lockState)} 
                                    style={{ color: !_.isUndefined(this.props.widget.params.style) ? this.props.widget.params.style.iconColor : "#f0f8ff85" }} className={getIconCssClassFromStyleParams(itemFromDb.value === 1 ?
                                        (_.isUndefined(itemFromDb.style.iconForTrue) ? itemFromDb.style : {icon:itemFromDb.style.iconForTrue}) :
                                        (_.isUndefined(itemFromDb.style.iconForFalse) ? itemFromDb.style : {icon:itemFromDb.style.iconForFalse}) , { isBig: true })+ " pointer"} />
    
                                    break;
                                case 3:
                                let unit = "";
                                if(!_.isUndefined(itemFromDb.address)){
                                    if(!_.isUndefined(itemFromDb.address.sensorInfo)){
                                        if(!_.isUndefined(itemFromDb.address.sensorInfo.unitShort)){
                                            unit = itemFromDb.address.sensorInfo.unitShort
                                        }
                                    }
                                }
    
                                let designT = this.getDeviceDesignType(itemFromDb)
                                let readOut = ""
    
                                if(designT === "powermeter"){
                                    let pwrMeterReadout = itemFromDb.states || {activePower: 0, current:0, totalActiveEnergy:0};
    
                                    readOut = <div style={{fontSize:"1rem"}}>
                                        <div >{pwrMeterReadout.current.toFixed(2) + " A"} / {pwrMeterReadout.voltage.toFixed(2) + " V"}</div>
                                        <div >{pwrMeterReadout.activePower.toFixed(2) + " W"} / {pwrMeterReadout.totalActiveEnergy.toFixed(2) + " kWh"}</div>
                                    </div>
                                }
                                
                                wrapperClassName = "dashboard-analog-input"
                                    actionOrStatus = designT === "powermeter" ? readOut : <div>{Math.round(parseInt(itemFromDb.value, 10))}{unit}</div>
                                    icon = itemFromDb.lockState === true ? lockedIcon :  <i onClick={this.lockState.bind(this, itemFromDb.id,  itemFromDb.lockState)} 
                                    style={{zIndex:"11", color: !_.isUndefined(this.props.widget.params.style) ? this.props.widget.params.style.iconColor : "#f0f8ff85" }} className={getIconCssClassFromStyleParams(itemFromDb.style, { isBig: true })+ " pointer"} />
    
                                    break;
                                case 4:
                                 let volumeSliderValue = Math.round((itemFromDb.value * 20) / 255)
    
                                if(isVolumeDevice === true){
                                    wrapperClassName = "dashboard-volume"
                                    actionOrStatus = <input disabled={itemFromDb.lockState === true} value={this.state.sliderValue} type="range" step="1" min="0"
                                    max={itemFromDb.address.analogOutputInfo.max} className="analog" onChange={this.changeStateOnSliderMove.bind(this)} />
                                icon = parseInt(volumeSliderValue, 10) === parseInt(this.state.sliderValue, 10) ? 
                                        <i onClick={this.lockState.bind(this, itemFromDb.id,  itemFromDb.lockState)} 
                                        style={{ color: !_.isUndefined(this.props.widget.params.style) ? 
                                        this.props.widget.params.style.iconColor : "#f0f8ff85" }} className={getIconCssClassFromStyleParams(itemFromDb.style, { isBig: true })+ " pointer"} />
                                        : <i style={{ color: !_.isUndefined(this.props.widget.params.style) ? this.props.widget.params.style.iconColor : "#f0f8ff85" }} 
                                            onClick={this.setAnalogValueViaButton.bind(this, itemFromDb)} className="fa fa-check-circle fa-3x infinite-animation animated faster pulse"></i>
                                if(itemFromDb.lockState === true){icon = lockedIcon}
                                }
                                if(isModbusPowerDevice === true) {                                
    
                                    let pwrMeterReadout = itemFromDb.address.states || {activePower: 0, current:0, totalActiveEnergy:0};
                                    wrapperClassName = "dashboard-digital-input"
                                    actionOrStatus =  <div className="pointer" onClick={()=>{uiActions.setModalDialog({ type: "powerManagement", sceneParameter: this.props.sceneParameter }); window.location="#?deviceId=" + itemFromDb.id;}}><div >{pwrMeterReadout.current.toFixed(2) + " A"} / {pwrMeterReadout.activePower.toFixed(2) + " W"}</div>
                                    <div >{pwrMeterReadout.totalActiveEnergy.toFixed(2) + " kWh"}</div></div>
                                    icon = itemFromDb.lockState === true ? lockedIcon :  <i onClick={this.lockState.bind(this, itemFromDb.id,  itemFromDb.lockState)}  
                                    style={{ color: !_.isUndefined(this.props.widget.params.style) ? this.props.widget.params.style.iconColor : "#f0f8ff85" }} 
                                    className={getIconCssClassFromStyleParams(itemFromDb.style, { isBig: true })+ " pointer"} />
    
                                }
                            
                                   
                                    break;
                                default:
                                    break;
                            }
                            itemsInWidget.push(<div className={wrapperClassName} key={itemFromDb.model + "" +itemFromDb.id}>
                                <div className="dashboard-item-icon"> {isDeviceDisabled === false ? icon : <i className="icon-blocked triple-font-size"></i>}</div>
                                <div className="dashboard-item-action-status">{actionOrStatus} </div>
                                <div className="dashboard-item-name">{isDeviceDisabled === false ? itemFromDb.name : <i>{itemFromDb.name+ " offline"}</i>}</div>
    
    
                            </div>)
                        }
                        break;
    
                    case "Scene":
                    let lockedSceneIcon = <div className="pointer" >
                    <i className={"triple-font-size fa fa-lock " + this.state.animationName}  ></i>
                </div>
                        arrayToSearch = this.props.scenes;
                        itemFromDb = _.find(arrayToSearch, { id: parseInt(item.id, 10) })
                        let statusIcon = "fa fa-play"
                        if (!_.isUndefined(itemFromDb)) {
                            switch (itemFromDb.state) {
                                default:
                                case 0: // //0 - inactive
                                    statusIcon = "icon-play2";
                                    break;
                                case 1: //1 - active
                                    statusIcon = "fa fa-eject";
                                    break;
                                case 2: //2 - activating,
                                    statusIcon = "fa fa-spinner fa-spin";
                                    break;
                                case 3: //3 - deactivating, 
                                    statusIcon = "fa fa-spinner fa-spin";
                                    break;
                                case 4: //4 - overrided
                                    statusIcon = "fa fa-step-forward";
                                    break;
                            }
                        } else {
                            statusIcon = "fa fa-play";
                        }
    
                        if (!_.isUndefined(itemFromDb)) {
                            wrapperClassName = "dashboard-scene";
                            let lockedIcon = <i className={"fa fa-lock "}  ></i>
    
    
                            icon = itemFromDb.lockState === true ? lockedIcon :  <i className={getIconCssClassFromStyleParams(itemFromDb.style, { isBig: false })+ " pointer"} />
    
                            actionOrStatus = itemFromDb.lockState === true ? lockedSceneIcon : <i onClick={() => this.handleSceneStopAndPlay(itemFromDb)} 
                            style={{ color: !_.isUndefined(this.props.widget.params.style) ? this.props.widget.params.style.iconColor : "#ffffff8c" }} className={statusIcon + " pointer"}></i>
                            itemsInWidget.push(<div  className={wrapperClassName} key={_.uniqueId("devic")}>
                                {/* <div className="dashboard-item-icon col-xs-12 col-sm-6"> <i className={getIconCssClassFromStyleParams(itemFromDb.style, { isBig: true })} /></div> */}
                                <div className="dashboard-item-action-status col-xs-12 col-sm-12">{actionOrStatus}</div>
                                <div onClick={this.goToScene.bind(this, itemFromDb.id)} className="dashboard-item-name col-xs-12 pointer"><span>{icon} {itemFromDb.name}</span></div>
    
                            </div>)
                        }
                        break;
    
                    case "Group":
                        arrayToSearch = this.props.groups;
                        wrapperClassName = "dashboard-group pointer";
    
                        itemFromDb = _.find(arrayToSearch, { id: parseInt(item.id, 10) })
                        if (!_.isUndefined(itemFromDb)) {
    
                            var devicesInThisGroup = [];
                            _.forEach(this.props.devicegroups, d => {
                                if (parseInt(d.GroupId, 10) === parseInt(itemFromDb.id, 10)) {
                                    this.props.devices.map((item) => {
                                        if (d.DeviceId === item.id) {
                                            devicesInThisGroup.push(item);
                                        }
                                        return true;
                                    })
                                    this.props.compositeDevices.map((item) => {
                                        if (d.CompositeDeviceId === item.id) {
                                            devicesInThisGroup.push(item);
                                        }
                                        return true;
                                    })
                                }
                            }
                            )
    
                            devicesInThisGroup = _.uniq(devicesInThisGroup);
    
                            var numberOfDevices = devicesInThisGroup.length;
                            var title = "";
    
                            switch (numberOfDevices) {
                                case 0:
                                    title = "No devices";
                                    break;
                                case 1:
                                    title = "1 device";
                                    break;
                                default:
                                    title = numberOfDevices + " devices";
                                    break;
                            }
                            actionOrStatus = <div>{title}</div>
    
    
                            itemsInWidget.push(<div className={wrapperClassName} onClick={this.goToGroup.bind(this, itemFromDb.id)} key={_.uniqueId("device")}>
                                <div className="dashboard-item-icon"> <i style={{ color: !_.isUndefined(this.props.widget.params.style) ? this.props.widget.params.style.iconColor : "#f0f8ff85" }} className={getIconCssClassFromStyleParams(itemFromDb.style, { isBig: true })} /></div>
                                <div className="dashboard-item-name">{itemFromDb.name}</div>
                                <div className="dashboard-item-action-status">{actionOrStatus}</div>
                            </div>)
                        }
                        break;
    
                    case "CompositeDevice":
                    
                        wrapperClassName = "dashboard-composite-device pointer";
                        arrayToSearch = this.props.compositeDevices;
                        itemFromDb = _.find(arrayToSearch, { id: parseInt(item.id, 10) })
                        if (!_.isUndefined(itemFromDb)) {
    
                            let lockedIcon = <div onClick={this.lockCompositeDevice.bind(this, itemFromDb.id,  itemFromDb.lockState)} >
                    <i className={"triple-font-size fa fa-lock " + this.state.animationName}  ></i>
                </div>
                        switch (itemFromDb.type) {
                            case "shutters":
                                if (!_.isUndefined(itemFromDb)) {
                                    let sliderValue = Math.round((itemFromDb.value * 4) / 255)
                                    icon = parseInt(sliderValue, 10) === parseInt(this.state.sliderValue, 10) ? <i onClick={this.lockCompositeDevice.bind(this, itemFromDb.id,  itemFromDb.lockState)} style={{ color: !_.isUndefined(this.props.widget.params.style) ? this.props.widget.params.style.iconColor : "#f0f8ff85" }} className={getIconCssClassFromStyleParams(itemFromDb.style, { isBig: true })} />
                                        : <i style={{ color: !_.isUndefined(this.props.widget.params.style) ? this.props.widget.params.style.iconColor : "#f0f8ff85" }} onClick={this.setAnalogValueViaButton.bind(this, itemFromDb)} className="fa fa-check-circle fa-3x infinite-animation animated faster pulse"></i>
                                    if(itemFromDb.isExecuting){
                                        icon = <i style={{ color: !_.isUndefined(this.props.widget.params.style) ? this.props.widget.params.style.iconColor : "#f0f8ff85" }} className="fa fa-spinner fa-spin big-icon"/>
                                    }
                                    actionOrStatus = <input disabled={itemFromDb.lockState || itemFromDb.isExecuting} value={this.state.sliderValue} type="range" step="1" min="0"
                                        max="4" className="analog" onChange={this.changeStateOnSliderMove.bind(this)} />
                                        if(itemFromDb.lockState === true){icon = lockedIcon}
    
                                    itemsInWidget.push(<div className={wrapperClassName} key={itemFromDb.model + "" +itemFromDb.id}>
                                        <div className="dashboard-item-icon"> {icon}</div>
                                        <div className="dashboard-item-name">{itemFromDb.name}</div>
                                        <div className="dashboard-item-action-status">{actionOrStatus}</div>
                                    </div>)
    
    
                                }
                                break;
    
                            case "thermostat":
                                if (!_.isUndefined(itemFromDb)) {
                                    let sliderValue = itemFromDb.value
                                    icon = parseInt(sliderValue, 10) === parseInt(this.state.sliderValue, 10) ? <i onClick={this.lockCompositeDevice.bind(this, itemFromDb.id,  itemFromDb.lockState)} style={{ color: !_.isUndefined(this.props.widget.params.style) ? this.props.widget.params.style.iconColor : "#f0f8ff85" }} className={getIconCssClassFromStyleParams(itemFromDb.style, { isBig: true })} />
                                        : <i style={{ color: !_.isUndefined(this.props.widget.params.style) ? this.props.widget.params.style.iconColor : "#f0f8ff85" }} onClick={this.setAnalogValueViaButton.bind(this, itemFromDb)} className="fa fa-check-circle fa-3x infinite-animation animated faster pulse"></i>
                                    actionOrStatus = <div><input disabled={itemFromDb.lockState === true} value={this.state.sliderValue} type="range" step="1" min={itemFromDb.params.thermostatMin.value}
                                        max={itemFromDb.params.thermostatMax.value} className="analog" onChange={this.changeStateOnSliderMove.bind(this)} />
                                        <span>{this.state.sliderValue}</span></div>
                                        if(itemFromDb.lockState === true){icon = lockedIcon}
    
                                    itemsInWidget.push(<div className={wrapperClassName}  key={itemFromDb.model + "" +itemFromDb.id}>
                                        <div className="dashboard-item-icon"> {icon}</div>
    
                                        <div className="dashboard-item-name">{itemFromDb.name}</div>
                                        <div className="dashboard-item-action-status">{actionOrStatus}</div>
                                    </div>)
    
    
                                }
                                break;
    
                                case "linearWindow":
                                    if (!_.isUndefined(itemFromDb)) {
                                        let moistureSensorDIgitalInput = null                                    
    
                                        if (itemFromDb.params.moistureSensor.value) {
                                          moistureSensorDIgitalInput = _.find(this.props.devices, d => {
                                            return d.id === itemFromDb.params.moistureSensor.value
                                          })
                                        }
    
                                        let windowOpenAndRaining = moistureSensorDIgitalInput && moistureSensorDIgitalInput.value === 1 && itemFromDb.value === 0
                                        //  windowOpenAndRaining = true   //za testiranje
                                
                                        icon = itemFromDb.lockState === true ? lockedIcon :  <i   
                                    style={{ color: !_.isUndefined(this.props.widget.params.style) ? this.props.widget.params.style.iconColor : "#f0f8ff85" }} 
                                    className={getIconCssClassFromStyleParams(itemFromDb.style, { isBig: true }) + " pointer"} />
    
                                    if(itemFromDb.isExecuting){
                                        icon = <i style={{ color: !_.isUndefined(this.props.widget.params.style) ? this.props.widget.params.style.iconColor : "#f0f8ff85" }} className="fa fa-spinner fa-spin big-icon"/>
                                    }
                                
                                actionOrStatus = <input onChange={this.toggleWindowDeviceState.bind(this, itemFromDb)}
                                          checked={itemFromDb.value > 0} disabled={itemFromDb.isExecuting}
                                          value={itemFromDb.value > 0 ? "closed" : "open"} className="toggle-window-device" type="checkbox" />
                                
                                        itemsInWidget.push(<div className={wrapperClassName} key={itemFromDb.model + "" +itemFromDb.id}>
                                            <div className="dashboard-item-icon"> {icon} {windowOpenAndRaining
                                              ? <i style={{ fontSize: "3em" }} className="icon-cloud-rain blink"></i> : null}</div>
                                            <div className="dashboard-item-name">{itemFromDb.name}</div>
                                            <div className="dashboard-item-action-status">{actionOrStatus}</div>
                                        </div>)
        
        
                                    }
                                    break;
                            default:
                                break;
                        }
                    }
                        // arrayToSearch = this.props.compositeDevices;
                        // itemFromDb = _.find(arrayToSearch, { id: parseInt(item.id, 10) })
    
                        break;
    
                    default:
                        break;
                }
    
                widgets.push(<div key={this.props.widget.id} className="widget-main col-xs-6 col-sm-4 col-lg-4 text-center pull-left"
                    style={{ color: !_.isUndefined(this.props.widget.params.style) ? this.props.widget.params.style.fontColor : "#f0f8ff85", border: "1px solid #ffffff30", backgroundColor: !_.isUndefined(this.props.widget.params.style) ? this.props.widget.params.style.backgroundColor : "#f0f8ff00" }}>
                    {itemsInWidget}
                 {this.props.isDashboardOnly ? null : <i onClick={this.goToWidgetEdit.bind(this, this.props.widget.id)}  className="fa fa-edit pointer edit-widget-icon"></i>}   
                    <div></div>
                </div>)
    
    
    
            })

            if (this.props.widget.params.link)
                widgets.push(
                    <div key={this.props.widget.id} className="widget-main col-xs-6 col-sm-4 col-lg-4 text-center pull-left"
                        style={{ color: !_.isUndefined(this.props.widget.params.style) ? this.props.widget.params.style.fontColor : "#f0f8ff85", border: "1px solid #ffffff30", backgroundColor: !_.isUndefined(this.props.widget.params.style) ? this.props.widget.params.style.backgroundColor : "#f0f8ff00" }}>
                        {this.props.isDashboardOnly ? null : <i onClick={this.goToWidgetEdit.bind(this, this.props.widget.id)} className="fa fa-edit pointer edit-widget-icon"></i>}
                        <div style={{ paddingTop: "14px" }}><a href={this.props.widget.params.link} target="_blank" className="dashboard-item-icon white text-white dashboard-link-text"> <i className="fa fa-link fa-3x"></i></a></div>

                        <div style={{ paddingTop: "16px" }}><a href={this.props.widget.params.link} target="_blank" className=" white text-white dashboard-link-text"> {this.props.widget.params.linkText}</a></div>

                    </div>
                    
            ) 

        }
        
        return (<div>
            {widgets}
        </div>
        )
    }
}

export default branch({
    route: ["route"],
}, Widget);