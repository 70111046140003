import React, { useEffect } from 'react';
import { silentlyReloadLocationData } from '../data/actions/startupLoaders';
import { toastErrorMessage, toastInfoMessage } from '../utils/showNotification';

let windowFocusTimestamp = null

function WindowFocusListener(props) {
  // Define a function to handle window focus
  const handleWindowFocus = () => {
    // This function will be called when the window gains focus
    // console.log('Window focused');
    // console.log('windowFocusTimestamp', windowFocusTimestamp);
    
    if(Date.now() > (windowFocusTimestamp + (props.timeoutInMinutes*60*1000))){
        silentlyReloadLocationData()
        // toastInfoMessage("RELOADAO SAM")
        windowFocusTimestamp = Date.now()
    }
    // }else{
    //     let minutesPassed = ((Date.now() - windowFocusTimestamp)/1000)/60
    //     toastErrorMessage(`Ne reloadam jer nije proslo ${props.timeoutInMinutes} min od zadnjeg focusa. Remaining time to next reload: ${props.timeoutInMinutes - minutesPassed} min` )
    //     console.log('nedavno sam, necu');
    // }
  };

  useEffect(() => {
    // Add the event listener when the component mounts
    window.addEventListener('focus', handleWindowFocus);

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener('focus', handleWindowFocus);
    };
  }, []); // Empty dependency array to ensure it runs only once when mounted

  return null; // This component doesn't render any content
}

export default WindowFocusListener;
