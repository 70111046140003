import React, { Component } from 'react';
import { branch } from 'baobab-react/higher-order';
import _ from "lodash";
import actions from "../../data/actions/compositeActions";
import Thermostat from "./thermostat";
import updateForm, { loadFormData } from "../../data/actions/formStateController";
import saveFormData from "../../data/actions/formActions";
import showHeaderNotification from "../../utils/showNotification"
import WarningDialog from "../../components/warningDialog";
// import device from '../../../../slave/backend/models/device';

// import offImg from "../../assets/img/offThermostat.png";
// import onImg from "../../assets/img/onThermostat.png";



const getUrlParameterByName = (paramname, url) => {
  if (!url) url = window.location.href;
  paramname = paramname.replace(/[[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + paramname + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}


class ThermostatControl extends Component {

  constructor() {
    super()
    this.state = {
      away: false,
      targetTemperature: 22, //targeted temperature 
      ambientTemperature: 11, //current temperatura
      hvacMode: 'off',
      leaf: false,
      isThermostatOn: true,
      lockShown : false,
      isWarningShown : false,
      message:""
    };
  }

  showLockOption() {
    this.setState({
      lockShown: this.state.lockShown === true ? false : true
    })
  }


  roundToHalf(value) {
    var converted = parseFloat(value); // Make sure we have a number 
    let multiplier = 1;
    if (value < 0) {
      multiplier = -1
    }
    converted = Math.abs(converted)
    var decimal = (converted - parseInt(converted, 10));
    decimal = Math.round(decimal * 10);
    if (decimal === 5) {
      return (parseInt(converted, 10) + 0.5) * (multiplier);
    }
    if ((decimal < 3) || (decimal > 7)) {
      return Math.round(converted) * (multiplier);
    }
    else {
      return (parseInt(converted, 10) + 0.5) * (multiplier);
    }
  }

  componentDidMount() {
    var deviceFromDb = _.find(this.props.compositeDevices, { "id": parseInt(getUrlParameterByName("deviceId"), 10) })
    if(_.isUndefined(deviceFromDb)){
      return null
    }
    let sensorId = parseInt(deviceFromDb.params.temperatureSensor.value,10)
    let sensorDevice = _.find(this.props.devices, {id:sensorId})
    // let sensorTemperature = this.roundToHalf(sensorDevice.value)
    let sensorTemperature = Math.round(Number(sensorDevice.value)*10)/10
        
    // let targetTemperature = (this.roundToHalf((deviceFromDb.value / 255 * (parseInt(deviceFromDb.params.thermostatMax.value, 10) - parseInt(deviceFromDb.params.thermostatMin.value, 10))) + 
    // parseInt(deviceFromDb.params.thermostatMin.value, 10)))

    let targetTemperature = Math.round(deviceFromDb.value*10)/10

    if (deviceFromDb.value == 0 && deviceFromDb.exValue == 0) {
      loadFormData("compositedevice", parseInt(deviceFromDb.id, 10));
      var obj = {};
      obj["exValue"] =  deviceFromDb.params.thermostatMin.value;
      updateForm("compositedevice", obj);
      saveFormData("compositedevice").then(res => {
        this.setState({})  
      }) 
    }

    if (_.includes((this.props.route.location.pathname).toLowerCase(), "scene")) {
      var arrayToSearch = [];
      if (this.props.sceneParameter === "active") {
        arrayToSearch = this.props.sceneForm.params.sceneActiveDeviceState;
      }
      else {
        arrayToSearch = this.props.sceneForm.params.sceneExitDeviceState;
      }
      // targetTemperature = this.roundToHalf(((_.find(arrayToSearch, { "compositeDeviceId": parseInt(getUrlParameterByName("deviceId"), 10) })).value / 255 * (parseInt(deviceFromDb.params.thermostatMax.value, 10) -
      //   parseInt(deviceFromDb.params.thermostatMin.value, 10))) + parseInt(deviceFromDb.params.thermostatMin.value, 10))
      let sceneVal = (_.find(arrayToSearch, { "compositeDeviceId": Number(getUrlParameterByName("deviceId")) })).value 
      targetTemperature = Math.round(sceneVal*10)/10


    }


    this.setState({ targetTemperature: targetTemperature, ambientTemperature:sensorTemperature })
  }


  setThermostatValue(device) {

    // var dif = parseInt(device.params.thermostatMax.value, 10) - parseInt(device.params.thermostatMin.value, 10)

    // if (dif !== 0) {
      // let valueToSend = (255 / dif) * (this.state.targetTemperature - parseInt(device.params.thermostatMin.value, 10))
      let valueToSend = this.state.targetTemperature


      if (!_.includes((this.props.route.location.pathname).toLowerCase(), "scene")) {
        if(_.isUndefined(device.lockState) || device.lockState === false){

        actions.setAnalogOutputState(device.id, Number(Math.round(valueToSend*10)/10)).then(res => {
          this.props.onHideDialog()
        });
        }
        else {
          this.setState({
            isWarningShown:true,
            message:"Device is locked. Cannot change state at this moment"
          })
        }
      }
      else {
        let deviceId = parseInt(getUrlParameterByName("deviceId"), 10)
        var obj = { "compositeDeviceId": parseInt(getUrlParameterByName("deviceId"), 10), "value": Number(Math.round(valueToSend*10)/10) }
        var selection = this.props.sceneParameter === "active" ? _.clone(this.props.sceneForm.params.sceneActiveDeviceState) :
          _.clone(this.props.sceneForm.params.sceneExitDeviceState);

        var myObj = _.find(selection, obj => { return obj.compositeDeviceId === deviceId })
        selection[_.indexOf(selection, myObj)] = obj;
        var params = {};
        var ob = { params };
        if (this.props.sceneParameter === "active") {
          ob.params["sceneActiveDeviceState"] = selection;
          ob.params["sceneExitDeviceState"] = this.props.sceneForm.params.sceneExitDeviceState;
          ob.params["groups"] = this.props.sceneForm.params.groups;
          updateForm("scene", ob);
        }
        else {
          ob.params["sceneActiveDeviceState"] = this.props.sceneForm.params.sceneActiveDeviceState;
          ob.params["sceneExitDeviceState"] = selection;
          ob.params["groups"] = this.props.sceneForm.params.groups;
          updateForm("scene", ob);
        }
        this.props.onHideDialog()
      }

      // this.props.onHideDialog()

    // }

  }

  increase(maxValue) {
    if (parseFloat(this.state.targetTemperature) < maxValue) {
      this.setState({
        targetTemperature: this.state.targetTemperature + 0.5
      })
    }
  }
  decrease(minValue) {
    if (parseFloat(this.state.targetTemperature) > minValue) {
      this.setState({
        targetTemperature: this.state.targetTemperature - 0.5
      })
    }
  }

  turnThermostatOnOrOff() {
    this.setState({
      isThermostatOn: this.state.isThermostatOn === true ? false : true,
      away: this.state.away === true ? false : true
    })
  }

  onRangeChange(e){
    this.setState({
      targetTemperature: this.roundToHalf(e.target.value, 10)
    })
  }

  lockState(deviceId,lockState, e) {
    let currentStateValue = _.isUndefined(lockState) ? true : !lockState

    loadFormData("compositedevice", parseInt(deviceId, 10));

    var obj = {};
    obj["lockState"] = currentStateValue;
    if(lockState === true){
      obj["value"] = 20
    this.setState({ targetTemperature: 20 })

    }
    updateForm("compositedevice", obj);

        
    saveFormData("compositedevice").then(res => {
      showHeaderNotification(true, 3000, "Device state "+(currentStateValue === true ? "" : "un")+"locked")
    })
  }


  lockStateAndTurnOff(deviceId,lockState, e) {
    let currentStateValue = _.isUndefined(lockState) ? true : !lockState

    loadFormData("compositedevice", parseInt(deviceId, 10));

    var obj = {};
    obj["lockState"] = currentStateValue;
    obj["value"] = 0

    updateForm("compositedevice", obj);
        
    saveFormData("compositedevice").then(res => {
      showHeaderNotification(true, 3000, "Device state "+(currentStateValue === true ? "" : "un")+"locked")
    })
  }

  turnOn_Off(deviceId, value, exValue, e) {
    //let currentStateValue = _.isUndefined(on_off) ? true : !on_off
    if (!_.includes((this.props.route.location.pathname).toLowerCase(), "scene")) {
      loadFormData("compositedevice", parseInt(deviceId, 10));

      var obj = {};
      // obj["lockState"] = currentStateValue;
      obj["value"] = value == 0 ? exValue : 0;
      obj["exValue"] = value;
  
      updateForm("compositedevice", obj);
      saveFormData("compositedevice").then(res => {
        // showHeaderNotification(true, 3000, "Device state "+(currentStateValue === true ? "" : "un")+"locked")
      this.setState({ targetTemperature: value == 0 ? exValue : 0 })
  
      })
  
    }  else {

      const valueToSend = e.target.value === "off" ? 20 : 0
        let deviceId = parseInt(getUrlParameterByName("deviceId"), 10)
        var obj = { "compositeDeviceId": parseInt(getUrlParameterByName("deviceId"), 10), "value": Number(Math.round(valueToSend*10)/10) }
        var selection = this.props.sceneParameter === "active" ? _.clone(this.props.sceneForm.params.sceneActiveDeviceState) :
          _.clone(this.props.sceneForm.params.sceneExitDeviceState);

        var myObj = _.find(selection, obj => { return obj.compositeDeviceId === deviceId })
        selection[_.indexOf(selection, myObj)] = obj;
        var params = {};
        var ob = { params };
        if (this.props.sceneParameter === "active") {
          ob.params["sceneActiveDeviceState"] = selection;
          ob.params["sceneExitDeviceState"] = this.props.sceneForm.params.sceneExitDeviceState;
          ob.params["groups"] = this.props.sceneForm.params.groups;
          updateForm("scene", ob);
        }
        else {
          ob.params["sceneActiveDeviceState"] = this.props.sceneForm.params.sceneActiveDeviceState;
          ob.params["sceneExitDeviceState"] = selection;
          ob.params["groups"] = this.props.sceneForm.params.groups;
          updateForm("scene", ob);
        }
        // this.props.onHideDialog()
        this.setState({targetTemperature:valueToSend})
      }

  
  }


  hideWarning(){
    this.setState({
      isWarningShown:false,
      message:"",    
    })
  }
  render() {
    var deviceFromDb = _.find(this.props.compositeDevices, { "id": parseInt(getUrlParameterByName("deviceId"), 10) })
    if (_.isUndefined(deviceFromDb)) {
      return null
    }

    let isFullScreen = false;
    if( window.innerHeight === window.screen.height) {
        // browser is fullscreen
        isFullScreen = true;
    }


    let defaultShowValue = _.includes((this.props.route.location.pathname).toLowerCase(), "scene") ? parseInt(deviceFromDb.params.thermostatMin.value, 10) : deviceFromDb.exValue;
    

    return (
      <div className={isFullScreen ? "analog-output-control blinds top70" : "analog-output-control blinds"} >
          <WarningDialog message={this.state.message} hideWarning={this.hideWarning.bind(this)} isShown={this.state.isWarningShown}/>

        <div className="thermostat-middle analog-output-middle blinds animated faster zoomIn"  id="thermostat-background">
         
          <i onClick={this.props.onHideDialog}  className="fa fa-times pull-right close slider-close pointer clr-ececec" aria-hidden="true"></i>
          <i onClick={this.showLockOption.bind(this)} className={_.includes((this.props.route.location.pathname).toLowerCase(), "scene") ? "hidden" : "pointer fa fa-cogs lock-icon-style"}></i>
          <button onClick={this.lockState.bind(this, deviceFromDb.id, deviceFromDb.lockState )} 
          className={this.state.lockShown === true ? "btn btn-default lock-option animated zoomIn analog-device-lock-option" : "hidden"}>{deviceFromDb.lockState === true ? "UNLOCK STATE" : "LOCK STATE"}</button>
       
          {/* {deviceFromDb.lockState !== true ? <button onClick={this.lockStateAndTurnOff.bind(this, deviceFromDb.id, deviceFromDb.lockState)}
            className={this.state.lockShown === true ? "btn btn-default lock-option animated zoomIn analog-device-lock-option m-top-50" : "hidden"}>LOCK AND TURN OFF</button>
            : null} */}
       
          <div className="text-center">
            <Thermostat height="80%" width="80%" away={this.state.away}
              // targetTemperature={this.state.targetTemperature != 0 ? this.state.targetTemperature : deviceFromDb.exValue}
              targetTemperature={this.state.targetTemperature != 0 ? this.state.targetTemperature : defaultShowValue}
              ambientTemperature={this.state.ambientTemperature}
              hvacMode={this.state.hvacMode} leaf={this.state.leaf}
              maxValue={parseInt(deviceFromDb.params.thermostatMax.value, 10)} minValue={parseInt(deviceFromDb.params.thermostatMin.value, 10)}
            />
          </div>

          <div className="text-center text-white">
            <div className="text-center" style={{ marginTop: "-5rem", marginRight: "auto", marginLeft: "auto", zIndex: 10}}>
              <input onChange={this.turnOn_Off.bind(this, deviceFromDb.id, deviceFromDb.value, deviceFromDb.exValue)}
              checked={this.state.targetTemperature ? true : false}
              value={this.state.targetTemperature? "on" : "off"} className="toggle" type="checkbox" />
            </div> 
          </div>

          {/* <div> <i className="fa fa-power-off"></i></div> */}
            
          {(deviceFromDb.lockState === false && this.state.targetTemperature > 0 || _.includes((this.props.route.location.pathname).toLowerCase(), "scene"))  ?
          <div>
                   <div className="text-center thermometer-slider termo-input-range">
                   <input type="range" step="0.5" onChange={this.onRangeChange.bind(this)} value={this.state.targetTemperature != 0 ? this.state.targetTemperature : deviceFromDb.exValue}  min={deviceFromDb.params.thermostatMin.value} max={deviceFromDb.params.thermostatMax.value}/>
                 </div>
                 <div className="text-center margin-t10" style={{paddingBottom:"150px"}}>
                   <div 
                   onClick={this.decrease.bind(this, parseInt(deviceFromDb.params.thermostatMin.value, 10))} className="btn btn-default shutters-max-btns"><strong>-</strong></div>
                   <div  onClick={this.setThermostatValue.bind(this, deviceFromDb)} className=" btn btn-md shutters-max-btns"><strong>Apply</strong> </div>
                   <div
                   onClick={this.increase.bind(this, parseInt(deviceFromDb.params.thermostatMax.value, 10))} className="btn btn-default shutters-max-btns"><strong>+</strong></div>
                 {/* </div> </div> : <div className="text-center  text-white">DEVICE LOCKED {Number(deviceFromDb.value) === 0 ? "AND OFF" : ""}</div> } */}
                 </div> </div> : <div> </div> }
 
        </div>
      </div>

    )
  }
}

export default branch({
  // compositeDevices: ["model", "compositedevices"],
  compositeDevices: ['monkeys', 'accessibleCompositeDevices'],
  sceneForm: ['forms', 'scene'],
  route: ["route"],
  ui: ["ui", "showMainMenu"],
  devices:["model", "devices"]
}, ThermostatControl);