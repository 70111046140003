import React, { Component, Suspense } from 'react';
import PropTypes from 'prop-types';
//import _ from "lodash"
//import AceEditor from 'react-ace';
import '../../assets/css/code-editor.css';

//import 'brace/mode/javascript';
//import 'brace/theme/tomorrow';

React.lazy(()=>import ("ace-builds/src-noconflict/mode-java"))
React.lazy(()=>import ("ace-builds/src-noconflict/theme-tomorrow"))
const AceEditor = React.lazy(() => import('react-ace'))

class CodeInput extends Component {
	onChangeHandler(e){
		if(this.props.onValueChanged){
			this.props.onValueChanged(e, this.props.inputName); //propagate updated value
		}
		
	}
	render(){
		return (<div className="">
				<label className="control-label col-sm-2">{this.props.label}</label>
				<div className="col-sm-10">

				<div className="small-code-editor">
				<Suspense fallback={"Loading component ...."}>
					<AceEditor
				    	onChange={this.onChangeHandler.bind(this)}
						mode="javascript"
						theme="tomorrow"
						showPrintMargin={false}
				 		name="codeEditor"
						editorProps={{$blockScrolling: true}}
						highlightActiveLine={true}
						value={this.props.value}
						//annotations={lintAnotitations}
						setOptions={{
							showLineNumbers: true,
							tabSize: 2,
						}}
				  		/>
				</Suspense>
				</div>

				</div>	
				<div className=" clear"></div>
			</div>);
	}
}
CodeInput.propTypes = {
	value: PropTypes.string.isRequired,
	onValueChanged: PropTypes.func.isRequired,
	label: PropTypes.string.isRequired,
	inputName: PropTypes.string.isRequired
}

export default CodeInput;