import React, { Component } from 'react';
import { branch } from 'baobab-react/higher-order';

class WarningDialog extends Component {
    hideDialog(){
        this.props.hideWarning()
    }
    render() {
        // if(this.props.sessionErrors.length === 0){
        //     return null
        // }
        return (<div style={{zIndex:11111}} className={this.props.isShown === true ? "delete-dialog " : "hidden"}>
        <div className="dialog-center-error no-padding animated faster jackInTheBox">
            <div  className="dialog-content notification text-center">
              <div>
              <i className="fa fa-exclamation-triangle fa-3x text-warning"></i>
                  </div>  

                <div className="text-center notification-message">
                    <span style={{color:"black"}}>{this.props.message}.</span>
                </div>
                <div onClick={this.hideDialog.bind(this)} className="btn btn-warning notification-btn">
                        Dismiss
                </div>
            </div>
        </div>
    </div>
        )
    }
}

export default branch({
    route: ["route"],
    // showChangeStateFailInfo: ["ui", "showChangeStateFailInfo"],
    // notificationMessage: ["ui", "headerNotificationMessage"],
    // isNotificationSuccessfull: ["ui", "isSuccessfullNotification"],
	// sessionErrors: ["sessionErrors"]
}, WarningDialog);