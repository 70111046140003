import React, { Component } from 'react';
import { branch } from 'baobab-react/higher-order';
import uiActions from "../../data/actions/ui";
import _ from "lodash";


class ShowError extends Component {
    constructor() {
        super();
        this.state = {
            openErrorDetails: []
        }
    }
    messageRead() {
        uiActions.deleteLastError();
        this.setState({
            openErrorDetails: []
        })
    }
    showMore(id) {
        var niz = _.clone(this.state.openErrorDetails);
        if (_.includes(niz, id)) {
            niz = _.filter(niz, num => {
                return num !== id
            })
        }
        else niz.push(id);
        this.setState({
            openErrorDetails: niz
        })
    }
    render() {
        let errorToDisplay = [];
        let type = ""
        if (!_.isEmpty(this.props.sessionErrors)) {
            for (let i = 0; i < this.props.sessionErrors.length; i++) {
                type = "error"
                if (this.props.sessionErrors[i] instanceof Error) {
                    errorToDisplay.push(<div key={_.uniqueId("err")}>
                        <p>{!_.isUndefined(this.props.sessionErrors[i].message) ? this.props.sessionErrors[i].message : "error"}
                            <button className="btn btn-info btn-sm" onClick={this.showMore.bind(this, i)}>{_.includes(this.state.openErrorDetails, i) === true ? "Hide Details" : "Show Details"}</button>
                        </p>
                        <div className={_.includes(this.state.openErrorDetails, i) === true ? "error-details" : "hidden"}>{(this.props.sessionErrors[i]).details ? (this.props.sessionErrors[i]).details :
                            (this.props.sessionErrors[i]).toString()}
                        </div>
                    </div>)
                }
                else {
                    if (!_.isUndefined(this.props.sessionErrors[i].type)) {
                        
                        type = this.props.sessionErrors[i].type;
                        if (type === ""){
                            type = "error"
                        }

                        errorToDisplay.push(
                            <div key={_.uniqueId("err")}>
                                <p>{!_.isUndefined(this.props.sessionErrors[i].message) ? this.props.sessionErrors[i].message : "error"}
                                    <button className="btn btn-info btn-sm" onClick={this.showMore.bind(this, i)}>{_.includes(this.state.openErrorDetails, i) === true ? "Hide Details" : "Show Details"}</button>
                                </p>
                                <div className={_.includes(this.state.openErrorDetails, i) === true ? "error-details" : "hidden"}>{(this.props.sessionErrors[i]).details ? (this.props.sessionErrors[i]).details :
                                    (this.props.sessionErrors[i]).toString()}
                                </div>
                            </div>
                        )
                    }
                }
            }
        }

        return (
            <div  className={this.props.sessionErrors.length === 0 || this.props.showChangeStateFailInfo === true ? "hidden" : "delete-dialog"}>
                <div style={{zIndex:111}} className="dialog-center-error animated faster zoomIn">
                    <div style={{zIndex:111}} className={"dialog-header dialog-header-" + type}><span>Info</span>
                        <i onClick={this.messageRead.bind(this)} className="fa fa-times pull-right pointer" aria-hidden="true"></i>
                    </div>
                    <div className="dialog-message-content text-center">
                        <div className="error-text-wrapper" >{errorToDisplay}</div>
                    </div>
                    <div className="text-center dialog-button-wrapper">
                        <button onClick={this.messageRead.bind(this)} className="btn btn-info btn-sm err-btn">ok</button>
                    </div>
                </div>

            </div>
        )
    }
}

export default branch({
    route: ["route"],
    sessionErrors: ["sessionErrors"],
    ui: ["ui", "showDeleteDialog"],
    showChangeStateFailInfo: ["ui", "showChangeStateFailInfo"],
}, ShowError);