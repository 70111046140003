import baobabTree from '../state';
import rest from "../restWrapper"
import _ from "lodash"
import uiActions from "./ui"

const login = (email, password) => {
	return new Promise((resolve, reject) => {
		rest("/login", "POST", {email:email, password:password}).then(res=>{
			if(res.code===1){
				baobabTree.root.select(["isAuthenticated"]).set(true);
				return resolve(true);
			} else {
				baobabTree.root.select(["isAuthenticated"]).set(false);
				return resolve(false);
			}
		}).catch(err=>{
			baobabTree.root.select(["isAuthenticated"]).set(false);
			uiActions.pushErrorToSessionErrors(err)
			return resolve(false);
			//return reject(err);
		});
		
	});
}

const checkCurrentSessionAuthentication = ()=>{
	return new Promise((resolve, reject) => {
		rest("/sessionInfo", "GET", null).then(res=>{
			if(res.isSessionAuthenticated){
				baobabTree.root.select(["isAuthenticated"]).set(true);
				//console.log("------ user profile",res.data.userProfile);
				return resolve(!_.isUndefined(res.data.userProfile)); //this should be true

			} else {
				baobabTree.root.select(["isAuthenticated"]).set(false);
				return resolve(false);
			}
		}).catch(err=>{
			baobabTree.root.select(["ui","loadingScreenMessage"]).set(err.message);
			//baobabTree.root.select(["isAuthenticated"]).set(false);
			uiActions.pushErrorToSessionErrors(err)
			return resolve(false);
			//return reject(err);
		});

	});
}


const fetchSessionInfo = async ()=>{
	return new Promise((resolve, reject) => {
		rest("/sessionInfo", "GET", null).then(res=>{
			return resolve(res.data);
		}).catch(err=>{
			baobabTree.root.select(["ui","loadingScreenMessage"]).set(err.message);
			uiActions.pushErrorToSessionErrors(err)
			return resolve(-1);
		});
	});
}

const tokenDashboardLogin = async (apiKey)=>{
	let result = await rest("/apiKeyLogin", "POST", {apiKey, tokenBearer:"autoLogin"})
	let {pimatico_rest_id = null} = result.data
	baobabTree.root.select("apiKeyLoginToken").set(pimatico_rest_id);
	return true; //or it will fail
}

const tokenAutoLogin = async (apiKey)=>{
	let result = await rest("/autoLogin", "POST", {apiKey, tokenBearer:"autoLogin"})
	let {pimatico_rest_id = null} = result.data
	baobabTree.root.select("apiKeyLoginToken").set(pimatico_rest_id);
	return true; //or it will fail
}

export {login, checkCurrentSessionAuthentication, fetchSessionInfo, tokenDashboardLogin, tokenAutoLogin}



