import io from "socket.io-client"
import EventEmitter from 'events'
import baobabTree from "../../state"
import _ from "lodash"
import loggger from "../../sys/windowLogger"
import {toastErrorMessage} from "../../../utils/showNotification"
// tu
class masterSocketClientClass extends EventEmitter {
	constructor(socketNamespaceAndParams){
		super();
		this.reconectionAttempt = 0;
		this.onReconnectDebouncer = null;

		let keycloakAuth = baobabTree.root.select("keycloak").get();
		const {realm_access, resource_access, email, email_verified} = keycloakAuth.tokenParsed ? keycloakAuth.tokenParsed : {}
		let userData = btoa(JSON.stringify({realm_access, resource_access, email, email_verified}))

		let socketLink = `${socketNamespaceAndParams}/slaves?type=browser&userData=${userData}`

		this.socket = io(socketLink,{
			'reconnection': true,
			'reconnectionDelay': 1000,
			'reconnectionDelayMax' : 5000,
			'reconnectionAttempts': 2000,
			//transports: ['websocket', 'polling'] //treba napraviti da radi sa websoketom, sada radi samo sa poolingom po defaultu
		});

		this.socket.on("browserJoined2SlaveRoom", joinedSlaveUuid=>{
			console.log('Browser successefuly joined', Date.now());
			this.slaveUuid = joinedSlaveUuid; //remember to make proper requests
			loggger("Browser successefuly joined to a slave room", joinedSlaveUuid);
			if(this.callback){
				this.callback();
			}
		})

		this.socket.on("userRoomJoined", userEmail=>{
			//console.log("userRoomJoined", userEmail);
		})

		this.socket.on("abortConnection", message=>{
			loggger("Remote connection atempt failed", message);
			console.warn(message)
			this.emit("abortConnection", message)
			baobabTree.root.select(["ui","showLoadingScreen"]).set(false);

			toastErrorMessage(message)
			if(this.callback){
				this.callback();
			}
              setTimeout(()=>{
                window.location = "/"
              },3000)

			//loadingScreenMessage:["ui","loadingScreenMessage"], clear this
		})

		this.socket.on("connect", ()=>{
			if(this.slaveUuid){
				this.joinSlaveRoom(this.slaveUuid);
			}
		})

		this.joinUserRoom = ()=>{
			if(baobabTree.root.select("sessionInfo","userProfile","email").get()){ //required to recive user speccific data from server via sockets (session info or connection statuses...)
				this.socket.emit("joinUserRoom", baobabTree.root.select("sessionInfo","userProfile","email").get());
				loggger("joinUserRoom where email=", baobabTree.root.select("sessionInfo","userProfile","email").get());
			}
		}

		this.joinSlaveRoom = (slaveUuid, callback)=>{
			loggger("joinSlaveRoom", slaveUuid);
			//first join USER room for getting all of the MASTER related notifications

			this.joinUserRoom();
			//then join socket room to get all of the slave informations

			if(slaveUuid){
				if(this.socket.connected){
					console.log('LEAVING');
					
					this.socket.emit("leaveSlaveRoom", this.slaveUuid); //it should be already handled on server, but anyway
					this.callback = callback;
					console.log('JOININsG');

					this.socket.emit("joinSlaveRoom", slaveUuid); //the real stuff....
				} else {
					console.log('TU JER NISAM USAO');
					
					setTimeout(()=>{ //try again
						this.joinSlaveRoom(slaveUuid);
					},1000)
				}
			}
		}
	}

	get Socket(){
		return this.socket;
	}


}


class masterSocketClientClassWithRequestMessaging extends masterSocketClientClass{
	constructor(socketNamespaceAndParams, slaveUuid){
		super(socketNamespaceAndParams, slaveUuid);
		this.activeRequests = {};
		this.responseTimeoutSeconds = 10;
		this.socket.on("restRequestEmulationResponse", response=>{
			this.unwrapIfResponseOrIgnore(response);
			this.detectIncomingRequestOrIgnore(response);
		})

		this.socket.on("restRequestEmulationErrorResponse", errorResponse=>{
			this.closeRequestWithAnErrorResponse(errorResponse);
		})
	}

	cancelAllPendingRequests(){
		loggger("canceling socket requests");
		_.forEach(this.activeRequests, pendingRequest=>{
			clearTimeout(pendingRequest.timeout);
			if(pendingRequest.resolve){
				pendingRequest.resolve(null);
			}
		})
		this.activeRequests = {};
	}

	emitJSON(jsonMessage){
		this.socket.emit("restRequestEmulationRequest", jsonMessage, this.slaveUuid);
	}

	detectIncomingRequestOrIgnore(JSONObject){
		//ako ima requestId i ako taj id ne postoji u aktivnim requestovima, onda je to dolazni zahtjev. (Što ako je broadcast u pitanju?)
		if(JSONObject._requestId !== undefined && this.activeRequests[JSONObject._requestId] === undefined){ //then it is an incomming request
			this.activeRequests[JSONObject._requestId] = {
				timeout: setTimeout(()=>{
					delete this.activeRequests[JSONObject._requestId];
				}, this.responseTimeoutSeconds*1000) //request should be closed, if not, then clear the memmory
			}
			this.emit("requestReceived", JSONObject._requestId, JSONObject); //key and object with request explanation
		}
	}

	closeIncommingRequest(incommingRequestId, responseJSONObject){
		if(this.activeRequests[incommingRequestId]){
			clearTimeout(this.activeRequests[incommingRequestId].timeout); //release timeouts
			if(typeof(responseJSONObject) === "object"){
				responseJSONObject._requestId = incommingRequestId;
				this.emitJSON(responseJSONObject);
			}
		} else {
			throw new Error("Trying to close a non existing request.");
		}
	}


	wrapOutgoingRequest(JSONObject, resolve, reject, waitForResponseSeconds = this.responseTimeoutSeconds){ //this method would wrap
		JSONObject._requestId = "key"+Math.floor((Math.random() * 1000000000) + 1)+Date.now();
		this.activeRequests[JSONObject._requestId] = {
			direction:"out",
			resolve: resolve,
			reject:reject,
			timeout: setTimeout(()=>{ //memmory cleanup if response not received
			//	console.log('jsonobject', JSON.stringify(JSONObject));
				let err = new Error("Message response timeout");
				err.details = JSON.stringify(JSONObject);
				this.activeRequests[JSONObject._requestId].reject(err);
				delete this.activeRequests[JSONObject._requestId];
			},waitForResponseSeconds*1000)
		}
	}

	unwrapIfResponseOrIgnore(responseJSONObject){
		//ako ima responseId i ako se nalazi u aktivnim requestovima, onda je to odgovor na već postavljeni zahtjev
		if(responseJSONObject._requestId){
			if(this.activeRequests[responseJSONObject._requestId]){ //unwrap it
				let reqId = responseJSONObject._requestId;
				delete responseJSONObject._requestId;
				this.activeRequests[reqId].resolve(responseJSONObject);
				clearTimeout(this.activeRequests[reqId].timeout);
				delete this.activeRequests[reqId]; //free memmory alocation
			}
		} //othervise just ignore
	}

	closeRequestWithAnErrorResponse(responseJSONObject){
		if(responseJSONObject._requestId){
			if(this.activeRequests[responseJSONObject._requestId]){ //unwrap it
				let reqId = responseJSONObject._requestId;
				delete responseJSONObject._requestId;
				var err = new Error(responseJSONObject.err.details);
				err.details = responseJSONObject.err.url;
				this.activeRequests[reqId].reject(err);
				clearTimeout(this.activeRequests[reqId].timeout);
				delete this.activeRequests[reqId]; //free memmory alocation
			}
		} //othervise just ignore
	}

	sendRequest(message, waitForResponseSeconds){
		return new Promise((resolve, reject)=>{
			this.wrapOutgoingRequest(message, resolve, reject, waitForResponseSeconds);
			this.emitJSON(message); //promise would be handled asyhronicaly
		});
	}
}

export {masterSocketClientClassWithRequestMessaging};
export default masterSocketClientClassWithRequestMessaging

