import Baobab from "baobab";
import monkeys from "./monkey"
import formDefaults from "../dataTemplates/formDefaults";
import userLevelMonkeys from "./userLevelMonkeys"
import { reservations, roomInfo } from "../dataTemplates/roomDummy";
//import _ from "lodash";

var AppType = {
  native:"native",
  web:"web"
}

var initialStateObjectDescription = {
    model: {
      devices: [],
      compositedevices:[],
      groups: [],      
      scenes:[],
      devicegroups:[],
      eventsubscriptions:[], 
      scheduletemplates:[],
      widgets:[],
      genericstorages:[],
      featureaccessibilitys: [],
       //-------------------AVAILABLE ONLY BY LAZY LOAD ON EACH RELEVANT COMPONENT---------------------------------
      modbusconfigs:[], //available only if enabled in config
      powermanagements:[], //available only if enabled on slave (powemanagemenet)
      surveillances:[], //available only if enabled on slave (if surveillance )
      surveillancecameras:[], //available only if enabled on slave (if surveillance ) - OVAJ TREBAM LOADATI SISTEMSKI
      persons:[], //available only if enabled on slave (access controll)
      accessrules:[], //available only if enabled on slave (access controll)
      accesscontrolpoints:[], //available only if enabled on slave (access controll)
    },
    smartRoom : {
      reservations : reservations, 
      roomInfo : roomInfo
    },
    // compositedevices:[{"id":112,"name":"Roletna1","groupId":1,"type":0,"deviceIdList":[101,102],"style":{"icon":{}},"params":{}}, 
    // {"id":113,"name":"Roletna2","groupId":1,"type":0,"deviceIdList":[103,104],"style":{"icon":{}},"params":{}}],
    readOnlyModel: {
      deviceAddressParams: {},
      settings:{},
      console:[],
      evalApiHelp:[],
      apiEventsHelp:[], //it is an object.... beware...
      addOns:[]
     // sessionInfo: null //don't place it here..... 
    },
    masterOnlyModel:{
      mySlaves:[], //it holds all info about locations(slaves) with their online status
    },
    pushNotificationsMasterPublicKey:"",
    sessionInfo:null,
    monkeys: {
      flattenDeviceAddressParams : monkeys["flattenDeviceAddressParams"],
      uiSelectedModel: monkeys["defaultedUISelectedObjects"],
      devices: monkeys["devicesByType"],
      selfContainedDevices:monkeys["selfContainedDevices"],
      eventSubscriptions:monkeys["eventSubscriptionsByType"],
      groupedESScheduleTemplates : monkeys["groupedESScheduleTemplates"],
      selectedSlaveCodeVersion: monkeys["selectedSlaveCodeVersion"],
      selectedSlaveRunningSystemEnvironment:monkeys["selectedSlaveRunningSystemEnvironment"],
      roomsFilteredBySearchTerm:monkeys["roomsFilteredBySearchTerm"],
      devicesFilteredBySearchTerm : monkeys["getSearchedDevices"],
      compositeDevicesFilteredBySearchTerm : monkeys["getSearchedCompositeDevices"],
      hardwareModulesInfo: monkeys["hardwareModulesInfo"],

      accessibleDevices:monkeys["accessibleDevices"],
      accessibleCompositeDevices:monkeys["accessibleCompositeDevices"],
      accessibleGroups:monkeys["accessibleGroups"],
      accessibleScenes:monkeys["accessibleScenes"],
      accessibleWidgets:monkeys["accessibleWidgets"],


     // usersWithSlaves : monkeys.usersWithSlaves
    },
    searchedReservation:"",
    searchedDevice:"",
    unfinishedScenes:[],
    ui:{
      //routeSelection:{},
      actualNullableCodeVersion:null,
      slaveConnectionTier:0, //0 is hhtp, 1 socket relay, 2 p2p over wrtc 
      activeWrtcPeerKey: null, //slaveCommManager
      connectionStateMessageObject : {
        message : "",
        reconnectingEnded:false
      },
      headerTitle:monkeys["getCurrentRouteTitle"], //ovdje ide monkey
      loadingScreenMessage:"Establishing server connection",//"Establishing a secure p2p connection to location 'unnamed'",
    //  forceHideLoadingScreen: false,
      showLoadingScreen:true,
//      displayLogoutButtonOnLoadingScreen: false,
     // showNoLocationsScreen:false, //if freshly registered user has no slaves assigned to him
      //allDataLoaded:false,
      updateInProgress:false,
      headerNotificationMessage:[],
      showHeaderNotification:false,
      isSuccessfullNotification:true,
      showMainMenu: false,
      showScrollbar: false,
      mainMenuAnimation: "right",
      selectedSlaveOnline:true,//monkeys["selectedSlaveConnectionStatus"], 
      selectedSlaveOnlineForceHide: false,
      locationConnected: false,
      showIconSelect: false,
      brandName: "Pimatico CC",
      selected:{ //when router is not sufficient
      //  mainMenuItem:{},
        group:null,
        device:null,
        scene:null
      },
      selectedSettingsTabIndex:0,
      popup:null, //use for notifications and confirmations
      poorConnectionWarning: {
        level: "ok", // excelent, ok, low, poor, terrible
        fullScreen:false,
        footerNotification:false
      },
      showChangeStateFailInfo : false,
      showSystemMessagesModal: false,
      modalDialog : {isShown:false, type:"", sceneParameter:false}
    },
    userLevelMonkeys:userLevelMonkeys,
    forms:{
      device:formDefaults.device,
      compositedevice:formDefaults.compositedevice,
      group:formDefaults.group,
      devicegroup: formDefaults.devicegroup,
      scene:formDefaults.scene,
      eventsubscription:formDefaults.eventsubscription,
      widget:formDefaults.widget,
      genericstorage:formDefaults.genericstorage,
      modbusconfig:formDefaults.modbusconfig,
      powermanagement:formDefaults.powermanagement,
      surveillance: formDefaults.surveillance,
      surveillancecamera: formDefaults.surveillancecamera,
      accesscontrolpoint: formDefaults.accesscontrolpoint,
      person: formDefaults.person,
      accessrule : formDefaults.accessrule,
      featureaccessibility:formDefaults.featureaccessibility,

    },
    route: {},
    sessionErrors:[], //push Error or {type=error/warning/info/success, message, details}, 
    systemMessages:{warningOrInfo:[],alertOrError:[],critical:[]},
    deviceValueLogsCache:[],
    apiConfig:{
      selectedSlaveUuid:null,//placeholder for slaveUuid which is required for socket communication and api requests over master.... 
      url:"" //by default for WEB client use is empty because the same server serves REST API, but native app needs to know exact location of the server to function correctly
    },
    /*
    environment:{
      onConnectAndReconnectHandlerDebounced:null
    },*/
    doorBellsActive: [],
    isAuthenticated:false,
    appEnvironment: AppType.web,
    addOnStates:{
      /*
      smartHotelBilling:{
        componentToMount:"AllRooms",
        selectedPriceListId:null,
        selectedPriceListItemId:null,
        selectedRoomId:null,
        selectedReservationId:null,
        roomDataChanged: false,
        reservationDataChanged: false,
        priceListDataChanged: false
      },
      */
      parkingBillingClient:{
        componentToMount:"TicketHandling",
        selectedDay:+new Date()
      }
    },
    mainCameraStreamSnapshot:null,
    keycloak:{
      authenticated: false
    },
    apiKeyLoginToken: null //alternate login for hotels etc...
  };

var cachedStateObject = null;

try{
  cachedStateObject = window.localStorage.baobab !== undefined ?
  JSON.parse(window.localStorage.baobab) : initialStateObjectDescription;  
} catch(err){
  console.warn(err)
}


try {
  initialStateObjectDescription.apiConfig = cachedStateObject.apiConfig;
  initialStateObjectDescription.ui.showScrollbar = cachedStateObject.ui.showScrollbar;

} catch (error) {
  console.log('no data in baobab');
}

/*
initialStateObjectDescription.sessionInfo = cachedStateObject.sessionInfo;
initialStateObjectDescription.masterOnlyModel = cachedStateObject.masterOnlyModel;
initialStateObjectDescription.model = cachedStateObject.model;
initialStateObjectDescription.readOnlyModel = cachedStateObject.readOnlyModel;
*/

//ovo makni kada završitš sa dev-om
//initialStateObjectDescription.masterOnlyModel = cachedStateObject.masterOnlyModel;
//initialStateObjectDescription.model = cachedStateObject.model;
//initialStateObjectDescription.readOnlyModel = cachedStateObject.readOnlyModel;

//var stateObject = initialStateObjectDescription

//////DYNAMIC values must be outside of cached ones
/*
stateObject.monkeys = initialStateObjectDescription.monkeys;
stateObject.ui = initialStateObjectDescription.ui
stateObject.environment = initialStateObjectDescription.environment;
stateObject.isAuthenticated = initialStateObjectDescription.isAuthenticated;
//stateObject.sessionInfo = null; //reset session info
*/

var tree = new Baobab(initialStateObjectDescription, {immutable: true});

const saveCachedBaobabData = (baobabTree)=>{
  if (typeof(Storage) !== "undefined") {
    if(baobabTree._data){
      window.localStorage.baobab = JSON.stringify(baobabTree._data);
    } else {
      window.localStorage.baobab = JSON.stringify(baobabTree);
    }
  } else {
    //console.log("Sorry no WEB storage support.");
      // Sorry! No Web Storage support..
  }
}

const clearCachedDataOnLogOut = ()=>{
  try{
   delete window.localStorage.baobab;// = undefined;
  } catch(err){

  }
}

const clearUserAndLocationCachedData = ()=>{
  tree.root.select().set(initialStateObjectDescription);
  tree.root.select(["sessionInfo"]).set(null);
  tree.root.select(["apiConfig"]).set({
    selectedSlaveUuid:null,
    url:"" 
  });
}

const clearDataBeforeLocationChange = ()=>{
  tree.root.select(["model"]).set(initialStateObjectDescription.model);
  tree.root.select(["smartRoom"]).set(initialStateObjectDescription.smartRoom);
  tree.root.select(["readOnlyModel"]).set(initialStateObjectDescription.readOnlyModel);
  tree.root.select(["sessionErrors"]).set([]);
}

window.baobab = tree;


//saveCachedBaobabData(initialStateObjectDescription);
  
export default tree;
export {AppType, saveCachedBaobabData, clearCachedDataOnLogOut, clearUserAndLocationCachedData, clearDataBeforeLocationChange}


