import React from 'react';
import actions from '../../data/actions/actions';
import { getIconCssClassFromStyleParams } from '../../utils/fontIcons';
import _ from 'lodash';
import baobabTree from '../../data/state';
import { hashHistory } from 'react-router';
import logOut from '../../data/actions/logout.js';

import checkRoleAccess from '../../utils/checkRoleAccess';

const getUrlParameterByName = (paramname, url) => {
    if (!url) url = window.location.href;
    paramname = paramname.replace(/[[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + paramname + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

const handleSceneStopAndPlay = (scene) => {
    if (!_.isUndefined(scene.state)) {
        switch (scene.state) {
            default:
            case 0: // //0 - inactive
                actions.playScene(scene.id);
                break;
            case 1: //1 - active
                actions.stopScene(scene.id);
                break;
            case 2: //2 - activating,
            case 3: //3 - deactivating,
                //ignore
                break;
            case 4: //4 - overrided
                actions.playScene(scene.id);
                break;
        }
    } else {
        actions.playScene(scene.id); //backward model compatibility
    }
};

const onMenuItemClickHandler = (item, e) => {};

const getMainMenuItems = (userLevelMonkeys, addOns) => {
    let menuItems = [];

    let isMaster = baobabTree.root.select('sessionInfo', 'isMaster').get();
    let locationOnline = baobabTree.root.select(['ui', 'locationConnected']).get();

    let autoLogin = getUrlParameterByName('autoLogin');

    if (isMaster === undefined) {
        isMaster = false;
    }

    if (locationOnline) {
        if (userLevelMonkeys.showDashboard === true) {
            menuItems.push({
                pageTitle: 'Dashboard',
                label: 'DASHBOARD',
                icon: 'fa fa-desktop',
                href: '#/',
                onClick: null,
            });
        }

        if (userLevelMonkeys.showDevices === true) {
            menuItems.push(
                <span key={'devices'}>
                    Devices
                    {checkRoleAccess('admin', 'systemIntegrator', 'advancedUser') ? (
                        <i
                            onClick={() => {
                                hashHistory.push('/deviceManagement');
                            }}
                            className="fa fa-cogs pull-right pointer"
                        ></i>
                    ) : null}
                </span>
            );

            menuItems.push({
                pageTitle: 'All devices',
                label: 'All Devices',
                icon: 'fa fa-list',
                href: '#/allDevices',
                onClick: null,
            });

            if (checkRoleAccess('admin', 'camera')) {
                if (
                    baobabTree.root.select('readOnlyModel', 'settings', 'streaming', 'enableStreaming', 'value').get()
                ) {
                    menuItems.push({
                        pageTitle: 'Surveillance',
                        label: 'Surveillance',
                        icon: 'fa fa-video-camera',
                        href: '#/surveillance',
                        onClick: null,
                    });
                }
            }
        }

        if (userLevelMonkeys.showGroups === true) {
            const groups = baobabTree.root.select('monkeys', 'accessibleGroups').get();
            let showGroupsHeader = true;

            //special case... use has no groups and it has no ability to add new groups -  so don't display header
            showGroupsHeader = checkRoleAccess('user') && groups.length === 0 ? false : true;

            if(checkRoleAccess('admin', 'systemIntegrator', 'advancedUser')){
                showGroupsHeader = true
            }
            if (showGroupsHeader) {
                menuItems.push(
                    <span key={'groupmanagement'}>
                        Groups
                        {checkRoleAccess('admin', 'systemIntegrator', 'advancedUser') ? (
                            <i
                                onClick={() => {
                                    hashHistory.push('/groupManagement');
                                }}
                                className="fa fa-cogs pull-right pointer"
                            ></i>
                        ) : null}
                    </span>
                ); //------- groups ....
            }

            _.forEach(groups, (group) => {
                if (group.type !== 2) {
                    menuItems.push({
                        label: group.name,
                        pageTitle: group.name,
                        icon: getIconCssClassFromStyleParams(group.style),
                        href: '#/group/' + group.id,
                        onClick: null,
                    });
                }
            });
        }

        if (userLevelMonkeys.showScenes === true) {
            const scenes = baobabTree.root.select('monkeys', 'accessibleScenes').get();
            let showScenesHeader = true;
             //special case... use has no groups and it has no ability to add new groups - so don't display header
            showScenesHeader = checkRoleAccess('user') && scenes.length === 0 ? false : true;

            if(checkRoleAccess('admin', 'systemIntegrator', 'advancedUser')){
                showScenesHeader = true
            }

            if (showScenesHeader) {
                menuItems.push(
                    <span key={'scenes'}>
                        Scenes
                        {checkRoleAccess('admin', 'systemIntegrator', 'advancedUser') ? (
                            <i
                                onClick={() => {
                                    hashHistory.push('/sceneManagement');
                                }}
                                className="fa fa-cogs pull-right pointer"
                            ></i>
                        ) : null}
                    </span>
                );
            }

            
            _.forEach(scenes, (scene) => {
                let statusIcon = '';

                if (!_.isUndefined(scene.state)) {
                    switch (scene.state) {
                        default:
                        case 0: // //0 - inactive
                            statusIcon = 'fa fa-play';
                            break;
                        case 1: //1 - active
                            statusIcon = 'fa fa-eject';
                            break;
                        case 2: //2 - activating,
                            statusIcon = 'fa fa-spinner fa-spin';
                            break;
                        case 3: //3 - deactivating,
                            statusIcon = 'fa fa-spinner fa-spin';
                            break;
                        case 4: //4 - overrided
                            statusIcon = 'fa fa-step-forward';
                            break;
                    }
                } else {
                    statusIcon = 'fa fa-play';
                }

                let mainIcon =
                    !_.isUndefined(scene.lockState) && scene.lockState
                        ? 'fa fa-lock'
                        : getIconCssClassFromStyleParams(scene.style);

                menuItems.push({
                    pageTitle: scene.name,
                    label: scene.name,
                    icon: mainIcon, //getIconCssClassFromStyleParams(scene.style),
                    href: '#/scene/' + scene.id,
                    rightComponent: (
                        <i
                            onClick={handleSceneStopAndPlay.bind(this, scene)}
                            className={statusIcon + ' pull-right pointer'}
                            aria-hidden="true"
                        ></i>
                    ),
                    onClick: null,
                });
            });
        }


        ///END FOR REGULLAR USERS

        // let userProfile = baobabTree.root.select("sessionInfo", "userProfile").get();

        if (checkRoleAccess('admin', 'systemIntegrator')) {
            menuItems.push(<span key={'locationOpt'}>Location options</span>);

            menuItems.push({
                pageTitle: 'System config',
                label: 'System config',
                icon: 'fa fa-cogs',
                href: '#/advanced',
                onClick: null,
            });
        }

        if (checkRoleAccess('coder')) {
            menuItems.push({
                pageTitle: 'Code editor',
                label: 'Code editor',
                icon: 'fa fa-code',
                href: '#/letsCode',
                onClick: null,
            });
        }

        if (checkRoleAccess('terminal')) {
            menuItems.push({
                pageTitle: 'Terminal access',
                label: 'Terminal',
                icon: 'fa fa-terminal ',
                href: '#/terminal',
                onClick: null,
            });
        }

        if (checkRoleAccess('admin', 'systemIntegrator')) {
            menuItems.push({
                pageTitle: 'Activity logs',
                label: 'Activity logs',
                icon: 'fa fa-file-text-o ',
                href: '#/activityLogs',
                onClick: null,
            });
        }


        //END LOCATION SPECCIFIC ROUTES
    } //FROM locationOnline

    if (isMaster && checkRoleAccess('admin', 'systemIntegrator')) {
        menuItems.push(<span key={'advancedopt'}>Administrative options</span>);

        menuItems.push({
            pageTitle: 'My users',
            label: 'My users',
            icon: 'fa fa-users ',
            href: '#/administration/myUsers',
            onClick: null,
        });

        if (checkRoleAccess('admin')) {
            menuItems.push({
                pageTitle: 'System integrators',
                label: 'System integrators',
                icon: 'fa fa-users ',
                href: '#/administration/systemIntegrators',
                onClick: null,
            });

            menuItems.push({
                pageTitle: 'All locations',
                label: 'All locations',
                icon: 'fa fa-map-marker ',
                href: '#/administration/allLocations',
                onClick: null,
            });

            menuItems.push({
                pageTitle: 'Unassigned locations',
                label: 'Unassigned locations',
                icon: 'fa fa-map-marker ',
                href: '#/administration/unassignedLocations',
                onClick: null,
            });

            menuItems.push({
                pageTitle: 'Administration',
                label: 'Administration',
                icon: 'fa fa-user-secret ',
                href: '#/administration/management',
                onClick: null,
            });
            menuItems.push({
                pageTitle: 'Audit logs',
                label: 'Audit logs',
                icon: 'fa fa-file-text-o',
                href: '#/auditLogs',
                onClick: null,
            });
            ///administration/unassignedLocations
        }
    }

    if (autoLogin) {
        //offline case....

        menuItems.push({
            label: 'Cloud register',
            icon: 'fa fa-sign-out',
            href: '#/',
            onClick: (e) => {
                e.preventDefault();
                baobabTree.root.select('apiKeyLoginToken').set(null);
                logOut();
                window.location = '/index.html';
            },
        });
    } else {
        menuItems.push({
            label: 'Logout',
            icon: 'fa fa-sign-out',
            href: '#/logout',
            onClick: logOut,
        });
    }

    _.forEach(menuItems, (item) => {
        if (item.onClick === null) {
            //ako ima onClick handler, pregazi ga
            item.onClick = onMenuItemClickHandler.bind(this, item);
        }
    });
    return menuItems;
};

export default getMainMenuItems;