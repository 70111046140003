import React, { Component } from 'react';
import { branch } from 'baobab-react/higher-order';
import actions from "../../data/actions/ui";

class ChangeStateFailInfo extends Component {
    hideDialog(){
        actions.hideChangeStateFailInfoDialog();
        actions.deleteLastError();
    }
    render() {
        if(this.props.sessionErrors.length === 0){
            return null
        }
        return (<div className={this.props.showChangeStateFailInfo === true ? "delete-dialog " : "hidden"}>
        <div className="dialog-center-error no-padding animated faster jackInTheBox">
            <div className="notification dialog-content text-center">
              <div>
              <i className="fa fa-exclamation-triangle fa-3x text-warning"></i>
                  </div>  

                <div className="text-center notification-message">
                    <span>{this.props.sessionErrors[this.props.sessionErrors.length - 1].message}. Device state cannot be changed at this moment.</span>
                </div>
                <div onClick={this.hideDialog} className="btn btn-warning notification-btn">
                        Dismiss
                </div>
            </div>
        </div>
    </div>
        )
    }
}

export default branch({
    route: ["route"],
    showChangeStateFailInfo: ["ui", "showChangeStateFailInfo"],
    notificationMessage: ["ui", "headerNotificationMessage"],
    isNotificationSuccessfull: ["ui", "isSuccessfullNotification"],
	sessionErrors: ["sessionErrors"]
}, ChangeStateFailInfo);