import React, { Component } from 'react';
import { branch } from 'baobab-react/higher-order';
import uiActions from "../../data/actions/ui";
import actions from "../../data/actions/actions";
import compositeActions from "../../data/actions/compositeActions"
import _ from "lodash";

class SceneWarning extends Component {
    constructor() {
        super();
        this.debounceTimestamp = Date.now();
        this.state = {
        }
    }

    setDeviceValue(device, targetValue, e) {
        switch (device.type) {
            case 0: //digital output
                this.debounce(() => {
                    actions.toggleSwitch(device.id, targetValue === 0 ? 1 : 0);
                })
                break;
            case 1: //analog output
                this.debounce(() => {
                    actions.setAnalogOutputState(device.id, targetValue)
                })
                break;
            case 2:
                if (device.address.pushButton) {
                    if (device.address.pushButton === true) {
                        this.debounce(() => {
                            actions.toggleSwitch(device.id, 1); //must be 1 to emulate push event...
                        })
                    }
                }
                break;
            case 3:
            case 4:
            default:
                if (device.address.plcDeviceType === "volumeControll") {
                    this.debounce(() => {
                        if (device.value > 0) {
                            actions.setVolumeState(device.id, 0, "c");
                        } else {
                            actions.setVolumeState(device.id, 255, "c");
                        }
                    })
                } else {
                    console.log("No action allowed", device);
                }
                break;
        }
    }

    setCompositeDeviceValue(device, targetValue, ) {
        compositeActions.setAnalogOutputState(device.id, targetValue)
    }

    debounce(fn) {
        if (Date.now() - this.debounceTimestamp > 300) {
            fn();
        } //else is debounced....
        this.debounceTimestamp = Date.now();
    }

    setDeviceOrCompositeDeviceValue(deviceType, device, newValue) {
        switch (deviceType) {
            case "device":
                this.setDeviceValue(device, newValue)
                break;


            case "compositeDevice":
                this.setCompositeDeviceValue(device, newValue)
                break;

            default:
                break;
        }
    }


    hideDialog() {
        this.props.hideWarning()
    }
    render() {
        if (_.isUndefined(this.props.unfinishedScenes.length) || this.props.unfinishedScenes.length === 0) {
            return null
        }
        // if(this.props.sessionErrors.length === 0){
        //     return null
        // }
        let listOfUnsuccessfullDevices = []

        _.forEach(this.props.unfinishedScenes[this.props.unfinishedScenes.length - 1].failed, (device) => {
            let deviceType = ""
            if (!_.isUndefined(device.targetDeviceState.deviceId)) {
                deviceType = "device"
            } else if (!_.isUndefined(device.targetDeviceState.compositeDeviceId)) {
                deviceType = "compositeDevice"
            }

            let deviceFromDb = _.find(deviceType === "device" ? this.props.devices : this.props.compositeDevices, { id: device.targetDeviceState.deviceId })

            if (!_.isUndefined(deviceFromDb)) {
                listOfUnsuccessfullDevices.push(<div style={{ border: "1px solid lightgray", borderRadius: "8px" }}
                    key={device.targetDeviceState.deviceId}>{deviceFromDb.name}
                    <div className="btn btn-sm" onClick={this.setDeviceOrCompositeDeviceValue.bind(this, deviceType, deviceFromDb, device.targetDeviceState.value)} >retry</div></div>)

            }
        })

        return (<div
            className={this.props.isShown === true ? "delete-dialog " : "delete-dialog"}>
            <div style={{ zIndex: 1, height: "400px" }} className="dialog-center-error no-padding animated faster jackInTheBox">
                <div style={{ zIndex: 1, }} className="dialog-content notification text-center">
                    <div>
                        <i className="fa fa-exclamation-triangle fa-3x text-warning"></i>
                    </div>

                    <div className="text-center padding-10 notification-message">
                        <span style={{ color: "black" }}>Following scenes didn't execute completely: </span>
                        <strong>"{(this.props.unfinishedScenes[this.props.unfinishedScenes.length - 1]).executedScene.name}"</strong>
                    </div>
                    <div>
                        Devices that failed to change state:
                    <div style={{ maxHeight: "120px", overflowY: "scroll" }}>
                            {listOfUnsuccessfullDevices}

                        </div>

                    </div>
                    <div onClick={() => { uiActions.removeUnfinishedSceneFromState() }} className="btn btn-warning notification-btn">
                        Dismiss
                </div>
                </div>
            </div>
        </div>
        )
    }
}

export default branch({
    route: ["route"],
    unfinishedScenes: ["unfinishedScenes"],
    devices: ["model", "devices"],
    compositeDevices: ["model", "compositedevices"]

    // showChangeStateFailInfo: ["ui", "showChangeStateFailInfo"],
    // notificationMessage: ["ui", "headerNotificationMessage"],
    // isNotificationSuccessfull: ["ui", "isSuccessfullNotification"],
    // sessionErrors: ["sessionErrors"]
}, SceneWarning);