import React, { Component } from 'react';
import { branch } from 'baobab-react/higher-order';
import _ from "lodash";
import CompositeDevice from './compositeDevice';
import uiActions from "../../data/actions/ui";

import Device from "./templates"
// var modalDialogs = {
// analog: "analog",
// blinds: "blinwwds",
// thermostat: "thermostat",
// volume: "volume"
// }

class DeviceItems extends Component {
  constructor() {
    super();

    this.state = {
      showDialog: null
    };

  }
  showModalDialog(device, controlType) {   
    
    switch(controlType){
      case "analogOutput":
        uiActions.setModalDialog({ type: "analog", sceneParameter: this.props.sceneParameter })
      break;
      case "powermeter":
        uiActions.setModalDialog({ type: "powerManagement", sceneParameter: this.props.sceneParameter })
      break;
      case "volume":
        uiActions.setModalDialog({ type: "volume", sceneParameter: this.props.sceneParameter })
      break;
      case "singleThermostat":
      //console.log(controlType);
        uiActions.setModalDialog({ type: "singleThermostat", sceneParameter: this.props.sceneParameter })
      break;
      default:break;
    }
    var currentRoute = this.props.route.location.pathname;
    window.location = "#" + currentRoute + "?deviceId=" + device.id
    // this.setState(stateObject);
  }

  showCompositeModalDialog(device, controlType) {   
    switch (device.type) {
      case "shutters":
        uiActions.setModalDialog({ type: "shutters", sceneParameter: this.props.sceneParameter })
        break;
      case "thermostat":
      case "softThermostat":
      case 'underfloorHeating':
      case 'softIR':
        uiActions.setModalDialog({ type: "thermostat", sceneParameter: this.props.sceneParameter })
        break;
      default:
      break;
    }
    if(device.id){
      var currentRoute = this.props.route.location.pathname;
      window.location = "#" + currentRoute + "?deviceId=" + device.id
    }
    // this.setState(stateObject);
  }

  render() {

    let isDashboardOnly = this.props.dashboardOnlyRoutes && this.props.dashboardOnlyRoutes !== null && this.props.dashboardOnlyRoutes.toString() === "true"

    let tempCompositeDevices = [];
    let clonedCompositeDevices = _.cloneDeep(this.props.compositeDevices)
    _.forEach(clonedCompositeDevices, compDevice => {
      compDevice.isComposite = true
      tempCompositeDevices.push(compDevice)
    })

    let tempAllDevicesArray = tempCompositeDevices.concat(this.props.devices)

    tempAllDevicesArray = _.orderBy(tempAllDevicesArray, [device => device.name.toLowerCase()], ["asc"])


    let devices = _.map(tempAllDevicesArray, d => {
      if (_.isUndefined(d.isComposite) || d.isComposite === false) {
        let isThisDevicePushButton = false;

        if (!_.isUndefined(d)) {
          if (!_.isUndefined(d.address)) {
            if (!_.isUndefined(d.address.pushButton)) {
              if (d.address.pushButton === true) {
                isThisDevicePushButton = true
              }
            }
          }
        }

        let pom = []
        let numberOfEvents = 0
        _.forEach(this.props.eventSubscriptions, event => {
          if (event.eventRules.model === "Device" && event.eventRules.modelId === d.id && (isThisDevicePushButton === false ? parseInt(event.type, 10) !== 6 : parseInt(event.type, 10) === 6)) {
            if (!_.isUndefined(_.find(this.props.eventSubscriptionsByType.onScheduleTemplate.events, event))) {
              if (!_.includes(pom, event.eventRules.params.scheduleTemplate.id)) {
                pom.push(event.eventRules.params.scheduleTemplate.id)
                numberOfEvents += 1;
              }
            }
            else {
              numberOfEvents += 1;
            }
          }
        })



        let linkedEventsIncludingThisDevice = []

        _.forEach(this.props.events, event => {
          if (event.type === 5) {
            _.forEach(event.eventRules.params.ifThis, ifThisRule => {
              if (ifThisRule.model.toLowerCase() === "device" && parseInt(ifThisRule.modelId, 10) === parseInt(d.id, 10)) {
                linkedEventsIncludingThisDevice.push(event)
              }
            })
          }
        })

        return <Device
          sceneParameter={this.props.sceneParameter}
          sceneForm={this.props.sceneForm}
          route={this.props.route}
          showModalDialog={this.showModalDialog.bind(this)}
          groupId={this.props.route.params.groupId}
          device={d}
          key={"device" + d.id}
          numberOfEvents={numberOfEvents + parseInt(linkedEventsIncludingThisDevice.length, 10)}
          isDashboardOnly = {isDashboardOnly}
          />
      }
      else {
        let numberOfEvents = 0
        let pom = []
        _.forEach(this.props.eventSubscriptions, event => {
          if (event.eventRules.model === "CompositeDevice" && event.eventRules.modelId === d.id) {
            if (!_.isUndefined(_.find(this.props.eventSubscriptionsByType.onScheduleTemplate.events, event))) {
              if (!_.includes(pom, event.eventRules.params.scheduleTemplate.id)) {
                pom.push(event.eventRules.params.scheduleTemplate.id)
                numberOfEvents += 1;
              }
            }
            else {
              numberOfEvents += 1;
            }
          }
        })

        let linkedEventsIncludingThisDevice = []

        _.forEach(this.props.events, event => {
          if (event.type === 5) {
            _.forEach(event.eventRules.params.ifThis, ifThisRule => {
              if (ifThisRule.model.toLowerCase() === "compositedevice" && parseInt(ifThisRule.modelId, 10) === parseInt(d.id, 10)) {
                linkedEventsIncludingThisDevice.push(event)
              }
            })
          }
        })
        return <CompositeDevice
          sceneParameter={this.props.sceneParameter}
          sceneForm={this.props.sceneForm}
          route={this.props.route}
          showModalDialog={this.showCompositeModalDialog.bind(this)}
          groupId={this.props.route.params.groupId}
          device={d}
          key={"compositeDevice" + d.id}
          numberOfEvents={numberOfEvents + parseInt(linkedEventsIncludingThisDevice.length, 10)} 
          isDashboardOnly = {isDashboardOnly}
          />
      }
    })

    return (
      <div className="no-margin">
        <div className="row no-margin transparent-border">
          {devices}
        </div>
      </div>

    )
  }
}



export default branch({
  // devicesBaobab: ["model", "devices"],
  events: ["model", "eventsubscriptions"],
  route: ["route"],
  scenes: ["model", "scenes"],
  sceneForm: ['forms', 'scene'],
  deviceForm: ['forms', 'device'],
  eventSubscriptions: ["model", "eventsubscriptions"],
  eventSubscriptionsByType: ["monkeys", "eventSubscriptions"],
  dashboardOnlyRoutes:["dashboardOnlyRoutes"]
}, DeviceItems);