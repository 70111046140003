const DeviceForm = {
    id: null,
    name: "",
    type: -1,
    value: 0,
    isLocked: false,
    lockState:false,
    interruptMode: 1,
    connectionState: 0,
    address: {
        bus: "gpio",
        pin: ""
    },
    startupValue: 0,
    params: null,
    style: {
        icon: {}
    },
}

const GroupForm = {
    id: null,
    name: "Default Group",
    style: {
        icon: {}
    },
    isEnabled: true
}

const DeviceGroupForm = {
    id: null,
    GroupId: null,
    DeviceId: null
}

const SceneForm = {
    id: null,
    name: "Default Scene",
   // isActive: false,
    state:0,
    lockState:false,
    interruptMode: 1,
    style: {
        icon: {}
    },
    params: {
        sceneActiveDeviceState: [],
        sceneExitDeviceState: [],
        groups:[]
    }


}

const ScheduleTemplate = {
    id: null,
    name: null,
    description: null,
    params: {
        startDate: null,
        startHour: null,
        startMinute: null,
        startSecond: null,
        durationInDays: 1,
        endHour: null,
        endMinute: null,
        endSecond: null,
        recurring: 0, //0 - no recurring, 1 - everyDay, 2- everyWeek, 3-every month, 4- every year
        expiresOn: null //null= no expirateion timestamp
    }
}


const EventSubscription = {
    id: null,
    isExecuting: false,
    interruptMode:1,
    type:0,// 0 - onScheduleTemplateHit, 1-onDeviceStateChanges, 2-onBoot, 3-onAPICall,
    eventRules: {
        model: null,
        modelId: null,
        params: {
            scheduleTemplate:{
                startDate: null,
                startHour: null,
                startMinute: null,
                startSecond: null,
                durationInDays: 0,
                endHour: null,
                endMinute: null,
                endSecond: null,
                recurring: 2, //0 - no recurring, 1 - everyDay, 2- everyWeek, 3-every month, 4- every year
                expiresOn: null //null= no expirateion timestamp
            },
            toggleStateParams:{}           
        }
    }
}

const CompositeDeviceForm = {
    id: null,
    name: "",
    type:null,
    interruptMode:1,
    outputType:null,
    lockState:false,
    style:{
        icon: {}
    },
    params:{},
    isExecuting:false
}

// eventRules:{
//     params:{
//         ifThis:[
//             /**
//                 rule:"equals", //equals, greaterThan, loverThan, greaterOrEqual, loverOrEqual
//                 stateValue:255 //or 0 or 1 if digital output
//                 model: "",
//                 modelId:""
//              */

//         ],
//         thenThat:[
//             /*
//             model:"Device", // Device, Scene, ScriptBlock(disabled)
// 		modelId:12,
//         targetState:0,
//          */
//         ]
//     }
// }


/*
{"eventRules":{"params":{
	ifThis:{ //for every device type except pushButton subtype
		rule:"equals", //equals, greaterThan, loverThan, greaterOrEqual, loverOrEqual
		stateValue:255 //or 0 or 1 if digital output
	},
	thenThat:{
		model:"Device", // Device, Scene, ScriptBlock(disabled)
		modelId:12,
		targetState:0, //0 - off, 1- on, and all the way up to 255 for analog devices...
	},
	evalCode:""

},"modelId":14,"model":"Device"},"type":1,"id":2,"isExecuting":false}
*/

const WidgetForm = {
    id: null,
    title: "",
    GroupId:null,
    ParentGroupId:null,
   // isActive: false,
    type:1, // 0 for menu, 1 for "widget", 2 for "multiItems"
    params: {
    }


}

const GenericStorageForm = {
    id: null,
    key:"",
    params: {
    }


}

const 	modbusConfigForm = {
    id: null,
    group: "",
    deviceName: "",
    modbusId: 0,
    driverPath: "",
    portConfigId: 0,
    // linkedDeviceIDs: []
}

const powerManagementForm = {
    id: null,
    description: "",
    fromDateIncluding: new Date(),
    toDateExcluding: new Date(),
    price: 0,
    currency: "EUR/kWh",
    exceptions: {},
    exceptionsPrice: 0,
    isEnabled: true,
  }

const SurveillanceForm = {
    id: null,
    name:"Surveillance device",
    type:"dvr", //ipCamera/dvr
    brand:"",
    model: "",
    firmwareVersion:"",
    ipAddress:"",
    apiPort:"85",
    username:"",
    password:"",
    params:{}
}

const surveillanceCameraForm = {
    id:null,
    name:"",
    params:{
        streamScale: "320x240"
    },
    surveilanceCameraPosition:0,
    surveillanceId:0
}


const PersonForm = {
    id:null,
    name:"",
    surname:"",
    code:null,
    pin:null,
    desc:{},
    params:{},
    type:0, //default type (used for overrides)
    role:0 //default role (used for permissions)
}


const AccessControlPointForm = {
    id: null,
    GroupId: null,
    name:"",
    address: "local", //local is used for Gateway
    onAcceptOutputs:[],
    onRejectOutputs:[],
    outputHoldingTime: 2000, //relays holding time
    isEnabled: true
}

const AccessRuleForm = {
    id: null,
    AccessControlPointId: null,
    acceptPersonIds:[],
    rejectPersonIds:[],
    GenericStorageId: null,

}

const FeatureAccessibility = {
    id: null,
    email:"",
    params:{}
}


export default { device: DeviceForm, compositedevice:CompositeDeviceForm, group: GroupForm, devicegroup: DeviceGroupForm, 
    scene: SceneForm, scheduletemplate: ScheduleTemplate, eventsubscription: EventSubscription, 
    widget:WidgetForm, genericstorage:GenericStorageForm, modbusconfig:modbusConfigForm, 
    powermanagement:powerManagementForm, surveillance: SurveillanceForm, surveillancecamera:surveillanceCameraForm,
    person:PersonForm, accesscontrolpoint:AccessControlPointForm, accessrule : AccessRuleForm, featureaccessibility:FeatureAccessibility };



