import { unregister } from '../../registerServiceWorker';
import { logout } from '../../keycloak';

const logOut = (e) => {
    if (e) {
        e.preventDefault();
    }
    unregister(); //disable service worker on logout
    logout();
};

export default logOut;