import _ from "lodash"
const groupIcons = [
	{name:"bath", font:"font-awesome"},
	{name:"snowflake-o", font:"font-awesome"},
	{name:"shower", font:"font-awesome"},
	{name:"university", font:"font-awesome"},
	{name:"coffee", font:"font-awesome"},
	{name:"car", font:"font-awesome"},
	{name:"bed", font:"font-awesome"},
	{name:"sun-o", font:"font-awesome"},
	{name:"television", font:"font-awesome"},
	{name:"thermometer-quarter", font:"font-awesome"},
	{name:"thermometer-three-quarters", font:"font-awesome"},
	{name:"video-camera", font:"font-awesome"},
	{name:"wifi", font:"font-awesome"},
	{name:"film", font:"font-awesome"},
	{name:"plane", font:"font-awesome"},
	{name:"plug", font:"font-awesome"},
	{name:"laptop", font:"font-awesome"},
	{name:"picture-o", font:"font-awesome"},
	{name:"microphone", font:"font-awesome"},
	{name:"microphone-slash", font:"font-awesome"},
	{name:"beer", font:"font-awesome"},
	{name:"bell-slash-o", font:"font-awesome"},
	{name:"building-o", font:"font-awesome"},
	{name:"camera", font:"font-awesome"},
	{name:"fire", font:"font-awesome"},
	{name:"futbol-o", font:"font-awesome"},
	{name:"deaf", font:"font-awesome"},
	{name:"umbrella", font:"font-awesome"},
	{name:"volume-off", font:"font-awesome"},
	{name:"power-off", font:"font-awesome"},
	{name:"camera2", font:"icomoon"},
	{name:"film", font:"icomoon"},


];

const deviceIcons = [
	{name:"lightbulb-o", font:"font-awesome"},
	{name:"thermometer-full", font:"font-awesome"},
	{name:"snowflake-o", font:"font-awesome"},
	{name:"shower", font:"font-awesome"},
	{name:"coffee", font:"font-awesome"},
	{name:"car", font:"font-awesome"},
	{name:"gamepad", font:"font-awesome"},
	{name:"bed", font:"font-awesome"},
	{name:"mobile", font:"font-awesome"},
	{name:"television", font:"font-awesome"},
	{name:"thermometer-quarter", font:"font-awesome"},
	{name:"thermometer-three-quarters", font:"font-awesome"},
	{name:"video-camera", font:"font-awesome"},
	{name:"wifi", font:"font-awesome"},
	{name:"film", font:"font-awesome"},
	{name:"plane", font:"font-awesome"},
	{name:"plug", font:"font-awesome"},
	{name:"laptop", font:"font-awesome"},
	{name:"picture-o", font:"font-awesome"},
	{name:"microphone", font:"font-awesome"},
	{name:"microphone-slash", font:"font-awesome"},
	{name:"beer", font:"font-awesome"},
	{name:"bell-slash-o", font:"font-awesome"},
	{name:"building-o", font:"font-awesome"},
	{name:"camera", font:"font-awesome"},
	{name:"fire", font:"font-awesome"},
	{name:"futbol-o", font:"font-awesome"},
	{name:"deaf", font:"font-awesome"},
	{name:"umbrella", font:"font-awesome"},
	{name:"volume-off", font:"font-awesome"},
	{name:"power-off", font:"font-awesome"},
	{name:"microphone2", font:"icomoon"},
	{name:"sun-o", font:"font-awesome"},



];

const sceneICons = [
	{name:"futbol-o", font:"font-awesome"},
	{name:"snowflake-o", font:"font-awesome"},
	{name:"shower", font:"font-awesome"},
	{name:"university", font:"font-awesome"},
	{name:"coffee", font:"font-awesome"},
	{name:"car", font:"font-awesome"},
	{name:"bolt", font:"font-awesome"},
	{name:"bed", font:"font-awesome"},
	{name:"sun-o", font:"font-awesome"},
	{name:"television", font:"font-awesome"},
	{name:"thermometer-quarter", font:"font-awesome"},
	{name:"thermometer-three-quarters", font:"font-awesome"},
	{name:"video-camera", font:"font-awesome"},
	{name:"wifi", font:"font-awesome"},
	{name:"film", font:"font-awesome"},
	{name:"plane", font:"font-awesome"},
	{name:"plug", font:"font-awesome"},
	{name:"laptop", font:"font-awesome"},
	{name:"picture-o", font:"font-awesome"},
	{name:"microphone", font:"font-awesome"},
	{name:"microphone-slash", font:"font-awesome"},
	{name:"frown-o", font:"font-awesome"},
	{name:"beer", font:"font-awesome"},
	{name:"bell-slash-o", font:"font-awesome"},
	{name:"building-o", font:"font-awesome"},
	{name:"camera", font:"font-awesome"},
	{name:"fire", font:"font-awesome"},
	{name:"futbol-o", font:"font-awesome"},
	{name:"deaf", font:"font-awesome"},
	{name:"heart", font:"font-awesome"},
	{name:"umbrella", font:"font-awesome"},
	{name:"volume-off", font:"font-awesome"},
	{name:"power-off", font:"font-awesome"},



];


let icomoonIconNames = [
    "icon-Bathtub", 
  "icon-BedLamp", 
  "icon-Bedroom", 
  "icon-Bell", 
  "icon-Beverage", 
  "icon-Building", 
  "icon-ChristmasTree", 
  "icon-DeskLamp", 
  "icon-Dining", 
  "icon-Door1", 
  "icon-Door2", 
  "icon-Electric", 
  "icon-Fridge", 
  "icon-Gamepad", 
  "icon-Garage", 
  "icon-Home", 
  "icon-Home2", 
  "icon-if_Streamline-18_185038", 
  "icon-if_Streamline-75_185095", 
  "icon-Kitchen", 
  "icon-LightBulb", 
  "icon-Locked", 
  "icon-Parking", 
  "icon-Parking2", 
  "icon-Parking3", 
  "icon-Phone", 
  "icon-Plant", 
  "icon-Pool", 
  "icon-Remote", 
  "icon-Remote2", 
  "icon-Settings", 
  "icon-Shower", 
  "icon-Shower2", 
  "icon-Sofa1", 
  "icon-Sofa21", 
  "icon-Speakers", 
  "icon-Sprinkler3", 
  "icon-Surveillance", 
  "icon-Tag", 
  "icon-Tag2", 
  "icon-Unlocked", 
  "icon-WashingMachine", 
  "icon-Window", 
  "icon-Workplace", 
  "icon-Sofa2", 
  "icon-link", 
  "icon-tablet", 
  "icon-window", 
  "icon-monitor", 
  "icon-ipod", 
  "icon-tv", 
  "icon-camera", 
  "icon-camera2", 
  "icon-film", 
  "icon-film2", 
  "icon-microphone", 
  "icon-microphone2", 
  "icon-drink", 
  "icon-drink2", 
  "icon-coffee", 
  "icon-mug", 
  "icon-android", 
  "icon-chronometer", 
  "icon-headphones", 
  "icon-folder", 
  "icon-gamepad", 
  "icon-alarm", 
  "icon-alarm-cancel", 
  "icon-phone", 
  "icon-phone2", 
  "icon-open", 
  "icon-trashcan", 
  "icon-eightball", 
  "icon-soccer", 
  "icon-3dglasses", 
  "icon-microwave", 
  "icon-mouse", 
  "icon-smiley", 
  "icon-sad", 
  "icon-hand", 
  "icon-radio", 
  "icon-key", 
  "icon-cord", 
  "icon-locked", 
  "icon-unlocked", 
  "icon-locked2", 
  "icon-unlocked2", 
  "icon-lamp", 
  "icon-lamp2", 
  "icon-umbrella", 
  "icon-streetlight", 
  "icon-megaphone", 
  "icon-megaphone2", 
  "icon-thermometer", 
  "icon-lamp3", 
  "icon-lamp4", 
  "icon-lamp5", 
  "icon-list", 
  "icon-list2", 
  "icon-layout", 
  "icon-layout2", 
  "icon-layout3", 
  "icon-layout4", 
  "icon-tools",
  "icon-screwdriver",
  "icon-paint",
  "icon-volume",
  "icon-volume2",
  "icon-volume3",
  "icon-equalizer",
  "icon-calculator", 
  "icon-library",
  "icon-auction",
  "icon-justice",
  "icon-hourglass", 
  "icon-pencil",
  "icon-scissors",
  "icon-reply",
  "icon-forward",
  "icon-retweet",
  "icon-shuffle",
  "icon-envelope",
  "icon-music",
  "icon-presentation",
  "icon-piano",
  "icon-rain",
  "icon-snow",
  "icon-lightning", 
  "icon-cloudy",
  "icon-cloudy2",
  "icon-car",
  "icon-bike",
  "icon-truck", 
  "icon-bus",
  "icon-bike2",
  "icon-plane",
  "icon-rocket",
  "icon-expand",
  "icon-collapse",
  "icon-badge",
  "icon-microphone3",
  "icon-blocked", 
  "icon-stop1",
  "icon-keyboard",
  "icon-radio2",
  "icon-printer",
  "icon-shipping",
  "icon-yingyang", 
  "icon-magnet", 
  "icon-usb",
  "icon-clipboard", 
  "icon-switch",
  "icon-laptop",
  "icon-desktop",
  "icon-tablet2",
  "icon-phone3",
  "icon-video",
  "icon-camera3", 
  "icon-key2",
  "icon-lightbulb",
  "icon-mic",
  "icon-happy",
  "icon-sad2",
  "icon-lock",
  "icon-lock-open",
  "icon-lock-open2",
  "icon-lock-stripes",
  "icon-lock-rounded",
  "icon-lock-rounded-open",
  "icon-lock-rounded-open2", 
  "icon-combination-lock",
  "icon-soccer-ball",
  "icon-soccer-court",
  "icon-weights",
  "icon-basketball-hoop", 
  "icon-cloud",
  "icon-cloud-sun",
  "icon-cloud-moon",
  "icon-cloud-rain",
  "icon-cloud-sun-rain", 
  "icon-cloud-snow",
  "icon-cloud-sun-snow",
  "icon-cloud-lightning",
  "icon-cloud-wind",
  "icon-cloud-raindrops",
  "icon-cloud-snowflakes",
  "icon-sun",
  "icon-umbrella2",
  "icon-sunglasses",
  "icon-thermometer2",
  "icon-thermometer-low",
  "icon-thermometer-quarter",
  "icon-thermometer-half",
  "icon-sound",
  "icon-key3",
  "icon-lock2",
  "icon-display", 
  "icon-clock",
  "icon-music2",
  "icon-megaphone3",
  "icon-food",
  "icon-fire",
  "icon-shop",
  "icon-account_balance",
  "icon-goat",
  "icon-headset",
  "icon-headset_mic",
  "icon-local_hotel",
  "icon-notifications",
  "icon-notifications_active", 
  "icon-notifications_none",
  "icon-notifications_off",
  "icon-notifications_paused",
  "icon-personal_video",
  "icon-phone_android",
  "icon-washMachine2", 
  "icon-microvawe4", 
  "icon-blender",
  "icon-sprinkle",
  "icon-coolingFan",
  "icon-iron",
  "icon-hairdryer",
  "icon-vacuumCleaner", 
  "icon-fridge2",
  "icon-fridge1",
  "icon-coffeeMachine", 
  "icon-soundSystem",
  "icon-microwave1", 
  "icon-cooker2",
  "icon-cooker",
  "icon-livingRoomFurniture3",
  "icon-livingRoomFurniture",
  "icon-airConditioner2",
  "icon-kitchen",
  "icon-workingRoom",
  "icon-bed2",
  "icon-bed",
  "icon-sos",
  "icon-door",
  "icon-window1",
  "icon-curtain",
  "icon-opened-window-door-of-glasses",
  "icon-open-exit-door",
  "icon-concierge-bell-solid",
  "icon-play2",
  "icon-pause",
  "icon-stop",
  "icon-play3",
  "icon-pause2", 
  "icon-stop2",
  "icon-backward2",
  "icon-forward3", 
  "icon-first", 
  "icon-last",
  "icon-previous2", 
  "icon-next2",
  "icon-eject",
  "icon-volume-high",
  "icon-volume-medium",
  "icon-volume-low",
  "icon-volume-mute",
  "icon-volume-mute2",
  "icon-volume-increase",
  "icon-volume-decrease",
    "icon-link",
    "icon-tablet",
   "icon-window",
    "icon-monitor",
    "icon-ipod",
    "icon-tv",
    "icon-camera",
    "icon-camera2",
    "icon-film",
    "icon-film2",
    "icon-microphone",
    "icon-microphone2",
    "icon-drink",
    "icon-drink2",
    "icon-coffee",
    "icon-mug",
    "icon-android",
    "icon-chronometer",
    "icon-headphones",
    "icon-folder",
    "icon-gamepad",
    "icon-alarm",
    "icon-alarm-cancel",
    "icon-phone",
    "icon-phone2",
    "icon-open",
    "icon-trashcan",
    "icon-eightball",
    "icon-soccer",
    "icon-3dglasses",
    "icon-microwave",
    "icon-mouse",
    "icon-smiley",
    "icon-sad",
    "icon-hand",
    "icon-radio",
    "icon-key",
    "icon-cord",
    "icon-locked",
    "icon-unlocked",
    "icon-locked2",
    "icon-unlocked2",
    "icon-lamp",
    "icon-lamp2",
    "icon-umbrella",
    "icon-streetlight",
    "icon-megaphone",
    "icon-megaphone2",
    "icon-thermometer",
    "icon-lamp3",
    "icon-lamp4",
    "icon-lamp5",
    "icon-list",
    "icon-list2",
    "icon-layout",
    "icon-layout2",
    "icon-layout3",
    "icon-layout4",
    "icon-tools",
    "icon-screwdriver",
    "icon-paint",
    "icon-volume",
    "icon-volume2",
    "icon-volume3",
    "icon-equalizer",
    "icon-calculator",
    "icon-library",
    "icon-auction",
    "icon-justice",
    "icon-hourglass",
    "icon-pencil",
    "icon-scissors",
    "icon-reply",
    "icon-forward",
    "icon-retweet",
    "icon-shuffle",
    "icon-envelope",
    "icon-music",
    "icon-presentation",
    "icon-piano",
    "icon-rain",
    "icon-snow",
    "icon-lightning",
    "icon-cloudy",
    "icon-cloudy2",
    "icon-car",
    "icon-bike",
    "icon-truck",
    "icon-bus",
    "icon-bike2",
    "icon-plane",
    "icon-rocket",
    "icon-expand",
    "icon-collapse",
    "icon-badge",
    "icon-microphone3",
    "icon-blocked",
    "icon-stop",
    "icon-keyboard",
    "icon-radio2",
    "icon-printer",
    "icon-shipping",
    "icon-yingyang",
    "icon-magnet",
    "icon-usb",
    "icon-clipboard",
    "icon-switch",
    // "icon-laptop",
    // "icon-desktop",
    // "icon-tablet2",
    // "icon-phone3",
    // "icon-video",
    // "icon-camera3",
    // "icon-key2",
    // "icon-lightbulb",
    // "icon-mic",
    // "icon-happy",
    // "icon-sad2",
    // "icon-lock",
    // "icon-lock-open",
    // "icon-lock-open2",
    // "icon-lock-stripes",
    // "icon-lock-rounded",
    // "icon-lock-rounded-open",
    // "icon-lock-rounded-open2",
    // "icon-combination-lock",
    // "icon-soccer-ball",
    // "icon-soccer-court",
    // "icon-weights",
    // "icon-basketball-hoop",
    // "icon-cloud",
    // "icon-cloud-sun",
    // "icon-cloud-moon",
    // "icon-cloud-rain",
    // "icon-cloud-sun-rain",
    // "icon-cloud-snow",
    // "icon-cloud-sun-snow",
    // "icon-cloud-lightning",
    // "icon-cloud-wind",
    // "icon-cloud-raindrops",
    // "icon-cloud-snowflakes",
    // "icon-sun",
    // "icon-umbrella2",
    // "icon-sunglasses",
    "icon-thermometer2",
    "icon-thermometer-low",
    "icon-thermometer-quarter",
    "icon-thermometer-half",
    "icon-sound",
    "icon-key3",
    "icon-lock2",
    "icon-display",
    "icon-clock",
    "icon-music2",
    "icon-megaphone3",
    "icon-food",
    "icon-fire",
    "icon-shop",
    "icon-account_balance",
    "icon-goat",
    "icon-headset",
    "icon-headset_mic",
    "icon-local_hotel",
    "icon-notifications",
    "icon-notifications_active",
    "icon-notifications_none",
    "icon-notifications_off",
    "icon-notifications_paused",
    "icon-personal_video",
    "icon-phone_android",
    "icon-play2",
    "icon-pause",
    "icon-play3",
    "icon-pause2",
    "icon-stop2",
    "icon-backward2",
    "icon-forward3",
    "icon-first",
    "icon-last",
    "icon-previous2",
    "icon-next2",
    "icon-eject",
    "icon-volume-high",
    "icon-volume-medium",
    "icon-volume-low",
    "icon-volume-mute",
    "icon-volume-mute2",
    "icon-volume-increase",
    "icon-volume-decrease",

]

_.forEach(icomoonIconNames, ikona=> {
    let icnName = ikona.substring(ikona.indexOf("-")+1)    
deviceIcons.push({name:icnName, font:"icomoon"})
sceneICons.push({name:icnName, font:"icomoon"})
groupIcons.push({name:icnName, font:"icomoon"})
})


// const getIconCssClassFromStyleParams = (style, params)=>{ //params= {isBig: isActive:}

// 	let styleObj = style;
	
// 	if(_.isUndefined(style.icon)){
// 		styleObj = {
// 			icon:{
// 				font:"font-awesome",
// 				name:"file-o"
// 			} //default....
// 		}
// 	}

// 	let result = "";
// 	switch(styleObj.icon.font){
// 		case "glyphicon":
// 			result = `${styleObj.icon.font} ${styleObj.icon.font}-${styleObj.icon.name}`;
// 			if (params && params.isBig){
// 					result = result + " big-icon"
// 			}
// 			if (params && params.isActive){
// 					result = result + " active"
// 			}
// 		break;
// 		case "font-awesome":
// 			result = `fa fa-${styleObj.icon.name}`;
// 			if(params && params.isBig){
// 				result = result+" big-icon";
// 			}
// 			if(params && params.isActive){
// 					result = result+ " active"
// 			}
// 		break;
// 		default:
// 			result = "fa fa-file-o"
// 			if (params && params.isBig)
// 			result = result + " big-icon";
// 			if (params && params.isActive)
// 			result = result + " active";
// 		break;
// 	}
// 	return result;
// }


export default {
	group:groupIcons,
	device:deviceIcons,
	scene:sceneICons
};


const getIconCssClassFromStyleParams = (style, params, iconKind)=>{ //params= {isBig: isActive:}

var iconType = ""
	if(_.isUndefined(iconKind)){
		 iconType = "icon"
	}else {
		iconType = iconKind
	}

	let styleObj = style;

	if(_.isUndefined(style[iconType])){
		styleObj = {
			[iconType]:{
				font:"font-awesome",
				name:"file-o"
			} //default....
		}
	}

	let result = "";
	switch(styleObj[iconType].font){
		case "glyphicon":
			result = `${styleObj[iconType].font} ${styleObj[iconType].font}-${styleObj[iconType].name}`;
			if (params && params.isBig){
					result = result + " big-icon"
			}
			if (params && params.isActive){
					result = result + " active"
			}
		break;
		case "font-awesome":
			result = `fa fa-${styleObj[iconType].name}`;
			if(params && params.isBig){
				result = result+" big-icon";
			}
			if(params && params.isActive){
					result = result+ " active"
			}
		break;
		case "icomoon":
			result = `icon-${styleObj[iconType].name}`;		
			if(params && params.isBig){
				result = result+" big-icon";
			}
			if(params && params.isActive){
					result = result+ " active"
			}
		break;
		default:
			result = "fa fa-file-o"
			if (params && params.isBig)
			result = result + " big-icon";
			if (params && params.isActive)
			result = result + " active";
		break;
	}
	return result;
}


export {getIconCssClassFromStyleParams}