import React, { Component } from 'react';
import { branch } from 'baobab-react/higher-order';
import _ from "lodash";
import actions from "../../../data/actions/actions";

class IpConfig extends Component {
    constructor(props) {
        super(props)
        this._isMounted = true;
        this.state = {
            osNotSupported:false,
            DHCPEnabled: false,
            ipConfigFile: [],
            address: {
                first: "1",
                second: "1",
                third: "1",
                fourth: "2"
            },
            netmask: {
                first: "255",
                second: "255",
                third: "255",
                fourth: "0"
            },
            gateway: {
                first: "1",
                second: "1",
                third: "1",
                fourth: "1"
            },
        }
    }
    shouldComponentUpdate(nextProps, nextState){
        return this.props.tabIndex ===nextProps.selectedTabIndex;
    }


    onSave() {

        if (this.state.DHCPEnabled === false) {
           
            var ipAddress = "";
            var netmask = "";
            var gateway = "";

            ipAddress = (_.values(this.state.address)).join(".")
            netmask = (_.values(this.state.netmask)).join(".")
            gateway = (_.values(this.state.gateway)).join(".")
            actions.setIpAddress(ipAddress, netmask, gateway).then(res=>{

            }).catch(err => {
                console.log(err);
            });
        }

        else {
            actions.enableDHCP().then(res => {
            }).catch(err => {
                console.log(err);
                
            })
        }

    }

    handleInputChange(e) {
        if(parseInt(e.target.value,10)>255){
            e.target.value = 255;
        }
        else if(parseInt(e.target.value, 10) < 0) {
            e.target.value = 0;
        }
        switch (e.target.title) {
            case "address":
                switch (e.target.name) {
                    case "first":
                        let obj = _.clone(this.state.address)
                        obj.first = parseInt(e.target.value, 10)
                        this.setState({ address: obj })
                        break;
                    case "second":
                        obj = _.clone(this.state.address)
                        obj.second = parseInt(e.target.value, 10)
                        this.setState({ address: obj })
                        break;
                    case "third":
                        obj = _.clone(this.state.address)
                        obj.third =parseInt(e.target.value, 10)
                        this.setState({ address: obj })
                        break;
                    case "fourth":
                        obj = _.clone(this.state.address)
                        obj.fourth = parseInt(e.target.value, 10)
                        this.setState({ address: obj })
                        break;
                    default:
                        break;
                }
                break;
            case "netmask":
                switch (e.target.name) {
                    case "first":
                        let obj = _.clone(this.state.netmask)
                        obj.first = parseInt(e.target.value, 10)
                        this.setState({ netmask: obj })
                        break;
                    case "second":
                        obj = _.clone(this.state.netmask)
                        obj.second = parseInt(e.target.value, 10)
                        this.setState({ netmask: obj })
                        break;
                    case "third":
                        obj = _.clone(this.state.netmask)
                        obj.third = parseInt(e.target.value, 10)
                        this.setState({ netmask: obj })
                        break;
                    case "fourth":
                        obj = _.clone(this.state.netmask)
                        obj.fourth = parseInt(e.target.value, 10)
                        this.setState({ netmask: obj })
                        break;
                    default:
                        break;
                }
                break;
            case "gateway":
                switch (e.target.name) {
                    case "first":
                        let obj = _.clone(this.state.gateway)
                        obj.first = parseInt(e.target.value, 10)
                        this.setState({ gateway: obj })
                        break;
                    case "second":
                        obj = _.clone(this.state.gateway)
                        obj.second = parseInt(e.target.value, 10)
                        this.setState({ gateway: obj })
                        break;
                    case "third":
                        obj = _.clone(this.state.gateway)
                        obj.third = parseInt(e.target.value, 10)
                        this.setState({ gateway: obj })
                        break;
                    case "fourth":
                        obj = _.clone(this.state.gateway)
                        obj.fourth = parseInt(e.target.value, 10)
                        this.setState({ gateway: obj })
                        break;
                    default:
                        break;
                }
                break;
            default:
                break;
        }
    }

    handleDHCP(e) {
         if(this._isMounted){
            this.setState({ DHCPEnabled: this.state.DHCPEnabled === true ? false : true })
         }
        
    }
    componentWillUnmount(){
        this._isMounted = false;
    }
    componentDidMount() {
        actions.getIpConfigFileArray().then(res => {                        
            if(this._isMounted){
                // if (!_.isUndefined(res.data.osNotSupportedObj)){
                //     this.setState({osNotSupported:true})
                // } else {
                    // var arr = [];
                    // _.forEach(res.data.ipConfigArray, row => {
                    //     arr.push(row.replace(/^\s+|\s+$/gm, ''));
                    // })
                    // var fullArray = (arr.join(" ")).split(" ");
                    // var flag = false;
                    // _.forEach(res.data.ipConfigArray, fileRow => {
                    //     if(_.includes(fileRow,"eth0 inet dhcp" ) || (!_.includes(fileRow,"address") && !_.includes(fileRow,"netmask")))
                    //     {
                    //             flag=true                
                    //     }
                    // })
                    // if(flag===true){
                    //     this.setState({
                    //        DHCPEnabled:true
                    //     })
                    // }

                    // this.setState({DHCPEnabled:res.data.ipConfigArray.DHCP})

                    // else {
                        let address = (res.data.ipConfigArray.address).split(".");
                        let addrObj = {};
                        addrObj.first = address[0];
                        addrObj.second = address[1];
                        addrObj.third = address[2];
                        addrObj.fourth = address[3];
            
                        let netmask = (res.data.ipConfigArray.netmask).split(".");
                        let netObj = {};
                        netObj.first = netmask[0];
                        netObj.second = netmask[1];
                        netObj.third = netmask[2];
                        netObj.fourth = netmask[3];
            
                        // let gateway = (fullArray[fullArray.indexOf("gateway") + 1]).split(".");
                        let gateObj = {};
                        gateObj.first = address[0];
                        gateObj.second = address[1];
                        gateObj.third = address[2];
                        gateObj.fourth = 1;
                        // if(_.includes(fullArray, "address") && _.includes(fullArray,"gateway") && _.includes(fullArray,"netmask")){
                            this.setState({ address: addrObj, netmask: netObj, gateway: gateObj, DHCPEnabled:res.data.ipConfigArray.DHCP })
                        // }
                       
                    // }
                // }
            }
        }).catch(err => {
            if(this._isMounted){
                 this.setState({
                    osNotSupported:true
                })

            }
           
        })
    }
    render() {
        if(this.state.osNotSupported === true){
            return <div>OS not supported</div>
        } else {
            return (
                <div className="ip-address-wrapper">

                    <div className=""><label className="control-label col-sm-2">Enable DHCP</label>
                        <div className="col-sm-10">
                            <input id="checkbox-1-1" onChange={this.handleDHCP.bind(this)} checked={this.state.DHCPEnabled} type="checkbox" className="regular-checkbox" />
                            <label htmlFor="checkbox-1-1" className="margin-t10"></label>

                            <div className=" clear"></div>
                        </div>
                    </div>
                    <div>


                        <div className="light-gray" ><label className="control-label col-sm-2">Ip address</label>
                            <div className="col-sm-10">
                                <input disabled={this.state.DHCPEnabled} type="number" onChange={this.handleInputChange.bind(this)} title="address" name="first"  min="0" max="255" value={this.state.address.first} className={this.state.DHCPEnabled === true ? "form-control col-sm-2 light-gray ifttt-analogInput-input" : "form-control col-sm-2 ifttt-analogInput-input"} />
                                <i>.</i><input disabled={this.state.DHCPEnabled} type="number" onChange={this.handleInputChange.bind(this)} title="address" name="second"  min="0" max="255" value={this.state.address.second} className={this.state.DHCPEnabled === true ? "form-control col-sm-2 light-gray ifttt-analogInput-input" : "form-control col-sm-2 ifttt-analogInput-input"} />
                                <i>.</i> <input disabled={this.state.DHCPEnabled} type="number" onChange={this.handleInputChange.bind(this)} title="address" name="third"  min="0" max="255" value={this.state.address.third} className={this.state.DHCPEnabled === true ? "form-control col-sm-2 light-gray ifttt-analogInput-input" : "form-control col-sm-2 ifttt-analogInput-input"} />
                                <i>.</i> <input disabled={this.state.DHCPEnabled} type="number" onChange={this.handleInputChange.bind(this)} title="address" name="fourth"  min="0" max="255" value={this.state.address.fourth} className={this.state.DHCPEnabled === true ? "form-control col-sm-2 light-gray ifttt-analogInput-input" : "form-control col-sm-2 ifttt-analogInput-input"} />
                            </div><div className=" clear"></div>
                        </div>

                        <div className=""><label className="control-label col-sm-2">Netmask</label>
                            <div className="col-sm-10">
                                <input disabled={this.state.DHCPEnabled} type="number" onChange={this.handleInputChange.bind(this)} title="netmask" name="first"  min="0" max="255" value={this.state.netmask.first} className={this.state.DHCPEnabled === true ? "form-control col-sm-2 light-gray ifttt-analogInput-input" : "form-control col-sm-2 ifttt-analogInput-input"} />
                                <i>.</i>  <input disabled={this.state.DHCPEnabled} type="number" onChange={this.handleInputChange.bind(this)} title="netmask" name="second"  min="0" max="255" value={this.state.netmask.second} className={this.state.DHCPEnabled === true ? "form-control col-sm-2 light-gray ifttt-analogInput-input" : "form-control col-sm-2 ifttt-analogInput-input"} />
                                <i>.</i> <input disabled={this.state.DHCPEnabled} type="number" onChange={this.handleInputChange.bind(this)} title="netmask" name="third"  min="0" max="255" value={this.state.netmask.third} className={this.state.DHCPEnabled === true ? "form-control col-sm-2 light-gray ifttt-analogInput-input" : "form-control col-sm-2 ifttt-analogInput-input"} />
                                <i>.</i>  <input disabled={this.state.DHCPEnabled} type="number" onChange={this.handleInputChange.bind(this)} title="netmask" name="fourth"  min="0" max="255" value={this.state.netmask.fourth} className={this.state.DHCPEnabled === true ? "form-control col-sm-2 light-gray ifttt-analogInput-input" : "form-control col-sm-2 ifttt-analogInput-input"} />
                            </div><div className=" clear"></div>
                        </div>

                        <div className=""><label className="control-label col-sm-2">Gateway</label>
                            <div className="col-sm-10">
                                <input disabled={this.state.DHCPEnabled} type="number" onChange={this.handleInputChange.bind(this)} title="gateway" name="first"  min="0" max="255" value={this.state.gateway.first} className={this.state.DHCPEnabled === true ? "form-control col-sm-2 light-gray ifttt-analogInput-input" : "form-control col-sm-2 ifttt-analogInput-input"} />
                                <i>.</i> <input disabled={this.state.DHCPEnabled} type="number" onChange={this.handleInputChange.bind(this)} title="gateway" name="second"  min="0" max="255" value={this.state.gateway.second} className={this.state.DHCPEnabled === true ? "form-control col-sm-2 light-gray ifttt-analogInput-input" : "form-control col-sm-2 ifttt-analogInput-input"} />
                                <i>.</i> <input disabled={this.state.DHCPEnabled} type="number" onChange={this.handleInputChange.bind(this)} title="gateway" name="third"  min="0" max="255" value={this.state.gateway.third} className={this.state.DHCPEnabled === true ? "form-control col-sm-2 light-gray ifttt-analogInput-input" : "form-control col-sm-2 ifttt-analogInput-input"} />
                                <i>.</i> <input disabled={this.state.DHCPEnabled} type="number" onChange={this.handleInputChange.bind(this)} title="gateway" name="fourth"  min="0" max="255" value={this.state.gateway.fourth} className={this.state.DHCPEnabled === true ? "form-control col-sm-2 light-gray ifttt-analogInput-input" : "form-control col-sm-2 ifttt-analogInput-input"} />
                            </div><div className=" clear"></div>
                        </div>
                    </div>


                    <div className="col-sm-2"></div>
                    <div className="col-sm-10">
                        <button onClick={this.onSave.bind(this)} className="btn btn-info pull-left btn-sm"> <i className="fa fa-save"></i> Save</button>
                    </div>

                </div>
            )

        }
    }
}

export default branch({
    route: ["route"],
    ui: ["ui", "showMainMenu"],
    sessionInfo: ["sessionInfo"]
}, IpConfig);

