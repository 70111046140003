import React, { Component } from 'react';
import { branch } from 'baobab-react/higher-order';
import actions from "../../data/actions/actions";
import _ from "lodash";
import getMainMenuItems from "./mainMenu"
import {hashHistory} from "react-router"
//import baobabTree from "../../data/state"
//import avatarPic from "../../assets/img/default-avatar.png";

class MainMenu extends Component {
    constructor(props) {
        super(props)
        this.state={
            routeWithQuery:""
        }
    }

    getCssBasedOnRouteAndHrefTarget(hrefTarget){
        
         if(window.location.hash === hrefTarget){
            return "choices active"
        } else {
            return "choices";
        }
    }
    componentDidMount() {
        this.setState({
            routeWithQuery:"/"+window.location.hash
        })
     //   document.addEventListener("mousedown", this.handleClick, false)
     //   window.addEventListener("hashchange", this.handleHash.bind(this), false)
    }


    goToProfilePage(){
        hashHistory.push("/profile")
    }

    componentWillUnmount() {
     //   document.removeEventListener("mousedown", this.handleClick)
    //    window.removeEventListener("hashchange", this.handleHash)

    }

    handleHash(e){
        this.setState({
            routeWithQuery:e.newURL
        })
    }

    handleClick = (e) => {
        if (this.node.contains(e.target)) {
            return
        }
        else {
            if(this.props.mainMenuAnimation === "right"){
                actions.hideMainMenu()   
            }
        }
    }

    render() { 
        
        if(_.isUndefined(this.props.route.params)){
            return null;
        }
        let locationOnline = this.props.locationConnected 
        actions.hideIconSelect();
        let isShown = this.props.isMainMenuShown;
        let wholeClass= this.props.mainMenuAnimation === "right" ? "side-menu col-md-3 col-lg-3 animated faster fadeInRight" : "side-menu col-md-3 col-lg-3 ";
     
        let menuItems = getMainMenuItems(this.props.userLevelMonkeys, this.props.addOns);        
        menuItems = _.map(menuItems, item=>{            
            if(item.label){
                return <div key={item.href + "" + item.pageTitle} className="scene-side">
                <a onClick={item.onClick} className={this.getCssBasedOnRouteAndHrefTarget(item.href)} href={item.href}>
                <i className={item.icon} > </i><span>{item.label}</span>
                </a>
                {item.rightComponent ? item.rightComponent  : null}
                </div>
            } else {
                return item;
            }
        })
        
       
        let setClass = isShown===true ? wholeClass : "hidden"
        let version = this.props.actualNullableCodeVersion ? this.props.actualNullableCodeVersion : this.props.selectedSlaveCodeVersion;
        if(this.props.actualNullableCodeVersion===""){
            version = this.props.selectedSlaveCodeVersion;
        }
        if(version===""){
            version = "unknown"
        }

        //console.log(this.props.sessionInfo);

        let activeSessionsMarker = null;
        let activeSessionCount = this.props.sessionInfo.activeSessions ? this.props.sessionInfo.activeSessions.length : -1
        if(activeSessionCount>0){
            activeSessionsMarker = <span>({activeSessionCount})</span>
        }

        return (
            <div ref={node => this.node = node}  className={setClass + (this.props.isFullScreen === true ? " padding-top-15" : "")}>
            <i onClick={ () => actions.hideMainMenu() }className="fa fa-times pointer side-bar-icon"></i>
             <div id="side-user-info" onClick={this.goToProfilePage.bind(this)} className="text-center pointer">
                 <div >
                {/* <img src={avatarPic} className="img-circle img-responsive" alt=""/> */}
                {/* <i className="fa fa-user"></i> */}
                </div>
               
               <div ><span><span  className="clr-orange">{this.props.sessionInfo.userProfile.email}</span> {activeSessionsMarker}</span></div>

            </div> 

            <hr className="style-rainbow"/>
            <div id="menu-list">
                {menuItems}
                <div className="bottom-with-margin"></div>
                {locationOnline ? <div className="bottom-with-margin"> <i>Version: <a style={{display:"inline", color:"#337ab7"}} href="#updates">{ version}</a></i>  </div> : null}

                <div className="bottom-with-margin"></div>
            </div>
        </div>
        );
    }
}

// Branching the component by mapping the desired data to cursors
export default branch({
    userLevelMonkeys:["userLevelMonkeys"],
    route:["route"],
    isMainMenuShown:["ui", "showMainMenu"],
    mainMenuAnimation:["ui", "mainMenuAnimation"],
    sessionInfo:["sessionInfo"],
    selectedSlaveCodeVersion:["monkeys", "selectedSlaveCodeVersion"],
    actualNullableCodeVersion:["ui","actualNullableCodeVersion", "version"],
    addOns:["readOnlyModel","addOns"],
    scenes:["model", "scenes"],
    //selectedSlaveOnline: ["ui","selectedSlaveOnline"],
    locationConnected: ["ui","locationConnected"]
  }, MainMenu);