import baobabTree from '../state';
import _ from "lodash";
import { sanitizeForm } from "./formStateController"
import rest from "../restWrapper"
//import Promise, {  } from "bluebird";
import showHeaderNotification from "../../utils/showNotification";
import baobabFunnel from "../state/baobabFunnel";
import uiActions from "./ui";
import { hashHistory } from 'react-router';
import {requestData} from "../newCommManager"

function difference(object, base) {
    function changes(object, base) {
        return _.transform(object, (result, value, key) => {
            if (!_.isEqual(value, base[key])) {
                result[key] = (_.isObject(value) && _.isObject(base[key])) ? changes(value, base[key]) : value;
            }
        });
    }
    return changes(object, base);
}


const updateSaveForm = (formName) => {
    return new Promise((resolve, reject) => {
        var formData = _.clone(baobabTree.root.select('forms', formName).get());
        var data = _.clone((baobabTree.root.select('model', formName + "s", { id: formData.id }).get()));
        let isNewEntry = formData.id === null;
        if (isNewEntry) {
            delete formData.id;
        }
        else {
            
            var obj = {};
            var finObj = {};

            obj = difference(formData, data);
            var temp = _.defaults(formData, data)

            _.forIn(obj, (value, key) => {
                _.forIn(temp, (valuee, keyy) => {
                    if (key === keyy) {
                        finObj[keyy] = valuee;
                    }
                })
            })

            formData = _.clone(finObj)
        }

        let formNameFirstLetterCapital = formName.charAt(0).toUpperCase() + formName.slice(1);
       
            
        
        let restRoute = isNewEntry ? "/" + formName + "s" : "/" + formName + "s/" + (!_.isUndefined(data) ? data.id : formData.id);
        requestData(restRoute, isNewEntry ? "POST" : "PUT", formData)
            .then(response => { //"" zamjeni sa route mapperom  
                try {
                    baobabFunnel(response.model, response.method, response.data);
                    if (response.method === "PUT") {
                        if((formData.value || formData.value === 0) && (formData.value || formData.value === 0)){
                            console.log('value set');
                        }else{
                            showHeaderNotification(true, 2000, formNameFirstLetterCapital + " successfully updated");
                        }
                    } else {
                        showHeaderNotification(true, 2000, formNameFirstLetterCapital + " successfully added");
                    }                   
                    if(formName !== "scene"){
                        setTimeout(() => {sanitizeForm(formName);}, 300);
                    }
                    
                    return resolve(response);
                } catch (err) {
                    uiActions.pushErrorToSessionErrors(err);
                    if (!isNewEntry) { baobabTree.root.select('model', formName + "s", {id:parseInt(formData.id,10)}).set(data);}
                    setTimeout(() => {sanitizeForm(formName);}, 300);
                    return reject(err);
                }
            }).catch(err => {
                uiActions.pushErrorToSessionErrors(err);
                try{
                     if (!isNewEntry) { baobabTree.root.select('model', formName + "s", {id:parseInt(formData.id,10)}).set(data);}
                } catch(err){

                }
                setTimeout(() => {sanitizeForm(formName);}, 300);
                return reject(err);
            });
    });
}

const saveDeviceGroups = (stateArray, deviceId, deviceModel) => {
    return new Promise((resolve, reject) => {
        _.forEach(stateArray, d => {
            if (d.checked === true && d.deviceGroupId === null) {
                var formData = {"CompositeDeviceId": deviceModel.toLowerCase() === "device" ? null : deviceId, "DeviceId": deviceModel.toLowerCase() === "device" ? deviceId : null, "GroupId": parseInt(d.groupId, 10) };
                let restRoute = "/devicegroups";
                requestData(restRoute, "POST", formData)
                    .then(response => {
                        try {
                            baobabFunnel(response.model, response.method, response.data);
                            showHeaderNotification(true, 2000, "Successfully added");
                            // window.location = "#/"
                            // hashHistory.goBack();
                            return resolve(response);
                        } catch (err) {
                            uiActions.pushErrorToSessionErrors(err);
                            return reject(err);
                        }
                    }).catch(err => {
                        uiActions.pushErrorToSessionErrors(err);
                        return reject(err);
                    });
            }
        })

    });
}

const handleDeviceGroup = (stateArray, groupId) => {
    return new Promise((resolve, reject) => {
        let promises = [];
        _.forEach(stateArray, item => {
            let formData = {
                CompositeDeviceId:item.compositeDeviceId,
                DeviceId: item.deviceId,
                GroupId: parseInt(groupId, 10)
            };
            if (item.deviceGroupId === null && item.checked === true) {//post
                promises.push(requestData("/devicegroups", "POST", formData));
            } else if (item.deviceGroupId !== null && item.checked === false) {//delete
                promises.push(requestData("/devicegroups/" + item.deviceGroupId, "DELETE", formData));
            }
        });

        Promise.all(promises).then(results => {
            _.forEach(results, res => {
                try {
                    baobabFunnel(res.model, res.method, res.data);
                } catch (err) {
                    uiActions.pushErrorToSessionErrors(err);
                }
            });
            showHeaderNotification(true, 2000, "success");
            return resolve(results);
        }).catch(err => {
            uiActions.pushErrorToSessionErrors(err);
            return reject(err);
        });
    });
}

const saveSchedule = (stateArray) => {
    return new Promise((resolve, reject) => {
        let formData = null;
        let restRoute = null;
        if (Array.isArray(stateArray)) {
            _.forEach(stateArray, schedule => {
                formData = schedule;
                restRoute = "/eventsubscriptions";
                requestData(restRoute, "POST", formData)
                    .then(response => {
                        try {
                            baobabFunnel(response.model, response.method, response.data);
                            showHeaderNotification(true, 2000, "Successfully added");
                            return resolve(response);
                        } catch (err) {
                            uiActions.pushErrorToSessionErrors(err);
                            return reject(err);
                        }
                    }).catch(err => {
                        uiActions.pushErrorToSessionErrors(err);
                        return reject(err);
                    });

            })
        }
        else {
            formData = stateArray;
            restRoute = "/eventsubscriptions";
            requestData(restRoute, "POST", formData)
                .then(response => {
                    try {
                        baobabFunnel(response.model, response.method, response.data);
                        showHeaderNotification(true, 2000, "Successfully added");
                        return resolve(response);
                    } catch (err) {
                        uiActions.pushErrorToSessionErrors(err);
                        return reject(err);
                    }
                }).catch(err => {
                    uiActions.pushErrorToSessionErrors(err);
                    return reject(err);
                });
        }


    });
}

const saveStateChangeEvent = (formData) => {
    return new Promise((resolve, reject) => {
        //optimistični update!!!!!!
        let restRoute = null;       
            restRoute = "/eventsubscriptions";
            requestData(restRoute, "POST", formData)
                .then(response => {
                    try {
                        baobabFunnel(response.model, response.method, response.data);
                        showHeaderNotification(true, 2000, "Successfully added");
                        return resolve(response);
                    } catch (err) {
                        //rollback od optimistic update
                        uiActions.pushErrorToSessionErrors(err);
                        return reject(err);
                    }
                }).catch(err => {
                     //rollback od optimistic update
                    uiActions.pushErrorToSessionErrors(err);
                    return reject(err);
                });
        
    });
}


const deleteSchedule = (scheduleId) => {
    return new Promise((resolve, reject) => {
        var allSubscriptions = baobabTree.root.select('model', 'eventsubscriptions').get();
        
        var formData = _.filter(allSubscriptions, item => {
            if (!_.isUndefined(item.eventRules.params)) {
                if (!_.isUndefined(item.eventRules.params.scheduleTemplate)) {
                return item.eventRules.params.scheduleTemplate.id === parseInt(scheduleId, 10);
            }
        }
        })

        _.forEach(formData, data => {
            let restRoute = "/eventsubscriptions/" + data.id;
            sanitizeForm("eventsubscription");
            requestData(restRoute, "DELETE")
                .then(response => {
                    try {
                        baobabFunnel(response.model, response.method, response.data);
                        showHeaderNotification(true, 2000, "schedule successfully deleted");
                        return resolve(response);
                    } catch (err) {
                        uiActions.pushErrorToSessionErrors(err);
                        return reject(err);
                    }
                }).catch(err => {
                    uiActions.pushErrorToSessionErrors(err);
                    return reject(err);
                });
        })
    });
}

const deleteItem = (formName, id) => {
    return new Promise((resolve, reject) => {
        var formNameFirstLetterCapital = formName.charAt(0).toUpperCase() + formName.slice(1);
        let restRoute = "/" + formName + "s/" + id;
        sanitizeForm(formName);
        requestData(restRoute, "DELETE")
            .then(response => {
                try {
                    baobabFunnel(response.model, response.method, response.data);
                    showHeaderNotification(true, 2000, formNameFirstLetterCapital + " successfully deleted");
                    // switch (formName) {
                    //     case "device":
                    //         window.location = "#/allDevices";
                    //         break;
                    //     case "group":
                    //         window.location = "#/";
                    //         break;
                    //     default:
                    //         break;
                    // }
                    return resolve(response);
                } catch (err) {
                    uiActions.pushErrorToSessionErrors(err);
                    return reject(err);
                }
            }).catch(err => {
                uiActions.pushErrorToSessionErrors(err);
                return reject(err);
            });
    });
}

const backUpFiles = (email, description) => { // MASTER ROUTE ONLY
    return new Promise((resolve, reject) => {
        requestData("/backupDatabase", "POST", { email, description })
            .then(response => {
                if(response.code === -1){
                    var obj = new Error()
                    obj.message = "You reached maximum number of backup entries(5). If you want to save current backup, please remove one of your previous backups."
                    uiActions.pushErrorToSessionErrors(obj);
                } else {
                    showHeaderNotification(true, 2000, "Backup successfully created");
                    // hashHistory.push("/");
                    // hashHistory.goBack();
                    return resolve(response);
                }
               
            }).catch(err => {
                uiActions.pushErrorToSessionErrors(err);
                return reject(err);
            });
    });
}


const restoreBackup = (backupUuid) => { 
    return new Promise((resolve, reject) => {        
        requestData("/restoreDatabaseBackup", "POST", { backupUuid: backupUuid })
            .then(response => {
               setTimeout(() => {
                showHeaderNotification(true, 2000, "Backup successfully restored");
               }, 3000); 
                // hashHistory.push("/");
                // hashHistory.goBack();
                return resolve(response);
            }).catch(err => {
                uiActions.pushErrorToSessionErrors(err);
                return reject(err);
            });
    });
}

const deleteBackup = (backupUuid) => {
    return new Promise((resolve, reject) => {        
        rest("/deleteBackup", "POST", { backupUuid: backupUuid }) //FROM SERVER
            .then(response => {                
                showHeaderNotification(true, 2000, "Backup deleted successfully");
                // hashHistory.push("/");
                // hashHistory.goBack();
                return resolve(response);
            }).catch(err => {
                uiActions.pushErrorToSessionErrors(err);
                return reject(err);
            });
    });
}

const updateProfileInfo = (user) => { // MASTER ROUTE ONLY
    return new Promise((resolve, reject) => {
        rest("/updateProfileInfo", "POST", {user:user}) 
            .then(response => {                
                showHeaderNotification(true, 2000, "Assistance email set successfully");
                // hashHistory.push("/");
                hashHistory.goBack();
                return resolve(response);
            }).catch(err => {
                uiActions.pushErrorToSessionErrors(err);
                return reject(err);
            });
    });
}

const saveReservation = (reservation) =>{
    baobabTree.root.select('smartRoom', 'reservations').push(reservation);
}
export { saveDeviceGroups, handleDeviceGroup, deleteItem, saveSchedule, deleteSchedule, backUpFiles, restoreBackup ,deleteBackup, updateProfileInfo, saveStateChangeEvent, saveReservation};
export default updateSaveForm;