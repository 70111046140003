import baobabTree from '../state';
import _ from "lodash"

var uiActions = {
    /*
    PREVIŠE OVOGA IMA.... asocira na "code smell"
    */
    deleteLastError: () => {
        baobabTree.root.select('sessionErrors').set([]);
    },
    pushErrorToSessionErrors: (errorObject) => {
        let hasAlready = _.find(baobabTree.root.select('sessionErrors').get(), err => {
            return err.message === errorObject.message;
        })
        if (!hasAlready) {
            baobabTree.root.select('sessionErrors').push(errorObject);
        }
    },
    showHeaderNotification: () => {
        baobabTree.root.select('ui', 'showHeaderNotification').set(true);
    },
    hideHeaderNotification: () => {
        baobabTree.root.select('ui', 'showHeaderNotification').set(false);
    },
    setNotificationMessage: (message) => {
        baobabTree.root.select('ui', 'headerNotificationMessage').push(message);
    },
    setNoticationSuccesss: (bool) => {
        baobabTree.root.select("ui", 'isSuccessfullNotification').set(bool);
    },
    deleteNotificationMesages: () => {
        baobabTree.root.select('ui', 'headerNotificationMessage').set([]);
    },
    setConnectingMessage: (message) => {
        baobabTree.root.select('ui', 'connectionStateMessageObject', 'message').set(message);
    },
    setReconnectingEnded: (state) => {
        baobabTree.root.select('ui', 'connectionStateMessageObject', 'reconnectingEnded').set(state);
    },

    setShowLoadingSceen: (showLoadingScreen) => {
        if (showLoadingScreen === false) {
            setTimeout(() => {
                baobabTree.root.select('ui', 'showLoadingScreen').set(showLoadingScreen);
            }, 500);
        } else {
            baobabTree.root.select('ui', 'showLoadingScreen').set(showLoadingScreen);
        }
    },

    setShowNoLocationScreen: (showNoLocationsScreen) => {
        baobabTree.root.select('ui', 'showNoLocationsScreen').set(showNoLocationsScreen);
    },
    setFilterObject: (name, object) => {
        baobabTree.root.select('ui', "selected", name).set(object);
    },


    setSettingSelectedTabIndex: (type, index) => {
        baobabTree.root.select('ui', type).set(index);
    },

    toggleBadNotificationFullScreenWarning: (name, object) => {
        var currState = baobabTree.root.select('ui', "poorConnectionWarning", "fullScreen").get()
        baobabTree.root.select('ui', "poorConnectionWarning", "fullScreen").set(currState === true ? false : true);
    },
    toggleSystemMessagesModal: ()=>{
        var currState = baobabTree.root.select('ui', "showSystemMessagesModal").get()
        baobabTree.root.select('ui', "showSystemMessagesModal").set(currState === true ? false : true);
    },
    clearSystemMessagesOfType: (type)=> {
        baobabTree.root.select('systemMessages', type).set([]);
    },
    hideChangeStateFailInfoDialog: () => {
        baobabTree.root.select('ui', 'showChangeStateFailInfo').set(false);
    },
    setSearchTerm: (term) => {
        baobabTree.root.select('searchedReservation').set(term);
    },
    setModalDialog: (modalDialogObject) => {
        baobabTree.root.select('ui', 'modalDialog').set(modalDialogObject);
    },
    hideModalDialog: () => {
        baobabTree.root.select('ui', 'modalDialog').set({ isShown: false, type: "", sceneParameter: false });
    },
    setDeviceSearchTerm: (term) => {
        baobabTree.root.select('searchedDevice').set(term);
    },
    getMainMenuAnimation: () => {
        let animationDirection = baobabTree.root.select('ui', 'mainMenuAnimation').get();
        return animationDirection
    },
    removeLastDoorBellInfo: () => {
        let doorBellsActive = _.clone(baobabTree.root.select('doorBellsActive').get());
        doorBellsActive = doorBellsActive.slice(0, doorBellsActive.length - 1)
        baobabTree.root.select('doorBellsActive').set(doorBellsActive);
    },
    pushUnfinishedSceneToState: (sceneObject) => {
        baobabTree.root.select('unfinishedScenes').push(sceneObject);

    },
    removeUnfinishedSceneFromState: (sceneObject) => {
        baobabTree.root.select('unfinishedScenes').pop();

    },
    pushMessageToSystemMesages: (fullPayloadObj) => {

        let payload = _.clone(fullPayloadObj)

        payload["timestamp"] = +new Date()

        switch (payload.messageType) {
            case "info":
            case "warning":
                let warningOrInfoMessages = baobabTree.root.select('systemMessages', "warningOrInfo").get();
                if (warningOrInfoMessages.length < 50) {
                    baobabTree.root.select('systemMessages', "warningOrInfo").push(payload)
                } else {
                    baobabTree.root.select('systemMessages', "warningOrInfo").shift()
                    baobabTree.root.select('systemMessages', "warningOrInfo").push(payload)
                }
                break;

            case "alert":
            case "error":
                let alertOrErrorMessages = baobabTree.root.select('systemMessages', "alertOrError").get();
                if (alertOrErrorMessages.length < 50) {
                    baobabTree.root.select('systemMessages', "alertOrError").push(payload)
                } else {
                    baobabTree.root.select('systemMessages', "alertOrError").shift()
                    baobabTree.root.select('systemMessages', "alertOrError").push(payload)
                }
                break;

            case "critical":
                    baobabTree.root.select('ui', "showSystemMessagesModal").set(true);

                let criticalMessages = baobabTree.root.select('systemMessages', "critical").get();
                if (criticalMessages.length < 50) {
                    baobabTree.root.select('systemMessages', "critical").push(payload)
                } else {
                    baobabTree.root.select('systemMessages', "critical").shift()
                    baobabTree.root.select('systemMessages', "critical").push(payload)
                }
                break;


            default:
                break;
        }

    }
}

export default uiActions;