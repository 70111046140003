import EventEmitter from 'events'
import {masterSocketClientClassWithRequestMessaging} from "./masterClient"
import {slaveSocketClientClass} from "./slaveClient"
import * as Sentry from '@sentry/browser';
import _ from "lodash"


class connectionManager extends EventEmitter{
	constructor(isMaster, apiServerPath){
		super();
		this.apiServerPath = apiServerPath;
		this.onReconnectDebouncer = null;
		this.reconectionAttempt = 0;
		this.isMaster = isMaster;
		this.socketInstance = null;

		if(this.isMaster){
			this.socketInstance = new masterSocketClientClassWithRequestMessaging(this.apiServerPath);
		} else {
			this.socketInstance = new slaveSocketClientClass(this.apiServerPath);
		}
		this.initSocketConnectionHandlers();
		this.registerSocketEventHandlers();
		
	}


	get Socket(){
		return this.socketInstance.Socket;
	}

	sendRequest(message, waitForResponseSeconds){
		if(this.socketInstance.sendRequest){
			return this.socketInstance.sendRequest(message, waitForResponseSeconds);
		} else {
			return Promise.reject(new Error("Invalid call of masterSocket sendRequest() method"));
		}
	}

	initSocketConnectionHandlers(){
		this.socketInstance.on("abortConnection", (message)=>{
			this.emit("abortConnection", this.isMaster, message)
		})
		this.socketInstance.Socket.on("browserJoined2SlaveRoom", joinedSlaveUuid=>{
			this.emit("socketRoomJoined", joinedSlaveUuid);
		})

		this.socketInstance.Socket.on("connect", ()=>{
			this.emit("connect", this.isMaster)
		//	uiActions.setConnectingMessage ("");
		//	uiActions.setReconnectingEnded(false);
		});

		this.socketInstance.Socket.on("disconnect", ()=>{
			this.emit("disconnect", this.isMaster)
			//uiActions.setConnectingMessage ("Disconnected");
		});

		this.socketInstance.Socket.on("connect_error", error=>{
			console.warn(error);
			Sentry.captureException(error);
			//uiActions.setConnectingMessage ("Connection error");
		})

		this.socketInstance.Socket.on('reconnect', attempt=>{ //(Number) reconnection attempt number. Fired upon a successful reconnection.
			this.emit("reconnect", this.isMaster)
			this.socketRoomJoin(this.slaveUuid); //ovo treba ako server padne da notifikacije o online stanjima uređaja rade bez refreshanja sučelja
			//onConnectAndReconnectHandler();
		});

		this.socketInstance.Socket.on('reconnect_attempt',()=>{ //Fired upon an attempt to reconnect.
			
		})

		this.socketInstance.Socket.on('reconnecting', attemptCount=>{ //lasts only a milisecond
			this.reconectionAttempt = attemptCount;
		}) //(Number) reconnection attempt number. Fired upon a successful reconnection.

		this.socketInstance.Socket.on('reconnect_error', (error)=>{ // (Object) error object. Fired upon a reconnection attempt error.
			Sentry.captureException(error);
		//	uiActions.setConnectingMessage ("Reconnecting attempt "+this.reconectionAttempt);
		});

		this.socketInstance.Socket.on('reconnect_failed', ()=>{ //Fired when couldn't reconnect within reconnectionAttempts.
			//uiActions.setReconnectingEnded(true);
			//uiActions.setConnectingMessage ("Reconnect failed");
		});
	}

	registerSocketEventHandlers(){
		this.socketInstance.Socket.on('syncData', (data)=>{ //<<-------------THIS IS THE MOST IMPORTATN METHOD 
			if(!_.isUndefined(data.model) && !_.isUndefined(data.method) && !_.isUndefined(data.entity)){
				this.emit("incommingFunnelMessage", data.model, data.method, data.entity);
			} else {
				console.warn("Invalid socket syncData message", data);
			}
		});

		this.socketInstance.Socket.on("serverError", (errInfo)=>{
			Sentry.captureException(errInfo);
			console.warn("ERROR", errInfo);
		})
				
		this.socketInstance.Socket.on("requestData", (errInfo)=>{
			Sentry.captureException(errInfo);
			console.log("This shouldn't be here. Remove on production!!!!", errInfo);
		})
	}

	socketRoomJoin(slaveUuid, callback){ //essentialy try to join a scoket room or retrive webRtc peer info from cacke and join
		if(this.isMaster){
			this.socketInstance.joinSlaveRoom(slaveUuid, callback);
		} else {
			//onConnectAndReconnectHandler();
		} //nothing, you should be alread connected!!!!
	}
}

export default connectionManager;
