import React, { Component } from 'react';
import { branch } from 'baobab-react/higher-order';
import { hashHistory } from 'react-router';

import checkRoleAccess from "../../utils/checkRoleAccess"

class SceneManagement extends Component {

    render() {

        if(!checkRoleAccess("admin", "systemIntegrator", "advancedUser")){
          return "Insufficient permissions."
        }

        return (
            <div className={(this.props.showScrollbar === true ? "show-scrollbar" : "") + " content-w-background "}>

                <div className="device-buttons-wrapper">
                    <div className="deviceManagement-tiles-wrapper no-padding col-xs-6 col-sm-4 col-md-4 col-lg-3">
                        <div onClick={() => { hashHistory.push("/addScene") }} className="register-device-button pointer deviceManagement-tiles">
                            <div className="text-center deviceManagement-tiles-icon-wrapper">
                                <i className="fa fa-plus"></i>
                            </div>
                            <span className="custom-button-devices">Add Scene</span>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}

export default branch({
    showMainMenu: ["ui", "showMainMenu"],
    showScrollbar: ["ui", "showScrollbar"],
}, SceneManagement);