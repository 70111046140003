import React, { Component } from 'react';
import { branch } from 'baobab-react/higher-order';
import uiActions from "../../data/actions/ui";
import _ from "lodash";

class SystemMessagesModal extends Component {
    togglePoorConnectionWarning() {
        uiActions.toggleSystemMessagesModal()
    }
    clearMessagesOfType(type){
        uiActions.clearSystemMessagesOfType(type)
    }
    render() {
        let systemMessagesToShow = []
        let arrayFromMessages = _.flatten(Object.values(this.props.systemMessages))

        arrayFromMessages = _.orderBy(arrayFromMessages,  ["timestamp"], ["desc"])

          _.forEach(arrayFromMessages, (payload, index) => {
            let clName = ""

            switch (payload.messageType) {
                case "warning": case"info":
                    clName = "fa fa-info-circle text-info"
                    break;
                case "alert": case"error":
                    clName = "fa fa-exclamation-triangle color-pimatico-orange"
                    break;
                case "critical":
                    clName = "fa fa-times-circle text-danger"
                    break;
                default:
                    break;
            }
            systemMessagesToShow.push(<div className="system-message-log-row" key={index}>
                <div><i className={clName} aria-hidden="true"></i>
                </div>
                
                <div>
                {new Date(payload.timestamp).toLocaleTimeString() + " "}
                    </div>
                    
                    <div>
                    {payload.payload.toString()}
                    </div>
                   
                    </div>)
                })

        

        return (
            <div className={"delete-dialog"}>


                <div className="system-messages dialog-center text-center animated faster zoomIn text-center">

                   
                    <div style={{ maxHeight: "20%"}}>
                    <div onClick={this.clearMessagesOfType.bind(this,"warningOrInfo")} className="btn btn-sm btn-info">clear all <i className="fa fa-info-circle" aria-hidden="true"></i></div>
                    <div onClick={this.clearMessagesOfType.bind(this,"alertOrError")} className="btn btn-sm btn-pimatico-orange">clear all <i className="fa fa-exclamation-triangle" aria-hidden="true"></i></div>
                    <div onClick={this.clearMessagesOfType.bind(this,"critical")} className="btn btn-sm btn-danger">clear all <i className="fa fa-times-circle" aria-hidden="true"></i></div>
                    {/* <p>
                        <i className="fa fa-exclamation-triangle fa-2x text-warning"></i>
                    </p> */}
                    </div>
                    <div style={{ height: "80%", overflowY: "auto" }}>
                        {systemMessagesToShow}
                    </div>

                    <div style={{ position: "absolute", top: "5px", right: "5px" }}
                        className="btsn btsn-warning notificatsion-btn" onClick={this.togglePoorConnectionWarning}>
                        <i className="fa fa-times fa-2x pointer"></i>
                    </div>
                </div>
            </div>
        )
    }
}

export default branch({
    route: ["route"],
    poorConnectionWarning: ["ui", "poorConnectionWarning"],
    systemMessages: ["systemMessages"]
}, SystemMessagesModal);