import io from "socket.io-client" 
import EventEmitter from 'events'


class slaveSocketClientClass extends EventEmitter{
	constructor(socketNamespace){
		super();
		this.reconectionAttempt = 0;
		this.socketNamespace = socketNamespace;
		if(socketNamespace.lastIndexOf("?")!==-1){
			console.warn("SOCKET CLIENT NAMESPACE WARNING. NO ? SHOULD BE IN THE NAMESPACE STRING");
		}
		this.socket =  io(this.socketNamespace+'?type=browser',{
			'reconnection': true,
			'reconnectionDelay': 1000,
			'reconnectionDelayMax' : 5000,
			'reconnectionAttempts': 20000
		});
	}

	get Socket(){
		return this.socket;
	}


}

export {slaveSocketClientClass};