import React, { Component } from 'react';
import { branch } from 'baobab-react/higher-order';
import _ from "lodash";
import iconSet, {getIconCssClassFromStyleParams} from "../../utils/fontIcons"

class SelectIcon extends Component {

    iconSelectHandler(icon, e){
        this.props.inputHandler(icon, this.props.deviceForm, !_.isUndefined(this.props.styleParam) ? this.props.styleParam : "");
    }
   
    render() {

        let iconList2 = this.props.iconType ? iconSet[this.props.iconType] : iconSet["default"];
        let iconList = _.map(iconList2, (icon, key)=>{
            return <i key={key} title={icon.name}  onClick={this.iconSelectHandler.bind(this, icon)} 
            className={getIconCssClassFromStyleParams({icon},{isBig:true})}></i>;
        });

        
       /* let iconList = icons.map((item)=>
            <i key={_.uniqueId("grp")} title={item.name}  onClick={this.props.inputHandler} className={item.name +" big-icon"}></i>
        );
        */
       let classForRow = ""
       let classShow = ""
       if(_.isUndefined(this.props.styleParam)){
        classForRow = "icon-selector hidden-icon-select";
        classShow = "icon-selector";
       }
        else {
            classForRow = "icon-selector"
            classShow = "icon-selector";
        }
        return (
            <div className={this.props.ui === true ? classShow : classForRow }>
               {iconList}
            </div>
        );
    }
}

export default branch({
    route: ["route"],
    
}, SelectIcon);