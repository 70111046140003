import React, { Component } from 'react';
import PropTypes from 'prop-types';

class IntegerInput extends Component {
	onChangeHandler(e) {
		this.props.onValueChanged(Number(e.target.value), this.props.inputName); //propagate updated value
	}
	render() {
		const {label= "label", min=0, max=100, value, disabled } = this.props;

		return (
			<div className="">
				<label className="control-label col-sm-2">{label}</label>
				<div className="col-sm-10">
					<input
						min={min}
						max={max}
						onChange={this.onChangeHandler.bind(this)}
						step={1}
						required
						type="number"
						className="form-control"
						value={value}
						placeholder={label}
						disabled={disabled}
					/>
				</div>
				<div className=" clear"></div>
			</div>
		);
	}
}
IntegerInput.propTypes = {
	onValueChanged: PropTypes.func.isRequired,
	label: PropTypes.string.isRequired,
	inputName: PropTypes.string.isRequired,
};

export default IntegerInput;