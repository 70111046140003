import restClient from './restWrapper';

const rest = (path, method, obj, customHeaders) => {
	return restClient(path, method, obj, customHeaders);
};

const corsGet = async (path) => {
	const response = await fetch(path);
	const myJson = await response.json();
	return myJson;
};

export default rest;
export { corsGet };