import React, { Component, Suspense } from 'react';
import { branch } from 'baobab-react/higher-order';
import _ from "lodash";
import uiActions from "../../data/actions/ui";
import { startStopSurveillanceStreamOverWrtc } from "../../data/actions/lazyActions"

//import broadway from "broadway-player"
const broadway = React.lazy(() => import('broadway-player'))

class DoorBellStream extends Component {
	constructor(params) {
		super()
		this.player = new broadway.Player({ useWorker: true, webgl: true, size : {width:320, height:240} });
		this.state = { spinnerHidden: true, streamDataReceived:false }
	}

	componentDidMount() {
		if (!_.isEmpty(this.props.doorBellsActive)) {

		document.getElementById("video-wrapper").appendChild(this.player.canvas);
		window.addEventListener("surveillanceCameraStreamData", this.streamDataReceived.bind(this));

		let nextStreamingState = !this.state.streamingActive;
		this.setState({ streamingActive: nextStreamingState });

		startStopSurveillanceStreamOverWrtc(this.props.activeWrtcPeerKey, parseInt(this.props.doorBellsActive[this.props.doorBellsActive.length - 1].params.camera.value, 10), nextStreamingState ? "start" : "stop").then(() => {
			console.log("streaming activated. Avaiting the stream.");

		}).catch(err => {
			this.setState({ streamingActive: !nextStreamingState });
			console.log("Straeming wont start: ", err);
		})
	}
	}

	streamDataReceived(ev) {
		if (!_.isEmpty(this.props.doorBellsActive)) {

		if (ev.payload.surveillanceCameraId === parseInt(this.props.doorBellsActive[this.props.doorBellsActive.length - 1].params.camera.value, 10)) {
			this.player.decode(ev.payload.streamData);
		}
	}
	}
	componentWillUnmount() {
		window.removeEventListener("surveillanceCameraStreamData", this.streamDataReceived.bind(this));

	}

	onStreamClose() {

		uiActions.removeLastDoorBellInfo()

		startStopSurveillanceStreamOverWrtc(this.props.activeWrtcPeerKey, parseInt(this.props.doorBellsActive[this.props.doorBellsActive.length - 1].params.camera.value, 10), "stop").then(() => {
			console.log("streaming deactivated.");

		}).catch(err => {
			console.log("Straeming can't stop: ", err);
		})
	}


	render() {
		if (_.isEmpty(this.props.doorBellsActive)) {
			return null
		}
		return (       
			<div className="door-bell-wrapper">
				<div >
					<div className="analog-output-control">
						<div>
							<div className="text-center">
							<i className="fa fa-bell fa-3x color-white animated faster swing infinite"></i>
							<div className="color-white">{this.props.doorBellsActive[this.props.doorBellsActive.length - 1].name} is ringing</div>
							<div className="door-bell-close" onClick={this.onStreamClose.bind(this)}>Close</div>

								<div id={"video-wrapper"}>
									{this.state.spinnerHidden === true ? null : <div className="door-bell-spinner"><i className="fa fa-spinner fa-spin fa-4x"></i>
										<div><span>Fetching stream</span></div>
									</div>}
									<span className="video-span"></span>
								</div>
							</div>
						</div>
					</div>


					<div className="clear"> </div>

				</div>
			</div>

		)
	}
}
class DoorBellStreamWrapp extends Component {
	render(){
		return <Suspense fallback="Loading script ... "><DoorBellStream {...this.props}/></Suspense>
	}
}


export default branch({
	route: ["route"],
	serverNotifications: ["masterOnlyModel", "serverNotifications"],
	doorBellsActive: ["doorBellsActive"],
	sessionInfo: ["sessionInfo"],
	showMainMenu: ["ui", "showMainMenu"],
	pathname: ["route", "location", "pathname"],
	activeWrtcPeerKey: ["ui", "activeWrtcPeerKey"],
	slaveConnectionTier: ["ui", "slaveConnectionTier"],
	genericStorages: ["model", "genericstorages"],
}, DoorBellStreamWrapp);



