import React, { Component } from 'react';
import { branch } from 'baobab-react/higher-order';
import _ from "lodash";
import { getIconCssClassFromStyleParams } from "../../utils/fontIcons";

class AddDeviceToScene extends Component {
    constructor() {
        super()
        this.state = {
            deviceIds: [],
            compositeDeviceIds: []
        }
    }
    handleCheckboxChange(id, deviceModel, e) {
        if (deviceModel === "device") {
            let devIds = _.clone(this.state.deviceIds);
            if (_.includes(devIds, id)) {
                devIds = _.filter(devIds, devId => {
                    return devId !== id;
                })
            }
            else {
                devIds.push(id)
            }

            this.setState({
                deviceIds: devIds
            })

        } else {
            let compDevIds = _.clone(this.state.compositeDeviceIds);
            if (_.includes(compDevIds, id)) {
                compDevIds = _.filter(compDevIds, devId => {
                    return devId !== id;
                })
            }
            else {
                compDevIds.push(id)
            }

            this.setState({
                compositeDeviceIds: compDevIds
            })
        }
    }
    setDeviceStates() {
        let devIdArr = [];
        let compositeDeviceIds = [];
        _.forEach(this.props.devicesAlreadyInScene, dev => {
            if (_.isUndefined(dev.compositeDeviceId)) {
                devIdArr.push(dev.deviceId)
            }
            else {
                compositeDeviceIds.push(dev.compositeDeviceId)
            }
        })
        this.setState({ deviceIds: devIdArr, compositeDeviceIds: compositeDeviceIds })
    }
    componentDidMount() {
        if (!_.isUndefined(this.props.devicesAlreadyInScene)) {
            this.setDeviceStates()
        }
    }
    componentDidUpdate(prevProps, prevState) {
        if (this.props.devicesAlreadyInScene && (this.props.devicesAlreadyInScene.length !== prevProps.devicesAlreadyInScene.length)) {
            this.setDeviceStates()
        }
    }
    
    render() {

        var finDevices = [];
        _.forEach(this.props.devices, dev => {

            let isVolumeDevice = false
            if (!_.isUndefined(dev.address)) {
                if (!_.isUndefined(dev.address.plcDeviceType)) {
                    if (dev.address.plcDeviceType === "volumeControll") {
                        isVolumeDevice = true
                    }
                }
            }
            if (dev.type === 0 || dev.type === 1 || dev.type === 5 || isVolumeDevice === true) { //type 5 added on 20.12.20202
                finDevices.push(dev);
            }
        })
        let items = [];
        _.forEach(finDevices, device => {
            items.push(
                <div className="devices-to-add text-center pointer" key={_.uniqueId("devev")} onClick={this.handleCheckboxChange.bind(this, device.id, "device")}>
                    <i className={getIconCssClassFromStyleParams(device.style, { isBig: false })}></i>
                    <span>{device.name}</span>
                    <input onChange={() => { }} type="checkbox" checked={_.includes(this.state.deviceIds, device.id)} name="DeviceId" id="checkbox-1-1" className="regular-checkbox" /><label htmlFor="checkbox-1-1"></label>
                </div>
            )
        })


        var finCompositeDevices = [];
        _.forEach(this.props.compositeDevices, dev => {
            if (dev.type === "shutters" || dev.type === "thermostat" || dev.type === "linearWindow" || dev.type === "softThermostat" || dev.type === "underfloorHeating"  || dev.type === "softIR" ) {
                finCompositeDevices.push(dev);
            }
        })
        let compositeItems = [];
        _.forEach(finCompositeDevices, device => {
            compositeItems.push(
                <div className="devices-to-add text-center pointer" key={_.uniqueId("devev")} onClick={this.handleCheckboxChange.bind(this, device.id, "compositeDevice")}>
                    <i className={getIconCssClassFromStyleParams(device.style, { isBig: false })}></i>
                    <span>{device.name}</span>
                    <input onChange={() => { }} type="checkbox" checked={_.includes(this.state.compositeDeviceIds, device.id)} name="DeviceId" id="checkbox-1-1" className="regular-checkbox" /><label htmlFor="checkbox-1-1"></label>
                </div>
            )
        })



        return (
            <div className={this.props.isShown === true ? "add-device-to-scene-dialog" : "hidden"}>


                <div className="add-devices-to-scene-div">
                    {compositeItems}
                    {items}
                </div>

                <div className="add-device-to-scene-buttons">
                    <div className="text-center">
                        <button disabled={_.isEmpty(finDevices) ? true : false} onClick={this.props.addDevicesToSceneForm.bind(this, this.state.deviceIds, this.state.compositeDeviceIds, this.props.hideDialog)} className="btn btn-sm btn-success ">Save</button>
                        <div onClick={this.props.hideDialog} className="btn btn-sm btn-info add-device-to-scene-button">Dismiss</div>
                    </div>

                </div>


            </div>

        )
    }
}

export default branch({
    sceneForm: ["forms", "scene"],
    // devices: ["model", "devices"],
    devices: ['monkeys', 'selfContainedDevices'],
    route: ["route"],
    ui: ["ui", "showMainMenu"],
    // compositeDevices: ["model", "compositedevices"]
    compositeDevices: ['monkeys', 'accessibleCompositeDevices']
}, AddDeviceToScene);

