import React, { Component } from 'react';
import { branch } from 'baobab-react/higher-order';
import actions from "../../data/actions/actions";
import _ from "lodash";
import { deleteItem } from "../../data/actions/formActions";
import DeviceItems from "../../components/deviceItems/deviceItems";
import { loadFormData } from "../../data/actions/formStateController";
import saveFormData from "../../data/actions/formActions";
import ConfirmDelete from "../../components/confirmBox/confirmDelete";
import { hashHistory } from 'react-router';
import SelectInterruptMode from "../../components/selectInterruptMode";
import updateForm from "../../data/actions/formStateController";
import showHeaderNotification from "../../utils/showNotification"

import checkRoleAccess from "../../utils/checkRoleAccess"


class SceneView extends Component {
  constructor() {
    super();

    // this.setAnalogOutputValueFromSceneQuickEdit = _.debounce(this.setAnalogOutputValueFromSceneQuickEdit,1000);
    this.state = {
      isDeleteDialogShown: false,
      enableSaveSceneAfterEdit: false,
      showSelectInterrupt: false
    }
  }
  componentDidMount() {
    loadFormData("scene", parseInt(this.props.route.params.sceneId, 10));
  }

  componentDidUpdate(prevProps) {
    if (this.props.route.params.sceneId !== prevProps.route.params.sceneId) {
      loadFormData("scene", parseInt(this.props.route.params.sceneId, 10));
    }
  }


  delete = (group, id) => {
    deleteItem(group, id).then(response => {
      this.setState({
        isDeleteDialogShown: false
      })
      hashHistory.goBack();

    }).catch(err => {
      this.setState({
        isDeleteDialogShown: false
      })
    });

  }

  playScene = (sceneId) => {
    actions.playScene(sceneId);
  }

  stopScene = (sceneId) => {
    actions.stopScene(sceneId);
  }


  deviceEdit(id, name) {
    return true;
  }
  showDeleteDialog() {
    this.setState({
      isDeleteDialogShown: true
    })
  }

  hideDeleteDialog() {
    this.setState({
      isDeleteDialogShown: false
    })
  }
  deviceSchedule = (id) => {
    window.location = "#/device/" + id + "/schedule";
  }
  saveScene(e) {
    e.preventDefault();
    saveFormData("scene").then(response => {

      // return null;
    }).catch(err => {
      console.warn('err', err);
    })
  }

  shallowDeviceCopy(device) {
    let clonedDevice = {};
    let attributes = ["id", "name", "address", "value", "style", "type", "connectionState", "enableInterrupts", "isLocked", "params", "startupValue", "lockState"];
    _.forIn(device, (val, key) => {
      if (_.includes(attributes, key)) {
        clonedDevice[key] = val;
      }
    })
    return clonedDevice;
  }
  shallowCompositeDeviceCopy(device) {
    let clonedDevice = {};
    let attributes = ["id", "name", "address", "value", "style", "type", "params", "lockState"];
    _.forIn(device, (val, key) => {
      if (_.includes(attributes, key)) {
        clonedDevice[key] = val;
      }
    })
    return clonedDevice;
  }
  redirectToSchedule() {
    hashHistory.push("/scene/" + this.props.route.params.sceneId + "/scheduleList")
  }

  toggleSelectInterrupt(e) {
    this.setState({
      showSelectInterrupt: this.state.showSelectInterrupt === true ? false : true
    })
  }

  selectInterruptMode(value) {
    var obj = {};
    obj["interruptMode"] = parseInt(value, 10);
    setTimeout(() => {
      this.toggleSelectInterrupt()
    }, 200);
    updateForm("scene", obj);
  }

  lockState(sceneId, lockState, e) {
    let currentStateValue = _.isUndefined(lockState) ? true : !lockState

    loadFormData("scene", parseInt(sceneId, 10));

    var obj = {};
    obj["lockState"] = currentStateValue;
    updateForm("scene", obj);

    saveFormData("scene").then(res => {
      showHeaderNotification(true, 3000, "Scene state " + (currentStateValue === true ? "" : "un") + "locked")
    })
  }

  render() {

    var sceneActiveDeviceState = [];
    _.forEach(_.clone(this.props.sceneForm.params.sceneActiveDeviceState), devState => {
      if(devState.deviceId || devState.deviceId === 0){
            let device = _.find(this.props.devices, {id:parseInt(devState.deviceId, 10)})
            if(device){
              let clone = this.shallowDeviceCopy(device);
              clone.value = devState.value;
              sceneActiveDeviceState.push(clone)
            }else{
              let style = {}
              style["icon"] = {}
              sceneActiveDeviceState.push({deleted:true, name:"Deleted/hidden device", id:devState.deviceId, style:style, value:devState.value})
            }            
      }
      if(devState.compositeDeviceId || devState.compositeDeviceId === 0){
            let compDevice = _.find(this.props.compositeDevices, {id:parseInt(devState.compositeDeviceId, 10)})
            if(compDevice){

            let clone = this.shallowCompositeDeviceCopy(compDevice);
            clone.value = devState.value;
            sceneActiveDeviceState.push(clone)
            }else{
              let style = {}
              style["icon"] = {}
              sceneActiveDeviceState.push({deleted:true, name:"Deleted/hidden device", id:devState.compositeDeviceId, style:style, value:devState.value})
            }
      }
      
      
    })

    var sceneExitDeviceState = [];
    _.forEach(_.clone(this.props.sceneForm.params.sceneExitDeviceState), devState => {
      if(devState.deviceId || devState.deviceId === 0){
        let device = _.find(this.props.devices, { id: parseInt(devState.deviceId, 10) })
        if (device) {
          let clone = this.shallowDeviceCopy(device);
          clone.value = devState.value;
          sceneExitDeviceState.push(clone)
        } else {
          let style = {}
          style["icon"] = {}
          sceneExitDeviceState.push({deleted:true,  name: "Deleted/hidden device", id: devState.deviceId, style: style, value: devState.value })
        }
      }
      if(devState.compositeDeviceId || devState.compositeDeviceId === 0){
        let compDevice = _.find(this.props.compositeDevices, { id: parseInt(devState.compositeDeviceId, 10) })
        if (compDevice) {
          let clone = this.shallowCompositeDeviceCopy(compDevice);
          clone.value = devState.value;
          sceneExitDeviceState.push(clone)
        } else {
          let style = {}
          style["icon"] = {}
          sceneExitDeviceState.push({deleted:true,  name: "Deleted/hidden device", id: devState.compositeDeviceId, style: style, value: devState.value })
        }
      }
    })

    // if (_.isEmpty(this.props.sceneForm.params.sceneActiveDeviceState)) {
    //   return null;
    // }

    let compositeActiveArrayFromScene = []
    _.forEach(sceneActiveDeviceState, dev => {
      if (dev.type === "shutters" || dev.type === "thermostat"|| dev.type === "linearWindow" || dev.type === "softThermostat" || dev.type === "underfloorHeating" || dev.type === "softIR" ) {
        compositeActiveArrayFromScene.push(dev)
      }
    })

    let devicesActiveArrayFromScene = _.difference(sceneActiveDeviceState, compositeActiveArrayFromScene)


    let compositeExitArrayFromScene = []
    _.forEach(sceneExitDeviceState, dev => {
      if (dev.type === "shutters" || dev.type === "thermostat"|| dev.type === "linearWindow" || dev.type === "softThermostat" || dev.type === "underfloorHeating" || dev.type === "softIR") {
        compositeExitArrayFromScene.push(dev)
      }
    })
    let devicesExitArrayFromScene = _.difference(sceneExitDeviceState, compositeExitArrayFromScene)


    let iconClass = "fa fa-bolt";
    switch (this.props.sceneForm.interruptMode) {
      case 0:
        iconClass = "fa fa-bolt interruptsDisabled interruptState fa-2x pointer";
        break;
      case 1:
        iconClass = "fa fa-bolt  text-success interruptState fa-2x pointer";
        break;
      case 2:
        iconClass = "fa fa-bolt interruptsOutEnabled interruptState fa-2x pointer";
        break;
      case 3:
        iconClass = "fa fa-bolt interruptsInEnabled interruptState fa-2x pointer";
        break;
      default:
        iconClass = "fa fa-bolt text-success interruptState fa-2x pointer";
        break;
    }


    return (
      <div className="scene-index-wrapper">

        <ConfirmDelete hideDeleteDialog={this.hideDeleteDialog.bind(this)} isDeleteDialogShown={this.state.isDeleteDialogShown} delete={this.delete.bind(this, "scene", parseInt(this.props.route.params.sceneId, 10))} />
        <div className={(this.props.showScrollbar === true ? "show-scrollbar" : "") + " content-w-background "}>
        <div className="animated faster fadeIn">

        {this.props.dashboardOnlyRoutes && this.props.dashboardOnlyRoutes !== null && this.props.dashboardOnlyRoutes.toString() === "true" ? null :
          <div className="text-center scene-events-link"><span onClick={this.redirectToSchedule.bind(this)} className="text-white pointer">
          <i className="fa fa-calendar" aria-hidden="true"></i> event list</span>

              {this.props.sceneForm.lockState === true ? <span onClick={this.lockState.bind(this, this.props.sceneForm.id, this.props.sceneForm.lockState)} 
              className="pointer"><i className="fa fa-unlock"></i> unlock scene</span> : <span onClick={this.lockState.bind(this, this.props.sceneForm.id, this.props.sceneForm.lockState)} className="pointer"><i className="fa fa-lock"></i> lock scene</span>}
            
          </div>}
         
          {this.props.settings.general.uiInterruptManagement.value === true ? <div onClick={this.toggleSelectInterrupt.bind(this)}
            className="edit-widget-icon pointer"><i className={iconClass}></i></div>
            : null}
          <SelectInterruptMode isShown={this.state.showSelectInterrupt} value={_.isUndefined(this.props.sceneForm.interruptMode) ? 1 : this.props.sceneForm.interruptMode} selectInterruptMode={this.selectInterruptMode.bind(this)} />

            <div style={{ pointerEvents: "none" }}>
              <h5 className="">States while scene is active:</h5>
              <DeviceItems compositeDevices={compositeActiveArrayFromScene} devices={devicesActiveArrayFromScene} sceneParameter={"active"} />

              {/* <div className="bottom-with-margin"> </div> */}

              {_.isUndefined(this.props.sceneForm.params.sceneExitDeviceState) || _.isEmpty(this.props.sceneForm.params.sceneExitDeviceState) ? <div></div> :
                <div className="row no-margin"><h5>States after scene finishes:</h5>
                  <DeviceItems compositeDevices={compositeExitArrayFromScene} devices={devicesExitArrayFromScene} sceneParameter={"exit"} />
                </div>}
            </div>
        

          <div className="row"></div>

          <div className="row center-buttons">
          {this.props.dashboardOnlyRoutes && this.props.dashboardOnlyRoutes !== null && this.props.dashboardOnlyRoutes.toString() === "true" ?    <div>
          <div className="groupSaveButton text-center" onClick={() => { hashHistory.goBack()}}>
                <i className="fa fa-arrow-left group-button-icon" aria-hidden="true"></i>
                <span className="m-left-5">Go back</span>
              </div>
            
            </div>  :      <div className="text-center scene-buttons">
              <button
                className={!_.isUndefined(_.find(this.props.scenes, sce => sce.id === parseInt(this.props.route.params.sceneId, 10))) ? (_.find(this.props.scenes, sce => sce.id === parseInt(this.props.route.params.sceneId, 10)).state === 1 ? "hidden" : "scene-manage-button") : "scene-manage-button"}

                disabled={!_.isUndefined(_.find(this.props.scenes, sce => sce.id === parseInt(this.props.route.params.sceneId, 10))) ? (_.find(this.props.scenes, sce => sce.id === parseInt(this.props.route.params.sceneId, 10)).state === 1 ? true : false) : false}
                onClick={() => this.playScene(parseInt(this.props.route.params.sceneId, 10))} >
                <i className="fa fa-play group-button-icon" aria-hidden="true"></i>
                <span className="m-left-5">Play scene</span>
              </button>

              <button
                className={!_.isUndefined(_.find(this.props.scenes, sce => sce.id === parseInt(this.props.route.params.sceneId, 10))) ? (_.find(this.props.scenes, sce => sce.id === parseInt(this.props.route.params.sceneId, 10)).state === 1 ? "scene-manage-button" : "hidden") : "hidden"}
                onClick={() => this.stopScene(parseInt(this.props.route.params.sceneId, 10))}>
                <i className="fa fa-stop group-button-icon" aria-hidden="true"></i>
                <span className="m-left-5">Stop scene</span>
              </button>


              {
                 checkRoleAccess("admin", "systemIntegrator", "advancedUser") ?
                <div className="pull-left scene-manage-button" onClick={() => this.showDeleteDialog()}>
                  <i className="fa fa-trash group-button-icon" aria-hidden="true"></i>
                  <span className="m-left-5">Delete scene</span>
                </div> : null
              }


              {
                 checkRoleAccess("admin", "systemIntegrator", "advancedUser") ?
                 <div className="pull-right scene-manage-button" onClick={() => window.location = "#/scene/" + this.props.route.params.sceneId + "/edit"}>
                  <i className="fa fa-pencil-square-o group-button-icon" aria-hidden="true"></i>
                  <span className="m-left-5">Edit scene</span>
                </div> : null
              }
              

              {/* <button
                className="scene-manage-button"
                disabled={this.state.enableSaveSceneAfterEdit === true ? true : false}
                onClick={this.saveScene.bind(this)}>
                <i className="fa fa-save group-button-icon" aria-hidden="true"></i>
                <span className="m-left-5">Save scene</span>
              </button> */}
            </div>}


          </div>
          {/* <div className="bottom-with-margin"> </div>
          <div className="bottom-with-margin"> </div>
          <div className="bottom-with-margin"> </div> */}
           </div>
        </div>
      </div>
    )
  }
}

export default branch({
  scenes: ['model', 'scenes'],
  // devices: ['model', 'devices'],
  // devices: ['model', 'devices'],
  devices: ['monkeys', 'accessibleDevices'],
  // compositeDevices: ["model", "compositedevices"],
  compositeDevices: ['monkeys', 'accessibleCompositeDevices'],
  devicegroups: ['model', 'devicegroups'],
  route: ["route"],
  showMainMenu: ["ui", "showMainMenu"],
  showDeleteDialog: ["ui", "showDeleteDialog"],
  enableSaveSceneAfterEdit: ["ui", "enableSaveSceneAfterEdit"],
  sceneForm: ['forms', 'scene'],
  showScrollbar: ["ui", "showScrollbar"],
  settings: ["readOnlyModel", "settings"],
  dashboardOnlyRoutes:["dashboardOnlyRoutes"]
}, SceneView);