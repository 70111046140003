import React, { Component } from 'react';
import { branch } from 'baobab-react/higher-order';
import _ from "lodash";


class ConfirmDialog extends Component {

    render() {

        let classForDialog = "delete-dialog";
        if(!_.isUndefined(this.props.blindsControl)){
            classForDialog = "delete-dialog col-xs-12 col-md-12"
        }

        let additionalClass = ""
        if(!_.isUndefined(this.props.blindsControl)){
            additionalClass = "dialog-additional-style-for-blinds"
        }else {
            additionalClass = "dialog-additionalStyle"

        }
      

        return (
            <div className={this.props.isDialogShown === false ? "hidden" : classForDialog + " "+ additionalClass}>
                <div className="dialog-center animated faster zoomIn center-style" >
                    <div  className="dialog-title title-style"><span>Confirm <i onClick={this.props.hideDialog} className="fa fa-times pull-right pointer" aria-hidden="true"></i></span></div>
                    <div className="dialog-content text-center content-style">
                        <span>{this.props.message}</span>
                      
                    </div>
                    <div  className="text-center buttons-style">
                            <button  onClick={this.props.yesAction} className="m-right-10 btn btn-danger btn-sm">Yes</button>
                            <button  onClick={this.props.noAction} className="m-left-10 btn btn-default btn-sm">No</button>
                        </div>
                </div>
            </div>
        )
    }
}

export default branch({
    route: ["route"],
    ui: ["ui", "showDeleteDialog"]
}, ConfirmDialog);
