import React, { Component } from 'react';
import { branch } from 'baobab-react/higher-order';
import uiActions from "../../data/actions/ui";
import DeviceItems from "../../components/deviceItems/deviceItems";
import ChangeStateFailInfo from "../../components/notification/changeStateFailInfo";
// import { hashHistory } from 'react-router';
import actions from "../../data/actions/actions"

class AllDevices extends Component {
  setSearchedDeviceTerm(e) {
    uiActions.setDeviceSearchTerm(e.target.value)
  }

  deviceEdit = (id) => {
    window.location = "#/device/" + id + "/edit";
  }
  deviceSchedule = (id) => {
    window.location = "#/allDevices/device/" + id + "/scheduleList";
  }

  enableRMCUAutodetection = () => {
    actions.enableRMCUAutoDiscovery();
  }

  render() {
    return (<div>
      <ChangeStateFailInfo />
      <div className={(this.props.showScrollbar === true ? "show-scrollbar" : "") + " content-w-background "}>

        <div className="animated faster fadeIn">

          <div className="search-devices text-center">

            <div className="color-white"><i className="fa fa-search color-white m-right-5" aria-hidden="true"></i>
              <input type="text" className="form-control search-devices-input" placeholder="Search" onChange={this.setSearchedDeviceTerm.bind(this)} value={this.props.searchedDeviceTerm} />
            </div></div>


          <DeviceItems compositeDevices={this.props.compositeDevices} devices={this.props.devices} 
          deviceEdit={this.deviceEdit} deviceSchedule={this.deviceSchedule} />
        </div>
      </div>
    </div>

    )
  }
}

export default branch({
  // devices: ['monkeys', 'selfContainedDevices'],
  searchedDeviceTerm: ["searchedDevice"],
  devices: ["monkeys", "devicesFilteredBySearchTerm"],
  compositeDevices: ["monkeys", "compositeDevicesFilteredBySearchTerm"],
  // devices: ["model", "devices"],
  devicegroups: ['model', 'devicegroups'],
  // groups: ['model', 'groups'],
  groups: ["monkeys", "accessibleGroups"],
  route: ["route"],
  showMainMenu: ["ui", "showMainMenu"],
  isEngenieringOptionsEnabled: ["userLevelMonkeys", "isEngenieringOptionsEnabled"],
  settings: ["readOnlyModel", "settings"],
  showScrollbar: ["ui", "showScrollbar"],

}, AllDevices);