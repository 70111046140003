import React, { Component } from 'react';
import { branch } from 'baobab-react/higher-order';
import _ from "lodash";
import updateForm, { loadFormData, sanitizeForm } from "../../data/actions/formStateController";
import saveFormData from "../../data/actions/formActions";
import SelectIcon from "../../components/iconSelect";
// import actions from "../../data/actions/actions";
import { getIconCssClassFromStyleParams } from "../../utils/fontIcons"
import { hashHistory } from "react-router";

import checkRoleAccess from "../../utils/checkRoleAccess"

class GroupForm extends Component {
  constructor() {
    super()
    this.state = {
      testValue: "",
      saveButtonDisabled: false,
      showIconSelect: false
    }
  }
  submit(e) {
    e.preventDefault();
    this.setState({
      saveButtonDisabled: true
    })
    saveFormData("group").then(response => {
      this.setState({
        saveButtonDisabled: false
      })
      hashHistory.goBack()

    }).catch(err => {
      this.setState({ saveButtonDisabled: false })
    })
  }
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      sanitizeForm("group");
    }
  }
  componentDidMount() {
    if (!_.isUndefined(this.props.route.params.groupId)) {
      loadFormData("group", parseInt(this.props.route.params.groupId, 10));
    }
    else {
      sanitizeForm("group")
    }
  }
  inputHandler(e) {
    var obj = {};
    if (_.isInteger(parseInt(e.target.value, 10))) {
      obj[e.target.name] = parseInt(e.target.value, 10);
    } else {
      obj[e.target.name] = e.target.value;
    }
    updateForm("group", obj);
  }
  iconSelectHandler(icon) {
    var style = {};
    var obj = { style };
    obj.style["icon"] = icon;
    updateForm("group", obj);

    this.setState({
      showIconSelect: !this.state.showIconSelect
    })
    // actions.toggleIconSelect();    
  }
  onInputTestChange(e) {
    this.setState({
      testValue: e.target.value
    })
  }
  render() {

    if(!checkRoleAccess("admin", "systemIntegrator", "advancedUser")){
     return "Insufficient permissions."
    }

    return (
      <div>

        <div className="container">
          <form className="form-horizontal col-lg-8 col-md-8" autoComplete="off">
            <div className="form-group">
              <label className="control-label col-sm-2" htmlFor="name">Group Name:</label>
              <div className="col-sm-10">
                <input autoComplete="off" onChange={this.inputHandler} value={this.props.groupForm.name} required type="text" className="form-control" name="name" id="name" placeholder="Enter group name" />
              </div>
            </div>
            <div className="form-group">
              <label className="control-label col-sm-2" htmlFor="pwd">Select Icon:</label>
              <div className="col-sm-10 select-group">
                <div onClick={() => this.setState({showIconSelect: !this.state.showIconSelect})} className="select-icon">
                  <i className={getIconCssClassFromStyleParams(this.props.groupForm.style, { isBig: true })}></i> </div>
                <SelectIcon ui={this.state.showIconSelect} iconType="group" inputHandler={this.iconSelectHandler.bind(this)} />
              </div>
            </div>
            <div className="form-group">
            </div>
            <div className="form-group">
              <div className="col-sm-offset-2 col-sm-10 ">
                <button type="submit" disabled={this.state.saveButtonDisabled} onClick={this.submit.bind(this)} className="btn btn-sm btn-info text-center">Save</button>
              </div>
            </div>
          </form>
        </div>
        {/* <div className="bottom-with-margin"> </div>
        <div className="bottom-with-margin"></div> */}
      </div>
    )
  }
}

export default branch({
  // groups: ['model', 'groups'],
  groups: ["monkeys", "accessibleGroups"],
  route: ["route"],
  groupForm: ["forms", "group"],
  showMainMenu: ["ui", "showMainMenu"],

}, GroupForm);