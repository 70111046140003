import Baobab from "baobab";
//import _ from "lodash";
var monkey = Baobab.monkey;


//ako su engeniering opcije upaljene, korisnik ima pristup svemu čemu inače nebi imao
//ako 

var ulMonkeys = {
	isEngenieringOptionsEnabled:monkey({
		cursors: {
			userProfile:["sessionInfo","userProfile"], //sessionInfo.userProfile.level
			settings: ["readOnlyModel","settings"]
		},
		get: (data)=>{
			if(data.userProfile.level>0){
				return true
			} else {
				try {
					if(data.settings.general.preventEngenieringMode && data.settings.general.preventEngenieringMode.value===true){
						return false;
					} else {
						return data.settings.general.engenieringMode.value;
						//return _.isUndefined(data.settings.general.engenieringMode.value) ? data.settings.general.engenieringMode.defaultValue : data.settings.general.engenieringMode.value;
					}
				} catch(err){
					return false;
				}
			}
		},
		options: {
			immutable: true
		}
	}),
	showDashboard:monkey({ 
		cursors: {
			settings: ["readOnlyModel","settings"]
		},
		get: (data)=>{
			try {
				return data.settings.general.uiDashboard.value;
				//return _.isUndefined(data.settings.general.uiDashboard.value) ? data.settings.general.uiDashboard.defaultValue : data.settings.general.uiDashboard.value;
			} catch (err){
				return false;
			}
		},
		options: {
			immutable: true
		}
	}),
	showDevices:monkey({ 
		cursors: {
			settings: ["readOnlyModel","settings"]
		},
		get: (data)=>{
			try {
				return data.settings.general.uiDevices.value;
				//return _.isUndefined(data.settings.general.uiDevices.value) ? data.settings.general.uiDevices.defaultValue : data.settings.general.uiDevices.value;
			} catch(err){
				return false;
			}			
		},
		options: {
			immutable: true
		}
	}),
	showGroups:monkey({ 
		cursors: {
			settings: ["readOnlyModel","settings"]
		},
		get: (data)=>{
			try {
				return data.settings.general.uiGroups.value;
				//return _.isUndefined(data.settings.general.uiGroups.value) ? data.settings.general.uiGroups.defaultValue : data.settings.general.uiGroups.value;
			} catch(err){
				return false;
			}
		},
		options: {
			immutable: true
		}
	}),
	showScenes:monkey({ 
		cursors: {
			settings: ["readOnlyModel","settings"]
		},
		get: (data)=>{
			try {
				return data.settings.general.uiScenes.value;
				//return _.isUndefined(data.settings.general.uiScenes.value) ? data.settings.general.uiScenes.defaultValue : data.settings.general.uiScenes.value;
			} catch(err){
				return false;
			}
		},
		options: {
			immutable: true
		}
	}),

	showCodeEditor: monkey({ 
		cursors: {
			settings: ["readOnlyModel","settings"]
		},
		get: (data)=>{
			try {
				return data.settings.general.uiCodeEditor.value;
				//return _.isUndefined(data.settings.general.uiCodeEditor.value) ? data.settings.general.uiCodeEditor.defaultValue : data.settings.general.uiCodeEditor.value;
			}catch (err){
				return false;
			}
		},
		options: {
			immutable: true
		}
	}),
	showInterruptManagement: monkey({ 
		cursors: {
			settings: ["readOnlyModel","settings"]
		},
		get: (data)=>{
			try {
				return data.settings.general.uiInterruptManagement.value;
				//return _.isUndefined(data.settings.general.uiInterruptManagement.value) ? data.settings.general.uiInterruptManagement.defaultValue : data.settings.general.uiInterruptManagement.value;
			}catch (err){
				return false;
			}
		},
		options: {
			immutable: true
		}
	}),
	renderMainMenu:monkey({ 
		cursors: {
			userProfile:["sessionInfo","userProfile"],
			settings: ["readOnlyModel","settings"]
		},
		get: (data)=>{
			
			//console.log(data, data.settings.general.uiMainMenu.value);
			try {
				if(data.userProfile.level>0){
					return true;
				}
				////mainMenuShownByAdmin: ["readOnlyModel", "settings", "general", "uiMainMenu", "value"],
				//(this.props.mainMenuShownByAdmin === true || this.props.sessionInfo.userProfile.level === 2)
				return data.settings.general.uiMainMenu.value;
			}catch (err){
				return true;
			}
		},
		options: {
			immutable: true
		}
	}),
	/*
	showUserProfile:monkey({ 
		cursors: {
			settings: ["readOnlyModel","settings"]
		},
		get: (data)=>{
			try {
				return data.settings.general.uiUserProfile.value;
				//return _.isUndefined(data.settings.general.uiUserProfile.value) ? data.settings.general.uiUserProfile.defaultValue : data.settings.general.uiUserProfile.value;
			}catch (err){
				return false;
			}
		},
		options: {
			immutable: true
		}
	}),*/
	/*-----------------------*/

}

var adminSettings = {
	hideGeneralSettingsTab:monkey({ 
		cursors: {
			userProfile:["sessionInfo","userProfile"], //sessionInfo.userProfile.level
			settings: ["readOnlyModel","settings"]
		},
		get: (data)=>{
			if(data.userProfile.level>0){
				return false //dont hide for privileged user
			} else {
				try {
					return data.settings.admin.hideGeneralSettingsTab.value;
					//return _.isUndefined(data.settings.admin.hideGeneralSettingsTab.value) ? data.settings.admin.hideGeneralSettingsTab.defaultValue : data.settings.admin.hideGeneralSettingsTab.value;
				}catch (err){
					return false;
				}
			}
		},
		options: {
			immutable: true
		}
	}),
	hideSystemConfigurationMenu:monkey({ 
		cursors: {
			userProfile:["sessionInfo","userProfile"], //sessionInfo.userProfile.level
			settings: ["readOnlyModel","settings"]
		},
		get: (data)=>{
			if(data.userProfile.level>0){
				return false //dont hide for privileged user
			} else {
				try {
					return data.settings.admin.hideSystemConfigurationMenu.value;
					//return _.isUndefined(data.settings.admin.hideSystemConfigurationMenu.value) ? data.settings.admin.hideSystemConfigurationMenu.defaultValue : data.settings.admin.hideSystemConfigurationMenu.value;
				}catch (err){
					return false;
				}
			}
		},
		options: {
			immutable: true
		}
	}),
	
}

export default {
	isEngenieringOptionsEnabled:ulMonkeys.isEngenieringOptionsEnabled,
	showAddOnsTab:ulMonkeys.isEngenieringOptionsEnabled,
	showDashboard:ulMonkeys.showDashboard,
	showDevices:ulMonkeys.showDevices,
	showGroups:ulMonkeys.showGroups,
	showScenes:ulMonkeys.showScenes,
	showCodeEditor:ulMonkeys.showCodeEditor,
	showInterruptManagement:ulMonkeys.showInterruptManagement,
	showUserProfile:ulMonkeys.showUserProfile,
	renderMainMenu: ulMonkeys.renderMainMenu,
	hideGeneralSettingsTab:adminSettings.hideGeneralSettingsTab,
	hideSystemConfigurationMenu:adminSettings.hideSystemConfigurationMenu,
	
};