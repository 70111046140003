import React, { Component } from 'react';
import { branch } from 'baobab-react/higher-order';
import _ from "lodash"
//import { hashHistory } from 'react-router';
import actions from "../../data/actions/actions"
import ConfirmDelete from "../../components/confirmBox/confirmDelete";

import checkRoleAccess from "../../utils/checkRoleAccess"

class GatewayModule extends Component {
	render(){
		let counter = 0;
		return <div> 
		<h3>GATEWAY </h3>
		<b>Module I/O:</b>
		{
			_.map(this.props.info.names, n=>{
				return <div key={counter++} style={{marginLeft:"0.5rem", marginBottom:"0.5rem", backgroundColor:"#60aac3"}} className="badge">{n}</div>;
			})
		}
		<hr style={{borderColor:"#497080"}}/>
		</div>
	}
}

class ModbusPowermeter extends Component {
	render(){
		let counter = 0;
		return <div> <h3>MODBUS: {this.props.info.deviceType}</h3> 

		<div className="row">

			<div className="col-md-4">
			Modbus ID: {this.props.info.modbusId}<br/>
			Group: {this.props.info.grp}<br/>
			Modbus port number: {this.props.info.portConfigId}<br/>
			</div>
			<div className="col-md-6"> <button disabled className="btn btn-sm btn-info">EDIT MODBUS INFO</button>  </div>
		</div>

		<b>Module I/O:</b>
		{
			_.map(this.props.info.names, n=>{
				return <div key={counter++} style={{marginLeft:"0.5rem", marginBottom:"0.5rem", backgroundColor:"#60aac3"}} className="badge">{n}</div>;
			})
		}
		<hr style={{borderColor:"#497080"}}/>
		</div>
	}
}

class RMCUDevice extends Component {
	constructor(){
		super();
		this.state = {
			plc_address:"",
			showChangeAddressInput : false,
			showDeleteDialog:false
		}
	}
	inputHandler(e){
		this.setState({[e.target.name]:e.target.value})
	}
	applyChanges(e){
		if(this.props.info.plc_address!== this.state.plc_address && this.state.plc_address !== ""){
			actions.applyRMCUModuleAddressChnage(this.props.info.plc_address, this.state.plc_address).then(()=>{
				this.setState({showChangeAddressInput:false, plc_address:""});
			}).catch(err=>{
				console.warn(err)
			})
		} else {
			this.setState({showChangeAddressInput:false, plc_address:""})
		}
	}
	deleteModule(){
		actions.bulkRemoveDevices(this.props.info.ids).then(()=>{
			this.setState({showDeleteDialog:false})
		}).catch(err=>{
			console.warn(err)
		})
	}
	render(){
		let formArea = this.state.showChangeAddressInput ? <div>
			<span style={{color:"red"}}>WARNING: Do not make address collisions or the application may crash. Consult with the support and make backup before applying the changes.</span>
			<input name="plc_address" onChange={this.inputHandler.bind(this)} type="text" className="form-control" value={this.state.plc_address} />
			<button onClick={this.applyChanges.bind(this)} className="btn btn-danger">APPLY CHANGES</button>
			<button onClick={()=>{ this.setState({showChangeAddressInput:false, plc_address:""}) }} className="btn btn-info">CANCEL</button>
			<small>(device will restart after changes are applyed)</small>
		</div>:
		<div>
			<button onClick={()=>{ this.setState({showChangeAddressInput:true, plc_address:this.props.info.plc_address}) }} className="btn btn-sm btn-danger"><i className="fa fa-edit"/>&ensp;CHANGE MODULE ADDRESS</button>
			<button onClick={()=>{ this.setState({showDeleteDialog:true}) }} className="btn btn-sm btn-danger"><i className="fa fa-trash"/>&ensp;REMOVE MODULE</button>
		</div>


		let counter = 0;
		return <div>

		<ConfirmDelete hideDeleteDialog={()=>{
			this.setState({showDeleteDialog:false})
		}} isDeleteDialogShown={this.state.showDeleteDialog} delete={this.deleteModule.bind(this)} />

		<h3>RMCU: {this.props.info.plcDeviceType}</h3> 

		<div className="row">
			<div className="col-md-4">
			ADDRESS: {this.props.info.plc_address}  <br/>
			RMCU port number: {this.props.info.portConfigId}<br/>
			</div>
			<div className="col-md-6"> {formArea} </div>
		</div>

		
		<b>Module I/O:</b>
		{
			_.map(this.props.info.names, n=>{
				return <div key={counter++} style={{marginLeft:"0.5rem", marginBottom:"0.5rem", backgroundColor:"#60aac3"}} className="badge">{n}</div>;
			})
		}
		<hr style={{borderColor:"#497080"}}/>
		</div>
	}
}


class UnclasifiedModule extends Component {
	render(){
		return <div> <h3>UnclasifiedModule</h3> </div>
	}
}

class PsyhicalDevicesExplorer extends Component {
	render(){
		if(!checkRoleAccess("admin", "systemIntegrator")){
         return "Insufficient permissions."
        }

		let renderModules = [];
		_.forEach(this.props.hwModules, info=>{
			if(info.bus==="gpio"){
				renderModules.push(<GatewayModule key={info.key} info={info}/>)
			} else if(info.bus==="rmcu"){
				renderModules.push(<RMCUDevice key={info.key} info={info}/>)
			} else if(info.bus==="modbus"){
				if(info.deviceType==="sdm230"){
					renderModules.push(<ModbusPowermeter key={info.key} info={info}/>)
				} else {
					renderModules.push(<UnclasifiedModule key={info.key} info={info}/>)
				}
			} else {
				console.log("unclasified",info);
				renderModules.push(<UnclasifiedModule key={info.key} info={info}/>)
			}
		})
		return <div className="show-scrollbar padding-10">
				{renderModules}
		</div>
	}
}

export default branch({
	hwModules: ['monkeys', 'hardwareModulesInfo'],
}, PsyhicalDevicesExplorer);

