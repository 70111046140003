import React, { Component } from 'react';
import { branch } from 'baobab-react/higher-order';
import _ from "lodash";
import moment from "moment";
// import ConfirmBox from "../../components/confirmBox/confirmDialog";
// import showHeaderNotification from '../../utils/showNotification';
// import WarningDialog from "../../components/warningDialog";
// import 'moment/locale/hr'  // without this line it didn't work
import { hashHistory } from 'react-router';
import uiActions from "../../data/actions/ui"

import { powerManagementConsumption } from "../../data/actions/lazyActions";


const getUrlParameterByName = (paramname, url) => {
    if (!url) url = window.location.href;
    paramname = paramname.replace(/[[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + paramname + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}

class PowerManagementModal extends Component {
    constructor() {
        super();
        this.state = {
            isDialogShown: false,
            selectedDay: moment().clone(),
            totalKwh: "",
            totalPrice:"",
            periodType : "day",
            currency:"EUR"
        }
    }

    hideDialog() {
        this.setState({
            isDialogShown: false
        })
    }

    componentDidMount() {
        let startOfPeriod = moment().clone().utc().startOf('day');
        let endOfPeriod = moment().clone().utc().startOf('day').add(1, "days");

        powerManagementConsumption([parseInt(getUrlParameterByName("deviceId"), 10)], +new Date(startOfPeriod), +new Date(endOfPeriod), "day").then(res => {                 
            let totalKwh = _.sum(_.map(res.consumptionByPeriod, "sumKWh"))
            let totalPrice = _.sum(_.map(res.consumptionByPeriod, "sumKWhCost"))
            this.setState({
              totalKwh: totalKwh,
              totalPrice: totalPrice,
              currency:res.priceLists[0].currency
            })
          }).catch(err => {
            this.setState({
              inputDisabled: false,
              showAnimation: false
      
            })
            console.warn('err', err);
          })
    
}
    

    render() {
        var deviceFromDb = _.find(this.props.devices, { "id": parseInt(getUrlParameterByName("deviceId"), 10) })
        if (_.isUndefined(deviceFromDb)) {
            return null
        }        
        return (
            <div className="analog-output-control">

                <div  className="pwr-mngmnt analog-output-middle animated faster zoomIn">
                    <div className="device-modal-dialog-name text-center">{deviceFromDb.name}</div>
                    <i onClick={this.props.onHideDialog} className="fa fa-times pull-right close slider-close pointer" aria-hidden="true"></i>

                    <div  className="text-center pwr-mngmnt-modal-data">
                    {/* <div>
                        <i className="fa fa-bolt fa-4x"></i>
                    </div>  */}
                    
                        <div> <h4>So far today: {parseFloat(this.state.totalKwh).toFixed(2) + " kWh "}</h4></div>
                        <div> <h4> Price for today:  {parseFloat(this.state.totalPrice).toFixed(2) + " " + this.state.currency }</h4></div>
                    </div>

                    <div className="text-center margin-t10" >
                       <div onClick={()=>{uiActions.hideModalDialog(); hashHistory.push("/device/"+parseInt(getUrlParameterByName("deviceId"), 10)+"/powerManagementLogs")}} 
                       className="register-device-button pointer margin-t10" ><i className="pointer fa fa-line-chart"></i> Go to chart</div> 
                    </div>
                </div>
            </div>
        )
    }
}

export default branch({
    // devices: ["model", "devices"],
    devices: ['monkeys', 'selfContainedDevices'],
    sceneForm: ['forms', 'scene'],
    route: ["route"],
    ui: ["ui", "showMainMenu"],
    hotelBillingClientSettings: ["readOnlyModel", "addOns", { uuid: "hotelBillingClient" }, "settings"],
    isMaster: ["sessionInfo", "isMaster"],
    sessionInfo: ["sessionInfo"]
}, PowerManagementModal);

