import Keycloak from 'keycloak-js';
import baobabTree from './data/state';
const keycloak = Keycloak('/keycloakConfig');

async function setBaobabToken(authenticated) {
	const { realm, realmAccess, resourceAccess, sessionId, token, tokenParsed, refreshToken } = keycloak;
	baobabTree.root.select('keycloak').set({
		realm,
		realmAccess,
		resourceAccess,
		sessionId,
		token,
		tokenParsed,
		refreshToken,
		authenticated,
	});
}

async function handleTokenRefresh() {
	const refreshed = await keycloak.updateToken(30);
	if (refreshed) {
		setBaobabToken(refreshed);
	}
}

export async function initKeycloak() {
	keycloak.onTokenExpired = () => handleTokenRefresh();
	const isAuthenticated = await keycloak.init({
		onLoad: 'login-required',
		checkLoginIframe: false,
	});

	if (isAuthenticated) {
		setBaobabToken(isAuthenticated);
	}
	return keycloak;
}

const handleClearSiteData = () => {
    fetch(window.location, {
      method: 'GET',
      headers: {
        'Clear-Site-Data': '"cache", "cookies", "storage", "executionContexts"'
      }
    })
      .then(response => {
        if (response.status === 200) {
          console.log('Site data cleared successfully.');
        } else {
          console.error('Failed to clear site data.');
        }
      })
      .catch(error => {
        console.error('An error occurred:', error);
      });
  }

export function logout() {
	try{
		keycloak.logout();
		handleClearSiteData()
	} catch(err){}
}

export function attachAuthorizationHeader(headers = {}) {
	if(keycloak.token){
		headers['Authorization'] = `Bearer ${keycloak.token}`;
	}
}

export function checkClientRole(roleName) {
	const apiKeyLoginToken = baobabTree.root.select(['apiKeyLoginToken']).get();
	if(apiKeyLoginToken){
		return true;
	} else {
		return keycloak.hasResourceRole(roleName);
	}
}

export function checkRealmRole(roleName) {
	const apiKeyLoginToken = baobabTree.root.select(['apiKeyLoginToken']).get();
	if(apiKeyLoginToken){
		return true;
	} else {
		return keycloak.hasRealmRole(roleName);
	}
}