import React, { Component } from 'react';
import { branch } from 'baobab-react/higher-order';
import "./loadingScreen.css";
import logOut from "../../data/actions/logout.js"


class LoadingScreen extends Component {
	render(){
		// return(<div>LOADING DATA.... ovdje sadržaj mora biti identičan kao i onaj na index.html u public folderu...</div>)
		return(<div className="">
			<div className="spinner">

				  <div className="double-bounce1"></div>
				  <div className="double-bounce2"></div>
				</div>
			 <div className="loading-message">{this.props.message}</div>

			<div className=" text-center"><a onClick={logOut} href="#/logout" 
			className={this.props.showLogOutButton ? "btn btn-info pointer abort-loading-btn" : "hidden" }>Abort and logout</a></div>

		</div>)
	}
}

class LoadingWrapper extends Component {
	render(){
		if(!this.props.showLoadingScreen){
			return this.props.children;
		} else {
			return (<LoadingScreen showLogOutButton={true} message={this.props.loadingScreenMessage}/>);
		}
		
	}
}


export default branch({
	//showLoadingScreen:["monkeys","showLoadingScreen"], ppased via props
	loadingScreenMessage:["ui","loadingScreenMessage"],
}, LoadingWrapper);
