
import commManager from "./commManager"


if(window.cmanager === undefined){
	window.cmanager = new commManager();
}


var requestData = (path, method = "GET", obj={}, customHeaders = null, responseTimeoutSeconds = 15)=>{
	console.log("path", path, method, customHeaders);
	if(window.cmanager){
		return window.cmanager.requestFromSlave(path, method, obj, customHeaders, responseTimeoutSeconds);
	} else {
		throw new Error("Calling request before initLoaders!");
	}
}

var requestDataLongTimeout = (path, method, obj, customHeaders, timeoutLength = 80)=>{
	return requestData(path, method, obj, customHeaders, timeoutLength);
}


export default window.cmanager

export {requestData, requestDataLongTimeout}