import baobabTree from '../state';
import _ from "lodash";
import formDefaults from "../dataTemplates/formDefaults";


const updateForm = (formName, obj)=>{
    var temp = baobabTree.root.select('forms',formName).get();    
    baobabTree.root.select('forms',formName).set(_.defaults(obj, temp));
}

const updateScheduleForm = (value, paramName, type) => {
    if(paramName === "time"){
        let timeArr = value.split(':');
        baobabTree.root.select('forms',"eventsubscription", "eventRules", "params", "scheduleTemplate").set(type+"Hour", parseInt(timeArr[0], 10)); 
        baobabTree.root.select('forms',"eventsubscription", "eventRules", "params", "scheduleTemplate").set(type+"Minute", parseInt(timeArr[1], 10)); 
        baobabTree.root.select('forms',"eventsubscription", "eventRules", "params", "scheduleTemplate").set(type+"Second", parseInt(timeArr[2], 10));   
    }
    else {
        baobabTree.root.select('forms',"eventsubscription", "eventRules", "params", "scheduleTemplate").set(type+paramName, value);  
    }
}

const loadFormData = (formName, id)=>{
    if (_.isUndefined(id)){
        sanitizeForm(formName);
    }
    return new Promise((resolve, reject)=>{
        var formData = baobabTree.root.select('model',formName+"s", {id:parseInt(id,10)}).get();
        if(!_.isUndefined(formData)){
            baobabTree.root.select('forms',formName).set(formData);            
        }
    });
}

const updateFormIcon = (formName, icn) =>{
   baobabTree.root.select('forms', formName);
}

const sanitizeForm = (formName, newDefaults = {})=>{
    const defaults = _.clone(formDefaults[formName]);
    for(const key of Object.keys(newDefaults)){
        defaults[key] = newDefaults[key];
    }
    baobabTree.root.select('forms',formName).set(defaults);
}

const setBlindsExecuting = (id)=>{
    // var temp =_.clone(baobabTree.root.select('model',"devices", {id:id}).get());   
    // temp["isExecuting"] = true;   
    baobabTree.root.select('model',"devices", {id:id}).merge({isExecuting: true})
    // var temp =_.clone(baobabTree.root.select('model',"devices", {id:id}).get());
    
}
export {updateFormIcon, sanitizeForm, loadFormData,updateScheduleForm, setBlindsExecuting }
export default updateForm