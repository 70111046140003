import rest from "../restWrapper"
//import baobabTree from '../state';
import uiActions from "./ui"
import {requestData} from "../newCommManager"
import _ from "lodash"

const getUserLocationBackupList= (email)=>{
    return new Promise((resolve, reject) => {
        // rest, jer je master only 
        rest("/getBackupList?email="+email,"GET",null).then(response=>{
            if(response.data){
                return resolve(response.data);
            } else {
                let err = new Error("getLocationBackupList(): There was an error with received response.");
                uiActions.pushErrorToSessionErrors(err)
                return reject(err);
            }
        }).catch(err=>{
            uiActions.pushErrorToSessionErrors(err)
            return reject(err);
        });
    });
}

const getDeviceValueLogs = (deviceId, fromTimestamp = Date.now()-24*60*60*1000, toTimestamp = Date.now(), logType ="logs")=>{
    return new Promise((resolve, reject) => {        
        if(_.isArray(deviceId)){
            deviceId = deviceId.join("."); //backend would known how to deal with it
        }
        requestData("/getDeviceValueLogs?logtype=logs&deviceId="+deviceId+"&from="+fromTimestamp+"&to="+toTimestamp,"GET",null).then(response=>{
            return resolve(response.data);
        }).catch(err=>{
            uiActions.pushErrorToSessionErrors(err)
            //return reject(err);
        })
    });
}

const getCompositeDeviceValueLogs = (deviceId, fromTimestamp = Date.now()-24*60*60*1000, toTimestamp = Date.now())=>{
     return new Promise((resolve, reject) => {        
        if(_.isArray(deviceId)){
            deviceId = deviceId.join("."); //backend would known how to deal with it
        }
        requestData("/getDeviceValueLogs?logtype=compositelogs&deviceId="+deviceId+"&from="+fromTimestamp+"&to="+toTimestamp,"GET",null).then(response=>{
            return resolve(response.data);
        }).catch(err=>{
            uiActions.pushErrorToSessionErrors(err)
            //return reject(err);
        })
    });
}

const getPowerConsumptionLogs = (deviceId, fromTimestamp = Date.now()-24*60*60*1000, toTimestamp = Date.now())=>{
    return new Promise((resolve, reject) => {      
        if(_.isArray(deviceId)){
            deviceId = deviceId.join("."); //backend would known how to deal with it
        }  
        requestData("/getDeviceValueLogs?logtype=pwlogs&deviceId="+deviceId+"&from="+fromTimestamp+"&to="+toTimestamp,"GET",null).then(response=>{
            return resolve(response.data);
        }).catch(err=>{
            uiActions.pushErrorToSessionErrors(err)
            //return reject(err);
        })
    });
}


const getLazyData = (routePathAndParams, method = "GET", params=null)=>{
    return new Promise((resolve, reject) => {
        requestData(routePathAndParams,method,params).then(response=>{
            return resolve(response.data);
        }).catch(err=>{
            return reject(err);
        })
    });
}

const getLogFileList = (slaveUuid,type, subPath)  => {
    return new Promise((resolve, reject) => {        
        requestData("/getLogFileList?&type="+type+"&subPath="+subPath,"GET",null).then(response=>{
            if(response.data){
                return resolve(response.data);
            } else {
                let err = new Error("getUserSlaveUpdateReferences(): There was an error with received response.");
                uiActions.pushErrorToSessionErrors(err)
                return reject(err);
            }
        }).catch(err=>{
            uiActions.pushErrorToSessionErrors(err)
            return reject(err);
        });
    });
}

const getLogFile = (slaveUuid,type, fileName, subPath)  => {
    return new Promise((resolve, reject) => {
        requestData("/getLogFile?&type="+type+"&fileName="+fileName+"&subPath="+subPath,"GET",null).then(response=>{
            if(response.data){
                return resolve(response.data);
            } else {
                let err = new Error("getUserSlaveUpdateReferences(): There was an error with received response.");
                uiActions.pushErrorToSessionErrors(err)
                return reject(err);
            }
        }).catch(err=>{
            uiActions.pushErrorToSessionErrors(err)
            return reject(err);
        });
    });
}



const powerManagementConsumption = (deviceIdsArray, fromTimestamp, toTimestamp, responsePeriodType ) => {
    return new Promise((resolve, reject) => {
        return requestData("/powerManagementConsumption", "POST", { deviceIdsArray:deviceIdsArray, fromTimestamp:fromTimestamp, toTimestamp:toTimestamp, responsePeriodType:responsePeriodType }).then(res => {
            return resolve(res.data);
        }).catch(err => {
            uiActions.pushErrorToSessionErrors(err);
            return reject(err);
        });
    });
}

const getSurveillanceEnums = ()=>{
    return new Promise((resolve, reject) => {
        return requestData("/surveillanceEnums", "GET", {}).then(res=>{
             return resolve(res.data);
        }).catch(err=>{
            uiActions.pushErrorToSessionErrors(err);
            return reject(err);
        });
    })
}


const fetchCameraSnapshot = (surveillanceCameraId)=>{
    return new Promise((resolve, reject) => {
        return requestData("/fetchSurveillanceCameraSnapshot/"+surveillanceCameraId, "GET", {}).then(res=>{
             return resolve(res.data);
        }).catch(err=>{

                    // uiActions.pushErrorToSessionErrors(err); //to many errors....
                
            return reject(err);
        });
    });
}

const startStopSurveillanceStreamOverWrtc = (wrtcPeerKey, surveillanceCameraId, action="start")=>{ //start or stop or stopall
    return new Promise((resolve, reject) => {
        return requestData("/startStopSurveillanceStreamOverWrtc/"+action, "POST", {wrtcPeerKey, surveillanceCameraId}).then(res=>{            
             return resolve(res.data);
        }).catch(err=>{            
            uiActions.pushErrorToSessionErrors(err);
            return reject(err);
        });
    });
}


const fetchAddOnList = async ()=>{
     return new Promise((resolve, reject) => {
        return requestData("/addOns", "GET", {}).then(res=>{    
             return resolve(res.data);
        }).catch(err=>{            
            uiActions.pushErrorToSessionErrors(err);
            return reject(err);
        });
     });
}

const fetchAddOnApiHelp = async ()=>{
    return new Promise((resolve, reject) => {
        return requestData("/evalApiHelp", "GET", {}).then(res=>{
             return resolve(res.data);
        }).catch(err=>{
            uiActions.pushErrorToSessionErrors(err);
            return reject(err);
        })
    });
}

const fetchApiEventsHelp = async ()=>{
    return requestData("/apiEventsHelp", "GET", {}).then(res=>{
        return res.data;
    }).catch(err=>{
        uiActions.pushErrorToSessionErrors(err);
        throw err;
    })
}

const getWiegandDoorLockWhitelist = async (deviceAddress)=>{
    return requestData("/getWiegandDoorLockWhitelist", "POST", {deviceAddress}).then(res=>{
        return res.data;
    }).catch(err=>{
        uiActions.pushErrorToSessionErrors(err);
        throw err;
    })
}

const getParkingSettings = async (deviceAddress)=>{
    return requestData("/getParkingSettings", "GET", {}).then(res=>{
        return res.data;
    }).catch(err=>{
        uiActions.pushErrorToSessionErrors(err);
        throw err;
    })
}

export {getWiegandDoorLockWhitelist, fetchAddOnApiHelp, fetchApiEventsHelp, fetchAddOnList, 
    startStopSurveillanceStreamOverWrtc, fetchCameraSnapshot, getSurveillanceEnums, getUserLocationBackupList, getDeviceValueLogs, getCompositeDeviceValueLogs,
    getPowerConsumptionLogs, getLazyData, getLogFileList, getLogFile, powerManagementConsumption,getParkingSettings
}
