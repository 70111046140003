import React, { Component } from 'react';
import { branch } from 'baobab-react/higher-order';
import actions from "../../data/actions/actions";
import _ from "lodash";
import { hashHistory } from 'react-router';
import Widget from "../../components/widget/widget";
// import MultipleItems from "../../components/widget/multipleItems";
import logOut from "../../data/actions/logout"


class Dashboard extends Component {

    handleManageClick = (id) => {
        hashHistory.push("/group/" + id);
    }

    goToWidgetForm() {
        hashHistory.push("/widget/addNew")
    }

    setDeviceValue(device, targetValue, e) {


        switch (device.type) {
            case 0: //digital output
                this.debounce(() => {
                    actions.toggleSwitch(device.id, device.value);
                })
                break;
            case 1: //analog output
                this.debounce(() => {
                    actions.toggleAnalogSwitch(device.id, targetValue, device.value);
                })
                break;
            case 2:
                if (device.address.pushButton) {
                    if (device.address.pushButton === true) {
                        this.debounce(() => {
                            actions.toggleSwitch(device.id, device.value);
                        })
                    }
                }
                break;
            case 3:
            case 4:
            default:
                console.log("No action allowed");
                break;
        }
    }

    changeStateOnSliderMove(e, deviceId) {
        let inputValue = parseInt(e.target.value, 10);

        this.setState({
            sliderValue: inputValue,
            applyAnimation: true
        });
        if (_.includes((this.props.route.location.pathname).toLowerCase(), "scene")) {

            let device = _.find(this.props.devices, { "id": parseInt(deviceId, 10) })
            var obj = { "deviceId": parseInt(deviceId, 10), "value": (255 / parseInt(device.address.analogOutputInfo.max, 10)) * parseInt(inputValue, 10) }
            var selection = this.props.sceneParameter === "active" ? _.clone(this.props.sceneForm.params.sceneActiveDeviceState) :
                _.clone(this.props.sceneForm.params.sceneExitDeviceState);

            var myObj = _.find(selection, obj => { return obj.deviceId === deviceId })
            selection[_.indexOf(selection, myObj)] = obj;
        }
    }



    render() {

        let isDashboardOnly = this.props.dashboardOnlyRoutes && this.props.dashboardOnlyRoutes !== null && this.props.dashboardOnlyRoutes.toString() === "true"
        

        let showLogoutWidget = false
        
        if (!_.isUndefined(this.props.mainMenuShownByAdmin) && this.props.mainMenuShownByAdmin === false) {
            showLogoutWidget = true
        }
        let widgetSortFromGenericStorage = _.find(this.props.genericStorages, { key: "dashboardWidgetsSort" })
        let widgetArrayToDisplay = []

        if (_.isUndefined(widgetSortFromGenericStorage)) {
            widgetArrayToDisplay = this.props.widgets
        }
        else {
            _.forEach(widgetSortFromGenericStorage.params.sortedArray, id => {
                let widgetToAdd = _.find(this.props.widgets, { id: id })
                if (!_.isUndefined(widgetToAdd)) {
                    widgetArrayToDisplay.push(widgetToAdd)
                }
            })
            _.forEach(this.props.widgets, widget => {
                if (!_.includes(widgetArrayToDisplay, widget)) {
                    widgetArrayToDisplay.push(widget)
                }
            })
        }

        // let widgets = [];
        let widgets = _.map(widgetArrayToDisplay, widget => {
            if (widget.type === 1) {
                return <Widget key={widget.id} widget={widget} {...this.props} isDashboardOnly={isDashboardOnly}/>
            }
            // else if (widget.type === 2){
            //      return <MultipleItems key={widget.id} widget={widget} {...this.props} /> }
            else { return null }
        })

        return (
            <div className={(this.props.showScrollbar === true ? "show-scrollbar" : "") + " content-w-background "}>

                {widgets}
                {showLogoutWidget === true ? <div key={"logoutWidget"} className="widget-main col-xs-6 col-sm-4 col-lg-4 text-center pull-left"
                    style={{ color: "#f0f8ff85", border: "1px solid #ffffff30", backgroundColor: "#f0f8ff00" }}>

                    <div className="pointer" onClick={() => { logOut() }}>
                        <i className="fa fa-sign-out fa-3x"></i>
                        <div>Logout</div></div>
                    {/* <i onClick={this.goToWidgetEdit.bind(this, this.props.widget.id)}  className="fa fa-edit pointer edit-widget-icon"></i> */}
                    <div></div>
                </div> : null}


                {isDashboardOnly ? null : <div className="widget-main col-xs-6 col-sm-4 col-lg-4 text-center add-new-wid">
                    <div className="pointer" onClick={this.goToWidgetForm}
                    ><i className="fa fa-plus-circle fa-3x" aria-hidden="true"></i></div>
                    <div>Add new widget</div>
                    <div className="pointer widget-sort-icon"
                        onClick={() => { hashHistory.push("/widgetSort") }}>
                        <i className="fa fa-sort" aria-hidden="true"></i> <span>Sort</span></div>
                </div>}


            </div>
        )
    }
}

export default branch({
    // groups: ['model', 'groups'],
    groups: ["monkeys", "accessibleGroups"],
    scenes: ['model', 'scenes'],
    // widgets: ['model', 'widgets'],
    widgets: ['monkeys', 'accessibleWidgets'],
    devices: ["monkeys", "selfContainedDevices"],
    compositeDevices: ["model", "compositedevices"],
    devicegroups: ['model', 'devicegroups'],
    backups: ['model', 'backups'],
    showMainMenu: ["ui", "showMainMenu"],
    showScrollbar: ["ui", "showScrollbar"],
    genericStorages: ["model", "genericstorages"],
    mainMenuShownByAdmin: ["readOnlyModel", "settings", "general", "uiMainMenu", "value"],
    dashboardOnlyRoutes: ["dashboardOnlyRoutes"]
}, Dashboard);