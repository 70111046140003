// import React, { useEffect, useState } from 'react';
// import { silentlyReloadLocationData } from '../data/actions/startupLoaders';

// function InactivityRefresh({ timeoutInMinutes }) {
//   const [inactiveTime, setInactiveTime] = useState(0);

//   // Function to reset the inactive time
//   const resetInactiveTime = () => {
//     setInactiveTime(0);
//   };

//   useEffect(() => {
//     const handleActivity = () => {
//       resetInactiveTime();
//     };

//     const activityEvents = ['mousemove', 'keydown', 'touchstart', 'click'];

//     activityEvents.forEach((event) => {
//       window.addEventListener(event, handleActivity);
//     });

//     // Start the countdown timer
//     const countdownInterval = setInterval(() => {
//       setInactiveTime((prevTime) => prevTime + 1);
//     }, 1000);

//     // Reset the inactive time when the component is unmounted
//     return () => {
//       activityEvents.forEach((event) => {
//         window.removeEventListener(event, handleActivity);
//       });
//       clearInterval(countdownInterval);
//     };
//   }, []);

//   useEffect(() => {
//     // Reload the page after the specified timeout
//     if (inactiveTime >= timeoutInMinutes * 60) {
//       window.location.reload();
//     }
//   }, [inactiveTime, timeoutInMinutes]);

//   return (
//     <div>
//       Page will refresh after {timeoutInMinutes} minutes of inactivity.
//     </div>
//   );
// }

// export default InactivityRefresh;


import React, { useEffect, useState } from 'react';
import { silentlyReloadLocationData } from '../data/actions/startupLoaders';

function InactivityRefresh({ timeoutInMinutes }) {
  const [inactiveTimeInSeconds, setInactiveTime] = useState(0);
  const [isTabFocused, setIsTabFocused] = useState(true);

  // Function to reset the inactive time
  const resetInactiveTime = () => {
    setInactiveTime(0);
  };

  useEffect(() => {
    const handleActivity = () => {
      if (isTabFocused) {
        resetInactiveTime();
      }
    };

    const activityEvents = ['mousemove', 'keydown', 'touchstart', 'click'];

    activityEvents.forEach((event) => {
      window.addEventListener(event, handleActivity);
    });

    const handleVisibilityChange = () => {
      setIsTabFocused(!document.hidden);
    };

    // Add event listener for visibility change
    document.addEventListener('visibilitychange', handleVisibilityChange);

    // Start the countdown timer
    const countdownInterval = setInterval(() => {
      if (isTabFocused) {
        setInactiveTime((prevTime) => prevTime + 1);
      }
    }, 1000);

    // Reset the inactive time and clear event listeners when the component is unmounted
    return () => {
      activityEvents.forEach((event) => {
        window.removeEventListener(event, handleActivity);
      });
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      clearInterval(countdownInterval);
    };
  }, [isTabFocused]);

  useEffect(() => {

    if(timeoutInMinutes < 15){
      timeoutInMinutes = 15
    }

    // console.log('inactiveTime', inactiveTimeInSeconds);
    // console.log('timeoutInMinutes', timeoutInMinutes);
    
    // Reload the page after the specified timeout if tab is focused
    if (isTabFocused && inactiveTimeInSeconds >= timeoutInMinutes * 60) {
      // window.location.reload();
      // silentlyReloadLocationData()
      resetInactiveTime();

      window.location.reload()
    }
  }, [inactiveTimeInSeconds, timeoutInMinutes, isTabFocused]);

  return (
    <span style={{display:"none", width:0, height:0, visibility:"hidden", position:"absolute"}}></span>

  );
}

export default InactivityRefresh;
