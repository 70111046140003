import React, { Component } from 'react';
import { branch } from 'baobab-react/higher-order';
import AnalogOutputControl from "./analogOutputControl";
import BlindsControl from "./blindsControl";
import ThermostatControl from "./thermostatControl";
import SingleThermostatControl from "./singleThermostatControl"
import VolumeControl from "./volumeControl";
import PowerManagementModal from "./powerManagementModal";
import DoorBellStream from "./doorBellStream";
import SystemMessagesModal from "./systemMessagesModal";


import PoorConnectionWarning from "./poorConnectionWarning";
import ShowError from "./showError";
import SceneWarning from "./sceneWarning"


import uiActions from "../../data/actions/ui"
import { hashHistory } from "react-router";
import _ from "lodash";

class Modal extends Component {
	constructor(props) {
		super();
		this.state = {
			popupNotificationMessage: "",
			popupNotificationLevel: "null",
			showPopupNotification: false
		}
	}
	hideModalDialog() {
		hashHistory.goBack()
		uiActions.hideModalDialog()
	}
	onSlaveEventPayload(event) {
		let data = event.payload;
		if (data.eventName === "popup") {
			this.setState({
				popupNotificationMessage: data.payload.message,
				popupNotificationLevel: data.payload.level,
				showPopupNotification: true
			})
		}
	}
	messageRead() {
		this.setState({
			popupNotificationMessage: "",
			popupNotificationLevel: null,
			showPopupNotification: false
		})
	}

	componentDidMount() {
		window.addEventListener("SlaveEvent", this.onSlaveEventPayload.bind(this));

	}
	componentWillUnmount() {
		window.removeEventListener("SlaveEvent", this.onSlaveEventPayload)
	}


	render() {
		if (this.props.sessionInfo === null) {
			return null;
		}

		//console.log(this.props.modalDialog);
		let deviceStateControlDialog = <div></div>
		switch (this.props.modalDialog.type) {
			case "analog":
				deviceStateControlDialog = <AnalogOutputControl sceneParameter={this.props.modalDialog.sceneParameter} onHideDialog={this.hideModalDialog.bind(this)} />
				break;
			case "shutters":
				deviceStateControlDialog = <BlindsControl sceneParameter={this.props.modalDialog.sceneParameter} onHideDialog={this.hideModalDialog.bind(this)} />
				break;
			case "thermostat":
				deviceStateControlDialog = <ThermostatControl sceneParameter={this.props.modalDialog.sceneParameter} onHideDialog={this.hideModalDialog.bind(this)} />
				break;
			case "volume":
				deviceStateControlDialog = <VolumeControl sceneParameter={this.props.modalDialog.sceneParameter} onHideDialog={this.hideModalDialog.bind(this)} />
				break;
			case "powerManagement":
				deviceStateControlDialog = <PowerManagementModal sceneParameter={this.props.modalDialog.sceneParameter} onHideDialog={this.hideModalDialog.bind(this)} />
				break;
			case "singleThermostat":
				//console.log("singleThermostat");
				deviceStateControlDialog = <SingleThermostatControl sceneParameter={this.props.modalDialog.sceneParameter} onHideDialog={this.hideModalDialog.bind(this)} />
			break;
			default: break;
		}


		return (
			<div>
				{this.state.showPopupNotification === false ? null : <div className={"delete-dialog"}>
					<div style={{ zIndex: 111 }} className="dialog-center-error animated faster zoomIn">
						<div style={{ zIndex: 111 }} className={"dialog-header dialog-header-" + this.state.popupNotificationLevel}><span>Info</span>
							<i onClick={this.messageRead.bind(this)} className="fa fa-times pull-right pointer" aria-hidden="true"></i>
						</div>
						<div className="dialog-message-content text-center">
							<div className="error-text-wrapper" >{this.state.popupNotificationMessage}</div>
						</div>
						<div className="text-center dialog-button-wrapper">
							<button onClick={this.messageRead.bind(this)} className="btn btn-info btn-sm err-btn">ok</button>
						</div>
					</div>

				</div>}

				{_.isEmpty(this.props.unfinishedScenes) ? null : <SceneWarning />}
				
				{_.isEmpty(this.props.sessionErrors) ? null : <ShowError /> }

				{_.isEmpty(this.props.doorBellsActive) ? null : <DoorBellStream />}
				
				{this.props.poorConnectionWarning.fullScreen === false ? null : <PoorConnectionWarning />}

				{this.props.showSystemMessagesModal === false ? null : <SystemMessagesModal />}

				{deviceStateControlDialog}

			</div>);
	}
}

export default branch({
	sessionInfo: ["sessionInfo"],
	// devices: ['model', 'devices'],
	route: ["route"],
	showMainMenu: ["ui", "showMainMenu"],
	sessionErrors: ["sessionErrors"],
	doorBellsActive: ["doorBellsActive"],
	modalDialog: ["ui", "modalDialog"],
	poorConnectionWarning: ["ui", "poorConnectionWarning"],
	unfinishedScenes: ["unfinishedScenes"],
	showSystemMessagesModal: ["ui", "showSystemMessagesModal"]
}, Modal);