import React, {Component} from 'react';
import {branch} from 'baobab-react/higher-order';
// import _ from "lodash";
// import commManager from "../../data/newCommManager"
import _ from "lodash"
import uiActions from "../../data/actions/ui"
import { hashHistory } from 'react-router';
import baobabTree from "../../data/state"

const getUrlParameterByName = (paramname, url) => {
  if (!url) url = window.location.href;
  paramname = paramname.replace(/[[\]]/g, '\\$&');
  var regex = new RegExp('[?&]' + paramname + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

class LocationSelect extends Component {
  constructor(props){
    super(props);
    this.state={
      showMenu:false
    }
    this.toggleMenu = this.toggleMenu.bind(this);
  }
  toggleMenu(){
    if(this.state.showMenu === true){
      document.removeEventListener("mousedown", this.handleClick, false)
    }
    else {
      document.addEventListener("mousedown", this.handleClick, false)

    }
    this.setState({showMenu:!this.state.showMenu});
  }
  async selectLocationHandler(slaveUuid, e){
    const userEmail = getUrlParameterByName("userEmail")
    let userMailUrlAddition = ""

    if(userEmail){
      userMailUrlAddition = "&userEmail=" + userEmail
    }

    e.preventDefault();
    try{
      // await commManager.establishSlaveConnection(slaveUuid);
      //  hashHistory.push("/");
      let target = baobabTree.root.select('masterOnlyModel', 'mySlaves', { uuid: slaveUuid }).get();

      if(target.online){
        baobabTree.root.select(['apiConfig', 'selectedSlaveUuid']).set(slaveUuid);

        window.location = '/?slaveUuid=' + slaveUuid + "" + userMailUrlAddition
      }else{
        window.location = '/?slaveUuid=' + slaveUuid + "" + userMailUrlAddition

        console.log(`LOCATION "${target?.name}" IS OFFLINE!`);
      }

      document.removeEventListener("mousedown", this.handleClick, false)
      this.setState({showMenu:false});
    } catch(err){
      console.warn(err)
    }
  }
  
handleClick = (e) => {
  if(this.node !== null){
    if (this.node.contains(e.target)) {      
      return
  }
  else {
    this.toggleMenu()
}
  }
    


}
  render(){
    
    let locations = _.map(this.props.slaves, (slave, key)=>{
      if(slave.enabled === true){
        let uuidLength = slave.uuid.toString().length;
        return (<div onClick={this.selectLocationHandler.bind(this, slave.uuid)} key={key} className={slave.online===true ? "location-online-indicator select-location-common pointer": "location-offline-indicator select-location-common pointer"}>
  
          <span>
          {slave.name} {slave.name === "" ? slave.uuid.toString().substring(uuidLength-5, uuidLength) : ""}
          </span>

  
          </div>
      );
      } else {
        return (null)
      }
      
    });
    let activeLocation = _.find(this.props.slaves, slave=>slave.uuid===this.props.selectedSlaveUuid);
    let locationName = activeLocation ? activeLocation.name : "";
    let connectionTierString = "LAN";
    switch( this.props.slaveConnectionTier){
      case 1:
        connectionTierString = "!"
      break;
      case 2:
        connectionTierString = ""
      break;
      case 0:
      connectionTierString = "!"
      break;
      default:
        connectionTierString = ""
      break;
    }

    return (<div ref={node => this.node = node}>
        <div id="location-select-menu" className={this.state.showMenu ? "animated fadeInUp faster" : "hidden"}>
        <i onClick={this.toggleMenu}className="fa fa-times pull-right close pointer" aria-hidden="true"></i>
        <div className="location-select-title"><span>Select location:</span></div>
        <div className="scrollable">
          <ul>
            {locations}
          </ul>
          </div>
        </div>
        <div onClick={this.toggleMenu} className="pointer"><i className="fa fa-map-marker"></i> {connectionTierString} {locationName}</div>
    </div>);
  }

}

class Footer extends Component {
toggleFullScreenWarning() {
  uiActions.toggleSystemMessagesModal();
}
  render() {        
    let fullScreenClass = this.props.isMainMenuShown === true && this.props.renderMainMenu === true ? "col-xs-12 col-md-9 col-lg-9 pull-right" : "col-xs-12 col-md-12 col-lg-12 pull-right"
    
    let systemMessagesObject = this.props.systemMessages

    let showIcon = false

    let alertOrErrorLength = 0

    _.forIn(systemMessagesObject, (val,key) => {
      if(val.length > 0){
        showIcon = true
      }
      if(key === "alertOrError"){
        alertOrErrorLength = val.length
      }
    })

    let isMaster = this.props.sessionInfo.isMaster;
    if(isMaster===undefined){
        isMaster = false;
    }
    if(!isMaster){
        fullScreenClass = fullScreenClass + " bckg-orange" // bckg-red
    }

    return (
      <div id="footer" className={fullScreenClass}>

        <div className="text-center">
          <i onClick={()=>{hashHistory.push("/")}} className="icon-Home pointer footer-home"></i>
        </div>
        <div className="footer-location">
        
        {this.props.isMaster===true ? <LocationSelect {...this.props}/> : null}
       
        </div>
        <i onClick={this.toggleFullScreenWarning.bind(this)} 
        className={showIcon === false ? "hidden" : 
        "fa fa-exclamation-circle pull-right poor-connection-notification-icon pointer" }>
        </i>
        <div className={alertOrErrorLength === 0 ? "hidden" : "error-messages-counter"}>
              {alertOrErrorLength}
        </div>
      </div>
		);
  }
}

export default branch({
  sessionInfo: ["sessionInfo"],
  slaves:["masterOnlyModel","mySlaves"],
  selectedSlaveUuid:["apiConfig","selectedSlaveUuid"],
  isMaster : ["sessionInfo","isMaster"],
  poorConnectionWarning: ["ui", "poorConnectionWarning"],
  slaveConnectionTier: ["ui","slaveConnectionTier"],
  isMainMenuShown:["ui", "showMainMenu"],
  systemMessages:["systemMessages"],
  renderMainMenu : ["userLevelMonkeys", "renderMainMenu"],

}, Footer);

export {getUrlParameterByName}
