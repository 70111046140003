import React, { Component } from 'react';
import { branch } from 'baobab-react/higher-order';

class HeaderNotification extends Component {

    
    render() {

let isFullScreen = false;
if( window.innerHeight === window.screen.height) {
    // browser is fullscreen
    isFullScreen = true;
}

let classIfFullScreen = ""
if(isFullScreen){
    classIfFullScreen = "notification-if-fullscreen"
}
        var notification = this.props.ui === true ? 
        <div className={this.props.isNotificationSuccessfull === true ? 
            "notification-success col-xs-12 text-center "+ classIfFullScreen : 
            "notification-unsuccess col-xs-12 text-center " + classIfFullScreen }>
            <span>{this.props.notificationMessage[this.props.notificationMessage.length-1]}</span>
            </div> : <div></div>
        return (
            <div >
            {notification}
            </div>
        )
    }
}

export default branch({
    route: ["route"],
    ui: ["ui", "showHeaderNotification"],
    notificationMessage: ["ui", "headerNotificationMessage"],
    isNotificationSuccessfull: ["ui", "isSuccessfullNotification"]
}, HeaderNotification);