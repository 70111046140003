import React, { Component } from 'react';
import { branch } from 'baobab-react/higher-order';
import avatarPic from "../../assets/img/default-avatar.png";


class EmptyLayout extends Component {

    render() {

        return (

            <div id="app-content-wrapper">

                <div className="side-menu side-menu-visibility col-md-3 col-lg-3">
                    <i className="fa fa-times pointer side-bar-icon"></i>
                    <div id="side-user-info" className="text-center">
                        <div >
                        <img src={avatarPic} className="img-circle img-responsive" alt=""/>
                        </div>

                        <div ><span>Profile name</span></div>

                    </div>

                    <hr className="style-rainbow" />
                    <div id="menu-list">
                        <div className="scene-side">
                            <a className="choices active" href="#/">
                                <i className="fa fa-desktop"> </i><span>DASHBOARD</span></a>
                        </div>

                        <div className="bottom-with-margin"> </div>
                    </div>

                </div>
                <div id="header" className={this.props.showMainMenu === true ? "col-xs-12 col-md-9 col-lg-9 pull-right" : "col-xs-12 col-md-12 col-lg-12 pull-right" }  >
                    <i className="fa fa-bars header-icon hidden-lg pointer hidden-md visible-sm visible-xs" ></i>

                    <span>NO DATA LOADED</span>
                </div>
                <div>
                    <div className="content-w-background" >
                        <div className="row"></div>

                    </div>
                </div>
                <div id="footer" className={this.props.showMainMenu === true ? "col-xs-12 col-md-9 col-lg-9 pull-right" : "col-xs-12 col-md-12 col-lg-12 pull-right" }>

                    <div className="pull-right">
                        <i className="icon-Home hidden"></i>
                    </div>
                    <div className="pull-left ">
                        location

      </div>
                </div>

            </div>

        )

        //return (<div id="content-wrapper"> NO DATA LOADED</div>)
/*
        return (

            <div id="content-wrapper">

                <div className="side-menu side-menu-visibility col-md-3 col-lg-3">
                    <i className="fa fa-times pointer side-bar-icon"></i>
                    <div id="side-user-info" className="text-center">
                        <div >
                        <img src={avatarPic} className="img-circle img-responsive" alt=""/>
                        </div>

                        <div ><span>Profile name</span></div>

                    </div>

                    <hr className="style-rainbow" />
                    <div id="menu-list">
                        <div className="scene-side">
                            <a className="choices active" href="#/">
                                <i className="fa fa-desktop"> </i><span>DASHBOARD</span></a>
                        </div>
                        <span>Devices</span>
                        <div className="scene-side"><a className="choices" href="#/">
                            <i className="glyphicon glyphicon-list-alt"> </i><span>All Devices</span></a>
                        </div>
                        <div className="scene-side"><a className="choices" href="#/">
                            <i className="glyphicon glyphicon-bookmark"> </i><span>Unassigned Devices</span></a>
                        </div>
                        <span>Groups</span>
                        <div className="scene-side">
                            <a className="choices" href="#/"><i className="glyphicon glyphicon-plus">
                            </i><span>Add group</span></a>
                        </div>
                        <span>Scenes</span>
                        <div className="scene-side">
                            <a className="choices" href="#/"><i className="glyphicon glyphicon-plus"> </i>
                                <span>Add scene</span></a>
                        </div>
                        <span>Programming tools</span>
                        <div className="scene-side">
                            <a className="choices" href="#/"><i className="fa fa-code"> </i><span>Code editor</span>
                            </a>
                        </div>
                        <div className="scene-side"><a className="choices" href="#/">
                            <i className="glyphicon glyphicon-link"> </i><span>Events</span></a>
                        </div>
                        <div className="scene-side"><a className="choices" href="#/"><i className="glyphicon glyphicon-wrench">
                        </i><span>Settings</span></a>
                        </div>
                        <div className="scene-side">
                            <a className="choices" href="#/"><i className="glyphicon glyphicon-log-out">
                            </i><span>Logout</span></a>
                        </div>
                        <div className="bottom-with-margin"> </div>
                    </div>

                </div>
                <div id="header" className={this.props.showMainMenu === true ? "col-xs-12 col-md-9 col-lg-9 pull-right" : "col-xs-12 col-md-12 col-lg-12 pull-right" }  >
                    <i className="fa fa-bars header-icon hidden-lg pointer hidden-md visible-sm visible-xs" ></i>

                    <span></span>
                </div>
                <div>
                    <div className="content-w-background" >
                        <div className="row"></div>

                    </div>
                </div>
                <div id="footer" className={this.props.showMainMenu === true ? "col-xs-12 col-md-9 col-lg-9 pull-right" : "col-xs-12 col-md-12 col-lg-12 pull-right" }>

                    <div className="pull-right">
                        <i className="glyphicon glyphicon-home hidden"></i>
                    </div>
                    <div className="pull-left ">
                        location

      </div>
                </div>

            </div>

        )*/
    }
}

export default branch({
    route: ["route"],
    showMainMenu: ["ui", "showMainMenu"]
}, EmptyLayout);

