import React, { Component } from 'react';
import { branch } from 'baobab-react/higher-order';
import _ from 'lodash';
import { getIconCssClassFromStyleParams } from '../../utils/fontIcons';
import { hashHistory } from 'react-router';
import actions from '../../data/actions/actions';
import compositeActions from '../../data/actions/compositeActions';
import updateForm, { loadFormData } from '../../data/actions/formStateController';
import checkRoleAccess from '../../utils/checkRoleAccess';

import { ReactComponent as GapsFull } from "../../assets/img/gapsFull.svg"
import { ReactComponent as GapsHalf } from "../../assets/img/gapsHalf.svg"

function RelayDot () {
    return <i style={{borderRadius:"50%", backgroundColor:"#FFFFFF", width:"5px", height:"5px", marginLeft:"5px"}} className="fa fa-2x"></i>
}

class CompositeDevice extends Component {
    constructor() {
        super();
        this.debounceTimestamp = Date.now();
    }
    debounce(fn) {
        if (Date.now() - this.debounceTimestamp > 300) {
            fn();
        } //else is debounced....
        this.debounceTimestamp = Date.now();
    }
    ringTheBell(deviceId) {
        this.debounce(() => {
            actions.toggleSwitch(deviceId, 1); //must be 1 to emulate push event...
        });
    }

    setSceneCompositeDeviceValue(device, targetValue) {

        if (_.includes((this.props.route.location.pathname).toLowerCase(), "scene")) {
            let deviceId = parseInt(device.id, 10)
            var obj = { "compositeDeviceId": deviceId, "value":  parseInt(targetValue, 10) }
            var selection = this.props.sceneParameter === "active" ? _.clone(this.props.sceneForm.params.sceneActiveDeviceState) :
              _.clone(this.props.sceneForm.params.sceneExitDeviceState);
      
            var myObj = _.find(selection, obj => { return obj.compositeDeviceId === deviceId })
            selection[_.indexOf(selection, myObj)] = obj;
            var params = {};
            var ob = { params };
            if (this.props.sceneParameter === "active") {
              ob.params["sceneActiveDeviceState"] = selection;
              ob.params["sceneExitDeviceState"] = this.props.sceneForm.params.sceneExitDeviceState;
              ob.params["groups"] = this.props.sceneForm.params.groups;
              updateForm("scene", ob);
            }
            else {
              ob.params["sceneActiveDeviceState"] = this.props.sceneForm.params.sceneActiveDeviceState;
              ob.params["sceneExitDeviceState"] = selection;
              ob.params["groups"] = this.props.sceneForm.params.groups;
              updateForm("scene", ob);
            }
          }
        
    }

    toggleWindowDeviceState(compositeDevice) {
        if (!_.includes(this.props.route.location.pathname.toLowerCase(), 'scene')) {
            this.debounce(() => {
                if (!compositeDevice.lockState) {
                    if (compositeDevice.value === 0) {
                        compositeActions.toggleAnalogSwitch(compositeDevice.id, 255, compositeDevice.value);
                    } else {
                        compositeActions.toggleAnalogSwitch(compositeDevice.id, 0, compositeDevice.value);
                    }
                }
            });        
        } else {
            loadFormData('scene', parseInt(this.props.route.params.sceneId, 10));
            this.setSceneCompositeDeviceValue(compositeDevice, compositeDevice.value === 0 ? 255 : 0);
        }
        
     
    }

    roundToHalf(value) {
        var converted = parseFloat(value); // Make sure we have a number
        let multiplier = 1;
        if (value < 0) {
            multiplier = -1;
        }
        converted = Math.abs(converted);
        var decimal = converted - parseInt(converted, 10);
        decimal = Math.round(decimal * 10);
        if (decimal === 5) {
            return (parseInt(converted, 10) + 0.5) * multiplier;
        }
        if (decimal < 3 || decimal > 7) {
            return Math.round(converted) * multiplier;
        } else {
            return (parseInt(converted, 10) + 0.5) * multiplier;
        }
    }
    goToEdit(device) {
        hashHistory.push('/device/' + device.id + '/edit/compositeDevice');
    }

    scheduleDeviceAction(device, e) {
        if (!_.includes(this.props.route.location.pathname.toLowerCase(), 'alldevices')) {
            hashHistory.push('/group/' + this.props.groupId + '/compositeDevice/' + device.id + '/scheduleList');
        } else {
            hashHistory.push('/allDevices/compositeDevice/' + device.id + '/scheduleList');
        }
    }

    displayModalDialog(device, e) {
        this.props.showModalDialog(device);
    }

    goToDeviceLogs(deviceId) {
        hashHistory.push('/compositeDevice/' + deviceId + '/logs');
    }
    render() {
        let bigStyle = this.props.device.isExecuting
            ? 'fa fa-spinner fa-spin big-icon'
            : getIconCssClassFromStyleParams(this.props.device.style, { isBig: true });

        let sensorLogIcon = <i className="hidden"></i>;

        let shuttersValue = <div></div>;
        if (!_.isUndefined(this.props.device.params)) {
            if (this.props.device.params !== null) {
                if (!_.isUndefined(this.props.device.params.valueLogger)) {
                    if (this.props.device.params.valueLogger === true) {
                        sensorLogIcon = (
                            <i
                                onClick={this.goToDeviceLogs.bind(this, this.props.device.id)}
                                className={
                                    _.includes(this.props.route.location.pathname.toLowerCase(), 'scene')
                                        ? 'hidden'
                                        : 'pointer fa fa-line-chart sensorLog-icon'
                                }
                            ></i>
                        );
                        //sensorLogIcon = <i onClick={this.redirectToSensorLogScreen.bind(this, this.props.device.id)} className={_.includes((this.props.route.location.pathname).toLowerCase(), "scene") ? "hidden" : "pointer fa fa-line-chart sensorLog-icon"}></i>
                    }
                }
            }
        }

        let lockedIcon = (
            <span className="fa-stack fa-lg locked-icon-span">
                <i className="fa fa-lock text-danger"></i>
            </span>
        );

        //let sensorLogIcon = <i className={_.includes((this.props.route.location.pathname).toLowerCase(), "scene") ? "hidden" : "pointer fa fa-line-chart sensorLog-icon"}></i>
        let disabledStyle = ""
        let iconAndValue = <div>CompositeDevice</div>;
        let scheduleDeviceIcon = (
            <i
                onClick={this.scheduleDeviceAction.bind(this, this.props.device)}
                className={
                    _.includes(this.props.route.location.pathname.toLowerCase(), 'scene')
                        ? 'hidden'
                        : 'pointer fa fa-calendar clock-icon'
                }
            ></i>
        );
        switch (this.props.device.type) {
            
            case 'shutters':
            let shutVal = 100 - parseInt(100 - Math.round((this.props.device.value / 255) * 100), 10) + " "+ "%"
            if(this.props.device.value === 300){
                shutVal =  <GapsHalf style={{width:"32px"}}/>
            }
            if(this.props.device.value === 400){
                shutVal =  <GapsFull style={{width:"32px"}}/>
            }

                //  sensorLogIcon = <i onClick={this.goToDeviceLogs.bind(this, this.props.device.id)} className={_.includes((this.props.route.location.pathname).toLowerCase(), "scene") ? "hidden" : "pointer fa fa-line-chart sensorLog-icon"}></i>
                iconAndValue =
                    this.props.device.lockState === true ? (
                        <div className="margin-t-12" onClick={this.displayModalDialog.bind(this, this.props.device)}>
                            {lockedIcon}
                        </div>
                    ) : (
                        <div className="margin-t-12" onClick={this.displayModalDialog.bind(this, this.props.device)}>
                            <i className={bigStyle}></i>
                        </div>
                    );

                shuttersValue = (
                    <span className="no-margin sensor-state-value">
                        {shutVal}
                    </span>
                );
                break;

            case 'thermostat':

        //  let tempSensId = null 
        //  let thermostatRelayId = null 
         let relayDevice = null
         let temperatureSensorDevice = null
         let showRelayOffline = false
         let showTemperatureSensorOffline = false

         if (this.props.device.params.temperatureSensor && this.props.device.params.temperatureSensor.value) {
            temperatureSensorDevice = _.find(this.props.devices, (d) => {
                return d.id === Number(this.props.device.params.temperatureSensor.value);
            });
        }
        if (this.props.device.params.thermostatRelay && (this.props.device.params.thermostatRelay.value || this.props.device.params.thermostatRelay.value === 0)) {
            relayDevice = _.find(this.props.devices, (d) => {
                return d.id === Number(this.props.device.params.thermostatRelay.value);
            });
        }

        if (relayDevice && relayDevice.connectionState === 2) {
            disabledStyle = 'disabled-device';
            showRelayOffline = true
        }
        if(temperatureSensorDevice && temperatureSensorDevice.connectionState === 2){
            disabledStyle = 'disabled-device';
            showTemperatureSensorOffline = true
        }
        let isRelayOn = relayDevice && relayDevice.value

        let temperatureSensorDeviceValue = '-';
        if (temperatureSensorDevice != undefined && temperatureSensorDevice.address != undefined && temperatureSensorDevice.address.driver != undefined && temperatureSensorDevice.address.driver == "th01_v2") {
            temperatureSensorDeviceValue = temperatureSensorDevice && temperatureSensorDevice.states.currentTemperature ? (Math.round(Number(temperatureSensorDevice.states.currentTemperature)*10)/10) : "--"
        }            
        else if (temperatureSensorDevice != undefined && temperatureSensorDevice.value != undefined){
            temperatureSensorDeviceValue = temperatureSensorDevice && temperatureSensorDevice.value ? (Math.round(Number(temperatureSensorDevice.value)*10)/10) : "--"
        }		
     
        let isPoweredOff = false
        // if(this.props.device && this.props.device.value === 0 && this.props.device.lockState ){
        if(this.props.device && this.props.device.value === 0 ){
             isPoweredOff = true
        }
        
        let switchMode = undefined; 
        if (this.props.device.params.switchMode && this.props.device.params.switchMode.value) {
            switchMode = _.find(this.props.devices, (d) => {
                return d.id === Number(this.props.device.params.switchMode.value);
            });
        }

        let modIcon = this.props.device.params.invertedLogic.value ? (<i className="fa fa-snowflake-o"></i>) : (<i className="icon-fire"></i>);
        if (switchMode && switchMode.value == 1) {
            modIcon = (<i className="icon-fire"></i>);
        } else if (switchMode && switchMode.value === 0) {
            modIcon = (<i className="fa fa-snowflake-o"></i>);
        }
              
                iconAndValue =
                    this.props.device.lockState === true ? (
                        <div className="margin-t-12" onClick={this.displayModalDialog.bind(this, this.props.device)}>{lockedIcon}</div>
                    ) : (
                            <div  onClick={this.displayModalDialog.bind(this, this.props.device)}>
                                {modIcon}
                                <i className={bigStyle + ' pointer'}></i>
                                <span className="no-margin sensor-state-value">{isRelayOn ? <RelayDot/> : ""}</span>
                            </div>
                        );
                shuttersValue = (
                    <div>
                            <div>
                                {_.isUndefined(this.props.sceneParameter) ? (
                                    <span
                                        className= "powermeter-device-data pointer"
                                        style={{ fontSize: '1.8em' }}
                                    >
                                        {temperatureSensorDeviceValue + '°C'} /
                                    </span>
                                ) : null}
                                {/* {isPoweredOff ? 'OFF' : this.roundToHalf(
                                    (this.props.device.value / 255) *
                                        (parseInt(this.props.device.params.thermostatMax.value, 10) -
                                            parseInt(this.props.device.params.thermostatMin.value, 10)) +
                                        parseInt(this.props.device.params.thermostatMin.value, 10)
                                ) + '°C'} */}
                                {isPoweredOff ? 'OFF' : (Math.round(Number(this.props.device.value)*10)/10) + '°C'}
                            </div>
                        {showRelayOffline ? <small style={{fontSize:"70%"}}> <i className="fa fa-ban"></i> {relayDevice ? relayDevice.name : "relay"}
                          </small> : null}  
                        {showTemperatureSensorOffline ? <small style={{fontSize:"70%"}}> <i className="fa fa-ban"></i> {temperatureSensorDevice ? temperatureSensorDevice.name : "sensor"}
                          </small> : null}  
                    </div>
                );
            
                // sensorLogIcon = <i onClick={this.goToDeviceLogs.bind(this, this.props.device.id)} className={_.includes((this.props.route.location.pathname).toLowerCase(), "scene") ? "hidden" : "pointer fa fa-line-chart sensorLog-icon"}></i>
                // iconAndValue =
                //     this.props.device.lockState === true ? (
                //         <div className="margin-t-12" onClick={this.displayModalDialog.bind(this, this.props.device)}>
                //             {lockedIcon}
                //             <span className="no-margin sensor-state-value">
                //                 {this.roundToHalf(
                //                     (this.props.device.value / 255) *
                //                         (parseInt(this.props.device.params.thermostatMax.value, 10) -
                //                             parseInt(this.props.device.params.thermostatMin.value, 10)) +
                //                         parseInt(this.props.device.params.thermostatMin.value, 10)
                //                 )}{' '}
                //                 °C
                //             </span>
                //         </div>
                //     ) : (
                //         <div className="margin-t-12" onClick={this.displayModalDialog.bind(this, this.props.device)}>
                //             <i className={bigStyle}></i>
                //             <span className="no-margin sensor-state-value">
                //                 {this.roundToHalf(
                //                     (this.props.device.value / 255) *
                //                         (parseInt(this.props.device.params.thermostatMax.value, 10) -
                //                             parseInt(this.props.device.params.thermostatMin.value, 10)) +
                //                         parseInt(this.props.device.params.thermostatMin.value, 10)
                //                 )}{' '}
                //                 °C
                //             </span>
                //         </div>
                //     );
                break;

                case 'softIR': {

                    //  let tempSensId = null 
                    //  let thermostatRelayId = null 
                    // let relayDevice = null
                    let temperatureSensorDevice = null
                    let showRelayOffline = false
                    let showTemperatureSensorOffline = false

                    if (this.props.device.params.temperatureSensor && this.props.device.params.temperatureSensor.value) {
                        temperatureSensorDevice = _.find(this.props.devices, (d) => {
                            return d.id === Number(this.props.device.params.temperatureSensor.value);
                        });
                    }
                    // if (this.props.device.params.thermostatRelay && (this.props.device.params.thermostatRelay.value || this.props.device.params.thermostatRelay.value === 0)) {
                    //     relayDevice = _.find(this.props.devices, (d) => {
                    //         return d.id === Number(this.props.device.params.thermostatRelay.value);
                    //     });
                    // }

                    // if (relayDevice && relayDevice.connectionState === 2) {
                    //     disabledStyle = 'disabled-device';
                    //     showRelayOffline = true
                    // }
                    if(temperatureSensorDevice && temperatureSensorDevice.connectionState === 2){
                        disabledStyle = 'disabled-device';
                        showTemperatureSensorOffline = true
                    }
                    // let isRelayOn = relayDevice && relayDevice.value

                    let temperatureSensorDeviceValue = '-';
                    if (temperatureSensorDevice != undefined && temperatureSensorDevice.address != undefined && temperatureSensorDevice.address.driver != undefined && temperatureSensorDevice.address.driver == "th01_v2") {
                        temperatureSensorDeviceValue = temperatureSensorDevice && temperatureSensorDevice.states.currentTemperature ? (Math.round(Number(temperatureSensorDevice.states.currentTemperature)*10)/10) : "--"
                    }            
                    else if (temperatureSensorDevice != undefined && temperatureSensorDevice.value != undefined){
                        temperatureSensorDeviceValue = temperatureSensorDevice && temperatureSensorDevice.value ? (Math.round(Number(temperatureSensorDevice.value)*10)/10) : "--"
                    }		
                
                    let isPoweredOff = false
                    if(this.props.device && this.props.device.value === 0 ){
                        isPoweredOff = true
                    }

                    let switchMode = undefined; 
                    if (this.props.device.params.switchMode && this.props.device.params.switchMode.value) {
                        switchMode = _.find(this.props.devices, (d) => {
                            return d.id === Number(this.props.device.params.switchMode.value);
                        });
                    }

                    let modIcon = this.props.device.params.invertedLogic.value ? (<i className="fa fa-snowflake-o"></i>) : (<i className="icon-fire"></i>);
                    if (switchMode && switchMode.value == 1) {
                        modIcon = (<i className="icon-fire"></i>);
                    } else if (switchMode && switchMode.value === 0) {
                        modIcon = (<i className="fa fa-snowflake-o"></i>);
                    }
                        
                    iconAndValue =
                        this.props.device.lockState === true ? (
                            <div className="margin-t-12" onClick={this.displayModalDialog.bind(this, this.props.device)}>{lockedIcon}</div>
                        ) : (
                                <div  onClick={this.displayModalDialog.bind(this, this.props.device)}>
                                    {modIcon}
                                    <i className={bigStyle + ' pointer'}></i>
                                    {/* <span className="no-margin sensor-state-value">{isRelayOn ? <RelayDot/> : ""}</span> */}
                                </div>
                            );
                    shuttersValue = (
                        <div>
                                <div>
                                    {_.isUndefined(this.props.sceneParameter) ? (
                                        <span
                                            className= "powermeter-device-data pointer"
                                            style={{ fontSize: '1.8em' }}
                                        >
                                            {temperatureSensorDeviceValue + '°C'} /
                                        </span>
                                    ) : null}
                                    {/* {isPoweredOff ? 'OFF' : this.roundToHalf(
                                        (this.props.device.value / 255) *
                                            (parseInt(this.props.device.params.thermostatMax.value, 10) -
                                                parseInt(this.props.device.params.thermostatMin.value, 10)) +
                                            parseInt(this.props.device.params.thermostatMin.value, 10)
                                    ) + '°C'} */}
                                    {isPoweredOff ? 'OFF' : (Math.round(Number(this.props.device.value)*10)/10) + '°C'}
                                </div>
                            {/* {showRelayOffline ? <small style={{fontSize:"70%"}}> <i className="fa fa-ban"></i> {relayDevice ? relayDevice.name : "relay"}
                            </small> : null}   */}
                            {showTemperatureSensorOffline ? <small style={{fontSize:"70%"}}> <i className="fa fa-ban"></i> {temperatureSensorDevice ? temperatureSensorDevice.name : "sensor"}
                            </small> : null}  
                        </div>
                    );
                
                    // sensorLogIcon = <i onClick={this.goToDeviceLogs.bind(this, this.props.device.id)} className={_.includes((this.props.route.location.pathname).toLowerCase(), "scene") ? "hidden" : "pointer fa fa-line-chart sensorLog-icon"}></i>
                    // iconAndValue =
                    //     this.props.device.lockState === true ? (
                    //         <div className="margin-t-12" onClick={this.displayModalDialog.bind(this, this.props.device)}>
                    //             {lockedIcon}
                    //             <span className="no-margin sensor-state-value">
                    //                 {this.roundToHalf(
                    //                     (this.props.device.value / 255) *
                    //                         (parseInt(this.props.device.params.thermostatMax.value, 10) -
                    //                             parseInt(this.props.device.params.thermostatMin.value, 10)) +
                    //                         parseInt(this.props.device.params.thermostatMin.value, 10)
                    //                 )}{' '}
                    //                 °C
                    //             </span>
                    //         </div>
                    //     ) : (
                    //         <div className="margin-t-12" onClick={this.displayModalDialog.bind(this, this.props.device)}>
                    //             <i className={bigStyle}></i>
                    //             <span className="no-margin sensor-state-value">
                    //                 {this.roundToHalf(
                    //                     (this.props.device.value / 255) *
                    //                         (parseInt(this.props.device.params.thermostatMax.value, 10) -
                    //                             parseInt(this.props.device.params.thermostatMin.value, 10)) +
                    //                         parseInt(this.props.device.params.thermostatMin.value, 10)
                    //                 )}{' '}
                    //                 °C
                    //             </span>
                    //         </div>
                    //     );
                    break;
                }

            case 'softThermostat': {
                //  let tempSensId = null 
                //  let thermostatRelayId = null 
                let relayDevice1 = null
                let relayDevice2 = null
                let temperatureSensorDevice = null
                let showRelayOffline = false
                let showTemperatureSensorOffline = false

                if (this.props.device.params.temperatureSensor && this.props.device.params.temperatureSensor.value) {
                    temperatureSensorDevice = _.find(this.props.devices, (d) => {
                        return d.id === Number(this.props.device.params.temperatureSensor.value);
                    });
                }
                if (this.props.device.params.thermostatValve1 && (this.props.device.params.thermostatValve1.value || this.props.device.params.thermostatValve1.value === 0)) {
                    relayDevice1 = _.find(this.props.devices, (d) => {
                        return d.id === Number(this.props.device.params.thermostatValve1.value);
                    });
                }
                if (this.props.device.params.thermostatValve2 && (this.props.device.params.thermostatValve2.value || this.props.device.params.thermostatValve2.value === 0)) {
                    relayDevice2 = _.find(this.props.devices, (d) => {
                        return d.id === Number(this.props.device.params.thermostatValve2.value);
                    });
                }

                if (relayDevice1 && relayDevice1.connectionState === 2) {
                    disabledStyle = 'disabled-device';
                    showRelayOffline = true
                }
                if(temperatureSensorDevice && temperatureSensorDevice.connectionState === 2){
                    disabledStyle = 'disabled-device';
                    showTemperatureSensorOffline = true
                }
                let isRelayOn = (relayDevice1 && relayDevice1.value) || (relayDevice2 && relayDevice2.value);

                let temperatureSensorDeviceValue = '-';
                if (temperatureSensorDevice != undefined && temperatureSensorDevice.address != undefined && temperatureSensorDevice.address.driver != undefined && temperatureSensorDevice.address.driver == "th01_v2") {
                    temperatureSensorDeviceValue = temperatureSensorDevice && temperatureSensorDevice.states.currentTemperature ? (Math.round(Number(temperatureSensorDevice.states.currentTemperature)*10)/10) : "--"
                }            
                else if (temperatureSensorDevice != undefined && temperatureSensorDevice.value != undefined){
                    temperatureSensorDeviceValue = temperatureSensorDevice && temperatureSensorDevice.value ? (Math.round(Number(temperatureSensorDevice.value)*10)/10) : "--"
                }		
            
                let isPoweredOff = false
                if(this.props.device && this.props.device.value === 0){
                    isPoweredOff = true
                }
               
                let switchMode = undefined; 
                if (this.props.device.params.switchMode && this.props.device.params.switchMode.value) {
                    switchMode = _.find(this.props.devices, (d) => {
                        return d.id === Number(this.props.device.params.switchMode.value);
                    });
                }

                let modIcon = this.props.device.params.invertedLogic.value ? (<i className="fa fa-snowflake-o"></i>) : (<i className="icon-fire"></i>);
                if (switchMode && switchMode.value == 1) {
                    modIcon = (<i className="icon-fire"></i>);
                } else if (switchMode && switchMode.value === 0) {
                    modIcon = (<i className="fa fa-snowflake-o"></i>);
                }

                iconAndValue =
                    this.props.device.lockState === true ? (
                        <div className="margin-t-12" onClick={this.displayModalDialog.bind(this, this.props.device)}>{lockedIcon}</div>
                    ) : (
                            <div  onClick={this.displayModalDialog.bind(this, this.props.device)}>
                                {modIcon}
                                <i className={bigStyle + ' pointer'}></i>
                                <span className="no-margin sensor-state-value">{isRelayOn ? <RelayDot/> : ""}</span>
                            </div>
                        );
                shuttersValue = (
                    <div>
                            <div>
                                {_.isUndefined(this.props.sceneParameter) ? (
                                    <span
                                        className= "powermeter-device-data pointer"
                                        style={{ fontSize: '1.8em' }}
                                    >
                                        {temperatureSensorDeviceValue + '°C'} /
                                    </span>
                                ) : null}
                                {/* {isPoweredOff ? 'OFF' : this.roundToHalf(
                                    (this.props.device.value / 255) *
                                        (parseInt(this.props.device.params.thermostatMax.value, 10) -
                                            parseInt(this.props.device.params.thermostatMin.value, 10)) +
                                        parseInt(this.props.device.params.thermostatMin.value, 10)
                                ) + '°C'} */}
                                {isPoweredOff ? 'OFF' : (Math.round(Number(this.props.device.value)*10)/10) + '°C'}
                            </div>
                        {/* {showRelayOffline ? <small style={{fontSize:"70%"}}> <i className="fa fa-ban"></i> {relayDevice ? relayDevice.name : "relay"}
                          </small> : null}   */}
                        {showTemperatureSensorOffline ? <small style={{fontSize:"70%"}}> <i className="fa fa-ban"></i> {temperatureSensorDevice ? temperatureSensorDevice.name : "sensor"}
                          </small> : null}  
                    </div>
                );
            
                // sensorLogIcon = <i onClick={this.goToDeviceLogs.bind(this, this.props.device.id)} className={_.includes((this.props.route.location.pathname).toLowerCase(), "scene") ? "hidden" : "pointer fa fa-line-chart sensorLog-icon"}></i>
                // iconAndValue =
                //     this.props.device.lockState === true ? (
                //         <div className="margin-t-12" onClick={this.displayModalDialog.bind(this, this.props.device)}>
                //             {lockedIcon}
                //             <span className="no-margin sensor-state-value">
                //                 {this.roundToHalf(
                //                     (this.props.device.value / 255) *
                //                         (parseInt(this.props.device.params.thermostatMax.value, 10) -
                //                             parseInt(this.props.device.params.thermostatMin.value, 10)) +
                //                         parseInt(this.props.device.params.thermostatMin.value, 10)
                //                 )}{' '}
                //                 °C
                //             </span>
                //         </div>
                //     ) : (
                //         <div className="margin-t-12" onClick={this.displayModalDialog.bind(this, this.props.device)}>
                //             <i className={bigStyle}></i>
                //             <span className="no-margin sensor-state-value">
                //                 {this.roundToHalf(
                //                     (this.props.device.value / 255) *
                //                         (parseInt(this.props.device.params.thermostatMax.value, 10) -
                //                             parseInt(this.props.device.params.thermostatMin.value, 10)) +
                //                         parseInt(this.props.device.params.thermostatMin.value, 10)
                //                 )}{' '}
                //                 °C
                //             </span>
                //         </div>
                //     );
                break; 
            }

            case 'underfloorHeating': {
                //  let tempSensId = null 
                //  let thermostatRelayId = null 
                let relayDevice = null
                let temperatureSensorDevice = null
                let showRelayOffline = false
                let showTemperatureSensorOffline = false

                if (this.props.device.params.temperatureSensor && this.props.device.params.temperatureSensor.value) {
                    temperatureSensorDevice = _.find(this.props.devices, (d) => {
                        return d.id === Number(this.props.device.params.temperatureSensor.value);
                    });
                }
                if (this.props.device.params.underfloorHeatingValve && (this.props.device.params.underfloorHeatingValve.value || this.props.device.params.underfloorHeatingValve.value === 0)) {
                    relayDevice = _.find(this.props.devices, (d) => {
                        return d.id === Number(this.props.device.params.underfloorHeatingValve.value);
                    });
                }

                if (relayDevice && relayDevice.connectionState === 2) {
                    disabledStyle = 'disabled-device';
                    showRelayOffline = true
                }
                if(temperatureSensorDevice && temperatureSensorDevice.connectionState === 2){
                    disabledStyle = 'disabled-device';
                    showTemperatureSensorOffline = true
                }

                let isRelayOn = relayDevice && relayDevice.value;

                if (this.props.device.params.valveIsInverted && this.props.device.params.valveIsInverted.value) {
                    isRelayOn = !isRelayOn;
                }

                let temperatureSensorDeviceValue = '-';
                if (temperatureSensorDevice != undefined && temperatureSensorDevice.address != undefined && temperatureSensorDevice.address.driver != undefined && temperatureSensorDevice.address.driver == "th01_v2") {
                    temperatureSensorDeviceValue = temperatureSensorDevice && temperatureSensorDevice.states.currentTemperature ? (Math.round(Number(temperatureSensorDevice.states.currentTemperature)*10)/10) : "--"
                }            
                else if (temperatureSensorDevice != undefined && temperatureSensorDevice.value != undefined){
                    temperatureSensorDeviceValue = temperatureSensorDevice && temperatureSensorDevice.value ? (Math.round(Number(temperatureSensorDevice.value)*10)/10) : "--"
                }		
            
                let isPoweredOff = false
                if(this.props.device && this.props.device.value === 0){
                    isPoweredOff = true
                }
                iconAndValue =
                    this.props.device.lockState === true ? (
                        <div className="margin-t-12" onClick={this.displayModalDialog.bind(this, this.props.device)}>{lockedIcon}</div>
                    ) : (
                            <div  onClick={this.displayModalDialog.bind(this, this.props.device)}>
                                <i className={bigStyle + ' pointer'}></i>
                                <span className="no-margin sensor-state-value">{isRelayOn ? <RelayDot/> : ""}</span>
                            </div>
                        );
                shuttersValue = (
                    <div>
                            <div>
                                {_.isUndefined(this.props.sceneParameter) ? (
                                    <span
                                        className= "powermeter-device-data pointer"
                                        style={{ fontSize: '1.8em' }}
                                    >
                                        {temperatureSensorDeviceValue + '°C'} /
                                    </span>
                                ) : null}
                                {/* {isPoweredOff ? 'OFF' : this.roundToHalf(
                                    (this.props.device.value / 255) *
                                        (parseInt(this.props.device.params.thermostatMax.value, 10) -
                                            parseInt(this.props.device.params.thermostatMin.value, 10)) +
                                        parseInt(this.props.device.params.thermostatMin.value, 10)
                                ) + '°C'} */}
                                {isPoweredOff ? 'OFF' : (Math.round(Number(this.props.device.value)*10)/10) + '°C'}
                            </div>
                        {showRelayOffline ? <small style={{fontSize:"70%"}}> <i className="fa fa-ban"></i> {relayDevice ? relayDevice.name : "relay"}
                          </small> : null}  
                        {showTemperatureSensorOffline ? <small style={{fontSize:"70%"}}> <i className="fa fa-ban"></i> {temperatureSensorDevice ? temperatureSensorDevice.name : "sensor"}
                          </small> : null}  
                    </div>
                );
            
                // sensorLogIcon = <i onClick={this.goToDeviceLogs.bind(this, this.props.device.id)} className={_.includes((this.props.route.location.pathname).toLowerCase(), "scene") ? "hidden" : "pointer fa fa-line-chart sensorLog-icon"}></i>
                // iconAndValue =
                //     this.props.device.lockState === true ? (
                //         <div className="margin-t-12" onClick={this.displayModalDialog.bind(this, this.props.device)}>
                //             {lockedIcon}
                //             <span className="no-margin sensor-state-value">
                //                 {this.roundToHalf(
                //                     (this.props.device.value / 255) *
                //                         (parseInt(this.props.device.params.thermostatMax.value, 10) -
                //                             parseInt(this.props.device.params.thermostatMin.value, 10)) +
                //                         parseInt(this.props.device.params.thermostatMin.value, 10)
                //                 )}{' '}
                //                 °C
                //             </span>
                //         </div>
                //     ) : (
                //         <div className="margin-t-12" onClick={this.displayModalDialog.bind(this, this.props.device)}>
                //             <i className={bigStyle}></i>
                //             <span className="no-margin sensor-state-value">
                //                 {this.roundToHalf(
                //                     (this.props.device.value / 255) *
                //                         (parseInt(this.props.device.params.thermostatMax.value, 10) -
                //                             parseInt(this.props.device.params.thermostatMin.value, 10)) +
                //                         parseInt(this.props.device.params.thermostatMin.value, 10)
                //                 )}{' '}
                //                 °C
                //             </span>
                //         </div>
                //     );
                break; 
            }

            case 'doorBell':
                let doorBellInputId = parseInt(this.props.device.params.doorBellInput.value, 10);

                //  sensorLogIcon = <i onClick={this.goToDeviceLogs.bind(this, this.props.device.id)} className={_.includes((this.props.route.location.pathname).toLowerCase(), "scene") ? "hidden" : "pointer fa fa-line-chart sensorLog-icon"}></i>
                iconAndValue =
                    this.props.device.lockState === true ? (
                        <div className="margin-t-12">
                            {lockedIcon}
                            <span className="no-margin sensor-state-value"></span>{' '}
                        </div>
                    ) : (
                        <div className="margin-t-12">
                            <i className={bigStyle}></i>
                            <span className="no-margin sensor-state-value"></span>
                        </div>
                    );

                shuttersValue = (
                    <div
                        onClick={this.ringTheBell.bind(this, doorBellInputId, null)}
                        className="shutters-value pointer"
                    >
                        Ring
                    </div>
                );

                break;

            case 'linearWindow':
                let moistureSensorDigitalInput = null;
                let windowState = null;
                let windowStateDetectionEnabled = false;

                if (this.props.device.params.moistureSensor && this.props.device.params.moistureSensor.value) {
                    moistureSensorDigitalInput = _.find(this.props.devices, (d) => {
                        return d.id === Number(this.props.device.params.moistureSensor.value);
                    });
                }

                if (this.props.device.params.windowStateSensor && this.props.device.params.windowStateSensor.value) {
                    const windowStateInput = _.find(this.props.devices, (d) => {
                        return d.id === Number(this.props.device.params.windowStateSensor.value);
                    });
                    if (windowStateInput) {
                        windowStateDetectionEnabled = true;
                        windowState = windowStateInput.value;
                    } else {
                        windowState = this.props.device.value;
                    }
                } else {
                    windowState = this.props.device.value;
                }

                let windowOpenAndRaining =
                    moistureSensorDigitalInput &&
                    moistureSensorDigitalInput.value === 0 && //VALUE 0 = RAIN DETECTED (INPUT CLOSED)
                    this.props.device.value > 0; //WINDOW OPENED

                iconAndValue =
                    this.props.device.lockState === true ? (
                        <div className="margin-t-12">
                            {lockedIcon}
                            <span className="no-margin sensor-state-value"></span>{' '}
                        </div>
                    ) : (
                        <div className="margin-t-12" style={{display:"flex", flexDirection:"row",justifyContent:"space-around", alignItems:"center"}}>
                              {windowOpenAndRaining ? (
                                <i style={{ fontSize: '2em' }} className="icon-cloud-rain blink"></i>
                            ) : null}
                            <i  className={bigStyle}></i>
                           
                            {windowStateDetectionEnabled ? (
                                <small style={{marginTop:"-4px"}} className="">{windowState > 0 ? 'open' : 'closed'}</small>
                            ) : null}
                            {/* <span className="no-margin sensor-state-value"></span> */}
                           
                        </div>
                    );

                shuttersValue = (
                    <span style={{marginLeft:0}}>
                        <input
                            className="toggle-window-device"
                            //style={{pointer:"cursor",content:windowState > 0 ? 'closed' : 'open'}}
                            onChange={this.toggleWindowDeviceState.bind(this, this.props.device)}
                            checked={this.props.device.value > 0}
                            //value={windowState > 0 ? 'closed' : 'open'}

                            type="checkbox"
                        />
                    </span>
                );

                break;

            case 'wiegandDoorLock':
                let doorState = _.find(this.props.devices, (d) => {
                    return d.id === this.props.device.params.doorStateDetector.value;
                });

                let doorStateString = '- - - -';
                if (doorState) {
                    if (doorState.connectionState === 2) {
                        doorStateString = '[-]';
                    } else {
                        doorStateString = doorState.value === 0 ? '[CLOSED]' : '[OPEN]';
                    }
                }

                iconAndValue =
                    this.props.device.lockState === true ? (
                        <div className="margin-t-12" onClick={() => {}}>
                            {lockedIcon}
                        </div>
                    ) : (
                        <div className="margin-t-12" onClick={() => {}}>
                            <i className={bigStyle}></i>
                        </div>
                    );

                shuttersValue = <span className="no-margin"> {doorStateString}</span>;
                scheduleDeviceIcon = (
                    <i
                        onClick={() => {
                            hashHistory.push('/wiegandDoorLockWhitelist/' + this.props.device.id);
                        }}
                        className={'pointer fa fa-list clock-icon'}
                    ></i>
                );

                break;
            default:
                break;
        }

        let iconClass = 'fa fa-bolt';
        let title = '';
        switch (this.props.device.interruptMode) {
            case 0:
                iconClass = 'fa fa-bolt interruptsDisabled interruptState';
                title = 'All interrupts disabled';
                break;
            case 1:
                iconClass = 'fa fa-bolt  text-success hidden interruptState';
                title = 'All interrupts enabled';
                break;
            case 2:
                iconClass = 'fa fa-bolt interruptsOutEnabled interruptState';
                title = 'Out interrupts enabled';
                break;
            case 3:
                iconClass = 'fa fa-bolt interruptsInEnabled interruptState';
                title = 'In interrupts enabled';
                break;
            default:
                iconClass = 'fa fa-bolt  text-success hidden interruptState';
                break;
        }

        return (
            <div
                key={this.props.type + this.props.device.id}
                className={
                    _.includes(this.props.route.location.pathname.toLowerCase(), 'scene')
                        ? 'device col-md-3 col-xs-6 col-sm-3 col-lg-2'
                        : 'device col-md-3 col-xs-6 col-sm-3 col-lg-2'
                }
            >
                <div className={' device-inside ' + disabledStyle}>
                    <i
                        title={title}
                        className={
                            _.includes(this.props.route.location.pathname.toLowerCase(), 'scene') ||
                            _.isUndefined(this.props.device.interruptMode) ||
                            (!_.isUndefined(this.props.device.interruptMode) && this.props.device.interruptMode === 1)
                                ? 'hidden'
                                : iconClass + ' bolt-icon'
                        }
                    ></i>
                    {this.props.isDashboardOnly ? null : checkRoleAccess('admin', 'systemIntegrator', 'advancedUser', 'remoteAssistance') ? scheduleDeviceIcon : null}
                    {this.props.isDashboardOnly ? null : sensorLogIcon}
                    <div className="m-top-15">
                        <div className={'short-text no-margin'}>{this.props.device.name}</div>
                        {this.props.isDashboardOnly ? null : (
                            <i
                                onClick={this.goToEdit.bind(this, this.props.device)}
                                className={
                                    _.includes(this.props.route.location.pathname.toLowerCase(), 'scene')
                                        ? 'hidden'
                                        : 'pointer fa fa-cogs small-icon'
                                }
                            ></i>
                        )}
                    </div>
                    {iconAndValue}
                    <div className="toggle-button">{shuttersValue}</div>
                    {this.props.isDashboardOnly ? null : (
                        <div
                            className={
                                this.props.numberOfEvents === 0 ||
                                _.includes(this.props.route.location.pathname.toLowerCase(), 'scene')
                                    ? 'hidden'
                                    : 'device-event-icon'
                            }
                        >
                            {this.props.numberOfEvents}
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

//export default CompositeDevice
export default branch(
    {
        devices: ['model', 'devices'],
    },
    CompositeDevice
);