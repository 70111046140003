import actions from "../data/actions/ui";
import { toast } from 'react-toastify';

var timeoutDebouncer = null;

const showHeaderNotification = (isSuccess,time, message) => {
    if(isSuccess===false) {actions.setNoticationSuccesss(false)}
    else {actions.setNoticationSuccesss(true)}
    actions.setNotificationMessage(message);
    actions.showHeaderNotification();

    clearTimeout(timeoutDebouncer);
    timeoutDebouncer = setTimeout(()=> {
     actions.deleteNotificationMesages();
        actions.hideHeaderNotification()
    }, time);
    
 }

 const toastInfoMessage = (message, closeTimeout=5000, type="INFO")=>{
 	toast(message, {
 		type: toast.TYPE[type], 
		position:toast.POSITION.BOTTOM_RIGHT,
		autoClose: closeTimeout,
 	});
 }

  const toastErrorMessage = (message, closeTimeout=5000, type="ERROR")=>{
 	toast(message, {
 		type: toast.TYPE[type], 
		position:toast.POSITION.BOTTOM_RIGHT,
		autoClose: closeTimeout,
 	});
 }

 const closeToast = (toastId)=>{
    if(toastId){
         toast.dismiss(toastId);
    } else {
         toast.dismiss(toastId); //close all toasts
    }

 }
 
 export default showHeaderNotification;

 export {toastInfoMessage, toastErrorMessage, closeToast}