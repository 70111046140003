// import _ from "lodash"
import baobabTree from "../state";
import baobabFunnel from "../state/baobabFunnel";
import uiActions from "./ui";
import { /*requestData, */ requestDataLongTimeout } from "../newCommManager";

var actions = {
    toggleSwitch: (compositeDeviceId, currentState) => {
        const id = parseInt(compositeDeviceId, 10);
        const cursor = baobabTree.root.select("model", "compositedevices", {
            id,
        });
        cursor.set("value", currentState === 1 ? 0 : 1);
        return requestDataLongTimeout(
            "/setCompositeDeviceValue/" + compositeDeviceId,
            "PUT",
            { value: currentState === 1 ? 0 : 1 }
        )
            .then((res) => {
                if (!baobabFunnel(res.model, res.method, res.data)) {
                    //rolling back
                    cursor.set("value", currentState);
                }
            })
            .catch((err) => {
                cursor.set("value", currentState);
                uiActions.pushErrorToSessionErrors(err);
                baobabTree.root
                    .select("ui", "showChangeStateFailInfo")
                    .set(true);
            });
    },
    toggleAnalogSwitch: (compositeDeviceId, valueToSet, currentState) => {
        const id = parseInt(compositeDeviceId, 10);
        const cursor = baobabTree.root.select("model", "compositedevices", {
            id,
        });

        cursor.set("value", valueToSet);
        cursor.set("isExecuting", true);

        // console.log("VLAUE TO SET", valueToSet);
        return requestDataLongTimeout("/setCompositeDeviceValue/" + id, "PUT", {
            value: valueToSet,
        })
            .then((res) => {
                // console.log("VLAUE SETTED", valueToSet);
                if (!baobabFunnel(res.model, res.method, res.data)) {
                    //rolling back
                    cursor.set("value", currentState);
                    cursor.set("isExecuting", false);
                }
            })
            .catch((err) => {
                console.log("VLAUE ERROR", valueToSet);
                cursor.set("value", currentState);
                cursor.set("isExecuting", false);
                uiActions.pushErrorToSessionErrors(err);
                baobabTree.root
                    .select("ui", "showChangeStateFailInfo")
                    .set(true);
            });
    },
    setAnalogOutputState: (compositeDeviceId, state) => {
        const id = parseInt(compositeDeviceId, 10);
        const cursor = baobabTree.root.select("model", "compositedevices", {
            id,
        });

        let currentState = baobabTree.root
            .select("model", "compositedevices", { id }, "value")
            .get();
        cursor.set("value", state);
        cursor.set("isExecuting", true);

        return requestDataLongTimeout(
            "/setCompositeDeviceValue/" + compositeDeviceId,
            "PUT",
            { value: state }
        )
            .then((res) => {
                if (!baobabFunnel(res.model, res.method, res.data)) {
                    //rolling back
                    cursor.set("value", currentState);
                    cursor.set("isExecuting", false);
                }
            })
            .catch((err) => {
                cursor.set("value", currentState);
                cursor.set("isExecuting", false);
                uiActions.pushErrorToSessionErrors(err);
            });
    },
    autocalibrateShutters: (compositeDeviceId, targetedState) => {
        const id = parseInt(compositeDeviceId, 10);
        const cursor = baobabTree.root.select("model", "compositedevices", {
            id,
        });

        let currentState = baobabTree.root
            .select("model", "compositedevices", { id }, "value")
            .get();
        cursor.set("isExecuting", true);
        cursor.set("value", targetedState);

        return requestDataLongTimeout("/autocalibrateShutters", "POST", {
            compositeDeviceId: compositeDeviceId,
            targetedState: targetedState,
        })
            .then((res) => {
                if (!baobabFunnel(res.model, res.method, res.data)) {
                    //rolling back
                    cursor.set("value", currentState);
                    cursor.set("isExecuting", false);
                }
            })
            .catch((err) => {
                cursor.set("value", currentState);
                cursor.set("isExecuting", false);
                uiActions.pushErrorToSessionErrors(err);
            });
    },
};

export default actions;