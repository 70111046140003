import React, { Component } from 'react';
import { branch } from 'baobab-react/higher-order';
// import baobabTree from "../../data/state";
import actions from "../../data/actions/actions";
import _ from "lodash";
import { getIconCssClassFromStyleParams } from "../../utils/fontIcons";


class UnassignedDevices extends Component {

  switch = (id, value) => {
    actions.toggleSwitch(id, value);
  }

  handleClick = (id) => {
    window.location = "#/device/" + id + "/edit";
  }

  render() {
    if (_.isUndefined(this.props.devicegroups)) {
      return null;
    }

    var noDevices = <div className="text-center"><span className="color-white">There are no unassigned devices</span></div>
    let showNoDevices = false

    var assignedArray = [];
    var unassignedArray = [];
    _.forEach(this.props.devicegroups, d => {
      (this.props.devices).map((item) => {
        if (parseInt(item.id, 10) === parseInt(d.DeviceId, 10)) {
          assignedArray.push(item)
        };
        return true;
      }
      );
    }
    )
    unassignedArray = _.differenceWith(this.props.devices, assignedArray, _.isEqual);
    let deviceIcon = ""
   
    let items = unassignedArray.map((device) => {
      if(device.type === 2){
        deviceIcon = device.value === 1 ?  <i className={getIconCssClassFromStyleParams(device.style,{isBig:true}, "iconForTrue")}></i> :  
        <i className={getIconCssClassFromStyleParams(device.style,{isBig:true}, "iconForFalse")}></i>
    }
    else {
        deviceIcon = device.value === 1 ?  <i className={getIconCssClassFromStyleParams(device.style,{isBig:true, isActive:true})}></i> : 
        <i className={getIconCssClassFromStyleParams(device.style, { isBig: true })}></i>
    }
      return (
        <div key={_.uniqueId("dev")} className="device device col-md-3 col-xs-6 col-lg-2" >
          <div className="device-inside">
            <div >
              <span className="short-text no-margin">{device.name}</span>
            </div>
            <div>
            {deviceIcon}
            </div>
            <div className="toggle-button">
              <a className="assign-link color-white" href={"#/device/" + device.id + "/selectDeviceGroup"}>Assign to group</a>
            </div>
          </div>
        </div>
      )
    }
    )


    var assignedCompositeArray = [];
    var unassignedCompositeArray = [];
    _.forEach(this.props.devicegroups, d => {
      (this.props.compositeDevices).map((item) => {
        if (parseInt(item.id, 10) === parseInt(d.CompositeDeviceId, 10)) {
          assignedCompositeArray.push(item)
        };
        return true;
      }
      );
    }
    )
    unassignedCompositeArray = _.differenceWith(this.props.compositeDevices, assignedCompositeArray, _.isEqual);
     let compositeDeviceIcon = ""

     
     if (unassignedArray.length === 0 && unassignedCompositeArray.length === 0) {
      showNoDevices = true
    }
   
    let compositeItems = unassignedCompositeArray.map((device) => {
    
      compositeDeviceIcon = device.value === 1 ?  <i className={getIconCssClassFromStyleParams(device.style,{isBig:true, isActive:true})}></i> : 
        <i className={getIconCssClassFromStyleParams(device.style, { isBig: true })}></i>
    
      return (
        <div key={_.uniqueId("dev")} className="device device col-md-3 col-xs-6 col-lg-2" >
          <div className="device-inside">
            <div >
              <span className="short-text no-margin">{device.name}</span>
            </div>
            <div>
            {compositeDeviceIcon}
            </div>
            <div className="toggle-button">
              <a className="assign-link color-white" href={"#/compositeDevice/" + device.id + "/selectDeviceGroup"}>Assign to group</a>
            </div>
          </div>
        </div>
      )
    }
    )

    return (
      <div className={(this.props.showScrollbar === true ? "show-scrollbar" : "") + " content-w-background "}>
      <div className="transparent-border">
        {compositeItems}
          {items}
          {showNoDevices === true ? noDevices : <div></div>}
        </div>
        <div className="row"></div>
        <div className="row text-center">
        </div>

        {/* <div className="bottom-with-margin"> </div>
        <div className="bottom-with-margin"></div> */}
      </div>
    )
  }
}

export default branch({
  devices: ['monkeys', 'selfContainedDevices'],
  // compositeDevices:["model","compositedevices"],
  compositeDevices: ['monkeys', 'accessibleCompositeDevices'],
  devicegroups: ['model', 'devicegroups'],
  // groups: ['model', 'groups'],
  groups: ["monkeys", "accessibleGroups"],
  route: ["route"],
  showScrollbar: ["ui", "showScrollbar"],
  showMainMenu: ["ui", "showMainMenu"]
}, UnassignedDevices);

