
const EventEmitter = require('events');
class addOnEventEmitter extends EventEmitter {}

var eventEmitter = null;
if(window.pubsub){
	eventEmitter = window.pubsub;
} else {
	eventEmitter = new addOnEventEmitter();
	 window.pubsub = eventEmitter;
}

export default eventEmitter;