import React, { Component } from 'react';
import { branch } from 'baobab-react/higher-order';
import { handleDeviceGroup } from "../../data/actions/formActions";
import _ from "lodash";
import { getIconCssClassFromStyleParams } from "../../utils/fontIcons"
import { hashHistory } from "react-router"
// import ReactDOM from "react-dom"

class SelectDevicesFromGroup extends Component {
    constructor() {
        super();
        this.state = {
            devices:null,
            compositeDevices:null,
            saveButtonDisabled:false,
            searchTerm:""
        }
    }

    componentDidMount() {
        // this.setInitialState(this.props);
        if (!_.isUndefined(this.props.route.params.groupId)) {
            var temp = {};

            _.forEach(this.props.devices, dev => {
                let deviceGroupItem = _.find(this.props.devicegroups, dg => parseInt(dg.GroupId, 10) === parseInt(this.props.route.params.groupId, 10) && dg.DeviceId === dev.id);
                temp[dev.id] = {
                    checked: !_.isUndefined(deviceGroupItem),
                    deviceId: dev.id,
                    compositeDeviceId: null,
                    deviceGroupId: deviceGroupItem ? deviceGroupItem.id : null
                }
            })

            let compTemp = {}

            _.forEach(this.props.compositeDevices, dev => {
                let deviceGroupItem = _.find(this.props.devicegroups, dg => parseInt(dg.GroupId, 10) === parseInt(this.props.route.params.groupId, 10) && dg.CompositeDeviceId === dev.id);
                compTemp[dev.id] = {
                    checked: !_.isUndefined(deviceGroupItem),
                    compositeDeviceId: dev.id,
                    deviceId: null,
                    deviceGroupId: deviceGroupItem ? deviceGroupItem.id : null
                }
            })
            if (this.props.devices.length > 0) {
                this.setState({ devices: temp, compositeDevices: compTemp });
            }
        }

    }

    submit(e) {
        e.preventDefault();
        this.setState({saveButtonDisabled:true})
        var filteredDevicesStateArray = _.filter(this.state.devices, dg => !_.isUndefined(dg.checked));
        var filteredCompositeStateArray = _.filter(this.state.compositeDevices, dg => !_.isUndefined(dg.checked));
        // console.log(filteredDevicesStateArray.concat(filteredCompositeStateArray));

        let concatedArray = filteredDevicesStateArray.concat(filteredCompositeStateArray);

        handleDeviceGroup(concatedArray, parseInt(this.props.route.params.groupId, 10)).then(resp=>{
            this.setState({saveButtonDisabled:false})
            hashHistory.goBack()
        }).catch(err => {
            this.setState({saveButtonDisabled:false})
        });
    }

    inputHandler = (deviceId, currentState, deviceGroupId, deviceModel, e) => {
        var obj = {};

        let stateDevices = {}
        if (deviceModel === "devices") {
            obj = {
                checked: !currentState,
                deviceId: deviceId,
                compositeDeviceId: null,
                deviceGroupId: deviceGroupId
            }
            stateDevices = _.cloneDeep(this.state.devices)
            stateDevices[deviceId] = obj
            this.setState({ devices: stateDevices });
        }
        else {
            obj = {
                checked: !currentState,
                compositeDeviceId: deviceId,
                deviceId: null,
                deviceGroupId: deviceGroupId
            }
            stateDevices = _.cloneDeep(this.state.compositeDevices)
            stateDevices[deviceId] = obj
            this.setState({ compositeDevices: stateDevices });
        }
    }

    setSearchedDeviceTerm(e){
        this.setState({
            searchTerm:e.target.value
        })
    }

    render() {

        if (_.isUndefined(this.props)) {
            return null;
        }
        if (this.state.devices === null && this.state.compositeDevices === null) {
            return null;
        }
        let deviceIcon = "";
        let devices = [];

        

        _.forEach(this.props.devices, device => {
            if(_.includes(device.name.toLowerCase(), this.state.searchTerm.toLowerCase())){
                devices.push(device)
            }
        })
        let items = devices.map((device) => {
            if (device.type === 2) {
                deviceIcon = device.value === 1 ? <i className={getIconCssClassFromStyleParams(device.style, { isBig: true }, "iconForTrue")}></i> :
                    <i className={getIconCssClassFromStyleParams(device.style, { isBig: true }, "iconForFalse")}></i>
            }
            else {
                deviceIcon = <i className={getIconCssClassFromStyleParams(device.style, { isBig: true })}></i>
            }
            

            return (
                <div onClick={this.inputHandler.bind(this, device.id, this.state.devices[device.id].checked, this.state.devices[device.id].deviceGroupId, "devices")}
                    key={_.uniqueId("dev")} className={this.state.devices[device.id].checked === true ? "group-selected device groups col-xs-12 col-sm-6 col-md-6 checkboxes" :
                        "group-not-selected blue device groups col-xs-12 col-sm-6 col-md-6 checkboxes"}>
                    <div className="col-xs-3" >
                        {deviceIcon}
                        {/* <i className={getIconCssClassFromStyleParams(device.style,{isBig:true})}></i> */}
                    </div>
                    <div className="col-xs-7">
                        <span className="short-text-wide">{device.name}</span>
                    </div>
                    <div className="col-xs-2">
                        <input onChange={() => { }} type="checkbox" checked={this.state.devices[device.id].checked}
                            name="DeviceId" id="checkbox-1-1" className="regular-checkbox" />
                        <label htmlFor="checkbox-1-1"></label>
                    </div>
                </div>)
        }
        )


        let compositeDevices = this.props.compositeDevices.map((device) => {
if(_.includes(device.name.toLowerCase(), this.state.searchTerm.toLowerCase())){
    deviceIcon = <i className={getIconCssClassFromStyleParams(device.style, { isBig: true })}></i>


    return (
        <div onClick={this.inputHandler.bind(this, device.id, this.state.compositeDevices[device.id].checked, this.state.compositeDevices[device.id].deviceGroupId, "compositeDevices")}
            key={_.uniqueId("dev")} className={this.state.compositeDevices[device.id].checked === true ? "group-selected device groups col-xs-12 col-sm-6 col-md-6 checkboxes" :
                "group-not-selected blue device groups col-xs-12 col-sm-6 col-md-6 checkboxes"}>
            <div className="col-xs-3" >
                {deviceIcon}
                {/* <i className={getIconCssClassFromStyleParams(device.style,{isBig:true})}></i> */}
            </div>
            <div className="col-xs-7">
                <span className="short-text-wide">{device.name}</span>
            </div>
            <div className="col-xs-2">
                <input onChange={() => { }} type="checkbox" checked={this.state.compositeDevices[device.id].checked}
                    name="DeviceId" id="checkbox-1-1" className="regular-checkbox" />
                <label htmlFor="checkbox-1-1"></label>
            </div>
        </div>)
}else {return null}
          
        })
        return (<div>
        <div className="text-center"><i className="fa fa-search  m-right-5" aria-hidden="true"></i>
              <input  type="text" className="form-control search-devices-input color-black" 
              placeholder="Search" onChange={this.setSearchedDeviceTerm.bind(this)} value={this.state.searchTerm} />
            </div>
            <form >
                <div className="text-center sel-group">
                    <span>Select device(s) for selected group:</span>
                </div>
                <div style={{marginRight:0, marginLeft:0}} className="row">
                    {compositeDevices}
                    {items}
                </div>
                <div className="text-center">
                    <button disabled={this.state.saveButtonDisabled} onClick={this.submit.bind(this)} className="btn btn-info btn-sm">Submit</button>
                </div>
            </form>
            {/* <div className="bottom-with-margin"></div>
            <div className="bottom-with-margin"></div> */}
        </div>
        )
    }
}

export default branch({
    devices: ['monkeys', 'selfContainedDevices'],
    showMainMenu:["ui", "showMainMenu"],
    route: ["route"],
    devicegroups: ["model", "devicegroups"],
    // compositeDevices: ["model", "compositedevices"]
    compositeDevices: ['monkeys', 'accessibleCompositeDevices'],
}, SelectDevicesFromGroup);