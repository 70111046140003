import React, { Component } from 'react';
import { branch } from 'baobab-react/higher-order';
import PropTypes from 'prop-types';
import _ from "lodash"
import CardTabComponent from "../../../components/cardTabComponent"

//import TabAddOnsManagement from "./tabAddOnsManagement"
import baobabTree from '../../../data/state';

import serverSideInputs from "../../../components/serverSideInputs/"

import actions from "../../../data/actions/actions"
import IpConfig from "./ipConfig";
import ui from "../../../data/actions/ui"
import {fetchAddOnList} from "../../../data/actions/lazyActions"

import checkRoleAccess from "../../../utils/checkRoleAccess"

class Settings extends Component {
	async componentDidMount(){
		
		try{
			let addOnList = await fetchAddOnList()
			baobabTree.root.select("readOnlyModel", "addOns").set(addOnList);
			ui.setSettingSelectedTabIndex("userSettingsTabIndex",0);	
		} catch(err){
			console.warn("FAILED TO LOAD ADD ONS", err)
			ui.setSettingSelectedTabIndex("userSettingsTabIndex",0);	
		}
	}
	shouldComponentUpdate(nextProps, nextState){		
       return (this.props.selectedTabIndex !== nextProps.userSettingsTabIndex ||  this.props.showMainMenu !== nextProps.showMainMenu);
    }

	handleUserSettings(inputs, tabName){
		actions.saveUserSettingsTab(inputs, tabName)
	}
	handleAddOnUserSettings(inputs, tabName){
		actions.updateAddOnUserSettingsTab( this.props.route.params.addOnPath, inputs, tabName)
	}
	resetUserSettings(tabName){
		actions.resetUserSettingsTab(tabName);
	}
	resetAddOnSettings(tabName){
		actions.resetAddOnUserSettingsTab(this.props.route.params.addOnPath, tabName);
	}
	onSelectedTabIndexChange(type,index){
		ui.setSettingSelectedTabIndex(type,index);
	}
	componentDidUpdate(){
		if( _.isUndefined(this.props.route.params.addOnPath)){
			if(this.props.addOnUserSettingsTabIndex!==0){
				ui.setSettingSelectedTabIndex("addOnUserSettingsTabIndex",0);
			}
		}
	}
	render(){
		let showAddOnsTab = checkRoleAccess("admin", "systemIntegrator")
/*
		this.props.userLevelMonkeys.showAddOnsTab;
		if(this.props.sessionInfo.userProfile.level>0){
			showAddOnsTab = true;
		}
*/


		if(this.props.route.params.addOnPath === undefined){
			return <TabbedUserSettings
			{...this.props}
			selectedTabIndex={this.props.userSettingsTabIndex}
			onSelectedTabIndexChange={this.onSelectedTabIndexChange.bind(this,"userSettingsTabIndex")}
			 key="settings" saveButtonHandler={this.handleUserSettings} resetButtonHandler={this.resetUserSettings.bind(this)} settings={this.props.settings} showPluginManagementTab={showAddOnsTab}/>
			
		} else {  //LOAD ADD ON SETTINGS!!!!!!

			let addOn = _.find(this.props.addOns, a=>a.path===this.props.route.params.addOnPath);
			if(addOn === undefined){
				return null
			}

			return <TabbedUserSettings
			{...this.props}
			selectedTabIndex={this.props.addOnUserSettingsTabIndex}
			onSelectedTabIndexChange={this.onSelectedTabIndexChange.bind(this,"addOnUserSettingsTabIndex")}
			 key="plugin" saveButtonHandler={this.handleAddOnUserSettings.bind(this)} resetButtonHandler={this.resetAddOnSettings.bind(this)} settings={addOn.settings} showPluginManagementTab={false}/>
		}
	}
}

class TabbedUserSettings extends Component {
	onSelectedTabIndexChange(index){
		this.props.onSelectedTabIndexChange(index);
	}
	shouldComponentUpdate(nextProps, nextState){
       return (this.props.selectedTabIndex !== nextProps.userSettingsTabIndex ||  this.props.showMainMenu !== nextProps.showMainMenu)
    }
	render(){
		//console.log(this.props);
		let tabLabels = [];
		let tabContent = [];
		let tabIcons = [];

		let settingsTabs = [];
		_.forIn(this.props.settings, (tabValue, tabName)=>{
			let tabSettings = _.clone(tabValue);
			if(_.isObject(tabSettings)){
				tabSettings.tabName = tabName;
				settingsTabs.push(tabSettings);
			}else{
				console.warn("Check settings for tabName", tabName)
			}
		})

		_.forEach(_.orderBy(settingsTabs,i=>i.displayOrder,["asc"]), (tabContentDescription)=>{

			if(this.props.sessionInfo.userProfile && checkRoleAccess("admin", "systemIntegrator")){
				tabLabels.push(tabContentDescription.label);
				tabIcons.push(tabContentDescription.faIcon);
				tabContent.push(<SettingsTabContent 
					{...this.props}
					saveButtonHandler={this.props.saveButtonHandler}
					resetButtonHandler={this.props.resetButtonHandler}
					tabName={tabContentDescription.tabName} tabInputsDescription={tabContentDescription} key={tabContentDescription.tabName}/>);

/*
				if(tabContentDescription.tabName==="general" && this.props.userLevelMonkeys.hideGeneralSettingsTab===true){
					//remove pushed general item...
					tabLabels.splice(-1,1)
					tabIcons.splice(-1,1)
					tabContent.splice(-1,1)
				}*/

			}

			/*
			if(this.props.sessionInfo.userProfile && this.props.sessionInfo.userProfile.level>=tabContentDescription.userLevel){
				tabLabels.push(tabContentDescription.label);
				tabIcons.push(tabContentDescription.faIcon);
				tabContent.push(<SettingsTabContent 
					{...this.props}
					saveButtonHandler={this.props.saveButtonHandler}
					resetButtonHandler={this.props.resetButtonHandler}
					tabName={tabContentDescription.tabName} tabInputsDescription={tabContentDescription} key={tabContentDescription.tabName}/>);

				if(tabContentDescription.tabName==="general" && this.props.userLevelMonkeys.hideGeneralSettingsTab===true){
					//remove pushed general item...
					tabLabels.splice(-1,1)
					tabIcons.splice(-1,1)
					tabContent.splice(-1,1)
				}

			}
			*/
		});

/*
		if(this.props.showPluginManagementTab){
			tabLabels.push("Add ons")
			tabIcons.push("fa fa-plug");
			tabContent.push(<TabAddOnsManagement {...this.props} key={"AddOns"+tabContent.length}/>);
		}
		*/
		if(_.isUndefined(this.props.route.params.addOnPath)){
			if(!this.props.route.location.pathname.includes("parking")){
				tabLabels.push("IP CONFIG");
				tabIcons.push("fa fa-map-marker");
				tabContent.push(<IpConfig key={"IpConfig"}  {...this.props} tabIndex={tabIcons.length-1}/>);
			}
		
		}
		return (<div className="settings-content">
			<CardTabComponent
			{...this.props}
			selectedIndex={this.props.selectedTabIndex}
			onSelectedTabIndexChange={this.onSelectedTabIndexChange.bind(this)}
				tabNames={tabLabels}
				tabIcons={tabIcons}
				tabContentComponents={tabContent}
				/>
		</div>);

	}
}

TabbedUserSettings.defaultProps = {
	showPluginManagementTab : PropTypes.bool,
	saveButtonHandler: PropTypes.func.isRequired,
	resetButtonHandler: PropTypes.func.isRequired
}

class SettingsTabContent extends Component {
	constructor(props){
		super(props);
		this.state = {
			inputs:props.tabInputsDescription
		}
	}
	onValueChanged(newValue, inputName){
		let inputs = _.cloneDeep(this.state.inputs);
		inputs[inputName].value = newValue;
		this.setState({inputs:inputs});
	}
	saveChanges(){
		this.props.saveButtonHandler(this.state.inputs, this.props.tabName)
		//actions.saveUserSettingsTab(this.state.inputs, this.props.tabName)
	}

	resetSettingsTab(){
		this.props.resetButtonHandler(this.props.tabName);
	}
	render(){
		let contentControlls = [];
		_.forIn(this.state.inputs, (inputDesc, inputName)=>{
			if(this.props.sessionInfo && this.props.sessionInfo.userProfile && checkRoleAccess("admin", "systemIntegrator")){
				serverSideInputs(inputDesc, contentControlls, inputName, this.onValueChanged.bind(this));
			}			
		});

		return (<div>

		{contentControlls}
		<div className=" clear">
		<div className="col-sm-2"></div>
		<div className="col-sm-10">
		<button onClick={this.saveChanges.bind(this)} className="btn btn-success pull-left btn-sm"> <i className="fa fa-save"/> Save</button>
		<button onClick={this.resetSettingsTab.bind(this)} className="btn btn-info pull-left btn-sm"> <i className="fa fa-save"/> Reset to default</button>
		
		</div>
		</div>
		<div className="bottom-with-dblmargin"> </div>
		<div className="bottom-with-dblmargin"> </div>
		<div className="bottom-with-dblmargin"> </div>
		</div>);
	}
}

SettingsTabContent.propTypes = {
	tabInputsDescription: PropTypes.object.isRequired
}
export default branch({
	userSettingsTabIndex: ["ui","userSettingsTabIndex"],
	addOnUserSettingsTabIndex: ["ui","addOnUserSettingsTabIndex"],
	//userLevelMonkeys:["userLevelMonkeys"],
	sessionInfo:["sessionInfo"],
	settings:["readOnlyModel","settings"],
	addOns:["readOnlyModel","addOns"],
	showMainMenu:["ui", "showMainMenu"],
	route:["route"]
}, Settings);

export {TabbedUserSettings}