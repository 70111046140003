import baobabTree from "./index"
import _ from "lodash"
import uiActions from "../actions/ui"
import moment from "moment"
import pubsub from "../pubsub"

var modelChangeListenres = {};

//it returns boolean which is true if data is processed by the funnel in succesefull manner, false if not
const funnelData = (modelNameSingular, method, payload)=>{
    if(modelChangeListenres[modelNameSingular]){
        try{
             modelChangeListenres[modelNameSingular](method, payload);
        } catch(err){
            console.log(err);
        }
    }
    if(_.isUndefined(method)){
        throw new Error("WARNING: Funnel not processed.");
    }
    let continueProcess = true;
    var baobabRoute="";
    switch (modelNameSingular) {
        case "DeviceAddressParams":
        case "Settings":
        case "EvalApiHelp":
        case "ApiEventsHelp":
        case "AddOns": //consider removal!!!!!!
            baobabRoute = ["readOnlyModel",modelNameSingular.charAt(0).toLowerCase() + modelNameSingular.slice(1)];
        break;
        case "Console":
            baobabRoute = ["readOnlyModel",modelNameSingular.charAt(0).toLowerCase() + modelNameSingular.slice(1)];
            if(method==="POST"){
                return handleConsolePOSTS(payload, baobabRoute);
            }
        break;
        case "MySlaves":
            baobabRoute = ["masterOnlyModel",modelNameSingular.charAt(0).toLowerCase() + modelNameSingular.slice(1)];
         break;
        /*
        case "ServerNotifications":
            baobabRoute = ["masterOnlyModel",modelNameSingular.charAt(0).toLowerCase() + modelNameSingular.slice(1)];
        break;*/
        case "None":
            continueProcess = false;
        break;
        case "DoorBell":
            baobabRoute = ["doorBellsActive"];     
        break;
        case "SlaveEvent": //used for notification system from slave add ons...
          //  console.log("SlaveEvent",payload);
            if(payload.eventName==="error"){
                let err = new Error(payload.payload);
                uiActions.pushErrorToSessionErrors(err)
            } else if(payload.eventName==="warning" || payload.eventName==="alert") {
                let warning = {
                    type: payload.eventName==="alert" ? "error" : "warning",
                    message: payload.payload
                }
                uiActions.pushErrorToSessionErrors(warning)
            } else if(payload.eventName === "debugLog"){
                let ts = moment().format("HH:mm:ss")
                console.log("log:",ts, "-" ,payload.payload); //console out in browser console
            } else if(payload.eventName === "systemMessage"){
                // info, warning,alert,error,critical
                uiActions.pushMessageToSystemMesages(payload)
            } else {
                pubsub.emit("SlaveEvent", payload); //------ SVE OVE HANDLERE ISPOD TREBA PREBACITI NA PUBSUB ... ali oprezno i kasnije nekada
                
                
                let ev = new Event("SlaveEvent"); //ovo pročešlja
                ev.payload = payload;
                window.dispatchEvent(ev);
            }
            
            continueProcess = false;
        break;
        default:
            baobabRoute  = ["model",modelNameSingular.toLowerCase()+"s"];
        break;
    }

    if(!continueProcess){
        return true;
    }

    switch(method){
        case "GET":
            baobabTree.root.select(baobabRoute).set(payload);
        break;
        case "POST":
            if(!_.isArray(payload)){
                if(!_.includes(_.map(baobabTree.root.select(baobabRoute).get(),"id"),payload.id)){
                    baobabTree.root.select(baobabRoute).push(payload);
                } else if(!_.includes(_.map(baobabTree.root.select(baobabRoute).get(),"uuid"),payload.uuid)){
                    baobabTree.root.select(baobabRoute).push(payload);
                } else {
                    //just ignore
                }
            } else {
                throw Error("Not supported");
            }
        break;
        case "PUT":
            if(!_.isUndefined(payload.uuid)){
                try{
                    baobabRoute.push({uuid:payload.uuid});
                    let editingItem = _.clone(baobabTree.root.select(baobabRoute).get());
                    _.forIn(payload, (value, key)=>{
                        editingItem[key] = value;
                    });
                    baobabTree.root.select(baobabRoute).set(editingItem);
                } catch (err){
                    throw err
                   //console.warn("PROBLEM",baobabRoute, payload, err);
                }

            } else if(!_.isUndefined(payload.id)){
                try{
                    baobabRoute.push({id:parseInt(payload.id,10)});
                    let editingItem = _.clone(baobabTree.root.select(baobabRoute).get());
                    if(editingItem){
                        _.forIn(payload, (value, key)=>{
                            editingItem[key] = value;
                        });
                        baobabTree.root.select(baobabRoute).set(editingItem);
                    } else {
                        console.warn("undefined a", modelNameSingular, method,baobabRoute, payload)
                    }
                } catch (err){
                    throw err;
                }
                
            } else if(_.isArray(payload)){
                _.forEach(payload, item=>{
                     try{
                        let itemizedBaobabRoute = _.clone(baobabRoute);
                        itemizedBaobabRoute.push({id:parseInt(item.id,10)});
                        let editingItem = _.clone(baobabTree.root.select(itemizedBaobabRoute).get());

                        if(editingItem){
                            _.forIn(item, (value, key)=>{
                                editingItem[key] = value;
                            });
                            baobabTree.root.select(itemizedBaobabRoute).set(editingItem);
                        } else {
                            console.warn("unexpected item", editingItem, modelNameSingular, method, payload);
                        }
                        
                    } catch (err){
                        throw err;
                    }

                })
            } else {
                if(_.isArray(baobabTree.root.select(baobabRoute).get())){
                   // console.log(payload);
                    throw Error("There is no object ID.");
                } else {
                     baobabTree.root.select(baobabRoute).set(payload);
                }                
            }
        break;
        case "DELETE":
            if(!_.isUndefined(payload.id)){
                let filteredData = _.filter(baobabTree.root.select(baobabRoute).get(), item=>{
                    return item.id!==payload.id;
                });
                baobabTree.root.select(baobabRoute).set(filteredData);
            } else if(!_.isUndefined(payload.uuid)){
                let filteredData = _.filter(baobabTree.root.select(baobabRoute).get(), item=>{
                    return item.uuid!==payload.uuid;
                });
                baobabTree.root.select(baobabRoute).set(filteredData);
            } else if(_.isArray(payload)){
                let filteredData = _.filter(baobabTree.root.select(baobabRoute).get(), item=>{
                    if(_.isUndefined(item.id)){
                        return !_.includes(payload, item.uuid)
                    } else {
                        return !_.includes(payload, item.id)
                    }
                });
                baobabTree.root.select(baobabRoute).set(filteredData);
            } else {
                throw new Error("There is no object ID.");
            }
            
        break;
        default:
            throw new Error("Not supported");
        // break;
    }
    return true;
    //baobabTree.commit();
}

const handleConsolePOSTS = (payload, baobabRoute)=>{
    if(!_.isArray(payload)){
        if(baobabTree.root.select(baobabRoute).get().length>50){
            let temp = _.takeRight(baobabTree.root.select(baobabRoute).get(), 48);
            temp.push(payload);
            baobabTree.root.select(baobabRoute).set(temp);
        } else {
            baobabTree.root.select(baobabRoute).push(payload);
        }
    } else {
        throw Error("Not supported");
    }
    return true;
}

const registerFunnelModelChangeListener = (model, handlerFunction)=>{
    modelChangeListenres[model] = handlerFunction
}

export default funnelData;
export {registerFunnelModelChangeListener}