import React from 'react';
import ReactDOM from 'react-dom';
//import _ from "lodash"
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import Routes from './router';
import registerServiceWorker from './registerServiceWorker';
import { hashHistory as history } from 'react-router';

import { root } from 'baobab-react/higher-order';
import baobabTree from './data/state';
import actions from './data/actions/actions';

import './assets/css/bootstrap.min.css';
import './assets/font-awesome/css/font-awesome.css';
import './assets/css/material-kit.css';

import './assets/css/transitions.css';
import './assets/css/custom-mods.css';

import { loadStartupData, silentlyReloadLocationData } from './data/actions/startupLoaders';
import listenForForegroundVisibilityChange from './utils/foregroundDetection';

import { tokenDashboardLogin, tokenAutoLogin } from './data/actions/loginAndRegister';
import { initKeycloak } from './keycloak';

/************************global config vars***************************/

window.forceJoinLocation = (slaveUuid) => {
    return 'Not implemented';
};

var debugLogStreamingTimeoutStop = null;
window.enableDebugLogStreaming = (action = 'start') => {
    actions
        .enableDebugLogStreaming(action)
        .then((res) => {
            console.log('starting log streaming');
            clearTimeout(debugLogStreamingTimeoutStop);
            if (action === 'start') {
                debugLogStreamingTimeoutStop = setTimeout(() => {
                    actions.enableDebugLogStreaming('stop');
                }, 10 * 60 * 1000);
            }
        })
        .catch((err) => {
            console.warn(err);
        });
};

/************************END global config vars***************************/

const getUrlParameterByName = (paramname, url) => {
    if (!url) url = window.location.href;
    paramname = paramname.replace(/[[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + paramname + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

if (process.env.NODE_ENV === 'production') {
    Sentry.init({
        dsn: 'https://9c1a6889e7f745469c24954bf405d387@sentry.io/1287239',
        //release: "my-project-name@2.3.12",
        integrations: [new Integrations.BrowserTracing()],
        tracesSampleRate: 0.5,
    });
}

let isSlaveDiveIn = getUrlParameterByName('slaveUuid') === null ? false : true;
// console.log('-----diveInSlaveUuid----', diveInSlaveUuid);


let userEmail =  null

if(getUrlParameterByName('userEmail')){
    userEmail = Buffer.from(getUrlParameterByName('userEmail'), 'base64').toString()
}

const RootedApp = root(baobabTree, Routes);

let dashboardOnlyRoutes = getUrlParameterByName('dashboardOnly');
let autoLogin = getUrlParameterByName('autoLogin');
let token = getUrlParameterByName('token');

baobabTree.root.select('dashboardOnlyRoutes').set(dashboardOnlyRoutes);

async function loadOnDashboardOnlyLogin() {
let diveInSlaveUuid = getUrlParameterByName('slaveUuid');

    await tokenDashboardLogin(token);
    console.log('Auto login successeful - hotel');
    loadStartupData(diveInSlaveUuid, userEmail);
    listenForForegroundVisibilityChange(() => {
        silentlyReloadLocationData();
    });
}

async function loadStartupDataOKeycloakAutorization() {
let diveInSlaveUuid = getUrlParameterByName('slaveUuid');

    console.log('loadStartupDataOKeycloakAutorization');
    //primary authorization method
    await initKeycloak();
    await loadStartupData(diveInSlaveUuid, userEmail);
    listenForForegroundVisibilityChange(() => {
        silentlyReloadLocationData();
    });
}

async function loadStartupDataOKeycloakAutologin() {
let diveInSlaveUuid = getUrlParameterByName('slaveUuid');

    await tokenAutoLogin(token);
    console.log('Auto login successeful - localhost');
    loadStartupData(diveInSlaveUuid, userEmail);
    listenForForegroundVisibilityChange(() => {
        silentlyReloadLocationData();
    });
}

try {
    //hotel autologin
    if (dashboardOnlyRoutes && token) {
        //secondary authorization method
        loadOnDashboardOnlyLogin();
    } else if (autoLogin && token) {
        //autoLogin on LAN before init
        loadStartupDataOKeycloakAutologin();
    } else {
        //regular login
        loadStartupDataOKeycloakAutorization();
    }
} catch (err) {
    console.log('LOGIN ERROR', err);
}

ReactDOM.render(
    <RootedApp autoLogin={autoLogin} dashboardOnlyRoutes={dashboardOnlyRoutes} history={history} />,
    document.getElementById('root'),
    async () => {
        window.ReactLoaded = true;
        document.getElementById('index-full-screen').classList.add('hiddenElement'); //in index.html static file....
        if (window.innerWidth >= 991) {
            actions.showMainMenu();
        }
    }
);

if (isSlaveDiveIn === false) {
    registerServiceWorker();
}