import React, { Component } from 'react';
import { branch } from 'baobab-react/higher-order';
// import actions from "../../data/actions/actions";
import _ from "lodash";
import { deleteItem } from "../../data/actions/formActions";
import DeviceItems from "../../components/deviceItems/deviceItems";
import ConfirmDelete from "../../components/confirmBox/confirmDelete";
import ChangeStateFailInfo from "../../components/notification/changeStateFailInfo";
import { hashHistory } from "react-router";

import checkRoleAccess from "../../utils/checkRoleAccess"

class Devices extends Component {
  constructor() {
    super();
    this.state = {
      isDeleteDialogShown: false,
      deleteButtonDisabled: false
    }
  }

  delete = (group, id) => {
    this.setState({
      deleteButtonDisabled: true
    })
    deleteItem(group, id).then(response => {
      this.setState({
        isDeleteDialogShown: false,
        deleteButtonDisabled: false
      })
      // window.location = "#/"
      hashHistory.goBack();
    }).catch(err => {
      this.setState({
        isDeleteDialogShown: false,
        deleteButtonDisabled: false
      })
    });
  }
  handleClick = (id) => {
    window.location = "#/group/" + id + "/edit";
  }
  /*
  deviceEdit = (id) => {
    window.location = "#/device/" + id + "/edit";
  }*/
  showDeleteDialog() {
    this.setState({
      isDeleteDialogShown: true
    })
  }

  hideDeleteDialog() {
    this.setState({
      isDeleteDialogShown: false
    })
  }

  render() {

    
    var devicesInThisGroup = [];
    _.forEach(this.props.devicegroups, d => {
      if (parseInt(d.GroupId, 10) === parseInt(this.props.route.params.groupId, 10)) {
        this.props.devices.map((item) => {
          if (d.DeviceId === item.id) {
            devicesInThisGroup.push(item);
          }
          return true;
        })
      }
    }
    )
    devicesInThisGroup = _.uniq(devicesInThisGroup);

    var compositeDevicesInThisGroup = [];
    _.forEach(this.props.devicegroups, d => {
      if (parseInt(d.GroupId, 10) === parseInt(this.props.route.params.groupId, 10)) {
        this.props.compositeDevices.map((item) => {
          if (d.CompositeDeviceId === item.id) {
            compositeDevicesInThisGroup.push(item);
          }
          return true;
        })
      }
    }
    )
    compositeDevicesInThisGroup = _.uniq(compositeDevicesInThisGroup);

    const groupActions = checkRoleAccess("admin","systemIntegrator","advancedUser") ?  <div>
              <div className="groupSaveButton" onClick={() => { hashHistory.push("group/" + this.props.route.params.groupId + "/selectDevicesFromGroup") }}>
                <i className="fa fa-plus group-button-icon" aria-hidden="true"></i>
                <span className="m-left-5">Add or remove devices</span>
              </div>
            
              <div  className="pull-left groupDeleteButton" onClick={this.showDeleteDialog.bind(this)}>

                <i className="fa fa-trash group-button-icon" aria-hidden="true"></i>
                <span className="m-left-5">Delete group</span>

              </div>
              <div className="pull-right groupEditButton" onClick={() => this.handleClick(this.props.route.params.groupId)}>

                <i className="fa fa-pencil-square-o group-button-icon" aria-hidden="true"></i>
                <span className="m-left-5">Edit group</span>

            </div>
          </div> : null

    return (
      <div>
        <ChangeStateFailInfo />
        <ConfirmDelete hideDeleteDialog={this.hideDeleteDialog.bind(this)} isDeleteDialogShown={this.state.isDeleteDialogShown} delete={this.delete.bind(this, "group", parseInt(this.props.route.params.groupId, 10))} />
        <div className={(this.props.showScrollbar === true ? "show-scrollbar" : "") + " content-w-background "}>
        <div className="">
          <DeviceItems devices={devicesInThisGroup} compositeDevices={compositeDevicesInThisGroup} />
          <div className="row"></div>
          <div className="row center-buttons text-center group-buttons">
          
        {this.props.dashboardOnlyRoutes && this.props.dashboardOnlyRoutes !== null && this.props.dashboardOnlyRoutes.toString() === "true" ?  <div>
          <div className="groupSaveButton" onClick={() => { hashHistory.goBack()}}>
                <i className="fa fa-arrow-left group-button-icon" aria-hidden="true"></i>
                <span className="m-left-5">Go back</span>
              </div>
            
            </div> : 

            groupActions}      
          </div>

          {/* <div className="bottom-with-margin"> </div>
        <div className="bottom-with-margin"></div>
        <div className="bottom-with-margin"> </div> */}
        </div>
        </div>
      </div>
    )
  }
}

export default branch({
  devices: ['monkeys', 'selfContainedDevices'],
  // compositeDevices: ["model", "compositedevices"],
  compositeDevices: ['monkeys', 'accessibleCompositeDevices'],
  devicegroups: ['model', 'devicegroups'],
  route: ["route"],
  showMainMenu: ["ui", "showMainMenu"],
  showScrollbar: ["ui", "showScrollbar"],
  dashboardOnlyRoutes:["dashboardOnlyRoutes"]

}, Devices);