import React, { Component } from 'react';
import { branch } from 'baobab-react/higher-order';
import _ from "lodash";
import actions from "../../data/actions/actions";
import { hashHistory } from 'react-router'
import { getUrlParameterByName } from '../footer'

class Header extends Component {
    handleClick = () => {
        hashHistory.goBack()
    }

    render() {        

        let isFullScreen = false;
        if( window.innerHeight === window.screen.height) {
            // browser is fullscreen
            isFullScreen = true;
        }

        /* ------------------------------- */
        var naslov = "";
        if (!_.isUndefined(this.props.route.routes)){
            naslov = this.props.route.routes[0].title;
        }
        
        var isHomePage = window.location.hash === "#/" ? true : false ;
        if (_.isUndefined(this.props.route.params)){
            return null;
        }
        
        if(!_.isUndefined(this.props.route.params.groupId))
        {
        var activeGroup = _.find(this.props.groups, { id: parseInt(this.props.route.params.groupId, 10) });
        naslov = !_.isUndefined(activeGroup) ? activeGroup.name : "";
        isHomePage = false;
        }

        if(!_.isUndefined(this.props.route.params.sceneId))
        {
        var activeScene = _.find(this.props.scenes, { id: parseInt(this.props.route.params.sceneId, 10) });
        naslov = !_.isUndefined(activeScene) ? activeScene.name : ""
        isHomePage = false;
        }

        if(window.location.hash.startsWith("#/?")){
            isHomePage=true
        }
        if(window.location.hash==="#/hotelReception" || window.location.hash==="#/parking"){
            isHomePage=true
        }

        
        naslov = this.props.route.routes[0].pageTitle || this.props.headerTitle

        
        var backIcon = isHomePage === false ? <i onClick={this.handleClick} className="fa fa-arrow-left pointer header-back-icn"></i> : "" 


        let fullScreenClassBase = this.props.isMainMenuShown === true && this.props.renderMainMenu === true ? "col-xs-12 col-md-9 col-lg-9 pull-right" : "col-xs-12 col-md-12 col-lg-12 pull-right"
        let fullScreenClass = fullScreenClassBase;
        if(isFullScreen){
             fullScreenClassBase = fullScreenClassBase + " header-status-bar-filler"
        }

        let isMaster = this.props.sessionInfo.isMaster;
        if(isMaster===undefined){
            isMaster = false;
        }

        if(!isMaster){
            fullScreenClass = fullScreenClassBase + " bckg-orange" // bckg-red
        }

        let isReomoteAssistance = this.props.remoteAssistanceOrSlaveDiveInData?.remoteAssistance;

        let userEmail = ""
        
        if(getUrlParameterByName("userEmail")){
            userEmail = Buffer.from(getUrlParameterByName("userEmail"), "base64").toString()
        }


        // if(this.props.selectedSlaveUuid && this.props.slaveList.length>0){
        //    viewingLocation =  _.find(this.props.slaveList, s=>s.uuid===this.props.selectedSlaveUuid)
        // //    if(viewingLocation){
            //    if(viewingLocation.email !== this.props.sessionInfo.email){
                //    isReomoteAssistance = true;
            //    }
        //    }
        // }
        

        fullScreenClass = isReomoteAssistance ? (fullScreenClassBase + "  bckg-red") : fullScreenClass // bckg-red
       
        return (
            <div  id="header"  className={fullScreenClass} >
                <i onClick={ () => actions.showMainMenu("right") } className="fa fa-bars header-icon hidden-lg pointer hidden-md visible-sm visible-xs" ></i>
                {backIcon}
                <span>{isReomoteAssistance ? `ASSISTANCE FOR: ${userEmail ? userEmail : ""}` : naslov }</span>
            </div>
        );

    }
}

export default branch({
    sessionInfo: ["sessionInfo"],
    devices: ['model', 'devices'],
    groups: ['model', 'groups'],
    scenes: ['model', 'scenes'],
    route: ["route"],
    isMainMenuShown:["ui", "showMainMenu"],
    headerTitle:["ui", "headerTitle"],
    renderMainMenu : ["userLevelMonkeys", "renderMainMenu"],

    selectedSlaveUuid:["apiConfig","selectedSlaveUuid"],
    slaveList: ["masterOnlyModel", "mySlaves"],
    remoteAssistanceOrSlaveDiveInData:["remoteAssistanceOrSlaveDiveInData"]

}, Header);