import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

class EnumPicker extends Component {
	onChangeHandler(e) {
		let selectedValue = null;
		if (this.props.enumType === 'integer') {
			selectedValue = Number(e.target.value);
			if (isNaN(selectedValue)) {
				selectedValue = null;
			}
		} else {
			if (_.isString(e.target.value)) {
				//ditch off this sometimes, it is stupid.... now you have type...
				//old setting file failsafe
				if (
					e.target.value.startsWith('0') ||
					e.target.value.startsWith('1') ||
					e.target.value.startsWith('2') ||
					e.target.value.startsWith('3') ||
					e.target.value.startsWith('4') ||
					e.target.value.startsWith('5') ||
					e.target.value.startsWith('6') ||
					e.target.value.startsWith('7') ||
					e.target.value.startsWith('8') ||
					e.target.value.startsWith('9')
				) {
					selectedValue = parseInt(e.target.value, 10);
				} else {
					selectedValue = e.target.value;
				}
			} else {
				selectedValue = e.target.value;
			}
		}
		this.props.onValueChanged(selectedValue, this.props.inputName); //propagate updated value
	}
	render() {

		const {mandatorySelection = false, enumType, label = "label", value, enums=[]} = this.props;

		let selectedValue = '';
		if (enumType === 'integer') {
			selectedValue = parseInt(value, 10);
			if (isNaN(selectedValue)) {
				selectedValue = '';
			}
		} else {
			selectedValue = value;
		}
		let disabledOptions = _.isUndefined(this.props.disabledEnums) ? [] : this.props.disabledEnums;
		let options = _.map(enums, (opt) => {
			//console.log(opt,selectedValue)
			let value = opt;
			if (enumType === 'integer') {
				//whenever you have Device or some other ID data in format (ID - name)
				value = parseInt(value, 10);
			}
			return (
				<option key={_.uniqueId('opt')} disabled={_.includes(disabledOptions, opt)} value={value}>
					{opt}
				</option>
			);
		});
		if (mandatorySelection === false) {
			options = [
				<option key={_.uniqueId('default')} value={''}>
					Nothing selected
				</option>,
			].concat(options);
		}

		return (
			<div className="">
				<label className="control-label col-sm-2">{label}</label>
				<div className="col-sm-10">
					<select onChange={this.onChangeHandler.bind(this)} value={selectedValue}>
						{options}
					</select>
				</div>

				<div className=" clear"></div>
			</div>
		);
	}
}

EnumPicker.propTypes = {
	mandatorySelection: PropTypes.bool.isRequired,
	disabledOptions: PropTypes.array,
	onValueChanged: PropTypes.func.isRequired,
	//value: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	enums: PropTypes.array.isRequired,
	inputName: PropTypes.string.isRequired,
};

export default EnumPicker;