import React, { Component } from 'react';
import { branch } from 'baobab-react/higher-order';
//import _ from "lodash";


class ConfirmDelete extends Component {
    constructor() {
        super()
        this.state={
            deleteButtonDisabled:false
        }
    }

    componentDidMount(){
        this.setState({deleteButtonDisabled:false})
    }

    componentDidUpdate(nextProps){
        if(this.props !== nextProps){
            this.setState({
                deleteButtonDisabled:false
            })
        }
        
    }
    delete (){
        this.setState({
            deleteButtonDisabled:true
        })
        this.props.delete()
    }
    render() {

        return (
            <div className={this.props.isDeleteDialogShown === false ? "hidden" : "delete-dialog"}>
                <div className="dialog-center animated faster zoomIn">
                    <div className="dialog-title"><span>Confirm <i onClick={this.props.hideDeleteDialog} className="fa fa-times pull-right pointer" aria-hidden="true"></i></span></div>
                    <div className="dialog-content text-center">
                        <span>Are you sure you want to delete?</span>
                        <div className="text-center">
                            <button disabled={this.state.deleteButtonDisabled} onClick={this.delete.bind(this)} className="btn btn-danger btn-sm">Yes</button>
                            <button onClick={this.props.hideDeleteDialog} className="btn btn-default btn-sm">No</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default branch({
    route: ["route"],
    ui: ["ui", "showDeleteDialog"]
}, ConfirmDelete);