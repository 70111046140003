var reservations = [
    {
        id : 0,
        RoomId : 0,
        cardCode : "",
        checkInDateTime: 897123434729,
        checkOutDateTime: 1528362443039,
        deleted : false,
        customerId : "Ivo ivic"
    },
    {
        id : 5,
        RoomId : 0,
        cardCode : "",
        checkInDateTime: 1528197706314,
        checkOutDateTime: 1528197706314,
        deleted : false,
        customerId : "PEro peric"
    },
    {
        id : 1,
        RoomId : 1,
        cardCode : "",
        checkInDateTime: 1528197707314,
        checkOutDateTime: 1528197707314,
        deleted : false,
        customerId : "Marko Maric"
    },
    {
        id:2,
        RoomId: 2,
        cardCode : "",
        checkInDateTime: 1528197707314,
        checkOutDateTime: 1528197706314,
        deleted : false,
        customerId : "Ivana Ivanovic"
    },
    {
        id:3,
        RoomId: 3,
        cardCode : "",
        checkInDateTime: 897123434729,
        checkOutDateTime: 2159426876492,
        deleted : false,
        customerId : "Darth Vader"
    },
    {
        id:4,
        RoomId: 4,
        cardCode : "",
        checkInDateTime: 1528197707314,
        checkOutDateTime: 1528197706314,
        deleted : false,
        customerId : "Drazen Bludisa"
    }
]

var roomInfo = [];
for (let i = 0; i < 30; i++) {
    roomInfo.push({
        id: i,
        RoomId:i,
        title:"soba " +i,
        description : "",
        price: 20
    })
    
}

export {reservations, roomInfo}
