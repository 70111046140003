import React, { Component } from 'react';
import { branch } from 'baobab-react/higher-order';
import { saveDeviceGroups } from "../../data/actions/formActions";
import _ from "lodash";
// import actions from "../../data/actions/actions";
import { getIconCssClassFromStyleParams } from "../../utils/fontIcons";
import { hashHistory } from "react-router";

class DeviceGroup extends Component {
    constructor() {
        super();
        // this.state = null;
        this.state = {
            deviceGroups : null,
            saveButtonDisabled:false
        }
    }

    componentDidMount() {
        // this.setInitialState(this.props);
        if(!_.isUndefined(this.props.route.params.deviceId)){
            var temp = {};

            if(this.props.route.params.deviceModel.toLowerCase === "device"){
                _.forEach(this.props.groups, grp => {
                    let deviceGroupItem = _.find(this.props.devicegroups, dg => parseInt(dg.DeviceId, 10) === parseInt(this.props.route.params.deviceId, 10) && dg.GroupId===grp.id);
                    temp[grp.id] = {
                        checked:!_.isUndefined(deviceGroupItem),
                        groupId:grp.id,
                        deviceGroupId:deviceGroupItem ? deviceGroupItem.id : null
                    }
                })
            }
            else {
                _.forEach(this.props.groups, grp => {
                    let deviceGroupItem = _.find(this.props.devicegroups, dg => parseInt(dg.CompositeDeviceId, 10) === parseInt(this.props.route.params.deviceId, 10) && dg.GroupId===grp.id);
                    temp[grp.id] = {
                        checked:!_.isUndefined(deviceGroupItem),
                        groupId:grp.id,
                        deviceGroupId:deviceGroupItem ? deviceGroupItem.id : null
                    }
                })
            }
 
        
        if (this.props.groups.length > 0) {
            this.setState({deviceGroups:temp});
        }
        }
    }


    submit(e) {
        e.preventDefault(); 
        this.setState({
            saveButtonDisabled:true
        })
        var filteredStateArray = _.filter(this.state.deviceGroups, dg => !_.isUndefined(dg.checked));
        saveDeviceGroups(filteredStateArray,parseInt(this.props.route.params.deviceId,10), this.props.route.params.deviceModel).then(response => {
            this.setState({
                saveButtonDisabled:false
            })
            // window.location = "#/unassignedDevices";  
            hashHistory.goBack()          
    }).catch(err => {
        this.setState({
            saveButtonDisabled:false
        })
    });     
}

    inputHandler = (groupId, currentState, deviceGroupId, e) => {
        var obj = {};
        obj[groupId] = {
            checked: !currentState,
            groupId:groupId,
            deviceGroupId:deviceGroupId
        }
        let pom = {}
        pom = _.clone(this.state.deviceGroups)

        pom[groupId] = obj[groupId]
        this.setState({deviceGroups:pom});
    }

 
    render() {
        
        if (_.isUndefined(this.props)) {
            return null;
        }
        if (this.state.deviceGroups === null) {
            return <div className="text-center"><span className="">You haven't create any groups yet. Please create a group to be able to assign a device.</span></div>
        }

        if(this.props.groups.length === 0){
            var noGroups = <div className="text-center"><span className="">You haven't create any groups yet. Please create a group to be able to assign a device.</span></div>

        }

        let groups = this.props.groups;
        let items = groups.map((group) => {
            if(group.type !== 2){
            return (
                <div onClick={this.inputHandler.bind(this, group.id, this.state.deviceGroups[group.id].checked, this.state.deviceGroups[group.id].deviceGroupId)} key={_.uniqueId("dev")} className={this.state.deviceGroups[group.id].checked === true ? "group-selected device groups col-xs-12 col-sm-6 col-md-6 checkboxes" : "group-not-selected blue device groups col-xs-12 col-sm-6 col-md-6 checkboxes"}>
                    <div className="col-xs-3" >
                        <i className={getIconCssClassFromStyleParams(group.style,{isBig:true})}></i>
                    </div>
                    <div className="col-xs-7 text-center">
                        <span className="short-text-wide">{group.name}</span>
                    </div>
                    <div className="col-xs-2">
                		<input  onChange={()=>{}}  type="checkbox" checked={this.state.deviceGroups[group.id].checked}  name="GroupId" id="checkbox-1-1" className="regular-checkbox" /><label htmlFor="checkbox-1-1"></label>
                	</div>
                </div>)
                } else {return null}
        }
        )
        return (<div>
            <form >
                <div className="text-center sel-group">
                    <span>Select group(s) for selected device:</span>
                </div>
                <div className="row margin-l-r-0">
                {items}
                {noGroups}
                </div>
                <div className="text-center">
                    <button disabled={this.state.saveButtonDisabled} onClick={this.submit.bind(this)} className={this.props.groups.length === 0 ? "hidden" : "btn btn-info btn-sm"}>Submit</button>
                </div>
            </form>
            {/* <div className="bottom-with-margin"></div>
            <div className="bottom-with-margin"></div> */}
        </div>
        )
    }
}

export default branch({
    // groups: ['model', 'groups'],
    groups: ["monkeys", "accessibleGroups"],
    // devices: ['model', 'devices'],
    devices: ["monkeys", "accessibleDevices"],
    devicegroups: ['model', 'devicegroups'],
    deviceAddressParams: ['readOnlyModel', 'deviceAddressParams'],
    route: ["route"],
    showMainMenu: ["ui", "showMainMenu"],
}, DeviceGroup);