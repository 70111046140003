import React, { Component } from 'react';
//import { branch } from 'baobab-react/higher-order';

// import _ from "lodash";
import Modal from "../components/modals";

class DashboardOnlyRoutesWrapper extends Component {
    constructor(params) {
        super(params)
        this.state = {
        }
    }
    render() {
        return (
            <div id="dashboard-only">
                <Modal />	
                {this.props.children}
            </div>
        );
    }
}

export default DashboardOnlyRoutesWrapper;