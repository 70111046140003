import React, { Component } from 'react';
import _ from 'lodash';
import actions from '../../../data/actions/actions';
import { getIconCssClassFromStyleParams } from '../../../utils/fontIcons';
import { hashHistory } from 'react-router';
import updateForm, { loadFormData } from '../../../data/actions/formStateController';
import saveFormData from '../../../data/actions/formActions';
import showHeaderNotification from '../../../utils/showNotification';
import isDblTouchTap from '../isDblTouchTap';
import numbro from 'numbro';
import checkRoleAccess from '../../../utils/checkRoleAccess';

var deviceType = {
    digitalOutput: 0,
    analogOutput: 1,
    digitalInput: 2,
    analogInput: 3,
    special: 4,
    virtualSwitch: 5,
};

class Device extends Component {
    constructor() {
        super();
        this.debounceTimestamp = Date.now();
        this.state = {
            animationName: '',
        };
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (this.props.device.states) {
            //this one is special case
            return true;
        }

        if (this.props.device) {
            if (this.props.device.value !== nextProps.device.value) {
                return true;
            } else if (this.props.device.connectionState !== nextProps.device.connectionState) {
                return true;
            } else if (this.props.device.name !== nextProps.device.name) {
                return true;
            } else if (this.props.device.lockState !== nextProps.device.lockState) {
                return true;
            } else if (this.props.device.interruptMode !== nextProps.device.interruptMode) {
                return true;
            } else if (this.props.device.isExecuting !== nextProps.device.isExecuting) {
                return true;
            }

            if (this.props.device.address && this.props.device.address.states) {
                //other devices like SRC, update completly
                return true;
            }

            return false;
        }
    }

    debounce(fn) {
        if (Date.now() - this.debounceTimestamp > 300) {
            fn();
        } //else is debounced....
        this.debounceTimestamp = Date.now();
    }

    editDevice(device, e) {
        hashHistory.push('/device/' + device.id + '/edit/device');
    }

    scheduleDeviceAction(device, e) {
        if (!_.includes(this.props.route.location.pathname.toLowerCase(), 'alldevices')) {
            hashHistory.push('/group/' + this.props.groupId + '/device/' + device.id + '/scheduleList');
        } else {
            hashHistory.push('/allDevices/device/' + device.id + '/scheduleList');
        }
    }

    displayModalDialog(device, controlType, e) {
        this.props.showModalDialog(device, controlType);
    }

    inputChangeHandler(device, targetValue, e) {
        if (!_.includes(this.props.route.location.pathname.toLowerCase(), 'scene')) {
            this.setDeviceValue(device, targetValue, e);
        } else {
            loadFormData('scene', parseInt(this.props.route.params.sceneId, 10));
            this.setSceneDeviceValue(device, targetValue, e);
        }
    }

    setDeviceValue(device, targetValue, e) {
        // console.log(device)
        switch (device.type) {
            case deviceType.digitalOutput: //digital output
            case deviceType.virtualSwitch: //aded on 20.12.2020
                if (!device.isExecuting) {
                    actions.toggleSwitch(device.id, device.value);
                } /*
        this.debounce(() => {
          actions.toggleSwitch(device.id, device.value);
        })*/
                break;
            case deviceType.analogOutput: //analog output
                if (!device.isExecuting) {
                    actions.toggleAnalogSwitch(device.id, targetValue, device.value);
                } /*
        this.debounce(() => {
          actions.toggleAnalogSwitch(device.id, targetValue, device.value);
        })*/
                break;
            case deviceType.digitalInput:
                if (device.address.pushButton) {
                    if (device.address.pushButton === true) {
                        this.debounce(() => {
                            actions.toggleSwitch(device.id, 1); //must be 1 to emulate push event...
                        });
                    }
                }
                break;
            case deviceType.analogInput:
            case deviceType.special:
            default:
                if (device.address.plcDeviceType === 'volumeControll') {
                    if (!device.isExecuting) {
                        if (device.value > 0) {
                            actions.setVolumeState(device.id, 0, 'c');
                        } else {
                            actions.setVolumeState(device.id, 255, 'c');
                        }
                    }
                } else {
                    console.log('No action allowed', device);
                }
                break;
        }
    }

    setSceneDeviceValue(device, targetValue, e) {
        switch (device.type) {
            case deviceType.digitalOutput: //digital output
            case deviceType.virtualSwitch: //aded on 20.12.2020
            case deviceType.analogOutput:
                let max = 1;
                if (device.type === 1) {
                    max = 255;
                }
                var obj = { deviceId: device.id, value: e.target.value === 'off' ? max : 0 };
                var selection =
                    this.props.sceneParameter === 'active'
                        ? _.clone(this.props.sceneForm.params.sceneActiveDeviceState)
                        : _.clone(this.props.sceneForm.params.sceneExitDeviceState);

                var myObj = _.find(selection, (obj) => {
                    return obj.deviceId === device.id;
                });
                selection[_.indexOf(selection, myObj)] = obj;
                var params = {};
                var ob = { params };
                if (this.props.sceneParameter === 'active') {
                    ob.params['sceneActiveDeviceState'] = selection;
                    ob.params['sceneExitDeviceState'] = this.props.sceneForm.params.sceneExitDeviceState;
                    ob.params['groups'] = this.props.sceneForm.params.groups;
                    updateForm('scene', ob);
                } else {
                    ob.params['sceneActiveDeviceState'] = this.props.sceneForm.params.sceneActiveDeviceState;
                    ob.params['sceneExitDeviceState'] = selection;
                    ob.params['groups'] = this.props.sceneForm.params.groups;
                    updateForm('scene', ob);
                }
                break;

            case deviceType.analogInput:
            case deviceType.digitalInput:
            case deviceType.special:
            default:
                console.log('No action allowed');
                break;
        }
    }

    redirectToSensorLogScreen(deviceId) {
        hashHistory.push('/device/' + deviceId + '/logs');
    }

    lockState(deviceId, lockState, e) {
        if (isDblTouchTap(e)) {
            let currentStateValue = _.isUndefined(lockState) ? true : !lockState;

            loadFormData('device', parseInt(deviceId, 10));

            var obj = {};
            obj['lockState'] = currentStateValue;
            updateForm('device', obj);

            saveFormData('device').then((res) => {
                showHeaderNotification(
                    true,
                    3000,
                    'Device state ' + (currentStateValue === true ? '' : 'un') + 'locked'
                );
                //bounceIn
                this.setState({
                    animationName: 'animated faster flip',
                });
            });
        }
    }

    getDeviceDesignType(dev) {
        let renderType = null;
        if (dev.address) {
            if (dev.address.plcDeviceType === 'volumeControll') {
                renderType = 'volume';
            } else if (dev.address.driver === 'th01' || dev.address.driver === 'th01_v2') {
                renderType = 'singleThermostat';
            } else if (dev.address.driver === 'sdm230') {
                renderType = 'powermeter';
            } else if (dev.address.driver === 'bgtfs1' ) {
                renderType = 'windspeed';
            } else if(dev.address.driver === 'soilgxsm1' || dev.address.driver === 'bgtwsd2' || dev.address.driver === 'bgtwsd1' || dev.address.driver === 'bgtsm1'){
                renderType = 'temphum';
            } else if(dev.address.driver === 'jxbs_3001') {
                renderType = 'illumination';
            } else if(dev.address.driver === 'bgtsr') {
                renderType = 'snowrain';
            }
        }

        if (renderType === null && dev) {
            switch (dev.type) {
                case deviceType.digitalOutput:
                case deviceType.virtualSwitch: //aded on 20.12.2020
                    renderType = 'digitalOutput';
                    break;
                case deviceType.analogOutput:
                    renderType = 'analogOutput';
                    break;
                case deviceType.digitalInput:
                    renderType = 'digitalInput';
                    break;
                case deviceType.analogInput:
                    renderType = 'analogInput';
                    break;
                default:
                    break;
            }
        }

        return renderType;
    }

    render() {
        let actionArea = null;
        let iconAndValue = null;
        let disabledStyle = '';
        if (this.props.device.connectionState === 2) {
            disabledStyle = 'disabled-device';
        }

        //let showLogIcon = false
        let sensorLogIcon = <i className="hidden"></i>;
        if (!_.isUndefined(this.props.device.params)) {
            if (this.props.device.params !== null) {
                if (!_.isUndefined(this.props.device.params.valueLogger)) {
                    if (this.props.device.params.valueLogger === true) {
                        sensorLogIcon = (
                            <i
                                onClick={this.redirectToSensorLogScreen.bind(this, this.props.device.id)}
                                className={
                                    _.includes(this.props.route.location.pathname.toLowerCase(), 'scene')
                                        ? 'hidden'
                                        : 'pointer fa fa-line-chart sensorLog-icon'
                                }
                            ></i>
                        );
                    }
                }
            }
        }

        let bigAndActiveStyle =
            getIconCssClassFromStyleParams(this.props.device.style, { isBig: true, isActive: true }) + ' pointer';
        let bigStyle = getIconCssClassFromStyleParams(this.props.device.style, { isBig: true }) + ' pointer';

        let lockedIcon = (
            <div
                className="pointer"
                onClick={this.lockState.bind(this, this.props.device.id, this.props.device.lockState)}
            >
                <i className={'fa fa-lock triple-font-size ' + this.state.animationName}></i>
            </div>
        );

        iconAndValue =
            this.props.device.lockState === true ? (
                lockedIcon
            ) : (
                <div>
                    <i
                        onClick={this.lockState.bind(this, this.props.device.id, this.props.device.lockState)}
                        className={bigStyle + ' pointer'}
                    ></i>
                </div>
            );

        let scheduleDeviceIcon = (
            <i
                onClick={this.scheduleDeviceAction.bind(this, this.props.device)}
                className={
                    _.includes(this.props.route.location.pathname.toLowerCase(), 'scene')
                        ? 'hidden'
                        : 'pointer fa fa-calendar clock-icon'
                }
            ></i>
        );

        let designType = this.getDeviceDesignType(this.props.device);
        let toggleTargetValue = this.props.device.value === 0 ? 255 : 0;

        switch (designType) {
            case 'digitalOutput': //------------------------------- DIGITAL OUTPUTS
                actionArea = (
                    <input
                        onChange={this.inputChangeHandler.bind(this, this.props.device, null)}
                        checked={this.props.device.value > 0}
                        value={this.props.device.value > 0 ? 'on' : 'off'}
                        className="toggle"
                        type="checkbox"
                    />
                );

                iconAndValue =
                    this.props.device.lockState === true ? (
                        lockedIcon
                    ) : (
                        <div>
                            <i
                                onClick={this.lockState.bind(this, this.props.device.id, this.props.device.lockState)}
                                className={
                                    this.props.device.value === 1
                                        ? bigAndActiveStyle + ' pointer'
                                        : bigStyle + ' pointer'
                                }
                            ></i>
                        </div>
                    );
                break;

            case 'analogOutput': //------------------------------- ANALOG OUTPUTS
                let lockedAnalogIcon = (
                    <div onClick={this.displayModalDialog.bind(this, this.props.device, designType)}>
                        <i className={'fa fa-lock asdasd triple-font-size ' + this.state.animationName}></i>
                        <span className="no-margin sensor-state-value">
                            {parseInt((this.props.device.value / 255) * 100, 10)} %
                        </span>
                    </div>
                );

                //let toggleTargetValue = this.props.device.value === 0 ? 255 : 0;
                actionArea = (
                    <input
                        onChange={this.inputChangeHandler.bind(this, this.props.device, toggleTargetValue)}
                        checked={this.props.device.value > 0}
                        value={this.props.device.value > 0 ? 'on' : 'off'}
                        className="toggle"
                        type="checkbox"
                    />
                );
                iconAndValue =
                    this.props.device.lockState === true ? (
                        lockedAnalogIcon
                    ) : (
                        <div onClick={this.displayModalDialog.bind(this, this.props.device, designType)}>
                            <i className={this.props.device.value === 1 ? bigAndActiveStyle : bigStyle}></i>
                            <span className="no-margin sensor-state-value">
                                {parseInt((this.props.device.value / 255) * 100, 10)} %
                            </span>
                        </div>
                    );
                break;

            case 'digitalInput': //------------------------------- DIGITAL INPUT
                let iconForTrue =
                    getIconCssClassFromStyleParams(
                        this.props.device.style,
                        { isBig: true, isActive: false },
                        'iconForTrue'
                    ) + ' pointer';
                let iconForFalse =
                    getIconCssClassFromStyleParams(
                        this.props.device.style,
                        { isBig: true, isActive: false },
                        'iconForFalse'
                    ) + ' pointer';
                let trueFalseLabel =
                    this.props.device.value === 1
                        ? _.isUndefined(this.props.device.style.labelForTrue)
                            ? 'Open'
                            : this.props.device.style.labelForTrue
                        : _.isUndefined(this.props.device.style.labelForFalse)
                        ? 'Closed'
                        : this.props.device.style.labelForFalse;

                let inputLockedIcon = (
                    <div
                        className="pointer"
                        onClick={this.lockState.bind(this, this.props.device.id, this.props.device.lockState)}
                    >
                        <i className={'fa fa-lock triple-font-size ' + this.state.animationName}></i>
                    </div>
                );

                scheduleDeviceIcon = (
                    <i
                        onClick={this.scheduleDeviceAction.bind(this, this.props.device)}
                        className={
                            _.includes(this.props.route.location.pathname.toLowerCase(), 'scene')
                                ? 'hidden'
                                : 'pointer fa fa-bolt clock-icon'
                        }
                    ></i>
                );

                actionArea = (
                    <div className="inline-element">
                        <span className={this.props.device.address.pushButton ? 'hidden' : 'no-margin'}>
                            {trueFalseLabel}
                        </span>
                        <div
                            onClick={this.inputChangeHandler.bind(this, this.props.device, null)}
                            className={this.props.device.address.pushButton ? 'btn btn-sm btn-info' : 'hidden'}
                        >
                            {trueFalseLabel}
                        </div>
                    </div>
                );
                iconAndValue =
                    this.props.device.lockState === true ? (
                        inputLockedIcon
                    ) : (
                        <div>
                            <i
                                onClick={this.lockState.bind(this, this.props.device.id, this.props.device.lockState)}
                                className={
                                    this.props.device.value === 1 ? iconForTrue + ' pointer' : iconForFalse + ' pointer'
                                }
                            ></i>
                        </div>
                    );
                break;

            case 'analogInput': //------------------------------- ANALOG INPUT
                const analogReadout = this.props.device.value ? this.props.device.value : 0;
                scheduleDeviceIcon = (
                    <i
                        onClick={this.scheduleDeviceAction.bind(this, this.props.device)}
                        className={
                            _.includes(this.props.route.location.pathname.toLowerCase(), 'scene')
                                ? 'hidden'
                                : 'pointer fa fa-bolt clock-icon'
                        }
                    ></i>
                );

                actionArea = (
                    <span className={'no-margin analog-sensor-span'}>
                         {numbro(analogReadout).format({ thousandSeparated: true, mantissa: 2 })} 
                         {this.props.device?.address?.sensorInfo?.unitShort || ""}

                        {/* {this.props.device.address.sensorInfo.unitShort} */}
                    </span>
                );
                iconAndValue =
                    this.props.device.lockState === true ? (
                        lockedIcon
                    ) : (
                        <div>
                            <i
                                onClick={this.lockState.bind(this, this.props.device.id, this.props.device.lockState)}
                                className={bigStyle + ' pointer'}
                            ></i>
                        </div>
                    );
                break;

            case 'volume':
                let lockedVolumeIcon = (
                    <div onClick={this.displayModalDialog.bind(this, this.props.device, designType)}>
                        <i className={'fa fa-lock triple-font-size ' + this.state.animationName}></i>
                        <span className="no-margin sensor-state-value">
                            {parseInt((this.props.device.value / 255) * 100, 10)} %
                        </span>
                    </div>
                );
                // let toggleTargetValue = this.props.device.value === 0 ? 255 : 0;
                actionArea = (
                    <input
                        onChange={this.inputChangeHandler.bind(this, this.props.device, toggleTargetValue)}
                        checked={this.props.device.value > 0}
                        value="unknown"
                        className="toggle"
                        type="checkbox"
                    />
                );
                iconAndValue =
                    this.props.device.lockState === true ? (
                        lockedVolumeIcon
                    ) : (
                        <div onClick={this.displayModalDialog.bind(this, this.props.device, designType)}>
                            <i className={this.props.device.value === 1 ? bigAndActiveStyle : bigStyle}></i>
                            <span className="no-margin sensor-state-value">
                                {parseInt((this.props.device.value / 255) * 100, 10)} %
                            </span>
                        </div>
                    );
                break;

            case 'singleThermostat':
                let thermostatStates = this.props.device.states
                    ? this.props.device.states
                    : {
                          targetTemperature: _.isUndefined(this.props.sceneParameter)
                              ? 0
                              : this.props.device.value / 10,
                          currentTemperature: 0,
                          relaysBitMask: 0,
                          toggleButtonBitMask: 4,
                          pirState: 'closed',
                      };
                let isReady = false;
                let additionalIcon = null

                if(thermostatStates && thermostatStates.thermostatMode){
                    if(thermostatStates.thermostatMode === "heating"){
                        additionalIcon = <i className="icon-fire"></i>
                    }else if(thermostatStates.thermostatMode === "cooling"){
                        additionalIcon = <i className="fa fa-snowflake-o"></i>
                    }
                }                
                if (thermostatStates.targetTemperature > 0) {
                    isReady = true;
                }
                let relayDots = '';
                for (let i = 0; i <= 3; i++) {
                    if (thermostatStates.relaysBitMask & (1 << i)) {
                        relayDots = relayDots + '.';
                    }
                }

                let isPoweredOff = (thermostatStates.toggleButtonBitMask & (1 << 2)) === 0;
                if (isPoweredOff) {
                    //relayDots = "OFF"
                }

                iconAndValue =
                    this.props.device.lockState === true ? (
                        lockedIcon
                    ) : (
                        <div onClick={this.displayModalDialog.bind(this, this.props.device, designType)}>
                            {additionalIcon}
                            <i className={bigStyle + ' pointer'}></i>
                            <span className="no-margin sensor-state-value">{relayDots}</span>
                        </div>
                    );
                actionArea = (
                    <div>
                        {isReady ? (
                            <div>
                                {_.isUndefined(this.props.sceneParameter) ? (
                                    <span
                                        className={
                                            thermostatStates.pirState === 'open'
                                                ? 'powermeter-device-data animated flash slow infinite'
                                                : 'powermeter-device-data pointer '
                                        }
                                        style={{ fontSize: '1.8em' }}
                                    >
                                        {thermostatStates.currentTemperature.toFixed(1) + '°C'} /
                                    </span>
                                ) : null}
                                {isPoweredOff ? 'OFF' : thermostatStates.targetTemperature.toFixed(1) + '°C'}
                            </div>
                        ) : (
                            '(not ready)'
                        )}
                    </div>
                );
                break;

            case 'powermeter':
                let pwrMeterReadout = this.props.device.states || { activePower: 0, current: 0, totalActiveEnergy: 0 };

                sensorLogIcon = (
                    <i
                        onClick={() => {
                            hashHistory.push('/device/' + this.props.device.id + '/powerManagementLogs');
                        }}
                        className={
                            _.includes(this.props.route.location.pathname.toLowerCase(), 'scene')
                                ? 'hidden'
                                : 'pointer fa fa-line-chart sensorLog-icon'
                        }
                    ></i>
                );

                iconAndValue = !_.isUndefined(pwrMeterReadout.current) ? (
                    <div
                        onClick={() => {
                            hashHistory.push('/device/' + this.props.device.id + '/powerManagementLogs');
                        }}
                        className="powermeter-device-data pointer"
                    >
                        <div>
                            {numbro(pwrMeterReadout.current).format({ thousandSeparated: true, mantissa: 2 })} A /
                            {numbro(pwrMeterReadout.voltage).format({ thousandSeparated: true, mantissa: 2 })} V
                        </div>
                        <div>
                            {numbro(pwrMeterReadout.activePower).format({ thousandSeparated: true, mantissa: 2 })} W /
                            {numbro(pwrMeterReadout.totalActiveEnergy).format({ thousandSeparated: true, mantissa: 2 })}{' '}
                            kWh
                        </div>
                    </div>
                ) : (
                    <div className="powermeter-device-data"></div>
                );
                break;

            case 'windspeed':
                const sensorReadout = this.props.device.value ? this.props.device.value : 0;
                iconAndValue = (
                    <div>
                        <div>
                            <i className={bigStyle + ' pointer'}></i>
                        </div>
                        <div>
                            {' '}
                            {numbro(sensorReadout).format({ thousandSeparated: true, mantissa: 2 })} {this.props.device?.address?.sensorInfo?.unitShort || "m/s "}
                        </div>
                    </div>
                );
                break;

            case 'temphum':
                let temperature, humidity;
                if(this.props.device.states) {
                    temperature = this.props.device.states.temperature ? this.props.device.states.temperature : 0;
                    humidity = this.props.device.states.humidity ? this.props.device.states.humidity : 0;
                } 
                iconAndValue = (
                    <div>
                        <div>
                            <i className={bigStyle + ' pointer'}></i>
                        </div>
                        <div>
                            <span>
                                {' '}
                                {numbro(temperature).format({ thousandSeparated: true, mantissa: 2 })} {" °C "}
                            </span>
                            <span>
                                {' '}
                                {numbro(humidity).format({ thousandSeparated: true, mantissa: 2 })} {" %RH "}
                            </span>
                        </div>
                    </div>
                );
                break;

                case 'illumination':
                    let illumination = this.props.device.value ? this.props.device.value : 0;
                    iconAndValue = (
                        <div>
                            <div>
                                <i className={bigStyle + ' pointer'}></i>
                            </div>
                            <div>
                                <span className='no-margin analog-sensor-span'>
                                    {illumination} {" lx"}
                                </span>
                            </div>
                        </div>
                    );
                    break;

            case 'snowrain': {
                const sensorReadout = this.props.device.value;
                iconAndValue = (
                    <div>
                        {sensorReadout ? <i className="fa fa-tint big-icon"> </i> : <i className="icon-happy big-icon"> </i>}
                        <div>
                            {sensorReadout ? "It's raining/snowing" : "Dry weather"}
                        </div>
                    </div>
                );
                break;
            }
    
            default:
                //console.log("UNKNOWN DEVICE CONTROLL", this.props);
                break;
        }

        let iconClass = 'fa fa-bolt';
        let title = '';
        switch (this.props.device.interruptMode) {
            case 0:
                iconClass = 'fa fa-bolt interruptsDisabled interruptState';
                title = 'All interrupts disabled';
                break;
            case 1:
                iconClass = 'fa fa-bolt  text-success hidden interruptState';
                title = 'All interrupts enabled';
                break;
            case 2:
                iconClass = 'fa fa-bolt interruptsOutEnabled interruptState';
                title = 'Out interrupts enabled';
                break;
            case 3:
                iconClass = 'fa fa-bolt interruptsInEnabled interruptState';
                title = 'In interrupts enabled';
                break;
            default:
                iconClass = 'fa fa-bolt  text-success hidden interruptState';
                break;
        }

        return (
            <div
                className={
                    _.includes(this.props.route.location.pathname.toLowerCase(), 'scene')
                        ? 'device col-md-3 col-xs-6 col-sm-3 col-lg-2'
                        : 'device col-md-3 col-xs-6 col-sm-3 col-lg-2'
                }
            >
                <div className={disabledStyle + ' device-inside '}>
                    <i
                        title={title}
                        className={
                            _.includes(this.props.route.location.pathname.toLowerCase(), 'scene') ||
                            _.isUndefined(this.props.device.interruptMode) ||
                            (!_.isUndefined(this.props.device.interruptMode) && this.props.device.interruptMode === 1)
                                ? 'hidden'
                                : iconClass + ' bolt-icon'
                        }
                    ></i>

                    {this.props.isDashboardOnly ? null : checkRoleAccess('admin', 'systemIntegrator', 'advancedUser', 'remoteAssistance') ? scheduleDeviceIcon : null}
                    {this.props.isDashboardOnly ? null : sensorLogIcon}
                    <div className="m-top-15">
                        <div className={'short-text no-margin'}>{this.props.device.name}</div>
                        {this.props.isDashboardOnly ? null : (
                            <i
                                onClick={this.editDevice.bind(this, this.props.device)}
                                className={
                                    _.includes(this.props.route.location.pathname.toLowerCase(), 'scene')
                                        ? 'hidden'
                                        : 'pointer fa fa-cogs small-icon'
                                }
                            ></i>
                        )}
                    </div>
                    {iconAndValue}
                    <div className="toggle-button">{actionArea}</div>
                    {this.props.isDashboardOnly ? null : (
                        <div
                            className={
                                this.props.numberOfEvents === 0 ||
                                _.includes(this.props.route.location.pathname.toLowerCase(), 'scene')
                                    ? 'hidden'
                                    : 'device-event-icon'
                            }
                        >
                            {this.props.numberOfEvents}
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default Device;