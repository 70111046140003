
import _ from "lodash"

if(_.isUndefined(window.localStorage.debugLog)){
	window.localStorage.debugLog = false;
}



var log = (...params)=>{
	//console.log(params);
	if(window.localStorage.debugLog === true || window.localStorage.debugLog === "true"){
		console.log(...params);
	}
}

export default log;