import React, { Component } from 'react';
import { branch } from 'baobab-react/higher-order';
import { hashHistory } from 'react-router';
import actions from "../../data/actions/actions"

import checkRoleAccess from "../../utils/checkRoleAccess"

class DeviceManagement extends Component {
    constructor(){
        super();
        this.debouncer = null;
        this.debouncer2 = null;
        this.state = {
            rmcuAutodetectionState:null
        }
    }

    componentWillUnmount(){
        clearTimeout(this.debouncer)
        clearTimeout(this.debouncer2)
    }
    

    enableRMCUAutodetection = () => {
        if(this.debouncer2===null){
            actions.enableRMCUAutoDiscovery().then(res=>{
                this.setState({rmcuAutodetectionState:res.enabled})
            }).catch(err=>{
                console.warn(err)
            })

            this.debouncer2 = setTimeout(()=>{
                clearTimeout(this.debouncer2)
                this.debouncer2 = null;
            },2000)
        }
    }

    broadcastRMCURequestSlaveIntroductions = () => {
        if(this.debouncer===null){
            actions.broadcastRMCURequestSlaveIntroductions();
            this.debouncer = setTimeout(()=>{
                clearTimeout(this.debouncer)
                this.debouncer = null;
            },20000)
        }
    }

    render() {
        if(!checkRoleAccess("admin", "systemIntegrator")){
         return "Insufficient permissions."
        }

        let showRegisterModbusButton = false;
        if (this.props.settings && this.props.settings.modbus && this.props.settings.modbus.m1_enable) {
            showRegisterModbusButton = this.props.settings.modbus.m1_enable.value || this.props.settings.modbus.m2_enable.value;
        }

        let showEnableAutodetecRMCUDevices = true;
        if (this.props.settings && this.props.settings.rmcu && this.props.settings.rmcu.m1_enable) {
            showEnableAutodetecRMCUDevices = this.props.settings.rmcu.m1_enable.value || this.props.settings.rmcu.m2_enable.value;;
        }

        return (
            <div className={(this.props.showScrollbar === true ? "show-scrollbar" : "") + " content-w-background "}>
                {
                    
                     //removed from production version of software
                        this.props.isEngenieringOptionsEnabled === true ? (<div className="device-buttons-wrapper">

                            
                        <div className={"deviceManagement-tiles-wrapper no-padding col-xs-6 col-sm-4 col-md-4 col-lg-3"}>
                            <div onClick={() => { hashHistory.push("/registerBasicDevice") }} 
                            className="register-device-button pointer deviceManagement-tiles">
                                <div className="text-center deviceManagement-tiles-icon-wrapper">
                                    <i className="fa fa-plus"></i>
                                </div>
                                <span className="custom-button-devices">Register Device</span>
                            </div>
                        </div>
                    
                   
                        <div className="deviceManagement-tiles-wrapper no-padding col-xs-6 col-sm-4 col-md-4 col-lg-3">
                            <div onClick={() => { hashHistory.push("/registerI2C") }} 
                            className=" register-device-button pointer deviceManagement-tiles">
                                <div className="text-center deviceManagement-tiles-icon-wrapper">
                                    <i className="fa fa-plus"></i>
                                </div>
                                <span className="custom-button-devices">Register I2C expander</span>
                            </div>
                        </div>


                        <div className="deviceManagement-tiles-wrapper no-padding col-xs-6 col-sm-4 col-md-4 col-lg-3">
                            <div onClick={() => { hashHistory.push("/hardwareModules") }} 
                            className=" register-device-button pointer deviceManagement-tiles">
                                <div className="text-center deviceManagement-tiles-icon-wrapper">
                                    <i className="fa fa-microchip"></i>
                                </div>
                                <span className="custom-button-devices">Hardware modules</span>
                            </div>
                        </div>



                        <div className="deviceManagement-tiles-wrapper no-padding col-xs-6 col-sm-4 col-md-4 col-lg-3 hidden">
                            <div onClick={() => { hashHistory.push("/register1Wire") }} 
                            className=" register-device-button pointer deviceManagement-tiles">
                                <div className="text-center deviceManagement-tiles-icon-wrapper">
                                    <i className="fa fa-plus"></i>
                                </div>
                                <span className="custom-button-devices">Register 1-Wire device</span>
                            </div>
                        </div>


                        <div className="deviceManagement-tiles-wrapper no-padding col-xs-6 col-sm-4 col-md-4 col-lg-3">
                            <div onClick={() => { hashHistory.push("/registerWiegandDoorLock") }} 
                            className=" register-device-button pointer deviceManagement-tiles">
                                <div className="text-center deviceManagement-tiles-icon-wrapper">
                                    <i className="fa fa-key"></i>
                                </div>
                                <span className="custom-button-devices">Register Wiegand Door Lock</span>
                            </div>
                        </div>



                        {showEnableAutodetecRMCUDevices ? <div className="deviceManagement-tiles-wrapper no-padding col-xs-6 col-sm-4 col-md-4 col-lg-3">
                            <div onClick={this.enableRMCUAutodetection.bind(this)} 
                            className="register-device-button pointer deviceManagement-tiles">
                                <div className="text-center deviceManagement-tiles-icon-wrapper">
                                    <i className="fa fa-bolt"></i>
                                </div>
                                <span className="custom-button-devices">RMCU Autodetection 
                                ({ this.state.rmcuAutodetectionState=== null ? "UNKNOWN" :  this.state.rmcuAutodetectionState===true ? "ENABLED" : "DISABLED"})</span>
                            </div>
                        </div> : null}

                        {showEnableAutodetecRMCUDevices ? <div className="deviceManagement-tiles-wrapper no-padding col-xs-6 col-sm-4 col-md-4 col-lg-3">
                            <div onClick={this.broadcastRMCURequestSlaveIntroductions.bind(this)} 
                            className="register-device-button pointer deviceManagement-tiles">
                                <div className="text-center deviceManagement-tiles-icon-wrapper">
                                    <i className="fa fa-retweet"></i>
                                </div>
                                <span className="custom-button-devices">RMCU devices intro request</span>
                            </div>
                        </div> : null}

                        

                        {showRegisterModbusButton ? <div className="deviceManagement-tiles-wrapper no-padding col-xs-6 col-sm-4 col-md-4 col-lg-3">
                            <div onClick={() => { hashHistory.push("/modbusDevices") }} 
                            className="register-device-button pointer deviceManagement-tiles">
                                <div className="text-center deviceManagement-tiles-icon-wrapper">
                                    <i className="fa fa-cogs"></i>
                                </div>
                                <span className="custom-button-devices">Modbus Devices</span>
                            </div>
                        </div> : null}
                        

                    </div>) : null
                }
            </div>
        );
    }
}

export default branch({
    devices: ['monkeys', 'selfContainedDevices'],
    // compositeDevices: ["model", "compositedevices"],
    compositeDevices: ["monkeys", "accessibleCompositeDevices"],
    devicegroups: ['model', 'devicegroups'],
    route: ["route"],
    isEngenieringOptionsEnabled: ["userLevelMonkeys", "isEngenieringOptionsEnabled"],
    showMainMenu: ["ui", "showMainMenu"],
    searchedDeviceTerm: ["searchedDevice"],
    showScrollbar: ["ui", "showScrollbar"],

    // devices: ["model", "devices"],
    // groups: ['model', 'groups'],
    groups: ["monkeys", "accessibleGroups"],
    settings: ["readOnlyModel", "settings"]
}, DeviceManagement);