import React, { Component } from 'react';
import { branch } from 'baobab-react/higher-order';
import uiActions from "../../data/actions/ui";


class PoorConnectionWarning extends Component {
    togglePoorConnectionWarning(){
        uiActions.toggleBadNotificationFullScreenWarning()
    }
render(){
    return (
        <div className={this.props.poorConnectionWarning.fullScreen === false ? "hidden" : "delete-dialog"}>
        <div className="poor-connection dialog-center text-center animated faster zoomIn text-center">
        <p>
        <i className="fa fa-exclamation-triangle fa-2x text-warning"></i> <strong>Poor Connection</strong>
        </p>
        <p >
            Your connection is poor. You may experience some problems associated with device synchronization.
        </p>
           
            <div className="btn btn-warning notification-btn" onClick={this.togglePoorConnectionWarning}>
             OK
            </div>
        </div>
    </div>
    )
  }
}

export default branch({
  route: ["route"],
  poorConnectionWarning: ["ui", "poorConnectionWarning"],
  sessionInfo:["sessionInfo"]
}, PoorConnectionWarning);