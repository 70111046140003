import restLib from 'rest';
import mime from 'rest/interceptor/mime';
import baobabTree from '../state';
import { attachAuthorizationHeader } from '../../keycloak';
import * as Sentry from '@sentry/browser';

const client = restLib.wrap(mime, { mime: 'application/json' });

async function rest(path, method, obj, customHeaders){
	let apiServer = baobabTree.root.select(['apiConfig', 'url']).get();
	let selectedSlaveUuid = baobabTree.root.select(['apiConfig', 'selectedSlaveUuid']).get();
	let pathWithQuery = apiServer + path + '';

	if (selectedSlaveUuid) {
		if (pathWithQuery.lastIndexOf('?') === -1) {
			pathWithQuery = pathWithQuery + '?slaveUuid=' + selectedSlaveUuid + '&fullResponse';
		} else {
			pathWithQuery = pathWithQuery + '&slaveUuid=' + selectedSlaveUuid + '&fullResponse';
		}
	}

	let payload = { method: method, path: pathWithQuery, entity: obj };
	if (customHeaders) {
		payload.headers = customHeaders;
	} else {
		if (payload.headers === undefined) {
			payload.headers = {};
		}
	}

	let apiKeyLoginToken = baobabTree.root.select(['apiKeyLoginToken']).get();
	if (apiKeyLoginToken) {
		payload.headers['pimatico_rest_id'] = apiKeyLoginToken;
	} else {
		//regular token
		await attachAuthorizationHeader(payload.headers);
	}

	//treba ovo malo pročistiti dolje!!!!
	return client(payload)
		.then((response) => {
			if (response.entity) {
				if (response.status.code === 401) {
					console.log('unauthorized!!!!');
				}
				if (response.status.code >= 400) {
					let err = null;
					if (response.entity.message) {
						err = new Error(response.entity.message);
						err.details = response.status.text + ' ' + method + ' ' + path;
					} else if (response.entity.details) {
						err = new Error(response.entity.details);
						err.details = response.status.text + ' ' + method + ' ' + path;
					} else {
						err = new Error(method + ' ' + path);
						err.details = 'No details';
					}
					Sentry.captureException(err);
					throw err;
				} else {
					return response.entity;
				}
			} else {
				console.warn('Unexpected response:', response);
				throw new Error('Unexpected response');
			}
		})
		.catch((err) => {
			Sentry.captureException(err);
			throw err;
		});
};

export async function restData(path, method, obj, customHeaders){
	const res = await rest(path, method, obj, customHeaders)
	return res.data;
}

export default rest;