import React, { Component } from 'react';
import { branch } from 'baobab-react/higher-order';
import _ from "lodash";

class SelectInterruptMode extends Component {

    render() {
        if(this.props.settings.general.uiInterruptManagement.value === false){
            return null
        }
        let isSceneForm = _.includes((this.props.route.location.pathname).toLowerCase(), "scene")

        let borderStyle0 = isSceneForm === true ? { border: "1px solid #9a9a9a", borderRight: "0.5px solid #9a9a9a", borderTopLeftRadius: "8px", borderTopBottomRadius: "8px" } : { border: "0.5px solid #9a9a9a", borderTop: "1px solid #9a9a9a", borderLeft: "1px solid #9a9a9a", borderTopLeftRadius: "8px" }
        let borderStyle1 = isSceneForm === true ? { border: "1px solid #9a9a9a", borderLeft: "0.5px solid #9a9a9a", borderTopRightRadius: "8px", borderTopBottomRadius: "8px" } : { border: "0.5px solid #9a9a9a", borderTop: "1px solid #9a9a9a", borderRight: "1px solid #9a9a9a", borderTopRightRadius: "8px" }
        let borderStyle2 = { border: "0.5px solid #9a9a9a", borderBottom: "1px solid #9a9a9a", borderLeft: "1px solid #9a9a9a", borderBottomLeftRadius: "8px" }
        let borderStyle3 = { borderLeft: "0.5px solid #9a9a9a", borderTop: "0.5px solid #9a9a9a", borderBottom: "1px solid #9a9a9a", borderRight: "1px solid #9a9a9a", borderBottomRightRadius: "8px" }

        let options = [
            <div className="pointer" key={_.uniqueId("opt")} onClick={() => { this.props.selectInterruptMode(0) }} 
            style={this.props.value === 0 ? { ...selectedIconModeOptionsStyle, ...borderStyle0 } : { ...iconModeOptionsStyle, ...borderStyle0 }}><i style={{ marginTop: "10px" }} 
            className="fa fa-bolt interruptsDisabled fa-2x"></i><div>All disabled</div> </div>,
            
            <div className="pointer" key={_.uniqueId("opt")} onClick={() => { this.props.selectInterruptMode(1) }} 
            style={this.props.value === 1 ? { ...selectedIconModeOptionsStyle, ...borderStyle1 } : { ...iconModeOptionsStyle, ...borderStyle1 }}><i style={{ marginTop: "10px" }} 
            className="fa fa-bolt text-success fa-2x"></i><div>All enabled</div> </div>,
            
            <div className="pointer" key={_.uniqueId("opt")} onClick={() => { this.props.selectInterruptMode(2) }} 
            style={this.props.value === 2 ? { ...selectedIconModeOptionsStyle, ...borderStyle2 } : { ...iconModeOptionsStyle, ...borderStyle2 }}><i style={{ marginTop: "10px" }} 
            className="fa fa-bolt interruptsOutEnabled fa-2x"></i><div>Out enabled</div> </div>,
            
            <div className="pointer" key={_.uniqueId("opt")} onClick={() => { this.props.selectInterruptMode(3) }} 
            style={this.props.value === 3 ? { ...selectedIconModeOptionsStyle, ...borderStyle3 } : { ...iconModeOptionsStyle, ...borderStyle3 }}><i style={{ marginTop: "10px" }} 
            className="fa fa-bolt interruptsInEnabled fa-2x"></i><div>In enabled</div> </div>
        ]


        return (
            <div  className={this.props.isShown === true ? "animated faster zoomIn interruptModePopup" : "hidden"}>

                {isSceneForm === true ? options.slice(0, 2) : options}
            </div>
        );
    }
}

export default branch({
    // devices: ['model', 'devices'],
    devices: ['monkeys', 'accessibleDevices'],
    route: ["route"],
    ui: ["ui", "showIconSelect"],
    settings: ["readOnlyModel","settings"]

}, SelectInterruptMode);


const iconModeOptionsStyle = {
    width: "50%",
    display: "inline-block",
    height: "50%",
    backgroundColor: "#afafaf",
    boxSizing: "border-box",
    margin: 0,
    color: "black"
}

const selectedIconModeOptionsStyle = {
    width: "50%",
    display: "inline-block",
    height: "50%",
    backgroundColor: "white",
    boxSizing: "border-box",
    margin: 0,
    color: "black"
}