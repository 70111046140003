import React, { Component } from 'react';
import { ToastContainer,/* toast*/ } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class Toaster extends Component {
	render(){
		return  <ToastContainer />
	}
}

export default Toaster;

