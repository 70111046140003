import baobabTree from '../state';
import rest from "../restWrapper";
import baobabFunnel from "../state/baobabFunnel"
//import {requestFromSlave} from "../slaveCommManager"
import showHeaderNotification, {toastInfoMessage, toastErrorMessage, closeToast} from "../../utils/showNotification";
import _ from "lodash"
import uiActions from "./ui"
import {requestData, requestDataLongTimeout} from "../newCommManager"


var cache = {};

var actions = {
    toggleSwitch: (deviceId, currentState) => {
        let prevState =  baobabTree.root.select('model', 'devices', { id: parseInt(deviceId, 10) }, "value").get()
        baobabTree.root.select('model', 'devices', { id: parseInt(deviceId, 10) }).set("value", currentState === 1 ? 0 : 1);
        baobabTree.root.select('model', 'devices', { id: parseInt(deviceId, 10) }).set("isExecuting", true);

        //console.log(baobabTree.root.select('model', 'devices', { id: parseInt(deviceId, 10) }).get());

        return requestData("/setDeviceValue/" + deviceId, "PUT", { value: currentState === 1 ? 0 : 1 })
            .then(res => {
                if (!baobabFunnel(res.model, res.method, res.data)) { //rolling back
                    baobabTree.root.select('model', 'devices', { id: parseInt(deviceId, 10) }).set("value", currentState);
                }
                baobabTree.root.select('model', 'devices', { id: parseInt(deviceId, 10) }).set("isExecuting", false);
            }).catch(err => {
               // console.log("ERROR NA TOGLANJU trenutno stanje je", currentState);
                baobabTree.root.select('model', 'devices', { id: parseInt(deviceId, 10) }).set("value", prevState);
                uiActions.pushErrorToSessionErrors(err)
                baobabTree.root.select('ui', 'showChangeStateFailInfo').set(true);
                baobabTree.root.select('model', 'devices', { id: parseInt(deviceId, 10) }).set("isExecuting", false);
                //baobabTree.root.select('model', 'devices', { id: parseInt(deviceId, 10) }).set("connectionState", 2);
            })
    },


    toggleAnalogSwitch: (deviceId, valueToSet, currentState) => {
        let prevState =  baobabTree.root.select('model', 'devices', { id: parseInt(deviceId, 10) }, "value").get()
        baobabTree.root.select('model', 'devices', { id: parseInt(deviceId, 10) }).set("value", valueToSet);
        baobabTree.root.select('model', 'devices', { id: parseInt(deviceId, 10) }).set("isExecuting", true);
        return requestData("/setDeviceValue/" + deviceId, "PUT", { value: valueToSet })
            .then(res => {
                if (!baobabFunnel(res.model, res.method, res.data)) { //rolling back
                    baobabTree.root.select('model', 'devices', { id: parseInt(deviceId, 10) }).set("value", currentState);
                }
                baobabTree.root.select('model', 'devices', { id: parseInt(deviceId, 10) }).set("isExecuting", false);
            }).catch(err => {
                baobabTree.root.select('model', 'devices', { id: parseInt(deviceId, 10) }).set("value", prevState);
                uiActions.pushErrorToSessionErrors(err)
                baobabTree.root.select('ui', 'showChangeStateFailInfo').set(true);
                baobabTree.root.select('model', 'devices', { id: parseInt(deviceId, 10) }).set("isExecuting", false);
               // baobabTree.root.select('model', 'devices', { id: parseInt(deviceId, 10) }).set("connectionState", 0);
            })
    },

    setAnalogOutputState: (deviceId, state) => {        
        let currentState = baobabTree.root.select('model', 'devices', { id: parseInt(deviceId, 10) }, "value").get();
        baobabTree.root.select('model', 'devices', { id: parseInt(deviceId, 10) }).set("value", state);
        baobabTree.root.select('model', 'devices', { id: parseInt(deviceId, 10) }).set("isExecuting", true);

        return requestData("/setDeviceValue/" + deviceId, "PUT", { value: state })
            .then(res => {
                if (!baobabFunnel(res.model, res.method, res.data)) { //rolling back
                    baobabTree.root.select('model', 'devices', { id: parseInt(deviceId, 10) }).set("value", currentState);
                }
                baobabTree.root.select('model', 'devices', { id: parseInt(deviceId, 10) }).set("isExecuting", false);
            }).catch(err => {
                baobabTree.root.select('model', 'devices', { id: parseInt(deviceId, 10) }).set("value", currentState);
                uiActions.pushErrorToSessionErrors(err)
                baobabTree.root.select('model', 'devices', { id: parseInt(deviceId, 10) }).set("isExecuting", false);
            })
    },

    setVolumeState: (deviceId, state, channel) => {        
        let currentState = baobabTree.root.select('model', 'devices', { id: parseInt(deviceId, 10) }, "value").get();
        baobabTree.root.select('model', 'devices', { id: parseInt(deviceId, 10) }).set("isExecuting", true);
        baobabTree.root.select('model', 'devices', { id: parseInt(deviceId, 10) }).set("value", state);

        return requestData("/setVolume/" + deviceId, "PUT", { ch:channel ,value: state })
            .then(res => {
                if (!baobabFunnel(res.model, res.method, res.data)) { //rolling back
                    baobabTree.root.select('model', 'devices', { id: parseInt(deviceId, 10) }).set("value", currentState);
                }
                baobabTree.root.select('model', 'devices', { id: parseInt(deviceId, 10) }).set("isExecuting", false);
            }).catch(err => {
                baobabTree.root.select('model', 'devices', { id: parseInt(deviceId, 10) }).set("value", currentState);
                uiActions.pushErrorToSessionErrors(err)
                baobabTree.root.select('model', 'devices', { id: parseInt(deviceId, 10) }).set("isExecuting", false);
            })
    },

    //method needs to be finished:
    playScene: (sceneId) => {
        let sceneEnums = {
            inactive : 0,
            active: 1,
            activating:2,
            deactivating:3,
            overrided: 4
        }
        let currState = baobabTree.root.select('model', 'scenes', { id: parseInt(sceneId, 10) }).get("state");
        baobabTree.root.select('model', 'scenes', { id: parseInt(sceneId, 10) }).set("state", sceneEnums.activating);
        return requestDataLongTimeout("/playScene/" + sceneId, "PUT", {})
            .then(res => {
                if(!_.isEmpty(res.data.failed)){
                    uiActions.pushUnfinishedSceneToState(res.data)
                }               
                baobabFunnel(res.model, res.method, res.data);
            }).catch(err => {                
                baobabTree.root.select('model', 'scenes', { id: parseInt(sceneId, 10) }).set("state", currState);
                uiActions.pushErrorToSessionErrors(err)
            })
    },

    stopScene: (sceneId) => {
        let sceneEnums = {
            inactive : 0,
            active: 1,
            activating:2,
            deactivating:3,
            overrided: 4
        }
        let currState = baobabTree.root.select('model', 'scenes', { id: parseInt(sceneId, 10) }).get("state");
        baobabTree.root.select('model', 'scenes', { id: parseInt(sceneId, 10) }).set("state", sceneEnums.deactivating);
        return requestDataLongTimeout("/stopScene/" + sceneId, "PUT", {})
            .then(res => {
                if(!_.isEmpty(res.data.failed)){
                    uiActions.pushUnfinishedSceneToState(res.data)
                }  
                baobabFunnel(res.model, res.method, res.data);
            }).catch(err => {                
                //  baobabTree.root.select('model', 'scenes', {id:parseInt(sceneId,10)}).set("isActive", true);
                baobabTree.root.select('model', 'scenes', { id: parseInt(sceneId, 10) }).set("state", currState);
                uiActions.pushErrorToSessionErrors(err)
            })
    },
    setLoopingCode: (code) => {
        return new Promise((resolve, reject) => {
            return requestData("/setLoopingCode", "PUT", { code: code }).then(res => {
                //if code===-1, then validation is not passed and data contains validation errors...
                return resolve(res);
            }).catch(err => {
                uiActions.pushErrorToSessionErrors(err)
                return reject(err);
            });
        });
    },
    lintLoopingCode: (code) => {
        return new Promise((resolve, reject) => {
            return requestData("/lintLoopingCodeScript", "POST", { code: code }).then(res => {
                return resolve(res);
            }).catch(err => {
                uiActions.pushErrorToSessionErrors(err)
                return reject(err);
            });
        });
    },
    saveUserSettingsTab: (userSettingsTabObject, userSettingsTabName) => {
        let obj = {};
        obj[userSettingsTabName] = {};
        //clear up settings object to avoid message to big server validation
        _.forIn(userSettingsTabObject, (propValue, key) => {
            if (!_.isUndefined(propValue.value)) {
                obj[userSettingsTabName][key] = { value: propValue.value }
            }
        })

        baobabTree.root.select('model', 'settings', userSettingsTabName).set(userSettingsTabObject);//optimistic update...

        return requestData("/settings", "PUT", obj).then(res => {
            if (baobabFunnel(res.model, res.method, res.data)) {
                showHeaderNotification(true, 2000, "Settings updated succesefuly");
            } else {
                 toastErrorMessage("error on saveUserSettingsTab", 2000);
               // showHeaderNotification(false, 2000, "error");
            }
        }).catch(err => {
            uiActions.pushErrorToSessionErrors(err)
           // return reject(err);
        });
    },
    updateAddOnUserSettingsTab: (addOnPath, userSettingsTabObject, userSettingsTabName) => {
        let obj = {};
        obj[userSettingsTabName] = {};
        //clear up settings object to avoid message to big server validation
        _.forIn(userSettingsTabObject, (propValue, key) => {
            if (!_.isUndefined(propValue.value)) {
                obj[userSettingsTabName][key] = { value: propValue.value }
            }
        })

        //readOnlyModel, addOns
        baobabTree.root.select('readOnlyModel', 'addOns', userSettingsTabName).set(userSettingsTabObject); //optimistic update...

        return requestData("/updateAddOnSettings/" + addOnPath, "PUT", obj).then(res => {
            try {
                //console.log(res.model);
                baobabFunnel(res.model, res.method, res.data);
               // showHeaderNotification(true, 2000, "Add on settings updated succesefuly");
                toastInfoMessage("Add on settings updated succesefuly", 2000);
            } catch (err) {
                uiActions.pushErrorToSessionErrors(err)
            }
        }).catch(err => {
            uiActions.pushErrorToSessionErrors(err)
            //return reject(err);
        });

    },

    resetUserSettingsTab: (tabName)=>{
        return rest("/resetSettings/"+tabName, "PUT", {}).then(res => {

             if (baobabFunnel(res.model, res.method, res.data)) {
                //showHeaderNotification(true, 2000, "Settings defaulted succesefuly");
                toastInfoMessage("Settings defaulted succesefuly", 2000);
            } else {
                 toastErrorMessage("error on resetUserSettingsTab", 2000);
                //showHeaderNotification(false, 2000, "error");
            }

        }).catch(err=>{
            uiActions.pushErrorToSessionErrors(err)
           // return reject(err);
        })

    },
    resetAddOnUserSettingsTab: (addOnPath, tabName)=>{
        return rest("/resetAddOnSettings/"+addOnPath+"/"+tabName, "PUT", {}).then(res => {

          //  console.log("----->",res);
            //showHeaderNotification(true, 2000, "AddOn settings defaulted succesefuly. Restarting ...");
             toastInfoMessage("AddOn settings defaulted succesefuly. Restarting ...", 2000);

        }).catch(err=>{
            uiActions.pushErrorToSessionErrors(err);
        })
    },

    activateAddOn: (uuid, version) => {
        baobabTree.root.select("readOnlyModel", "addOns", { uuid: uuid }).set("activated", true);
        return requestData("/manageAddOnActivation/activate/" + uuid, "PUT", {version: version}).then(res => {
            try {
                baobabFunnel(res.model, res.method, res.data);
                toastInfoMessage("Add on activated", 2000);
                //showHeaderNotification(true, 2000, "Add on activated");
            } catch (err) {
                uiActions.pushErrorToSessionErrors(err);
            }
        }).catch(err => {
            uiActions.pushErrorToSessionErrors(err)
        });
    },
    deactivateAddOn: (uuid, version) => {
        baobabTree.root.select("readOnlyModel", "addOns", { uuid: uuid }).set("activated", false);
        return requestData("/manageAddOnActivation/deactivate/" + uuid, "PUT", {version: version}).then(res => {
            try {
                baobabFunnel(res.model, res.method, res.data);
            } catch (err) {
                uiActions.pushErrorToSessionErrors(err);
            }
        });
    },
    hideMainMenu: () => {
        baobabTree.root.select('ui', 'showMainMenu').set(false);        
    },

    showMainMenu: (direction = "") => {       
        baobabTree.root.select('ui', 'showMainMenu').set(true);
        baobabTree.root.select('ui', 'mainMenuAnimation').set(direction);
    },

    toggleScrollbar: () => {       
        var isShown = baobabTree.root.select('ui', 'showScrollbar').get();
        baobabTree.root.select('ui', 'showScrollbar').set(isShown === true ? false : true);
    },

    toggleIconSelect: () => {
        var isShown = baobabTree.root.select('ui', 'showIconSelect').get();
        baobabTree.root.select('ui', 'showIconSelect').set(isShown === true ? false : true);
    },
    hideIconSelect: () => {
        baobabTree.root.select('ui', 'showIconSelect').set(false);
    },
    setIpAddress: (ipAddress, netmask, gateway) => {
        return new Promise((resolve, reject) => {
            return requestData("/setIpAddress", "POST", { ipAddress: ipAddress, netmask: netmask, gateway: gateway }).then(res => {
                 if(res.data.error){
                    return reject(new Error(res.data.error));
                } else {
                    toastInfoMessage("Ip address successfully set", 2000);
                    //showHeaderNotification(true, 2000, "Ip address successfully set");
                    return resolve(true);
                }
            }).catch(err => {
                return reject(err);
            });
        });
    },

    getIpConfigFileArray: () => {
        return new Promise((resolve, reject) => {
            return requestData("/getIpConfigFileArray", "GET").then(res => {
                if(res.data.error){
                    return reject(new Error(res.data.error));
                } else {
                    return resolve(res);
                }
            }).catch(err => {
                return reject(err);
            });
        });
    },


    enableDHCP: () => {
        return new Promise((resolve, reject) => {
            return requestData("/enableDHCP", "PUT").then(res => {
                if(res.data.error){
                     uiActions.pushErrorToSessionErrors(new Error(res.data.error));
                    return reject(new Error(res.data.error));
                } else {
                      toastInfoMessage("DHCP successfully enabled", 2000);
                 //   showHeaderNotification(true, 2000, "DHCP successfully enabled");
                    return resolve(true);
                }
            }).catch(err => {
                uiActions.pushErrorToSessionErrors(err);
                return reject(err);
            });
        });
    },

    setUpdateSlaveSoftware: (updatePackageInfo) => {
        return new Promise((resolve, reject) => {
            requestData("/setUpdateSlaveSoftware", "POST", updatePackageInfo).then(res => {
                return resolve(res);
            }).catch(err => {
                uiActions.pushErrorToSessionErrors(err);
                return reject(err);
            })
        });
    },
    
    setLocationName: (slaveUuid, locationName) => {
        return new Promise((resolve, reject) => {
            rest("/setLocationName", "POST", {slaveUuid:slaveUuid, locationName:locationName}).then(res => {
                res.data.online = true;                
                // baobabTree.root.select(["masterOnlyModel","mySlaves"]).set(sessionInfoResponse.data.slaves);
                if (baobabFunnel(res.model, res.method, res.data)) {
                     toastInfoMessage("Location name changed successfuly", 2000);
                   // showHeaderNotification(true, 2000, "Location name changed successfuly");
                } else {
                     toastErrorMessage("error on setLocationName", 2000);
                  //  showHeaderNotification(false, 2000, "error");
                }
    
                return resolve(res);
            }).catch(err => {
                return reject(err);
            })
        });
    },

    restartSlaveProcesses: ()=>{
        return new Promise((resolve, reject) => {
            requestData("/restartSlaveProcesses", "POST",{}).then(res=>{

                if (process.env.NODE_ENV !== 'production') {
                      toastErrorMessage( "PM2 is not available in development mode. Please restart the process manualy.", 5000);
                   // showHeaderNotification(false, 5000, "PM2 is not available in development mode. Please restart the process manualy.");
                }
                 return resolve(true);

            }).catch(err=>{
                 toastErrorMessage(err.message, 2000);
               // showHeaderNotification(false, 2000, err.message);
                return reject(err);
            })
        });
    },
    enableRMCUAutoDiscovery: ()=>{
        return new Promise((resolve, reject) => {
            requestData("/enableRMCUAutoDiscovery", "POST",{timeout:4*60*1000}).then((res)=>{
                let minutes = res.data.timeout/(60*1000);
                if(res.data.enabled!==undefined){
                    if(res.data.enabled===true){
                        cache["autodetection"] = toastInfoMessage("RMCU autodetection ENABLED for "+minutes+" minutes.", res.data.timeout, "DEFAULT");
                    } else {
                        closeToast(cache["autodetection"])
                        cache["autodetection"] = toastInfoMessage("RMCU autodetection DISABLED", 2000);
                    }
                } else { //legacy support
                    toastInfoMessage("RMCU autodetection ENABLED for 5 minutes.", 5*60*1000);
                }
                 
                 return resolve(res.data);
            }).catch(err=>{
                 toastErrorMessage(err.message, 2000);
                //showHeaderNotification(false, 2000, err.message);
                 return reject(err);
            })
        });
    },
    enableDebugLogStreaming: (action="start")=>{
        return new Promise((resolve, reject) => {
            requestData("/enableDebugLogStreaming/"+action, "POST",{}).then(()=>{
                return resolve(true);
              //  showHeaderNotification(true, 2000, "Log streaming enabled. Open your console for details.");
            }).catch(err=>{
                 toastErrorMessage(err.message, 2000);
               //  showHeaderNotification(false, 2000, err.message);
                 return reject(err);
            })
        });
    },
    registerI2CExpander: (expanderType, dipSwitchPositions, title)=> {
        return new Promise((resolve, reject) => {
            requestData("/registerI2CExpander", "POST",{expanderType:expanderType, dipSwitchPositions:dipSwitchPositions, title:title}).then((res)=>{
                //showHeaderNotification(true, 2000, "Device registered.");
                toastInfoMessage("Device registered", 2000);
                return resolve(res.data);
            }).catch(err=>{
                toastErrorMessage(err.message, 2000);
                // showHeaderNotification(false, 2000, err.message);
                 return reject(err);
            })
        });
    },
    broadcastRMCURequestSlaveIntroductions: ()=>{
        return new Promise((resolve, reject) => {
            requestData("/broadcastRMCURequestSlaveIntroductions", "POST", {}).then(res=>{
                toastInfoMessage("RMCU devices intro request is sent. Enable RMCU autodetection to automaticly register all newly discovered devices.", 5000);
                return resolve(true);
            }).catch(err=>{
                return reject(err);
            })
        });
    },
    applyRMCUModuleAddressChnage: (oldModuleAddress, newModuleAddress)=>{
        return new Promise((resolve, reject) => {
            requestData("/applyRMCUModuleAddressChnage", "POST", {oldModuleAddress, newModuleAddress}).then(res=>{
                toastInfoMessage("Changes are accepted. Device will restart now.", 2000,"SUCCESS");
                return resolve(true);
            }).catch(err=>{
                toastErrorMessage(err.message, 2000);
                return reject(err);
            })
        });
    },
    bulkRemoveDevices: (deviceIds)=>{
        return new Promise((resolve, reject) => {
            requestData("/bulkRemoveDevices", "POST", {deviceIds}).then(res=>{
                toastInfoMessage("SUCCESS! Device will restart now.", 2000,"SUCCESS");
                return resolve(true);
            }).catch(err=>{
                toastErrorMessage(err.message, 2000);
                return reject(err);
            })
        });
    },
    registerWiegandDoorLockCompositeDevice: (deviceAddress, deviceName)=>{
        return new Promise((resolve, reject) => {
            let protocol = deviceAddress==="local" ? "local" : "rmcu"; //local means gateway
            requestData("/registerWiegandDoorLockCompositeDevice", "POST", {protocol, deviceAddress, deviceName}).then(response=>{
                toastInfoMessage("SUCCESS!", 2000,"SUCCESS");
                return resolve(response);
             }).catch(err=>{
                toastErrorMessage(err.message, 2000);
                return reject(err);
             })
        });
    },

    saveWiegandDoorLockWhitelist: (deviceAddress, whitelistDataArray, whiteListTimeRestrictions)=>{
        return new Promise((resolve, reject) => {
            requestData("/saveWiegandDoorLockWhitelist", "POST", {deviceAddress, whitelistDataArray, whiteListTimeRestrictions}).then(response=>{
                toastInfoMessage("SUCCESS!", 2000,"SUCCESS");
                return resolve(response);
             }).catch(err=>{
                toastErrorMessage(err.message, 2000);
                return reject(err);
             })
        });
    },

    bulkSaveWiegandDoorLockWhitelist: (doorAndWhitelistsData)=>{
        return new Promise((resolve, reject) => {
            requestData("/bulkSaveWiegandDoorLockWhitelist", "POST", {doorAndWhitelistsData}).then(response=>{
                toastInfoMessage("SUCCESS!", 2000,"SUCCESS");
                return resolve(response);
             }).catch(err=>{
                toastErrorMessage(err.message, 2000);
                return reject(err);
             })
        });
    }

}

export default actions;


