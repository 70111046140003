var debounce = null;
const listenForForegroundVisibilityChange = (handlerFn) => {
	var visibilityChange, hidden;
	if (typeof document.hidden !== 'undefined') {
		visibilityChange = 'visibilitychange';
		hidden = 'hidden';
	} else if (typeof document.mozHidden !== 'undefined') {
		visibilityChange = 'mozvisibilitychange';
		hidden = 'mozHidden';
	} else if (typeof document.msHidden !== 'undefined') {
		visibilityChange = 'msvisibilitychange';
		hidden = 'msHidden';
	} else if (typeof document.webkitHidden !== 'undefined') {
		visibilityChange = 'webkitvisibilitychange';
		hidden = 'webkitHidden';
	} else {
		console.log('Page Visibility API not supported.');
	}

	function handleVisibilityChange() {
		if (debounce === null) {
			if (handlerFn) {
				if (!document[hidden]) {
					handlerFn();
				}
			}
			clearTimeout(debounce);
			debounce = setTimeout(() => {
				debounce = null;
			}, 500);
		}
	}

	document.addEventListener(visibilityChange, handleVisibilityChange, false);
};

export default listenForForegroundVisibilityChange;