import React, { Component } from 'react';
import { branch } from 'baobab-react/higher-order';
import _ from "lodash";
import actions from "../../data/actions/actions";
import updateForm, { loadFormData } from "../../data/actions/formStateController";
import saveFormData from "../../data/actions/formActions";
import showHeaderNotification from "../../utils/showNotification"
import WarningDialog from "../../components/warningDialog";


const getUrlParameterByName = (paramname, url) => {
  if (!url) url = window.location.href;
  paramname = paramname.replace(/[[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + paramname + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

class AnalogOutputControl extends Component {
  constructor() {
    super();
    this.debounceTimeout = null;
    this.state = {
      sliderValue: null,
      lockShown : false,
      applyAnimation:false,
      isWarningShown : false,
      message:""
    }
  }


/* ///OVO JE OPASNO JOPA
  componentDidUpdate(nextProps) {
    this.setState({
      sliderValue: null
    });
  }
  */
  

  showLockOption() {
    this.setState({
      lockShown: this.state.lockShown === true ? false : true
    })
  }
  lockState(deviceId,lockState, e) {
    let currentStateValue = _.isUndefined(lockState) ? true : !lockState

    loadFormData("device", parseInt(deviceId, 10));

    var obj = {};
    obj["lockState"] = currentStateValue;
    updateForm("device", obj);
        
    saveFormData("device").then(res => {
      showHeaderNotification(true, 3000, "Device state "+(currentStateValue === true ? "" : "un")+"locked")
    })
  }

  debounce(fn) {
    clearTimeout(this.debounceTimeout);
    this.debounceTimeout = setTimeout(() => {
      fn();
    }, 500)
  }

  changeStateOnSliderMove(e) {
    let inputValue = parseInt(e.target.value, 10);

    this.setState({
      sliderValue: inputValue,
      applyAnimation : true
    });
    if (_.includes((this.props.route.location.pathname).toLowerCase(), "scene")) {

      let device = _.find(this.props.devices, { "id": parseInt(getUrlParameterByName("deviceId"), 10) })
      let deviceId = parseInt(getUrlParameterByName("deviceId"), 10)
      var obj = { "deviceId": parseInt(getUrlParameterByName("deviceId"), 10), "value": (255 / parseInt(device.address.analogOutputInfo.max, 10)) * parseInt(inputValue, 10) }
      var selection = this.props.sceneParameter === "active" ? _.clone(this.props.sceneForm.params.sceneActiveDeviceState) :
        _.clone(this.props.sceneForm.params.sceneExitDeviceState);

      var myObj = _.find(selection, obj => { return obj.deviceId === deviceId })
      selection[_.indexOf(selection, myObj)] = obj;
      var params = {};
      var ob = { params };
      if (this.props.sceneParameter === "active") {
        ob.params["sceneActiveDeviceState"] = selection;
        ob.params["sceneExitDeviceState"] = this.props.sceneForm.params.sceneExitDeviceState;
        ob.params["groups"] = this.props.sceneForm.params.groups;
        updateForm("scene", ob);
      }
      else {
        ob.params["sceneActiveDeviceState"] = this.props.sceneForm.params.sceneActiveDeviceState;
        ob.params["sceneExitDeviceState"] = selection;
        ob.params["groups"] = this.props.sceneForm.params.groups;
        updateForm("scene", ob);
      }
    }
  }

  setMinOrMaxAnalogOutputValue(minOrMax, device) {
    if(_.isUndefined(device.lockState) || device.lockState === false){
      let id = null;

    switch (minOrMax) {
      case "min":
        id = parseInt(getUrlParameterByName("deviceId"), 10);
        actions.setAnalogOutputState(id, 0);
        break;
      case "max":
        id = parseInt(getUrlParameterByName("deviceId"), 10);
        actions.setAnalogOutputState(id, 255);
        break;

      default:
        break;
    }
  }
  else {
    this.setState({
      isWarningShown:true,
      message:"Device is locked. Cannot change state at this moment"
    })
  }
  }

  setAnalogValueViaButton(compositeDevice){
    if(_.isUndefined(compositeDevice.lockState) || compositeDevice.lockState === false){
      if(this.state.sliderValue !== null){
        this.setState({
          applyAnimation:false
        })
          let deviceFromDb = _.find(this.props.devices, { "id": parseInt(getUrlParameterByName("deviceId"), 10) })
          let id = parseInt(getUrlParameterByName("deviceId"), 10);
        
          let stateVal = this.state.sliderValue === null ? 0 : this.state.sliderValue
          let valueToSend = (255 / parseInt(deviceFromDb.address.analogOutputInfo.max, 10)) * parseInt(stateVal, 10);
          
          // actions.setAnalogOutputState(id, parseInt(Math.round(valueToSend), 10));
          actions.setAnalogOutputState(id, Math.round(valueToSend));
      }
    }
    else {
      this.setState({
        isWarningShown:true,
        message:"Device is locked. Cannot change state at this moment"
      })
    }
  }

  hideWarning(){
    this.setState({
      isWarningShown:false,
      message:"",    
    })
  }

  render() {    
   
    var deviceFromDb = _.find(this.props.devices, { "id": parseInt(getUrlParameterByName("deviceId"), 10) })
    if (_.isUndefined(deviceFromDb)){
      return null
    }

    let sliderValue = this.state.sliderValue === null ? (deviceFromDb.value * deviceFromDb.address.analogOutputInfo.max) / 255 : this.state.sliderValue;
    var displayValue = Math.round((sliderValue * 100) / deviceFromDb.address.analogOutputInfo.max)

    if (_.includes((this.props.route.location.pathname).toLowerCase(), "scene")) {
      var arrayToSearch = [];
      if (this.props.sceneParameter === "active") {
        arrayToSearch = this.props.sceneForm.params.sceneActiveDeviceState;
      }
      else {
        arrayToSearch = this.props.sceneForm.params.sceneExitDeviceState;
      }
      sliderValue = ((_.find(arrayToSearch, { "deviceId": parseInt(getUrlParameterByName("deviceId"), 10) })).value * deviceFromDb.address.analogOutputInfo.max) / 255
      displayValue = (sliderValue * 100) / deviceFromDb.address.analogOutputInfo.max
    }

    let isFullScreen = false;
    if( window.innerHeight === window.screen.height) {
        // browser is fullscreen
        isFullScreen = true;
    }


    return (
      <div className={isFullScreen ? "analog-output-control top70" : "analog-output-control"}>
                <WarningDialog message={this.state.message} hideWarning={this.hideWarning.bind(this)} isShown={this.state.isWarningShown}/>

        <div className="analog-output-middle animated faster zoomIn">
        <div className="text-center device-modal-dialog-name">{deviceFromDb.name}</div>

          <div className="range-slider text-center">
          <i onClick={this.showLockOption.bind(this)} className={_.includes((this.props.route.location.pathname).toLowerCase(), "scene") ? "hidden" : "pointer fa fa-cogs lock-icon-style"}></i>
          <button onClick={this.lockState.bind(this, deviceFromDb.id, deviceFromDb.lockState )} className={this.state.lockShown === true ? "btn btn-default analog-device-lock-option lock-option animated zoomIn " : "hidden"}>
          {deviceFromDb.lockState === true ? "UNLOCK STATE" : "LOCK STATE"}</button>
            <i onClick={this.props.onHideDialog} className="fa fa-times pull-right close slider-close pointer" aria-hidden="true"></i>
            <div className="vertical-aligned analog-slider-wrapper">
              <input className="analog"
                type="range" min="0" max={deviceFromDb.address.analogOutputInfo.max}
                step={deviceFromDb.address.analogOutputInfo.step}
                value={sliderValue}
                onChange={this.changeStateOnSliderMove.bind(this)}
              />
             
            </div>
            <div className="analog-buttons-div text-value-only" >{displayValue}%</div>
            
          </div>

          <div className="on-off-buttons-slider relative-position">
              <div  className="on-off-buttons-slider-inside text-center">
                <div className={_.includes((this.props.route.location.pathname).toLowerCase(), "scene") ? "hidden" : ""}>
                  <div  onClick={this.setMinOrMaxAnalogOutputValue.bind(this, "min", deviceFromDb)} 
                  className={deviceFromDb.value === 0 ? "slider-button off-active pointer margin-top-0" : "slider-button pointer  margin-top-0"}>OFF</div>
                  <div  onClick={this.setAnalogValueViaButton.bind(this, deviceFromDb)} 
                  className={this.state.applyAnimation === false ? "slider-button pointer color-white margin-top-0" : "color-white margin-top-0 slider-button pointer animated faster pulse"  }>
                    APPLY
                  </div>
                  <div onClick={this.setMinOrMaxAnalogOutputValue.bind(this, "max", deviceFromDb)} 
                  className={deviceFromDb.value !== 0 ? "slider-button on-active pointer margin-top-0" : "slider-button pointer margin-top-0"}>ON</div>

                </div>
                <div className={!_.includes((this.props.route.location.pathname).toLowerCase(), "scene") ? "hidden" : ""}>

                  <div  className="slider-button pointer color-white margin-top-0" onClick={this.props.onHideDialog}><strong>Save</strong></div>
                </div>

              </div>
            </div>

          <div className="text-center">

            {/* {confimButton} */}
          </div>
        </div>
      </div>
    )
  }
}

export default branch({
  // devices: ["model", "devices"],
  devices: ['monkeys', 'selfContainedDevices'],
  sceneForm: ['forms', 'scene'],
  route: ["route"],
  ui: ["ui", "showMainMenu"]
}, AnalogOutputControl);