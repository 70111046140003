import baobabTree, { saveCachedBaobabData, clearDataBeforeLocationChange } from '../state';
import EventEmitter from 'events';
import baobabRest from '../restWrapper/restWrapper';
import socketClass from './sockets';
import baobabFunnel, { registerFunnelModelChangeListener } from '../state/baobabFunnel';
import loggger from '../sys/windowLogger';
import _ from 'lodash';
import uiActions from '../actions/ui';
import * as Sentry from '@sentry/browser';

/*
connection manager abstract the underlaying connection for all actions and loaders
based on available connections, it choses the best method for message transporing (options are: HTTP, WebSockets and WebRTS)
This module is responsible for upgrading to the fastest available module.
Also this module is responsible for displaying connection statuses all over the application!!!

*/

var forceRESTRelay = _.isUndefined(window.localStorage.forceRESTRelay)
	? false
	: window.localStorage.forceRESTRelay === 'true';

class transportManager extends EventEmitter {
	constructor() {
		super();
		this.lastKnownTier = 0;
	}

	init(isMaster, apiServerPath) {
		this.apiServerPath = apiServerPath;
		this.isMaster = isMaster;
		this.loadSlaveData_lastCallTimestamp = null;

		if (_.isUndefined(this.socketManager)) {
			this.socketManager = new socketClass(this.isMaster, this.apiServerPath);
			this.socketManager.on('incommingFunnelMessage', this.onIncommingFunnelMessage.bind(this));
			this.socketManager.on('socketRoomJoined', this.onServerSocketRoomJoined.bind(this));
			this.on('slaveConnectionReady', this.onSlaveConnectionReady.bind(this));

			registerFunnelModelChangeListener('MySlaves', (method, slaveInfo) => {
				//provjeri šta ti ovo radi i zašto ovako?
				//console.log("OVO PRESTALO DA RADI", slaveInfo, method);
				if (method === 'PUT') {
					let updating = _.clone(
						baobabTree.root.select('masterOnlyModel', 'mySlaves', { uuid: slaveInfo.uuid }).get()
					);
					updating = _.defaults(slaveInfo, updating);
					baobabTree.root.select('masterOnlyModel', 'mySlaves', { uuid: slaveInfo.uuid }).set(updating);
					let selectedSlaveUuid = baobabTree.root.select(['apiConfig', 'selectedSlaveUuid']).get();
					if (selectedSlaveUuid === slaveInfo.uuid) {
						if (
							baobabTree.root.select(['ui', 'selectedSlaveOnline']).get() === false &&
							slaveInfo.online === true
						) {
							//then reload location data
							window.location.reload(); //nakon dva restarta slavea-a izgubi se online status zauvijek.... ovaj refresh to rješava...
						}
						//console.log(slaveInfo)
						baobabTree.root.select(['ui', 'selectedSlaveOnline']).set(slaveInfo.online);
						baobabTree.root.select(['ui', 'selectedSlaveOnlineForceHide']).set(false);
					}
				}
			});

			/* possible connection tier changes*/
			this.socketManager.on('connect', (isMaster) => {
				loggger('socketManager.connect', isMaster);
				this.onPossibleConnectionTierChange();
				if (isMaster === false) {
					baobabTree.root.select(['ui', 'selectedSlaveOnline']).set(true);
					if (baobabTree.root.select(['model', 'devices']).get().length > 0) {
						if (Date.now() - this.loadSlaveData_lastCallTimestamp > 1000) {
							//reload is nececary
							let slaveUuid = baobabTree.root.select(['apiConfig', 'selectedSlaveUuid']).get();
							this.loadSlaveData(slaveUuid, true);
						}
					}
				}
			});

			this.socketManager.on('abortConnection', (isMaster, message) => {
				this.cancelAllPendingRequests(isMaster);
				uiActions.setShowLoadingSceen(false);
			});

			this.socketManager.on('disconnect', (isMaster, message) => {
				loggger('socketManager.disconnect', isMaster);
				this.onPossibleConnectionTierChange(isMaster);
				this.cancelAllPendingRequests();
				if (isMaster === false) {
					baobabTree.root.select(['ui', 'selectedSlaveOnline']).set(false);
					baobabTree.root.select(['ui', 'selectedSlaveOnlineForceHide']).set(false);
				}
			});
			this.socketManager.on('reconnect', (isMaster) => {
				this.onPossibleConnectionTierChange();
			});
		} else {
			console.warn('Already initilized!');
		}
	}

	onPossibleConnectionTierChange() {
		if (this.transportTier > this.lastKnownTier) {
			//tier upgrade
			this.emit('connectionUpgrade', this.lastKnownTier, this.transportTier);
			this.lastKnownTier = this.transportTier;
		} else if (this.transportTier < this.lastKnownTier) {
			//tier downgrade
			this.emit('connectionDowngrade', this.lastKnownTier, this.transportTier);
			this.lastKnownTier = this.transportTier;
		}
		loggger('onPossibleConnectionTierChange', this.lastKnownTier);
		baobabTree.root.select('ui', 'slaveConnectionTier').set(this.lastKnownTier);
	}

	onServerSocketRoomJoined(slaveUuid) {
		loggger('onServerSocketRoomJoined', slaveUuid);
		let locationInfo = baobabTree.root.select(['masterOnlyModel', 'mySlaves', { uuid: slaveUuid }]).get();
		this.emit('slaveConnectionReady', locationInfo, slaveUuid);
	}

	onIncommingFunnelMessage(model, method, entity) {
		loggger('onIncommingFunnelMessage', model, method);
		baobabFunnel(model, method, entity);
	}

	onSlaveConnectionReady(slave, slaveUuid) {
		loggger('onSlaveConnectionReady', slaveUuid);
		baobabTree.root.select(['ui', 'selectedSlaveOnline']).set(true);
		baobabTree.root.select(['ui', 'selectedSlaveOnlineForceHide']).set(false);
		baobabTree.root.select(['ui', 'locationConnected']).set(true);
		this.loadSlaveData(slaveUuid);
	}

	async establishSlaveConnection(slaveUuid, callback) {
		loggger('establishSlaveConnection', slaveUuid);
		let target = baobabTree.root.select('masterOnlyModel', 'mySlaves', { uuid: slaveUuid }).get();
		if (target !== undefined) {
			if (target.online === true) {
				let locationName = target.name || slaveUuid;
				uiActions.setShowLoadingSceen(true);
				baobabTree.root
					.select(['ui', 'loadingScreenMessage'])
					.set('Establishing secure connection with ' + locationName);
				this.socketManager.socketRoomJoin(slaveUuid, callback);
				return slaveUuid;
			} else {
				throw new Error('Slave is offline');
			}
		} else {
			console.warn('If slaveDiveInMode is enabled, please before call add masterOnlyModel.mySlaves');
			throw new Error('Slave does not exist!');
		}
	}

	//async breakConnectionEstablishing()

	async wait(ms = 1000) {
		return new Promise((resolve, reject) => {
			setTimeout(() => {
				return resolve(true);
			}, ms);
		});
	}

	async loadSlaveData(slaveUuid, background = false) {

		console.log("loadSlaveData");
		if (background === false) {
			uiActions.setShowLoadingSceen(true);
			clearDataBeforeLocationChange();
		}

		this.loadSlaveData_lastCallTimestamp = Date.now();

		baobabTree.root.select(['apiConfig', 'selectedSlaveUuid']).set(slaveUuid);

		//this.cancelAllPendingRequests(); //this would break WRTC reconnecting
		let firstStageModels = [
			'settings',
			'addOns',
			'devices',
			'compositedevices',
			'groups',
			'scenes',
			'devicegroups',
			'eventsubscriptions',
			'scheduletemplates',
			'widgets',
			'genericstorages',
		];
		let promises = [];
		_.forEach(firstStageModels, (modelNamePlural) => {
			promises.push(this.requestFromSlave('/' + modelNamePlural, 'GET', null));
		});

		let locationInfo = { ...baobabTree.root.select(['masterOnlyModel', 'mySlaves', { uuid: slaveUuid }]).get() };
		if (locationInfo) {
			try {
				//it will fail for older API versions
				const res = await this.requestFromSlave('/featureaccessibilitys', 'GET', null);
				baobabFunnel(res.model, res.method, res.data);
			} catch (err) {}
		}

		Promise.all(promises)
			.then((results) => {
				results.forEach((res) => {
					baobabFunnel(res.model, res.method, res.data);
				});
				saveCachedBaobabData(baobabTree);
				uiActions.setShowLoadingSceen(false);
			})
			.catch((err) => {
				//uiActions.pushErrorToSessionErrors(err);
				uiActions.setShowLoadingSceen(false);
				Sentry.captureException(err);
				console.warn('---loadSlaveData()----', err);
			});

		//this is later added feature for detecting sw version. More correctly than calculated SW version
		this.requestFromSlave('/version', 'GET', null)
			.then((response) => {
				baobabTree.root.select(['ui', 'actualNullableCodeVersion']).set(response.data);
			})
			.catch((err) => {
				baobabTree.root.select(['ui', 'actualNullableCodeVersion']).set(null);
			});
	}

	cancelAllPendingRequests() {
		loggger('cancelAllPendingRequests');
		if (
			this.socketManager &&
			this.socketManager.socketInstance &&
			this.socketManager.socketInstance.cancelAllPendingRequests
		) {
			this.socketManager.socketInstance.cancelAllPendingRequests();
		}
	}

	get transportTier() {
		//0-http (basic), 1-sockets (ok), 2-peerToPeer (prefered)
		if (this.isMaster) {
			if (this.socketManager.Socket.connected === true) {
				return 1;
			} else {
				return 0;
			}
		} else {
			return 0;
		}
	}

	async requestFromSlave(path, method, body, customHeaders, waitForResponseSeconds = 15) {
		//custom headers are implemented only for real rest methods
		if (forceRESTRelay === true) {
			loggger('forced sent via REST relay request', path, method);
			return baobabRest(path, method, body, customHeaders);
		} else {
			if (this.transportTier === 1) {
				loggger('request made via sockets', path, method);
				return this.socketManager.sendRequest({ path, method, body, customHeaders }, waitForResponseSeconds);
			} else {
				loggger('plain old request', path, method);
				return baobabRest(path, method, body, customHeaders);
			}
		}
	}
}

export default transportManager;