import React, { Component } from 'react';
import PropTypes from 'prop-types';
//import _ from "lodash"

class TextInput extends Component {
	onChangeHandler(e){
		this.props.onValueChanged(e.target.value, this.props.inputName); //propagate updated value
	}
	render(){

		const {type = "text"} = this.props


		let input = this.props.multiline===true ? 
					(<textarea onChange={this.onChangeHandler.bind(this)} required  className="form-control" rows={6} value={this.props.value} placeholder={this.props.label}></textarea>) : 
					(<input onChange={this.onChangeHandler.bind(this)} required type={type} className="form-control" value={this.props.value} placeholder={this.props.label}  />)
				

		return (<div className="">
				<label className="control-label col-sm-2">{this.props.label}</label>
				<div className="col-sm-10">
				{input}
				</div>
				<div className=" clear"></div>	
			</div>);
	}
}
TextInput.propTypes = {
	value: PropTypes.string.isRequired,
	onValueChanged: PropTypes.func.isRequired,
	label: PropTypes.string.isRequired,
	inputName: PropTypes.string.isRequired,
	multiline:PropTypes.bool.isRequired
}

export default TextInput;