import React, { Component } from 'react';
import { branch } from 'baobab-react/higher-order';
// import actions from "../../data/actions/actions";
import _ from "lodash";
import { loadFormData, sanitizeForm } from "../../data/actions/formStateController";
import SelectIcon from "../../components/iconSelect";
import SceneForm from "../forms/sceneForm";
import saveFormData from "../../data/actions/formActions";
import updateForm from "../../data/actions/formStateController";
import { getIconCssClassFromStyleParams } from "../../utils/fontIcons";
import { hashHistory } from "react-router";
import SelectInterruptMode from "../../components/selectInterruptMode";
import AddDeviceToScene from "../../components/addDeviceToScene";

import checkRoleAccess from "../../utils/checkRoleAccess"

class AddScene extends Component {
    constructor() {
        super();
        this.state = {
            sceneActiveDevicesShown: true,
            sceneExitDevicesShown: false,
            saveButtonDisabled: false,
            showSelectInterrupt: false,
            showIconSelect: false,
            showAddDeviceDialog: false
        }
    }

    addDevicesToSceneForm(deviceIds, compositeDeviceIds, func, e) {

        e.preventDefault();
        func();
        let mySceneActiveDeviceState = []
        _.forEach(deviceIds, id => {
          let obj = _.find(this.props.sceneForm.params.sceneActiveDeviceState, { deviceId: id })
          if (!_.isUndefined(obj)) {
            mySceneActiveDeviceState.push({ "deviceId": id, "value": obj.value })
          }
          else {
            mySceneActiveDeviceState.push({ "deviceId": id, "value": 0 })
          }
        })
    
        _.forEach(compositeDeviceIds, id => {
          let obj = _.find(this.props.sceneForm.params.sceneActiveDeviceState, { compositeDeviceId: id })
          if (!_.isUndefined(obj)) {
            mySceneActiveDeviceState.push({ "compositeDeviceId": id, "value": obj.value })
          }
          else {
            mySceneActiveDeviceState.push({ "compositeDeviceId": id, "value": 0 })
          }
        })
    
    
    
    
        // let mySceneExitDeviceState = _.clone(this.props.sceneForm.params.sceneExitDeviceState)
        // if (!_.isEmpty(mySceneExitDeviceState)) {
        //   _.forEach(deviceIds, id => {
        //     mySceneExitDeviceState.push({ "deviceId": id, "value": 0 })
        //   })
        // }   
    
        let mySceneExitDeviceState = []
        if (this.props.sceneForm.params.sceneExitDeviceState.length > 0) {
          _.forEach(deviceIds, id => {
            let pom = _.find(this.props.sceneForm.params.sceneExitDeviceState, { deviceId: id })
            if (!_.isUndefined(pom)) {
              mySceneExitDeviceState.push({ "deviceId": id, "value": pom.value })
            }
            else {
              mySceneExitDeviceState.push({ "deviceId": id, "value": 0 })
            }
          })
          _.forEach(compositeDeviceIds, id => {
            let pom = _.find(this.props.sceneForm.params.sceneExitDeviceState, { compositeDeviceId: id })
            if (!_.isUndefined(pom)) {
              mySceneExitDeviceState.push({ "compositeDeviceId": id, "value": pom.value })
            }
            else {
              mySceneExitDeviceState.push({ "compositeDeviceId": id, "value": 0 })
            }
          })
        }
    
    
        var params = {};
        var ob = { params };
        ob.params["sceneActiveDeviceState"] = mySceneActiveDeviceState;
        ob.params["sceneExitDeviceState"] = mySceneExitDeviceState;
        ob.params["groups"] = this.props.sceneForm.params.groups;
        updateForm("scene", ob);
      }

  showAddDeviceDialog(e) {
    e.preventDefault();
    this.setState({
      showAddDeviceDialog: true
    })
  }

  hideAddDeviceDialog() {
    this.setState({
      showAddDeviceDialog: false
    })
  }

    componentDidMount() {        
        loadFormData("scene", this.props.route.params.sceneId);
    }


    componentDidUpdate(nextProps) {       
        if ((this.props.route.location.pathname !== nextProps.route.location.pathname) && _.includes(nextProps.route.location.pathname, "add")) {
            sanitizeForm("scene")
        }
    }

    inputHandler(e) {
        var obj = {};
        if (_.isInteger(parseInt(e.target.value, 10))) {
            obj[e.target.name] = parseInt(e.target.value, 10);
        } else {
            obj[e.target.name] = e.target.value;
        }
        updateForm("scene", obj);
    }



    saveScene(e) {
        e.preventDefault();
        this.setState({ saveButtonDisabled: true })
        saveFormData("scene").then(response => {
            this.setState({
                saveButtonDisabled: false
            })
            hashHistory.push("/")
            return null;
        }).catch(err => {
            this.setState({ saveButtonDisabled: false })
        })

    }
    toggleSceneExitDevicesShown() {
        this.setState({ sceneExitDevicesShown: !this.state.sceneExitDevicesShown });
    }
    handleStateChange(groupId, state) {
        var obj = {}
        obj = { groupId: groupId, checked: state }

        var currentState = _.clone(this.props.sceneForm.params.groups);
        if (state === true) {
            currentState.push(obj)
        }
        else {
            currentState = currentState.filter((item) => {
                if (item.groupId !== groupId) {
                    return item;
                }
                else return null;
            })
        }

        currentState = _.uniqBy(currentState);
        var devicesStatesInThisGroup = _.cloneDeep(this.props.sceneForm.params.sceneActiveDeviceState) || [];



        if(state === false){
            _.forEach(this.props.devicegroups, d => {
                if(Number(groupId) === Number(d.GroupId)){
                    devicesStatesInThisGroup = _.filter(devicesStatesInThisGroup, devSt => {
                        return devSt.deviceId !== d.DeviceId 
                    })
                }
            })

        }

        _.forEach(this.props.devicegroups, d => {
            _.forEach(currentState, g => {
                if (parseInt(d.GroupId, 10) === parseInt(g.groupId, 10)) {
                    this.props.devices.forEach((item) => {
                        if (d.DeviceId === item.id && ((item.type === 0) || item.type === 1 || item.type === 5)) { //type 5 added on 20.12.20202
                            let devobj = { "deviceId": item.id, "value": 0 };
                            devicesStatesInThisGroup.push(devobj);
                        }

                    })
                    this.props.compositeDevices.forEach((item) => {
                        if (d.CompositeDeviceId === item.id) {
                            // let compdevobj = { "compositeDeviceId": item.id, "value": 0 };
                            devicesStatesInThisGroup.push();
                        }

                    })
                }
            })

        }
        )

        devicesStatesInThisGroup = _.uniqWith(devicesStatesInThisGroup, _.isEqual)

        devicesStatesInThisGroup.forEach(devState => {
            this.props.sceneForm.params.sceneActiveDeviceState.forEach((sceneAct) => {
                if (devState.deviceId === sceneAct.deviceId || devState.compositeDeviceId === sceneAct.compositeDeviceId) {
                    devState.value = sceneAct.value;
                }
            })
        })

        var groups = _.clone(currentState)

        devicesStatesInThisGroup = _.uniqWith(devicesStatesInThisGroup, _.isEqual)
        var params = {};
        var paramObj = { params };
        paramObj.params["sceneActiveDeviceState"] = devicesStatesInThisGroup;
        if (this.state.sceneExitDevicesShown) {
            paramObj.params["sceneExitDeviceState"] = devicesStatesInThisGroup;
        }
        else {
            paramObj.params["sceneExitDeviceState"] = [];
        }

        paramObj.params["groups"] = groups;

        updateForm("scene", paramObj);
        this.setState({ sceneActiveDevicesShown: true });


    }

    iconSelectHandler(icon) {
        var style = {};
        var obj = { style };
        obj.style["icon"] = icon;
        updateForm("scene", obj);
        this.setState({
            showIconSelect: !this.state.showIconSelect
          })
        // actions.toggleIconSelect();
    }

    toggleSelectInterrupt(e) {
        this.setState({
            showSelectInterrupt: this.state.showSelectInterrupt === true ? false : true
        })
    }

    selectInterruptMode(value) {
        var obj = {};
        obj["interruptMode"] = parseInt(value, 10);
        setTimeout(() => {
            this.toggleSelectInterrupt()
        }, 200);
        updateForm("scene", obj);
    }
    render() {
        if(!checkRoleAccess("admin", "systemIntegrator", "advancedUser")){
          return "Insufficient permissions."
        }

        let items = this.props.groups.map((group) => {
            if(group.type !== 2){
                
            var isChecked = this.props.sceneForm.params.groups.find((item) => { if (item.groupId === group.id) return item.checked; else return undefined })


            return (
                <div key={_.uniqueId("dev")} className="blue device groups col-xs-12 col-sm-6 col-md-6 checkboxes scene scene-checks">

                    <div className="col-xs-3">
                        <i className={_.isUndefined(group.style.icon.name) ? "icon-Home" : getIconCssClassFromStyleParams(group.style, { isBig: false })} />
                    </div>
                    <div className="col-xs-7">
                        <span className="short-text-wide">{group.name}</span>
                    </div>
                    <div className="col-xs-2">
                        {/* <input onChange={this.handleStateChange.bind(this, group.id)} checked={isChecked} 
                        type="checkbox" name="groupId" className="" /><label htmlFor="checkbox-1-1"></label> */}


                    <i onClick={this.handleStateChange.bind(this, group.id, !isChecked)} className={isChecked ? "fa fa-check-square-o pointer" : "fa fa-square-o pointer"}></i>
                    </div>

                </div>)
                }else {return null}
        })
        if (items.length === 0) {
            items = <div className="text-danger">You have to create at least one group</div>
        }

        let isSaveButtonDisabled = (_.isEmpty(this.props.sceneForm.params.sceneActiveDeviceState) ? true : false) || (this.state.saveButtonDisabled)

        let iconClass = "fa fa-bolt";
        switch (this.props.sceneForm.interruptMode) {
            case 0:
                iconClass = "fa fa-bolt interruptsDisabled interruptState fa-2x pointer";
                break;
            case 1:
                iconClass = "fa fa-bolt  text-success interruptState fa-2x pointer";
                break;
            case 2:
                iconClass = "fa fa-bolt interruptsOutEnabled interruptState fa-2x pointer";
                break;
            case 3:
                iconClass = "fa fa-bolt interruptsInEnabled interruptState fa-2x pointer";
                break;
            default:
                iconClass = "fa fa-bolt text-success interruptState fa-2x pointer";
                break;
        }

        return (
            <div className="analog-output-on-scene-edit">
                <div className="container scene-add-form">
                    {this.props.settings.general.uiInterruptManagement.value === true ? <div onClick={this.toggleSelectInterrupt.bind(this)} 
                    className="edit-widget-icon pointer"><i className={iconClass}></i></div>
                        : null}
                    <SelectInterruptMode isShown={this.state.showSelectInterrupt} value={_.isUndefined(this.props.sceneForm.interruptMode) ? 1 : this.props.sceneForm.interruptMode} selectInterruptMode={this.selectInterruptMode.bind(this)} />

                    {/* <SelectInterruptMode isShown={this.state.showSelectInterrupt} value={_.isUndefined(this.props.deviceForm.interruptMode) ? 1 : this.props.deviceForm.interruptMode } selectInterruptMode={this.selectInterruptMode.bind(this)} /> */}

                    <form className="form-horizontal col-sm-12 col-lg-9 col-md-9 relative-center" autoComplete="off">
                        <div className="form-group">
                            <label className="control-label col-sm-2" htmlFor="name">Scene Name:</label>
                            <div className="col-sm-10">
                                <input autoComplete="off" onChange={this.inputHandler.bind(this)} value={this.props.sceneForm.name} required type="text" className="form-control" name="name" id="name" placeholder="Enter scene name" />
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="control-label col-sm-2" htmlFor="pwd">Select Icon:</label>
                            <div className="col-sm-10 select-group">
                                <div onClick={() => {this.setState({showIconSelect: !this.state.showIconSelect})}} className="select-icon">
                                    <i className={getIconCssClassFromStyleParams(this.props.sceneForm.style, { isBig: true })}></i> </div>
                                <SelectIcon  ui={this.state.showIconSelect} iconType="group" inputHandler={this.iconSelectHandler.bind(this)} />
                            </div>
                        </div>
                        {/* <div className="form-group">
                            <label className="control-label col-sm-2" htmlFor="pwd">Select Interrupt Mode:</label>
                            <div className="col-sm-10 select-group">
                                <SelectInterruptMode value={_.isUndefined(this.props.sceneForm.interruptMode) ? 1 : this.props.sceneForm.interruptMode} inputHandler={this.inputHandler} />
                            </div>
                        </div> */}
                        <div className={_.isUndefined(this.props.route.params.sceneId) ? "form-group" : "hidden"} >

                            <label className="control-label col-sm-2" htmlFor="name">Select a group on which you want to apply a scene:</label>
                            <div className="col-sm-10">
                                {items}
                                <div className="row"></div>

                                <br />

                            </div>
                        </div>

                        <div className="form-group" >

                            <label className="control-label col-sm-2" htmlFor="name">Select devices separately:</label>
                            <div className="col-sm-10">
                                <button onClick={this.showAddDeviceDialog.bind(this)} className={_.isUndefined(this.props.route.params.sceneId) ? "btn btn-sm btn-info" : "btn btn-sm btn-info"}>Add or remove devices</button>
                                <AddDeviceToScene addDevicesToSceneForm={this.addDevicesToSceneForm} isShown={this.state.showAddDeviceDialog}
                                    hideDialog={this.hideAddDeviceDialog.bind(this)} devicesAlreadyInScene={this.props.sceneForm.params.sceneActiveDeviceState} />
                                <div className="row"></div>
                                <br />
                            </div>
                        </div>

                 
                        <SceneForm toggleSceneExitDevicesShown={this.toggleSceneExitDevicesShown.bind(this)}
                            sceneExitDevicesShown={this.props.sceneForm.params.sceneExitDeviceState.length > 0} sceneActiveDevicesShown={this.state.sceneActiveDevicesShown}
                            groupsFromForm={this.props.sceneForm.params.groups} groupsFromActive={this.props.sceneForm.params.sceneActiveDeviceState} />



                        <div className="form-group">
                            <div className="col-sm-offset-2 col-sm-10 ">
                                <button disabled={isSaveButtonDisabled} type="submit" onClick={this.saveScene.bind(this)} className="btn btn-sm btn-info text-center">Save</button>
                            </div>
                        </div>
                    </form>
                    {/* <div className="bottom-with-margin"></div>

                    <div className="bottom-with-margin"></div> */}
                </div>
            </div>



        )
    }
}

export default branch({
    sceneForm: ['forms', 'scene'],
    // groups: ['model', 'groups'],
    groups: ["monkeys", "accessibleGroups"],
    // compositeDevices: ["model", "compositedevices"],
    compositeDevices: ['monkeys', 'accessibleCompositeDevices'],
    // devices: ['model', 'devices'],
    devices: ['monkeys', 'selfContainedDevices'],
    devicegroups: ['model', 'devicegroups'],
    deviceAddressParams: ['readOnlyModel', 'deviceAddressParams'],
    route: ["route"],
    settings: ["readOnlyModel", "settings"],
    ui: ["ui", "showMainMenu"]
}, AddScene);