import React, { Component } from 'react';
import { branch } from 'baobab-react/higher-order';
import _ from "lodash";
import  commManager  from "../../data/newCommManager"
import logout from "../../data/actions/logout"
import baobabTree from "../../data/state"
import { hashHistory } from "react-router";
import checkRoleAccess from "../../utils/checkRoleAccess"

class SlaveStatusInfo extends Component {

  async selectLocationHandler(slaveUuid, e) {
    e.preventDefault();
    try{
      await commManager.establishSlaveConnection(slaveUuid);
    } catch(err){
      console.log(err);
    }
  }

  hideThisModal(){
    baobabTree.root.select("ui", "selectedSlaveOnlineForceHide").set(true)
    ///administration/myUsers
    hashHistory.push("/administration/myUsers");
  }

  render() {

    let slaves = _.map(this.props.slaveList, slave => {
      if(slave.enabled === true){
        let activeLocationUuid = this.props.activeLocation;
      if(activeLocationUuid === null){
        activeLocationUuid = this.props.slaveList[0].uuid;
      }
      return (
        <tr key={_.uniqueId("slav")}>
          <td>
            <a href="/#" onClick={this.selectLocationHandler.bind(this, slave.uuid)} className="pointer">{slave.name}{activeLocationUuid === slave.uuid ? "*" : ""}</a>
          </td>
          <td className={slave.online === true ? "text-success" : "text-danger"}>
            {slave.online === true ? "online" : "offline"}
          </td>
        </tr>)
      }else {
        return (null)
      }
      
    })

    const {selectedSlaveOnlineForceHide} = this.props
    let selectedSlaveOnline = _.clone(this.props.selectedSlaveOnline)
    const isMaster = this.props.sessionInfo.isMaster

    if(!isMaster){
     // selectedSlaveOnline = true; //this should be fixed through baobab
    }

    return (
      <div className={selectedSlaveOnline === true || selectedSlaveOnlineForceHide===true ? "hidden" : "slave-status-info"}>
        <div className="text-center">
          <div><h3>Location Offline</h3>

          </div>
          <div><h4>Description</h4><p>Selected location is offline. {this.props.slaveList.length>0 ? "Check your internet connection or try to select another location from list below." : "Check your connection."}</p></div>
          
          {this.props.slaveList.length>0 ? (
            <div className="location-scrollable-div">
            <table className="table text-centered">
              <thead>
                <tr>
                  <td><b>Name</b></td>
                  <td><b>Status</b></td>
                </tr>
              </thead>
              <tbody>
                {slaves}
              </tbody>
            </table>
            <br />
            <i>*Selected Location</i>
          </div>) : null}
          

          <div className="center-block text-center">
            <span>Logged in as: <strong>{this.props.sessionInfo.userProfile.name} {this.props.sessionInfo.userProfile.surname}</strong> ({this.props.sessionInfo.userProfile.email}).</span>
            <br />
            <br />

            {
              checkRoleAccess("admin","systemIntegrator") && isMaster? 
             <button className="btn btn-sm btn-success location-logout-span" onClick={this.hideThisModal.bind(this)}>Show my users</button> 
             :
             <button className="btn btn-sm btn-info location-logout-span" onClick={logout.bind(this)}>Log out</button>
           }
           
          </div>
        </div>


      </div>

    )
  }
}

export default branch({
  route: ["route"],
  slaveList: ["masterOnlyModel", "mySlaves"],
  selectedSlaveOnline: ["ui", "selectedSlaveOnline"],
  selectedSlaveOnlineForceHide: ["ui", "selectedSlaveOnlineForceHide"],
  activeLocation: ["apiConfig", "selectedSlaveUuid"],
  sessionInfo: ["sessionInfo"]
}, SlaveStatusInfo);