import { checkClientRole, checkRealmRole } from '../keycloak';

export default function checkRoleAccess(...requiredRoles) {
	let accessAllowed = false;
	for (const roleName of requiredRoles) {
		if (checkClientRole(roleName)) {
			accessAllowed = true;
			break;
		} else if (checkRealmRole(roleName)) {
			accessAllowed = true;
			break;
		}
	}

	return accessAllowed;
}