import {restData as rest} from '../restWrapper/restWrapper';
import baobabTree from '../state';
import baobabRest from '../restWrapper/restWrapper';
import baobabFunnel from '../state/baobabFunnel';
import _ from 'lodash';
import commManager from '../newCommManager';
import * as Sentry from '@sentry/react';
import loggger from '../sys/windowLogger';
import uiActions from './ui';

var loadMasterOnlyData = async () => {
	let masterOnlyModels = Object.keys(baobabTree.root.select('masterOnlyModel').get());
	for (var i = 0; i < masterOnlyModels.length; i++) {
		let res = await baobabRest('/' + masterOnlyModels[i], 'GET', null);
		baobabFunnel(res.model, res.method, res.data);
	}
	return true;
};

var silentlyReloadLocationData = async () => {
	try {
		let slaves = baobabTree.root.select('masterOnlyModel', 'mySlaves').get();
		let previouslySelectedSlaveUuid = baobabTree.root.select(['apiConfig', 'selectedSlaveUuid']).get();
		let targetSlaveInfo = _.find(slaves, (s) => s.uuid === previouslySelectedSlaveUuid);
		if (targetSlaveInfo && targetSlaveInfo.online) {
			loggger('startup: refreshing location info');
			commManager.loadSlaveData(targetSlaveInfo.uuid, true);
		} else {
			uiActions.setShowLoadingSceen(false);
		}
	} catch (err) {
		console.warn(err);
	}
};

var loadStartupData = async (slaveUuid = null, userEmail = null) => {

	let sessionInfo = await rest('/sessionInfo');
	let isSlaveDiveIn = false

	let remoteAssistanceOrSlaveDiveInData = false
	if(slaveUuid && sessionInfo.isMaster){		
		remoteAssistanceOrSlaveDiveInData = await rest('/assistanceOrDiveIn?userEmail=' + userEmail ) 
		if(remoteAssistanceOrSlaveDiveInData?.admin || remoteAssistanceOrSlaveDiveInData?.remoteAssistance){
			isSlaveDiveIn = true
		}
	} 
	baobabTree.root.select('remoteAssistanceOrSlaveDiveInData').set(remoteAssistanceOrSlaveDiveInData);
	
	sessionInfo.isSlaveDiveIn = isSlaveDiveIn;
	sessionInfo.diveInSlaveUuid = slaveUuid;
	Sentry.configureScope((scope) => {
		scope.setUser({ email: sessionInfo.userProfile.email });
	});

	Sentry.setExtras({
		sessionInfo
	});

	baobabTree.root.select(['ui', 'loadingScreenMessage']).set('Loading session data');
	baobabTree.root.select('sessionInfo').set(sessionInfo);

	let isSessionAuthenticated =
		baobabTree.root.select('keycloak', 'authenticated').get() || baobabTree.root.select('apiKeyLoginToken').get();

	if (isSessionAuthenticated) {
		loggger('startup: Session authenticated');
		let apiServer = baobabTree.root.select(['apiConfig', 'url']).get();
		commManager.init(sessionInfo.isMaster, apiServer); //load sockets classes only if session is authenticated!!!!
		if (sessionInfo.isMaster) {
			baobabTree.root.select(['ui', 'locationConnected']).set(false);
			baobabTree.root.select(['ui', 'loadingScreenMessage']).set('Collecting location data');
			loggger('startup: awaiting loadMasterOnlyData');
			await loadMasterOnlyData();
			loggger('master data loaded');

			if (isSlaveDiveIn === true) {
				baobabTree.root
					.select(['ui', 'loadingScreenMessage'])
					.set('Loading slave data with ADMINISTRATIVE privileges');
				loggger('startup: awaiting slave data via slaveDiveIn option');
				try {
					let tempSlave = {
						uuid: slaveUuid,
						online: true,
						name: slaveUuid,
					};
					baobabTree.root.select('masterOnlyModel', 'mySlaves').set([tempSlave]); //add required slave info
					if(userEmail){
						baobabTree.root.select('masterOnlyModel', 'mySlaves').set(remoteAssistanceOrSlaveDiveInData.selectedUserSlaves); //add required slave info
					}
					await commManager.establishSlaveConnection(slaveUuid);
				} catch (err) {
					console.warn('divein Load failed', err);
					uiActions.setShowLoadingSceen(false);
				}
			} else {
				let slaves = baobabTree.root.select('masterOnlyModel', 'mySlaves').get();
				let previouslySelectedSlaveUuid = baobabTree.root.select(['apiConfig', 'selectedSlaveUuid']).get();
				let targetSlaveInfo = _.find(slaves, (s) => s.uuid === (slaveUuid ? slaveUuid : previouslySelectedSlaveUuid));
				if (targetSlaveInfo && targetSlaveInfo.online) {
					loggger('startup: awaiting establishSlaveConnection');
					commManager.establishSlaveConnection(targetSlaveInfo.uuid);
				} else {
					uiActions.setShowLoadingSceen(false);
				}
			}
		} else {
			baobabTree.root.select(['ui', 'loadingScreenMessage']).set('Loading local slave data');
			baobabTree.root.select(['ui', 'selectedSlaveOnline']).set(true); //window.localStorage.debugLog
			baobabTree.root.select(['ui', 'locationConnected']).set(true);
			loggger('startup: awaiting slave data');
			await commManager.loadSlaveData(sessionInfo.selectedSlaveInfo.uuid);
		}

		if (isSlaveDiveIn === false && sessionInfo.userProfile.level > 1) {
			loggger('startup divein: awaiting slave date');
			baobabTree.root.select('masterOnlyModel', 'mySlaves').set([]);
		}
	}
	return true;
};

export { loadStartupData, silentlyReloadLocationData };