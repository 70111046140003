import Baobab from "baobab";
import _ from "lodash";
import getMainMenuItems from "../../components/mainMenu/mainMenu"
//import onConnectAndReconnectHandler from "../sockets/onConnectAndReconnectHandler"
var monkey = Baobab.monkey;


var calculatedCursors = {
	flattenDeviceAddressParams:monkey({
		cursors: {
			deviceAddressParams: ["readOnlyModel", "deviceAddressParams"],
		},
		get: (data)=>{
			if(_.isUndefined(data.deviceAddressParams.parameterName)){
	            return [];
	        }
	        let result = [];
	        let stackInput = (p, parents)=>{
	            let param = _.clone(p);
	            param["parents"] = parents;
	            result.push(param);
	            if(!_.isUndefined(param.enumParams)){
	                _.forIn(param.enumParams, (value, key)=>{
	                   stackInput(value, param.parents.concat([key]));
	                });
	            }
	        }
	        stackInput(data.deviceAddressParams, []);
	        return _.flatten(result);
		},
		options: {
			immutable: true
		}
	}),
	defaultedUISelectedObjects:monkey({
		cursors: {
			group: ["ui", "selected","group"],
			device: ["ui", "selected","device"],
			scene: ["ui", "selected","scene"],

			groups: ["model","groups"],
			devices: ["model","devices"],
			scenes: ["model","scenes"],

			devicegroups : ["model","devicegroups"]
		},
		get: (data)=>{
			let selectedGroupCopy = data.group ? _.find(data.groups, g=>g.id===data.group.id) : null; //make a copy from updated model...
			let selectedDeviceCopy = data.device ? _.find(data.devices, d=>d.id===data.device.id) : null;
			let selectedSceneCopy = data.scene ? _.find(data.scenes, s=>s.id===data.scene.id) : null;

			let result = {
				group:_.clone(selectedGroupCopy), //because monkey imutability and adding devices into array below
				device:_.clone(selectedDeviceCopy),
				scene:_.clone(selectedSceneCopy),
			}
			if(result.group===null){
				result.group = data.groups.length>0 ? _.clone(data.groups[0]) : {id:-1, name:"", devices:[]};				
			}
			//now attach all of the related devices to this group
			result.group["devices"] = []; //initial value
			let groupDevicesMap = _.filter(data.devicegroups, dgmap=>parseInt(dgmap.GroupId,10)===result.group.id);
			_.forEach(groupDevicesMap, dgmap=>{
				let finding = _.find(data.devices, d=>d.id===dgmap.DeviceId);
				if(finding){
					result.group.devices.push(finding)
				}
			});

			if(result.device===null){
				result.device = data.devices.length>0 ? _.clone(data.devices[0]) : {id:-1, name:""}
			}
			if(result.scene===null){
				result.scene = data.scenes.length>0 ? _.clone(data.scenes[0]) : {id:-1, name:""}
			}
			return result;
		},
		options: {
			immutable: true
		}
	}),
	devicesByType:monkey({ //used in references and other dynamic components
		cursors: {
			// devices: ["model", "devices"],
			devices: ["monkeys", "accessibleDevices"],
		},
		get: (data)=>{
			let result = {
				digitalOutputs: {
					label:"Digital output",
					devices:_.filter(data.devices, d=>d.type===0)
				},
				analogOutputs:{
					label:"Analog output",
					devices:_.filter(data.devices, d=>d.type===1)
				},
				digitalInputs:{
					label:"Digital input",
					devices: _.filter(data.devices, d=>d.type===2)
				},
				analogInputs:{
					label:"Analog input",
					devices:_.filter(data.devices, d=>d.type===3)
				}
			};
			return result;
		},
		options: {
			immutable: true
		}
	}),
	eventSubscriptionsByType:monkey({
		cursors: {
			eventSubscriptions: ["model", "eventsubscriptions"],
		},
		get: (data)=>{
			let results = {
				onScheduleTemplate:{
					label:"Schedule template hit",
					events: _.filter(data.eventSubscriptions, e=>e.type===0)
				},
				onDeviceStateChange:{
					label:"On device state change",
					events: _.filter(data.eventSubscriptions, e=>e.type===1)
				},
				onBoot:{
					label:"On booting up",
					events: _.filter(data.eventSubscriptions, e=>e.type===2)
				},
				onAPICall:{
					label:"On API call",
					events: _.filter(data.eventSubscriptions, e=>e.type===3)
				},
				onBootLoopingCode:{
					label:"Looping code for PLC",
					events: _.filter(data.eventSubscriptions, e=>e.type===4)
				}
			}
			return results;
		},
		options: {
			immutable: true
		}
	}),
	groupedESScheduleTemplates:monkey({
		cursors: {
			eventSubscriptions: ["monkeys","eventSubscriptions","onScheduleTemplate","events"],
		},
		get: (data)=>{
			let results = _.groupBy(data.eventSubscriptions, "eventRules.params.scheduleTemplate.id");
			return results;
		},
		options: {
			immutable: true
		}
	}),
	selectedSlaveCodeVersion:monkey({
		cursors:{
			selectedSlaveUuid:["apiConfig","selectedSlaveUuid"],
			mySlaves: ["masterOnlyModel","mySlaves"]
		},
		get: (data)=>{
			let selectedSlave = _.find(data.mySlaves, slave=>slave.uuid===data.selectedSlaveUuid);
			return selectedSlave ? selectedSlave.codeVersion : "0.0.0";
		},
		options: {
			immutable: true
		}
	}),
	selectedSlaveRunningSystemEnvironment:monkey({
		cursors:{
			selectedSlaveUuid:["apiConfig","selectedSlaveUuid"],
			mySlaves: ["masterOnlyModel","mySlaves"]
		},
		get: (data)=>{
			let selectedSlave = _.find(data.mySlaves, slave=>slave.uuid===data.selectedSlaveUuid);
			if(_.isUndefined(selectedSlave)){
				return "unknown";
			} else {
				return selectedSlave.runningSystemEnvironment;
			}
		},
		options: {
			immutable: true
		}
	}),
	getCurrentRouteTitle: monkey({
		cursors:{
			mainMenuItem:["ui","selected","mainMenuItem"],
			userLevelMonkeys:["userLevelMonkeys"],
			route: ["route"],
			devices: ["model", "devices"],
			groups: ["model", "groups"],
			scenes: ["model", "scenes"],
			compositeDevices: ["model", "compositedevices"]
		},
		get: (data)=>{
			let result = "";
			if(data.route.location){
				let editString = "";
				if(_.includes((data.route.location.pathname).toLowerCase(), "edit")){
					editString = "/edit";
				}
				if(!_.isUndefined(data.route.params.deviceId)){
					if(!_.isUndefined(data.route.params.deviceModel)){
						if(data.route.params.deviceModel === "device"){
							result = !_.isUndefined(_.find(data.devices, {id:parseInt(data.route.params.deviceId, 10)})) ? (_.find(data.devices, {id:parseInt(data.route.params.deviceId, 10)})).name + editString : ""
						}
						else {
							result = !_.isUndefined(_.find(data.compositeDevices, {id:parseInt(data.route.params.deviceId, 10)})) ? (_.find(data.compositeDevices, {id:parseInt(data.route.params.deviceId, 10)})).name + editString : ""
						}
					}
					else {
						result = !_.isUndefined(_.find(data.devices, {id:parseInt(data.route.params.deviceId, 10)})) ? (_.find(data.devices, {id:parseInt(data.route.params.deviceId, 10)})).name + editString : ""
					}
				}else if (!_.isUndefined(data.route.params.groupId)){
					result = !_.isUndefined(_.find(data.groups, {id:parseInt(data.route.params.groupId, 10)})) ? (_.find(data.groups, {id:parseInt(data.route.params.groupId, 10)})).name + editString : ""
				}else if (!_.isUndefined(data.route.params.sceneId)){
					result = !_.isUndefined(_.find(data.scenes, {id:parseInt(data.route.params.sceneId, 10)})) ? (_.find(data.scenes, {id:parseInt(data.route.params.sceneId, 10)})).name + editString : ""
				}
				else {
				_.forEach(getMainMenuItems(data.userLevelMonkeys), menuItem=>{
					if(!_.isUndefined(menuItem.href)){
						if(menuItem.href.endsWith(data.route.location.pathname)){
							result = menuItem.pageTitle;
						}
					}
				})
			}
			}
			return result
		},
		options: {
			immutable: true
		}
	}),

	roomsFilteredBySearchTerm: monkey({
        cursors: {
            rooms: ["smartRoom", "roomInfo"],
            reservations: ["smartRoom", "reservations"],
            userslaves: ["model", "userslaves"],
            searchedTerm: ["searchedReservation"],
        },
        get: (data) => {
            let result = [];
            if(data.searchedTerm === ""){
                result = data.rooms;
            }
            else {
                var searchValue = data.searchedTerm;
                if (typeof searchValue === 'string') {
                    searchValue = searchValue.toLowerCase();
                }
    
                _.forEach(data.reservations, reservation => {
					if(_.includes(reservation.customerId.toLowerCase(), (data.searchedTerm).toLowerCase())){
						let room = _.find(data.rooms, {RoomId:reservation.RoomId})
						result.push(room)
					}
                })
                result = _.uniq(_.orderBy(result, ["RoomId"],["asc"]));
            }
            
            return result
        },
        // options: {
        // 	immutable: true
        // }
	}),
	getSearchedDevices: monkey({
        cursors: {
            devices: ["monkeys", "selfContainedDevices"],
            searchedDevice: ["searchedDevice"],
        },
        get: (data) => {			
            let result = [];
            if(data.searchedDevice === ""){
                result = data.devices;
            }
            else {
                var searchValue = data.searchedDevice;
                if (typeof searchValue === 'string') {
                    searchValue = searchValue.toLowerCase();
                }
    
                _.forEach(data.devices, device => {
                        if (_.includes(device.name.toLowerCase(), searchValue)) {
                            result.push(device);
                        }
                })
            }
            return result
        },
        // options: {
        // 	immutable: true
        // }
	}),
	getSearchedCompositeDevices: monkey({
        cursors: {
            compositeDevices: ["monkeys", "accessibleCompositeDevices"],
            searchedDevice: ["searchedDevice"],
        },
        get: (data) => {			
            let result = [];
            if(data.searchedDevice === ""){
                result = data.compositeDevices;
            }
            else {
                var searchValue = data.searchedDevice;
                if (typeof searchValue === 'string') {
                    searchValue = searchValue.toLowerCase();
                }
    
                _.forEach(data.compositeDevices, device => {
                        if (_.includes(device.name.toLowerCase(), searchValue)) {
                            result.push(device);
                        }
                })
			}		
			// result = _.uniq(result)
            return result
        },
        // options: {
        // 	immutable: true
        // }
    }),
	selfContainedDevices:monkey({
		cursors: {
			// devices: ["model", "devices"],
			devices: ["monkeys", "accessibleDevices"],
			// compositeDevices:["model","compositedevices"],
			compositeDevices: ["monkeys", "accessibleCompositeDevices"],
		},
		get: (data)=>{
			let result = [];
			let devicesThatBelogToComposite = [];

			_.forEach(data.compositeDevices, compDev=> {
				switch (compDev.type) {
					case "shutters":
					case "linearWindow":
					devicesThatBelogToComposite.push(parseInt(compDev.params.upRelay.value, 10))
					devicesThatBelogToComposite.push(parseInt(compDev.params.downRelay.value, 10))
					break;
					
					case "thermostat":
					devicesThatBelogToComposite.push(parseInt(compDev.params.thermostatRelay.value, 10))
					// devicesThatBelogToComposite.push(parseInt(compDev.params.temperatureSensor.value, 10))
					break;

					case "doorBell":
					devicesThatBelogToComposite.push(parseInt(compDev.params.doorBellInput.value, 10))
					devicesThatBelogToComposite.push(parseInt(compDev.params.doorBellOutput.value, 10))
					break;

					case "softThermostat":
					devicesThatBelogToComposite.push(parseInt(compDev.params.thermostatFanSpeed1.value, 10))
					devicesThatBelogToComposite.push(parseInt(compDev.params.thermostatFanSpeed2.value, 10))
					devicesThatBelogToComposite.push(parseInt(compDev.params.thermostatFanSpeed3.value, 10))
					devicesThatBelogToComposite.push(parseInt(compDev.params.thermostatValve1.value, 10))
					devicesThatBelogToComposite.push(parseInt(compDev.params.thermostatValve2.value, 10))
					break;

					case "underfloorHeating":
					devicesThatBelogToComposite.push(parseInt(compDev.params.underfloorHeatingValve.value, 10))
					break;
					
					
					default:
						break;
				}
			})
			
				_.forEach(data.devices, device => {
					if(!_.includes(devicesThatBelogToComposite, device.id)){
						result.push(device)
					}
				})
			
			return result;
		},
		options: {
			immutable: true
		}
	}),
	hardwareModulesInfo:monkey({
		cursors: {
			devices: ["model", "devices"],
		},
		get: (data)=>{
			let hwModules = {};
			let psyDevicesAddress = [];
			_.forEach(data.devices, device=>{
				let d = _.cloneDeep(device)
				let key = d.address.plcDeviceType !== undefined ? d.address.plcDeviceType : "";
				key +=  d.address.plc_address !== undefined ? "_"+ d.address.plc_address : ""
				key +=  d.address.port !== undefined ? "_"+ d.address.port : ""
				key +=  d.address.portConfigId !== undefined ? "_"+ d.address.portConfigId : ""
				key +=  d.address.bus !== undefined  ? "_"+ d.address.bus : "";
				if(!_.includes(psyDevicesAddress, key)){
					psyDevicesAddress.push(key);
					d.address.key = key;
					hwModules[key] = d.address;
					hwModules[key].ids = [d.id];
					hwModules[key].names = [d.name];
				} else {
					hwModules[key].ids.push(d.id);
					hwModules[key].names.push(d.name);
				}
			})
			return hwModules;
		},
		options: {
			immutable: true
		}
	}),


accessibleDevices:monkey({
	cursors: {
		devices: ["model", "devices"],
		featureAccessibilities: ["model", "featureaccessibilitys"],
		sessionInfo: ["sessionInfo"],
		
	},
	get: (data)=>{
		let devicesResult = []
		let myFeatures = null;

		if(data?.featureAccessibilities?.length===0){ //fallback for older versions
			return data.devices;
		}
		
		if(data.sessionInfo && data.sessionInfo.userProfile){
			myFeatures = _.find(data.featureAccessibilities, {email:data.sessionInfo.userProfile.email})
		}

		if(myFeatures){
			_.forEach(data.devices, device => {
				if(_.includes(myFeatures.params.deviceIds, device.id)){
					devicesResult.push(device)
				}
			})
		}else{
			devicesResult=data.devices
		}
		
		return devicesResult;
	},
	options: {
		immutable: true
	}
}),

accessibleCompositeDevices:monkey({
	cursors: {
		compositeDevices: ["model", "compositedevices"],
		featureAccessibilities: ["model", "featureaccessibilitys"],
		sessionInfo: ["sessionInfo"],
	},
	get: (data)=>{
		let compositeDevicesResult = []
		let myFeatures = null;

		if(data?.featureAccessibilities?.length===0){ //fallback for older versions
			return data.compositeDevices;
		}
		
		if(data.sessionInfo && data.sessionInfo.userProfile){
			myFeatures = _.find(data.featureAccessibilities, {email:data.sessionInfo.userProfile.email})
		}
		if(myFeatures){
			_.forEach(data.compositeDevices, compDevice => {
				if(_.includes(myFeatures.params.compositeDeviceIds, compDevice.id)){
					compositeDevicesResult.push(compDevice)
				}
			})
		}else{
			compositeDevicesResult=data.compositeDevices
		}
		return compositeDevicesResult;
	},
	options: {
		immutable: true
	}
}),
accessibleGroups:monkey({
	cursors: {
		groups: ["model", "groups"],
		featureAccessibilities: ["model", "featureaccessibilitys"],
		sessionInfo: ["sessionInfo"],
	},
	get: (data)=>{
		let groupsResult = []
		let myFeatures = null;

		if(data?.featureAccessibilities?.length===0){ //fallback for older versions
			return data.groups;
		}
		
		if(data.sessionInfo && data.sessionInfo.userProfile){
			myFeatures = _.find(data.featureAccessibilities, {email:data.sessionInfo.userProfile.email})
		}
		if(myFeatures){
			_.forEach(data.groups, group => {
				if(_.includes(myFeatures.params.groupIds, group.id)){
					groupsResult.push(group)
				}
			})
		}else{
			groupsResult=data.groups
		}
		
		return groupsResult;
	},
	options: {
		immutable: true
	}
}),

accessibleScenes:monkey({
	cursors: {
		scenes: ["model", "scenes"],
		featureAccessibilities: ["model", "featureaccessibilitys"],
		sessionInfo: ["sessionInfo"],
	},
	get: (data)=>{
		let scenesResult = []
		let myFeatures = null;

		if(data?.featureAccessibilities?.length===0){ //fallback for older versions
			return data.scenes;
		}
		
		if(data.sessionInfo && data.sessionInfo.userProfile){
			myFeatures = _.find(data.featureAccessibilities, {email:data.sessionInfo.userProfile.email})
		}
		if(myFeatures){
			_.forEach(data.scenes, scene => {
				if(_.includes(myFeatures.params.sceneIds, scene.id)){
					scenesResult.push(scene)
				}
			})
		}else{
			scenesResult=data.scenes
		}
		
		return scenesResult;
	},
	options: {
		immutable: true
	}
}),


accessibleWidgets:monkey({
	cursors: {
		widgets: ["model", "widgets"],
		featureAccessibilities: ["model", "featureaccessibilitys"],
		sessionInfo: ["sessionInfo"],
	},
	get: (data)=>{
		let widgetsResult = []
		let myFeatures = {}

		if(data?.featureAccessibilities?.length===0){ //fallback for older versions
			return data.widgets;
		}
		
		if(data.sessionInfo && data.sessionInfo.userProfile){
			myFeatures = _.find(data.featureAccessibilities, {email:data.sessionInfo.userProfile.email})
		}
		if(myFeatures){
			_.forEach(data.widgets, widget => {
				if(_.includes(myFeatures.params.widgetIds, widget.id)){
					widgetsResult.push(widget)
				}
			})
		}else{
			widgetsResult=data.widgets
		}
		return widgetsResult;
	},
	options: {
		immutable: true
	}
}),
}

export default calculatedCursors;
