import React, { Component } from 'react';
import { Router, Route } from 'react-router';
import { branch } from 'baobab-react/higher-order';
import baobabTree from './data/state';
import _ from 'lodash';
import checkRoleAccess from './utils/checkRoleAccess';

import asyncComponent from './components/asyncComponent';
import LoadingWrapper from './pages/infoPages/loadingScreen';
import MasterLayout from './pages/masterLayout';
import DashboardOnlyRoutesWrapper from './pages/dashboardOnlyRoutesWrapper';

import Devices from './pages/devices';
import GroupForm from './pages/groups/groupForm';
import UnassignedDevices from './pages/devices/unassignedDevices';
import HardwareModules from './pages/devices/hardwareModules';

import DeviceGroup from './pages/forms/deviceGroupForm';
import AllDevices from './pages/devices/allDevices';
import DeviceManagement from './pages/devices/deviceManagement';
import GroupManagement from './pages/groups/groupManagement';
import SceneManagement from './pages/scenes/sceneManagement';
import SelectDevicesFromGroup from './pages/forms/selectDevicesFromGroup';
import AddScene from './pages/scenes/addScene';
import SceneView from './pages/scenes';
import Settings from './pages/advanced/settings';
import SmartHome from './pages/dashboard/smartHome';
import AuditLogs from "./pages/logs/auditLogs"
import { logout } from './keycloak';

//using newer code splitting techinuque
const LetsCode = asyncComponent(() => import('./pages/advanced/letsCode'));
const Terminal = asyncComponent(() => import('./pages/terminal'));
//end new code splitting tech

const Advanced = asyncComponent(() => import('./pages/advanced'));

const PushButtonForm = asyncComponent(() => import('./pages/forms/pushButtonEventForm'));
const DeviceForm = asyncComponent(() => import('./pages/devices/deviceForm'));
const ScheduleList = asyncComponent(() => import('./pages/scheduleList'), 'background');
const ScheduleUserFriendly = asyncComponent(() => import('./pages/forms/scheduleUserFriendly'));
const StateChangeEventForm = asyncComponent(() => import('./pages/forms/stateChangeEventForm'));
const PageNotFound = asyncComponent(() => import('./pages/pageNotFound'));
const NoLocations = asyncComponent(() => import('./pages/infoPages/noLocations'));
const ConnectionError = asyncComponent(() => import('./pages/infoPages/clientConnectionLost'));
const ProfileAndLocation = asyncComponent(() => import('./pages/profileAndLocation'));
const Backup = asyncComponent(() => import('./pages/backup'));
const Updates = asyncComponent(() => import('./pages/updates'));

const ParkingSettings = asyncComponent(() => import('./pages/advanced/settings/parkingSettings'));

const LinkedRulesEvent = asyncComponent(() => import('./pages/advanced/linkedRulesEvent'), 'background');
const EventLinking = asyncComponent(() => import('./pages/forms/eventLinking'));
const PowerManagementForm = asyncComponent(() => import('./pages/powerManagement/form'));
const ActivityLogs = asyncComponent(() => import('./pages/logs/activityLogs'));
const WidgetForm = asyncComponent(() => import('./pages/forms/widgetForm'));
const RegisterI2CForm = asyncComponent(() => import('./pages/forms/registerI2CForm'));
const RegisterWiegandDoorLockCompositeDevice = asyncComponent(() =>
	import('./pages/devices/registerWiegandDoorLockCompositeDevice')
);
const WiegandDoorLockWhitelist = asyncComponent(() => import('./pages/wiegandDoorLockWhitelist'));

const WidgetSort = asyncComponent(() => import('./pages/widget/widgetSort'));
const ModbusDevices = asyncComponent(() => import('./pages/modbusDevices'));
const ModbusDeviceForm = asyncComponent(() => import('./pages/modbusDevices/form'));

const AddOnManagement = asyncComponent(() => import('./pages/advanced/settings/addOnManagement.js'));
const FeatureAccessibility = asyncComponent(() => import('./pages/featureAccessibility'));
const FeatureAccessibilityForm = asyncComponent(() => import('./pages/featureAccessibility/form'));

/*-----------login and registration add on -------------*/
const SlaveRegistration = asyncComponent(() => import('./loginAndRegistration/pages/slaveRegistration'));
const Logs = asyncComponent(() => import('./pages/logs'));
const PowerManagementLogs = asyncComponent(() => import('./pages/logs/powerManagementLogs'));
const PowerManagement = asyncComponent(() => import('./pages/powerManagement'));

/*----------- administrative ADD ON -------------*/
const AdministrationMyUsers = asyncComponent(() => import('./adminPages/myUsers'));
const AdministrationSystemIntegrators = asyncComponent(() => import('./adminPages/systemIntegrators'));
const AdministrationSystemIntegratorUsers = asyncComponent(() =>
	import('./adminPages/systemIntegrators/integratorUsers')
);
const AdministrationUserLocations = asyncComponent(() => import('./adminPages/systemIntegrators/userLocations'));
const AdministrationUnassignedLocations = asyncComponent(() => import('./adminPages/unassignedLocations'));
const ShuttersNonLinearAdjustments = asyncComponent(() => import('./pages/devices/shuttersNonLinearAdjustments'));
const AdministrationSlaveInfo = asyncComponent(() => import('./adminPages/locationInfo'));
const AdministrationManagement = asyncComponent(() => import('./adminPages'));
const AdministrationAllLocations = asyncComponent(() => import('./adminPages/allLocations'));

const DoorAccess = asyncComponent(() => import('./pages/doorAccess'));

const Routes = (props) => {
	var onEnterRouteHandler = (nextRouteObj) => {
		if (props.sessionInfo.userProfile.level === 0) {
			let homePage =
				baobabTree.root.select('readOnlyModel', 'settings', 'general', 'uiHomePageRoute', 'value').get() || '/';

			if (nextRouteObj.location.pathname === '/') {
				if (!_.isUndefined(homePage) && !_.isEmpty(homePage) && homePage !== '/') {
					props.history.push(homePage);
				}
			} else {
				if (
					baobabTree.root.select('readOnlyModel', 'settings', 'general', 'uiMainMenu', 'value').get() ===
					false
				) {
					try {
						let isRouteAllowed = false;
						let allowedRoutes =
							_.clone(
								baobabTree.root
									.select('readOnlyModel', 'settings', 'general', 'uiAllowedRoutes', 'value')
									.get()
							) || [];
						allowedRoutes.push(homePage);
						allowedRoutes.forEach((element) => {
							if (nextRouteObj.location.pathname.startsWith(element)) {
								if (!_.isUndefined(element) && element !== '/' && !_.isEmpty(element)) {
									isRouteAllowed = true;
								}
							}
						});
						if (isRouteAllowed === false) {
							let userLevel = baobabTree.root.select('sessionInfo', 'userProfile', 'level').get();
							userLevel = parseInt(userLevel, 10);
							if (userLevel === 0) {
								props.history.push('/');
								console.log(
									'baobab.root.select("readOnlyModel", "settings", "general", "uiAllowedRoutes", "value").set(["' +
										nextRouteObj.location.pathname +
										'"])'
								);
								//console.log(nextRouteObj.location.pathname);
							} else {
								//allow to force route, do not redirect
							}
						}
					} catch (err) {
						console.warn(err);
					}
				}
			}
		}
		baobabTree.root.select(['route']).set(nextRouteObj);
	};

	return (
		<MasterLayout {...props}>
			<Router {...props} ignoreScrollBehavior>
				<Route
					path="/"
					pageTitle="Dashboard"
					component={SmartHome}
					onEnter={onEnterRouteHandler}
					ignoreScrollBehavior
				/>

				<Route
					path="/smartHomeDashboard"
					pageTitle="Dashboard"
					component={SmartHome}
					onEnter={onEnterRouteHandler}
					ignoreScrollBehavior
				/>

				<Route
					path="/group/createGroup"
					pageTitle="Create group of devices"
					component={GroupForm}
					onEnter={onEnterRouteHandler}
					ignoreScrollBehavior
				/>
				<Route path="/group/:groupId" component={Devices} onEnter={onEnterRouteHandler} ignoreScrollBehavior />
				<Route
					path="/group/:groupId/edit"
					onEnter={onEnterRouteHandler}
					component={GroupForm}
					ignoreScrollBehavior
				/>
				<Route
					path="/registerBasicDevice"
					onEnter={onEnterRouteHandler}
					component={DeviceForm}
					ignoreScrollBehavior
				/>
				<Route
					path="/registerWiegandDoorLock"
					pageTitle="Wiegand lock registration"
					onEnter={onEnterRouteHandler}
					component={RegisterWiegandDoorLockCompositeDevice}
					ignoreScrollBehavior
				/>
				<Route
					path="/wiegandDoorLockWhitelist/:compositeDeviceId"
					pageTitle="Wiegand door lock whitelist"
					onEnter={onEnterRouteHandler}
					component={WiegandDoorLockWhitelist}
					ignoreScrollBehavior
				/>

				<Route
					path="/group/:groupId/registerBasicDevice"
					onEnter={onEnterRouteHandler}
					component={DeviceForm}
					ignoreScrollBehavior
				/>
				<Route
					path="/group/:groupId/selectDevicesFromGroup"
					onEnter={onEnterRouteHandler}
					component={SelectDevicesFromGroup}
					ignoreScrollBehavior
				/>
				<Route
					path="/device/:deviceId/edit/:deviceModel"
					onEnter={onEnterRouteHandler}
					component={DeviceForm}
					ignoreScrollBehavior
				/>
				<Route
					path="/:deviceModel/:deviceId/selectDeviceGroup"
					onEnter={onEnterRouteHandler}
					component={DeviceGroup}
					ignoreScrollBehavior
				/>
				<Route
					path="/:deviceModel/:deviceId/scheduleUserFriendly"
					onEnter={onEnterRouteHandler}
					component={ScheduleUserFriendly}
					ignoreScrollBehavior
				/>
				<Route
					path="/:deviceModel/:deviceId/scheduleUserFriendly/:scheduleId"
					onEnter={onEnterRouteHandler}
					component={ScheduleUserFriendly}
					ignoreScrollBehavior
				/>
				<Route
					path="/:deviceModel/:deviceId/logs"
					onEnter={onEnterRouteHandler}
					component={Logs}
					ignoreScrollBehavior
				/>
				<Route
					path="/:deviceModel/:deviceId/powerManagementLogs"
					onEnter={onEnterRouteHandler}
					component={PowerManagementLogs}
					ignoreScrollBehavior
				/>
				<Route
					path="/:deviceModel/:deviceId/stateChangeEvent"
					onEnter={onEnterRouteHandler}
					component={StateChangeEventForm}
					ignoreScrollBehavior
				/>
				<Route
					path="/:deviceModel/:deviceId/stateChangeEvent/:eventSubscriptionId"
					onEnter={onEnterRouteHandler}
					component={StateChangeEventForm}
					ignoreScrollBehavior
				/>
				<Route
					path="/:deviceModel/:deviceId/pushButtonEvent"
					onEnter={onEnterRouteHandler}
					component={PushButtonForm}
					ignoreScrollBehavior
				/>
				<Route
					path="/:deviceModel/:deviceId/pushButtonEvent/:eventSubscriptionId"
					onEnter={onEnterRouteHandler}
					component={PushButtonForm}
					ignoreScrollBehavior
				/>

				<Route
					path="/widget/:widgetId/edit"
					pageTitle="Edit widget"
					component={WidgetForm}
					onEnter={onEnterRouteHandler}
					ignoreScrollBehavior
				/>
				<Route
					path="/widget/addNew"
					pageTitle="New widget"
					component={WidgetForm}
					onEnter={onEnterRouteHandler}
					ignoreScrollBehavior
				/>

				<Route
					path="/modbusDevices"
					pageTitle="Modbus devices"
					component={ModbusDevices}
					onEnter={onEnterRouteHandler}
					ignoreScrollBehavior
				/>
				<Route
					path="/modbusDevice/addNew"
					pageTitle="Modbus device"
					component={ModbusDeviceForm}
					onEnter={onEnterRouteHandler}
					ignoreScrollBehavior
				/>
				<Route
					path="/modbusDevice/:deviceId"
					pageTitle="Modbus device edit"
					component={ModbusDeviceForm}
					onEnter={onEnterRouteHandler}
					ignoreScrollBehavior
				/>

				<Route
					path="/allDevices/:deviceModel/:deviceId/scheduleList"
					onEnter={onEnterRouteHandler}
					component={ScheduleList}
					ignoreScrollBehavior
				/>
				<Route
					path="/group/:groupId/:deviceModel/:deviceId/scheduleList"
					onEnter={onEnterRouteHandler}
					component={ScheduleList}
					ignoreScrollBehavior
				/>

				<Route
					path="/widgetSort"
					pageTitle="Widget sorter"
					component={WidgetSort}
					onEnter={onEnterRouteHandler}
					ignoreScrollBehavior
				/>

				<Route
					path="/scheduleUserFriendly"
					component={ScheduleUserFriendly}
					onEnter={onEnterRouteHandler}
					ignoreScrollBehavior
				/>
				<Route
					path="/scene/:sceneId"
					component={SceneView}
					onEnter={onEnterRouteHandler}
					ignoreScrollBehavior
				/>
				<Route
					path="/scene/:sceneId/edit"
					onEnter={onEnterRouteHandler}
					component={AddScene}
					ignoreScrollBehavior
				/>

				<Route
					path="/scene/:sceneId/scheduleList"
					onEnter={onEnterRouteHandler}
					component={ScheduleList}
					ignoreScrollBehavior
				/>
				<Route
					path="/scene/:sceneId/scheduleUserFriendly"
					onEnter={onEnterRouteHandler}
					component={ScheduleUserFriendly}
					ignoreScrollBehavior
				/>
				<Route
					path="/scene/:sceneId/scheduleUserFriendly/:scheduleId"
					onEnter={onEnterRouteHandler}
					component={ScheduleUserFriendly}
					ignoreScrollBehavior
				/>
				<Route
					path="/linkedRulesEvent"
					pageTitle="Linked rules management"
					onEnter={onEnterRouteHandler}
					component={LinkedRulesEvent}
					ignoreScrollBehavior
				/>
				<Route
					path="/linkedRulesEvent/addNew"
					pageTitle="Add new linked rule"
					onEnter={onEnterRouteHandler}
					component={EventLinking}
					ignoreScrollBehavior
				/>
				<Route
					path="/linkedRulesEvent/:eventSubscriptionId/edit"
					pageTitle="Adjust linked rule"
					onEnter={onEnterRouteHandler}
					component={EventLinking}
					ignoreScrollBehavior
				/>
				<Route
					path="/addScene"
					pageTitle="Add scene"
					onEnter={onEnterRouteHandler}
					component={AddScene}
					ignoreScrollBehavior
				/>
				<Route
					path="/allDevices"
					pageTitle="All devices"
					component={AllDevices}
					onEnter={onEnterRouteHandler}
					ignoreScrollBehavior
				/>
				<Route
					path="/deviceManagement"
					pageTitle="Device management"
					component={DeviceManagement}
					onEnter={onEnterRouteHandler}
					ignoreScrollBehavior
				/>
				<Route
					path="/groupManagement"
					pageTitle="Group management"
					component={GroupManagement}
					onEnter={onEnterRouteHandler}
					ignoreScrollBehavior
				/>
				<Route
					path="/sceneManagement"
					pageTitle="Scene management"
					component={SceneManagement}
					onEnter={onEnterRouteHandler}
					ignoreScrollBehavior
				/>

				<Route
					path="/unassignedDevices"
					pageTitle="Devices not assigned to any group"
					component={UnassignedDevices}
					onEnter={onEnterRouteHandler}
					ignoreScrollBehavior
				/>

				<Route
					path="/profile"
					pageTitle="Profile and location options"
					component={ProfileAndLocation}
					onEnter={onEnterRouteHandler}
					ignoreScrollBehavior
				/>
				<Route
					path="/profile/backup"
					pageTitle="Backup location configuration"
					component={Backup}
					onEnter={onEnterRouteHandler}
					ignoreScrollBehavior
				/>
				<Route
					path="/updates"
					pageTitle="Software updates"
					component={Updates}
					onEnter={onEnterRouteHandler}
					ignoreScrollBehavior
				/>

				<Route
					path="/activityLogs"
					pageTitle="Activity logs"
					component={ActivityLogs}
					onEnter={onEnterRouteHandler}
					ignoreScrollBehavior
				/>
				<Route
					path="/activityLogs/:subPath"
					pageTitle="Activity logs"
					component={ActivityLogs}
					onEnter={onEnterRouteHandler}
					ignoreScrollBehavior
				/>

				<Route
					path="/powerManagement/addNew"
					pageTitle="Power meter registration"
					onEnter={onEnterRouteHandler}
					component={PowerManagementForm}
					ignoreScrollBehavior
				/>
				<Route
					path="/powerManagement/:powerManagementId"
					onEnter={onEnterRouteHandler}
					component={PowerManagementForm}
					ignoreScrollBehavior
				/>
				<Route
					path="/powerManagement"
					pageTitle="Power management"
					onEnter={onEnterRouteHandler}
					component={PowerManagement}
					ignoreScrollBehavior
				/>

				<Route
					path="/featureAccessibility/addNew"
					pageTitle="Power meter registration"
					onEnter={onEnterRouteHandler}
					component={FeatureAccessibilityForm}
					ignoreScrollBehavior
				/>
				<Route
					path="/featureAccessibility/:featureAccessibilityId"
					onEnter={onEnterRouteHandler}
					component={FeatureAccessibilityForm}
					ignoreScrollBehavior
				/>
				<Route
					path="/featureAccessibility"
					pageTitle="Feature Accessibility"
					onEnter={onEnterRouteHandler}
					component={FeatureAccessibility}
					ignoreScrollBehavior
				/>

				<Route
					path="/registerI2C"
					pageTitle="Register i2c extension"
					onEnter={onEnterRouteHandler}
					component={RegisterI2CForm}
					ignoreScrollBehavior
				/>
				<Route
					path="/hardwareModules"
					pageTitle="Hardware modules"
					onEnter={onEnterRouteHandler}
					component={HardwareModules}
					ignoreScrollBehavior
				/>

				<Route
					path="/letsCode"
					pageTitle="Coding editor (experimental)"
					component={LetsCode}
					onEnter={onEnterRouteHandler}
					ignoreScrollBehavior
				/>
				<Route
					path="/settings"
					pageTitle="Device & UI settings"
					component={Settings}
					onEnter={onEnterRouteHandler}
					ignoreScrollBehavior
				/>
				<Route
					path="/settings/:addOnPath"
					pageTitle="Plugin settings"
					component={Settings}
					onEnter={onEnterRouteHandler}
					ignoreScrollBehavior
				/>
				<Route
					path="/addOnManagement"
					pageTitle="Plugin management"
					component={AddOnManagement}
					onEnter={onEnterRouteHandler}
					ignoreScrollBehavior
				/>
				<Route
					path="/advanced"
					pageTitle="Advanced"
					component={Advanced}
					onEnter={onEnterRouteHandler}
					ignoreScrollBehavior
				/>

				<Route
					path="/terminal"
					pageTitle="Console terminal"
					component={Terminal}
					onEnter={onEnterRouteHandler}
					ignoreScrollBehavior
				/>

				<Route
					path="/parkingSettings"
					pageTitle="Parking settings"
					component={ParkingSettings}
					onEnter={onEnterRouteHandler}
					ignoreScrollBehavior
				/>

				<Route
					path="/doorAccess"
					pageTitle="Door access"
					component={DoorAccess}
					onEnter={onEnterRouteHandler}
					ignoreScrollBehavior
				/>

				<Route
					path="/administration/myUsers"
					pageTitle="My users"
					component={AdministrationMyUsers}
					onEnter={onEnterRouteHandler}
					ignoreScrollBehavior
				/>
				<Route
					path="/administration/systemIntegrators"
					pageTitle="System integrators"
					component={AdministrationSystemIntegrators}
					onEnter={onEnterRouteHandler}
					ignoreScrollBehavior
				/>
				<Route
					path="/administration/management"
					pageTitle="Administration"
					component={AdministrationManagement}
					onEnter={onEnterRouteHandler}
					ignoreScrollBehavior
				/>
				
				<Route
					path="/administration/systemIntegrators/:administrativeEmail"
					pageTitle="Account users"
					component={AdministrationSystemIntegratorUsers}
					onEnter={onEnterRouteHandler}
					ignoreScrollBehavior
				/>
				<Route
					path="/administration/showUserLocations/:email"
					pageTitle="User locations"
					component={AdministrationUserLocations}
					onEnter={onEnterRouteHandler}
					ignoreScrollBehavior
				/>
				<Route
					path="/administration/allLocations"
					pageTitle="All locations"
					component={AdministrationAllLocations}
					onEnter={onEnterRouteHandler}
				/>
				<Route
					path="/administration/unassignedLocations"
					pageTitle="Unassigned locations"
					component={AdministrationUnassignedLocations}
					onEnter={onEnterRouteHandler}
					ignoreScrollBehavior
				/>
				<Route
					path="/shuttersNonLinearAdjustments/:deviceId"
					pageTitle="shutters"
					component={ShuttersNonLinearAdjustments}
					onEnter={onEnterRouteHandler}
					ignoreScrollBehavior
				/>

    
				<Route
					path="/administration/slaveInfo/:slaveUuid"
					pageTitle="Slave info"
					component={AdministrationSlaveInfo}
					onEnter={onEnterRouteHandler}
					ignoreScrollBehavior
				/>

				<Route
					path="/auditLogs"
					pageTitle="Audit Logs"
					component={AuditLogs}
					onEnter={onEnterRouteHandler}
					ignoreScrollBehavior
				/>
				<Route path="*" pageTitle="Page not found" component={PageNotFound} onEnter={onEnterRouteHandler} />
			</Router>
		</MasterLayout>
	);
};

const DashboardOnlyRoutes = (props) => {
	var onEnterRouteHandler = (nextRouteObj) => {
		baobabTree.root.select(['route']).set(nextRouteObj);
	};
	return (
		<DashboardOnlyRoutesWrapper {...props}>
			<Router {...props} ignoreScrollBehavior>
				<Route
					path="/"
					pageTitle="Dashboard"
					component={SmartHome}
					onEnter={onEnterRouteHandler}
					ignoreScrollBehavior
				/>
				<Route path="/group/:groupId" component={Devices} onEnter={onEnterRouteHandler} ignoreScrollBehavior />
				<Route
					path="/scene/:sceneId"
					component={SceneView}
					onEnter={onEnterRouteHandler}
					ignoreScrollBehavior
				/>
				<Route path="*" pageTitle="Page not found" component={SmartHome} onEnter={onEnterRouteHandler} />
			</Router>
		</DashboardOnlyRoutesWrapper>
	);
};

var onEnterRouteHandlerForLoginAndRegistration = (nextRouteObj) => {
	baobabTree.root.select(['route']).set(nextRouteObj);
};

const SlaveRegistrationRoutes = (props) => (
	<Router {...props} ignoreScrollBehavior>
		<Route
			path="/"
			component={SlaveRegistration}
			onEnter={onEnterRouteHandlerForLoginAndRegistration}
			ignoreScrollBehavior
		/>

		<Route path="*" component={SlaveRegistration} onEnter={onEnterRouteHandlerForLoginAndRegistration} />
	</Router>
);

class ExportRoutes extends Component {
	componentDidMount() {
	//	this.props.loadAppData();
	}
	
	render() {
		const props = this.props;

		const isKeycloakAuth = props.keycloakAuth && props.keycloakAuth.authenticated;
		const isMaster = props.sessionInfo && props.sessionInfo.isMaster;

		if (props.dashboardOnlyRoutes && !props.autoLogin) {
			if (props.sessionInfo && props.sessionInfo.isMaster) {
				//skip....
				return DashboardOnlyRoutes(props);
			} else {
				return DashboardOnlyRoutes(props);
			}
		}

		//return "SAMRS"

		if (props.autoLogin && !isMaster) {
			//used for autologin (whn device is not connected to the cloud....)
			console.log('autologin route');
			return Routes(props);
		} else if (isKeycloakAuth) {
			if (props.sessionInfo && props.sessionInfo.initialUserAssignment === false) {
				if (checkRoleAccess('admin', 'systemIntegrator', 'user', 'advancedUser')) {
					return SlaveRegistrationRoutes(props); //DEVICE REGISTRATION AND USER 2 DEVICE ASSIGMENT
				} else {
					return (
						<div>
							You do not have enough privileges to do this registration.{' '}
							<button
								onClick={() => {
									logout();
								}}
							>
								LOGOUT
							</button>{' '}
						</div>
					);
				}
			} else if (props.showLoadingScreen === true) {
				return <LoadingWrapper {...props} />;
			} else if (props.showNoLocationsScreen === true) {
				return <NoLocations {...props} />;
			} else if (props.connectionStateMessage !== '') {
				return <ConnectionError {...props} />;
			} else {
				return Routes(props);
			}
		} else {
			return null; //<LoadingWrapper {...props}/>; //prevents load screen flickering
		}
	}
}

export default branch(
	{
		showLoadingScreen: ['ui', 'showLoadingScreen'],
		isAuthenticated: ['isAuthenticated'],
		showNoLocationsScreen: ['ui', 'showNoLocationsScreen'],
		connectionStateMessage: ['ui', 'connectionStateMessageObject', 'message'],
		sessionInfo: ['sessionInfo'],
		keycloakAuth: ['keycloak'],
	},
	ExportRoutes
);