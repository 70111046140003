import React, { useState, useEffect } from 'react';
import rest from '../../data/restWrapper';
import moment from 'moment';

function AuditLogs() {
    const [auditLogsState, setAuditLogs] = useState([]);
    const [searchTerm, setSearchTerm] = useState(null);
    const [page, setPage] = useState(0);
    const rows = 30;

    useEffect(() => {
        try {
            fetchData();
        } catch (error) {
            console.log(error.message);
        }
    }, []);

    async function fetchData(_page = page) {
        const _auditLogs = await rest('/getAuditLogs?page=' + _page + '&rows=' + rows);
        setAuditLogs([..._auditLogs.data]);
        setPage(_page);
    }

    const onIntegratorSearch = (e) => {
        setSearchTerm(e.target.value);
    };

    const pageDown = async () => {
        if (page > 0) {
            let _page = page - 1;
            await fetchData(_page);
        }
    };

    const pageUp = async () => {
        if (auditLogsState.length > 0) {
            let _page = page + 1;
            await fetchData(_page);
        }
    };

    let auditLogs = auditLogsState;

    if (auditLogsState) {
        if (searchTerm) {
            auditLogs = auditLogsState.filter((logs) => {
                if (logs.email.toLowerCase().includes(searchTerm.toLowerCase())) {
                    return logs.email.toLowerCase().includes(searchTerm.toLowerCase());
                } else if (logs.slaveUuid.toLowerCase().includes(searchTerm.toLowerCase())) {
                    return logs.slaveUuid.toLowerCase().includes(searchTerm.toLowerCase());
                } else {
                    if (logs.name !== null) return logs.name.toLowerCase().includes(searchTerm.toLowerCase());
                }
            });
        } else {
            auditLogs = auditLogsState;
        }
    }

    return (
        <div className="text-center">
            <div className="row m-0">
                <div className="col-md-8">
                    <button onClick={pageDown.bind(this)} className="btn btn-sm mr-2">
                        prev
                    </button>
                    <small className="mt-1 p1">page {page + 1}</small>
                    <button onClick={pageUp.bind(this)} className="btn btn-sm ml-2">
                        next
                    </button>
                </div>
                <div className="col-md-4">
                    <input
                        className="form-control"
                        onChange={(e) => {
                            onIntegratorSearch(e);
                        }}
                        placeholder="&#128269; Search "
                    />
                </div>
            </div>
            <br />

            <div className="row m-0">
                <div className="col-md-3 text-bold">Email</div>
                <div className="col-md-3 text-bold">Device uuid</div>
                <div className="col-md-3 text-bold">Device name</div>
                <div className="col-md-3 text-bold">Logging time</div>
            </div>

            <div style={{ border: '0.1remsolid grey' }}>
                {auditLogs?.sort().map((log, key) => {
                    return (
                        <div
                            className="row mx-0"
                            key={key}
                            style={{
                                background: key % 2 === 0 ? '#FFFFFF' : '#e8f5ff',
                                display: 'flex',
                                alignItems: 'center',
                                padding: '0.3rem',
                            }}
                        >
                            <div className="col-xs-3">{log.email}</div>
                            <div className="col-xs-3 selectable-text">{log.slaveUuid}</div>
                            <div className="col-md-3">{log.name}</div>
                            <div className="col-xs-3">{moment(log.loggingTimestamp).format('MM/DD/YYYY hh:mm')}</div>
                        </div>
                    );
                })}
            </div>
            <br />
        </div>
    );
}

export default AuditLogs;
