import React, { Component } from 'react';
import { branch } from 'baobab-react/higher-order';
// import actions from "../../data/actions/actions";
import _ from "lodash";
import updateForm from "../../data/actions/formStateController";
// import AddDeviceToScene from "../../components/addDeviceToScene";
import DeviceItems from "../../components/deviceItems/deviceItems";



class SceneForm extends Component {
  constructor() {
    super();
    this.state = {
      showAnalogOutputDialog: false,
      showBlindsControlDialog: false,
      deviceToShow: 0,
      showAddDeviceDialog: false
    };
  }

  closeDialog() {
    this.setState({
      showAnalogOutputDialog: false,
      showBlindsControlDialog: false,
    })
  }

  showAddDeviceDialog(e) {
    e.preventDefault();
    this.setState({
      showAddDeviceDialog: true
    })
  }

  hideAddDeviceDialog() {
    this.setState({
      showAddDeviceDialog: false
    })
  }

  removeDeviceFromScene (id, isComposite){
    
    let idType = isComposite === true ? "compositeDeviceId" : "deviceId"
    // obj.params["sceneExitDeviceState"] = this.props.sceneForm.params.sceneActiveDeviceState;
    //   obj.params["sceneActiveDeviceState"] = this.props.sceneForm.params.sceneActiveDeviceState;
    //   obj.params["groups"] = this.props.sceneForm.params.groups;
    //   updateForm("scene", obj);
    let activeDeviceStates = _.cloneDeep(this.props.sceneForm.params.sceneActiveDeviceState)
    let exitDeviceStates = _.cloneDeep(this.props.sceneForm.params.sceneExitDeviceState)

    activeDeviceStates = _.filter(activeDeviceStates, deviceState => { return deviceState[idType] !== id})
    exitDeviceStates = _.filter(exitDeviceStates, deviceState => { return deviceState[idType] !== id})

    var params = {};
    var ob = { params };
    ob.params["sceneActiveDeviceState"] = activeDeviceStates;
    ob.params["sceneExitDeviceState"] = exitDeviceStates;
    ob.params["groups"] = this.props.sceneForm.params.groups;
    updateForm("scene", ob);
  }

  addDevicesToSceneForm(deviceIds, compositeDeviceIds, func, e) {

    e.preventDefault();
    func();
    let mySceneActiveDeviceState = []
    _.forEach(deviceIds, id => {
      let obj = _.find(this.props.sceneForm.params.sceneActiveDeviceState, { deviceId: id })
      if (!_.isUndefined(obj)) {
        mySceneActiveDeviceState.push({ "deviceId": id, "value": obj.value })
      }
      else {
        mySceneActiveDeviceState.push({ "deviceId": id, "value": 0 })
      }
    })

    _.forEach(compositeDeviceIds, id => {
      let obj = _.find(this.props.sceneForm.params.sceneActiveDeviceState, { compositeDeviceId: id })
      if (!_.isUndefined(obj)) {
        mySceneActiveDeviceState.push({ "compositeDeviceId": id, "value": obj.value })
      }
      else {
        mySceneActiveDeviceState.push({ "compositeDeviceId": id, "value": 0 })
      }
    })




    // let mySceneExitDeviceState = _.clone(this.props.sceneForm.params.sceneExitDeviceState)
    // if (!_.isEmpty(mySceneExitDeviceState)) {
    //   _.forEach(deviceIds, id => {
    //     mySceneExitDeviceState.push({ "deviceId": id, "value": 0 })
    //   })
    // }   

    let mySceneExitDeviceState = []
    if (this.props.sceneForm.params.sceneExitDeviceState.length > 0) {
      _.forEach(deviceIds, id => {
        let pom = _.find(this.props.sceneForm.params.sceneExitDeviceState, { deviceId: id })
        if (!_.isUndefined(pom)) {
          mySceneExitDeviceState.push({ "deviceId": id, "value": pom.value })
        }
        else {
          mySceneExitDeviceState.push({ "deviceId": id, "value": 0 })
        }
      })
      _.forEach(compositeDeviceIds, id => {
        let pom = _.find(this.props.sceneForm.params.sceneExitDeviceState, { compositeDeviceId: id })
        if (!_.isUndefined(pom)) {
          mySceneExitDeviceState.push({ "compositeDeviceId": id, "value": pom.value })
        }
        else {
          mySceneExitDeviceState.push({ "compositeDeviceId": id, "value": 0 })
        }
      })
    }


    var params = {};
    var ob = { params };
    ob.params["sceneActiveDeviceState"] = mySceneActiveDeviceState;
    ob.params["sceneExitDeviceState"] = mySceneExitDeviceState;
    ob.params["groups"] = this.props.sceneForm.params.groups;
    updateForm("scene", ob);
  }

  setAfterSceneState(state, e) {

    var params = {}
    var obj = { params }
    if (state === true) {

      obj.params["sceneExitDeviceState"] = this.props.sceneForm.params.sceneActiveDeviceState;
      obj.params["sceneActiveDeviceState"] = this.props.sceneForm.params.sceneActiveDeviceState;
      obj.params["groups"] = this.props.sceneForm.params.groups;
      updateForm("scene", obj);
    }

    else {
      obj.params["sceneActiveDeviceState"] = this.props.sceneForm.params.sceneActiveDeviceState;
      obj.params["sceneExitDeviceState"] = [];
      obj.params["groups"] = this.props.sceneForm.params.groups;
      updateForm("scene", obj);
    }

  }


  shallowDeviceCopy(device) {
    let clonedDevice = {};
    let attributes = ["id", "name", "address", "value", "style", "type", "connectionState", "enableInterrupts", "isLocked", "params", "startupValue", "lockState"];
    _.forIn(device, (val, key) => {
      if (_.includes(attributes, key)) {
        clonedDevice[key] = val;
      }
    })
    return clonedDevice;
  }

  shallowCompositeDeviceCopy(device) {
    let clonedDevice = {};
    let attributes = ["id", "name", "address", "value", "style", "type", "params", "lockState"];
    _.forIn(device, (val, key) => {
      if (_.includes(attributes, key)) {
        clonedDevice[key] = val;
      }
    })
    return clonedDevice;
  }

  render() {
    var deletedDevices = []

    var sceneActiveDeviceState = [];
    _.forEach(_.clone(this.props.sceneForm.params.sceneActiveDeviceState), devState => {
      if (devState.deviceId || devState.deviceId === 0) {
            let device = _.find(this.props.devices, {id:parseInt(devState.deviceId, 10)})
            if(device){
              let clone = this.shallowDeviceCopy(device);
              clone.value = devState.value;
              sceneActiveDeviceState.push(clone)
            }else{
              let style = {}
              style["icon"] = {}
              deletedDevices.push({deleted:true, param:"active", name:"Deleted/hidden device", deviceId:devState.deviceId, style:style, value:devState.value})
            }            
      }
      if (devState.compositeDeviceId || devState.compositeDeviceId === 0) {
        let compDevice = _.find(this.props.compositeDevices, {id:parseInt(devState.compositeDeviceId, 10)})
            if(compDevice){

            let clone = this.shallowCompositeDeviceCopy(compDevice);
            clone.value = devState.value;
            sceneActiveDeviceState.push(clone)
            }else{
              let style = {}
              style["icon"] = {}
              deletedDevices.push({deleted:true, param:"active", name:"Deleted/hidden device", compositeDeviceId:devState.compositeDeviceId, style:style, value:devState.value})
            }
      }
      
      
    })

    var sceneExitDeviceState = [];
    _.forEach(_.clone(this.props.sceneForm.params.sceneExitDeviceState), devState => {
      if (devState.deviceId || devState.deviceId === 0) {
        let device = _.find(this.props.devices, { id: parseInt(devState.deviceId, 10) })
        if (device) {
          let clone = this.shallowDeviceCopy(device);
          clone.value = devState.value;
          sceneExitDeviceState.push(clone)
        } else {
          let style = {}
          style["icon"] = {}
          deletedDevices.push({deleted:true,param:"exit", name: "Deleted/hidden device", deviceId: devState.deviceId, style: style, value: devState.value })
        }
      }
      if (devState.compositeDeviceId || devState.compositeDeviceId === 0) {
        let compDevice = _.find(this.props.compositeDevices, { id: parseInt(devState.compositeDeviceId, 10) })
        if (compDevice) {
          let clone = this.shallowCompositeDeviceCopy(compDevice);
          clone.value = devState.value;
          sceneExitDeviceState.push(clone)
        } else {
          let style = {}
          style["icon"] = {}
          deletedDevices.push({deleted:true, param:"exit", name: "Deleted/hidden device", compositeDeviceId: devState.compositeDeviceId, style: style, value: devState.value })
        }
      }
    })

    let deletedActiveDevicesDeviceItems = []
    let deletedExitDevicesDeviceItems = []

    _.forEach(deletedDevices, dev => {
      
      if(dev.param === "active"){
        deletedActiveDevicesDeviceItems.push(<div key={"active"+ dev.deviceId ? dev.deviceId : dev.compositeDeviceId} className="device col-md-3 col-xs-6 col-lg-3">
        <div className="device-inside">
          <div className="m-top-15">Deleted/hidden device</div>
          <div className="toggle-button">
          <div onClick={this.removeDeviceFromScene.bind(this, dev.deviceId ? dev.deviceId : dev.compositeDeviceId, dev.deviceId ? false : true)} className="btn btn-info btn-sm">remove</div>
          </div>
        </div>
      </div>)
      }else {
        deletedExitDevicesDeviceItems.push(<div key={"exit"+ dev.deviceId ? dev.deviceId : dev.compositeDeviceId} className="device col-md-3 col-xs-6 col-lg-3">
        <div className="device-inside">
          <div className="m-top-15">Deleted/hidden device</div>
          <div className="toggle-button">
          <div onClick={this.removeDeviceFromScene.bind(this, dev.deviceId ? dev.deviceId : dev.compositeDeviceId, dev.deviceId ? false : true)} className="btn btn-info btn-sm">remove</div>
          </div>
        </div>
      </div>)
      }
     
    })

  
    if (_.isEmpty(this.props.sceneForm.params.sceneActiveDeviceState) && this.props.groupsFromForm.length !== 0) {
      return <div>           <label className="control-label col-sm-2" htmlFor="ss"></label>
        <div className="col-sm-10 ">

          There are no scene supported devices in group(s) you selected</div></div>;
    }
    else if (_.isEmpty(this.props.sceneForm.params.sceneActiveDeviceState)) {
      return null
    }

    let compositeActiveArrayFromScene = []
    _.forEach(sceneActiveDeviceState, dev => {
      if (dev.type === "shutters" || dev.type === "thermostat"|| dev.type === "linearWindow" || dev.type === "softThermostat" || dev.type === "underfloorHeating"  || dev.type === "softIR") {
        compositeActiveArrayFromScene.push(dev)
      }
    })
    let devicesActiveArrayFromScene = _.difference(sceneActiveDeviceState, compositeActiveArrayFromScene)


    let compositeExitArrayFromScene = []
    _.forEach(sceneExitDeviceState, dev => {
      if (dev.type === "shutters" || dev.type === "thermostat" || dev.type === "linearWindow" || dev.type === "softThermostat" || dev.type === "underfloorHeating"  || dev.type === "softIR") {
        compositeExitArrayFromScene.push(dev)
      }
    })
    let devicesExitArrayFromScene = _.difference(sceneExitDeviceState, compositeExitArrayFromScene)


    return (
      <div>
        <div className={this.props.sceneActiveDevicesShown === true ? "form-group scene-edit-wrapper"  : "hidden"}>
          <label className="control-label col-sm-2" htmlFor="name">Adjust device state:</label>
          <div className="col-sm-10 row">
        {deletedActiveDevicesDeviceItems}
            <DeviceItems compositeDevices={compositeActiveArrayFromScene} devices={devicesActiveArrayFromScene} sceneParameter={"active"} />
          </div>
          <label className="control-label col-sm-2" htmlFor="ss"></label>

          <div className="col-sm-10 ">

            {/* <button onClick={this.showAddDeviceDialog.bind(this)} className={_.isUndefined(this.props.route.params.sceneId) ? "btn btn-sm btn-info" : "btn btn-sm btn-info"}>Add or remove devices</button>
            <AddDeviceToScene addDevicesToSceneForm={this.addDevicesToSceneForm} isShown={this.state.showAddDeviceDialog}
              hideDialog={this.hideAddDeviceDialog.bind(this)} devicesAlreadyInScene={this.props.sceneForm.params.sceneActiveDeviceState} /> */}

          </div>

          <div className="row no-margin ">
            <div className="row"></div>
            <label className="control-label col-sm-2 scene-before-devices-wrapper" htmlFor="name">Adjust device state after the scene is finished:</label>

            {/* <input  className="col-sm-1 marg-top-fifteen "
            checked={this.props.sceneExitDevicesShown} onChange={this.setAfterSceneState.bind(this)} type="checkbox" /> */}
            <div className="col-sm-10 ">

              <i onClick={this.setAfterSceneState.bind(this, !this.props.sceneExitDevicesShown)}
                className={this.props.sceneExitDevicesShown ? "fa fa-check-square-o fa-2x padding-10" : "fa fa-square-o fa-2x padding-10"}></i>
            </div>
          </div>

          <div className="row no-margin">
            <div className="col-sm-2"></div>
            <div className="col-sm-10 scene-after-devices-wrapper " >
              {deletedExitDevicesDeviceItems}
              <DeviceItems compositeDevices={compositeExitArrayFromScene} devices={devicesExitArrayFromScene} sceneParameter={"exit"} />

            </div>
          </div>

          <div className="row">
            <div className="row"> </div>
            <div className="text-center full-width">
            </div>
          </div>

        </div>
      </div>
    )
  }
}

export default branch({
  // scenes: ['model', 'scenes'],
  scenes: ["monkeys", "accessibleScenes"],
  groups: ['model', 'groups'],
  sceneForm: ['forms', 'scene'],
  // compositeDevices: ["model", "compositedevices"],
  compositeDevices: ['monkeys', 'accessibleCompositeDevices'],
  // devices: ['model', 'devices'],
  devices: ['monkeys', 'selfContainedDevices'],
  devicegroups: ['model', 'devicegroups'],
  route: ["route"],
  ui: ["ui", "showMainMenu"],
  sceneExitDevices: ["ui", "showSceneExitDevices"],
  showSceneForm: ["ui", "showSceneForm"]
}, SceneForm);

