import React, { Component } from 'react';
import { branch } from 'baobab-react/higher-order';
import Header from '../components/header';
import Footer from '../components/footer';
import MainMenu from '../components/mainMenu';
import HeaderNotification from "../components/notification/headerNotification";
import LocationOffline from "../pages/infoPages/locationOffline";
import EmptyLayout from "./emptyLayout";
import actions from "../data/actions/actions";
// import _ from "lodash";
import InactivityReload from './inactivityReload';
import Modal from "../components/modals";
import Toaster from "../components/toaster"
import { silentlyReloadLocationData } from "../data/actions/startupLoaders"
import moment from "moment"
import WindowFocusListener from "./onFocus"
class MasterLayout extends Component {

	constructor(params) {
		super(params)
		this.state = {
			timeoutID: "",
			status: "active",
			dateTime:new Date()
		}
	}

	componentDidMount() {
		if (this.props.screensaver && this.props.screensaver.value === true) {
			this.setup();
		}
		this.clockInterval = setInterval(()=>{
			this.setState({dateTime:new Date()})
		},30*1000)
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.status !== this.state.status && this.state.status === "active") {
			silentlyReloadLocationData()
			// window.location.reload()
		}
	}

	componentWillUnmount(){
		clearInterval(this.clockInterval)
	}


	setup() {
		//     window.addEventListener("mousemove", this.resetTimer.bind(this), false);
		document.getElementById("root").addEventListener("mousedown", this.resetTimer.bind(this), false);
		document.getElementById("root").addEventListener("keypress", this.resetTimer.bind(this), false);
		document.getElementById("root").addEventListener("DOMMouseScroll", this.resetTimer.bind(this), false);
		// document.getElementById("root").addEventListener("mousewheel", this.resetTimer.bind(this), false);
		document.getElementById("root").addEventListener("touchmove", this.resetTimer.bind(this), { passive: true});
		// document.getElementById("root").addEventListener("MSPointerMove", this.resetTimer.bind(this), false);

		this.startTimer();
	}

	startTimer() {
		this.setState({
			timeoutID: window.setTimeout(this.goInactive.bind(this), this.props.screensaverTimeoutInSeconds * 1000)
		})
	}

	resetTimer(e) {
		window.clearTimeout(this.state.timeoutID);
		this.setState({
			timeoutID: 0
		})
		this.goActive();
	}

	goInactive() {
		// do something
		this.setState({
			status: "inactive"
		})
	}

	goActive() {
		// do something
		this.setState({
			status: "active"
		})
		this.startTimer();
	}
	render() {
		if (this.state.status === "inactive") {
			return <div className="text-center">
			<Toaster />
				<img className="animated faster infinite pulse slower" style={{width:"300px", paddingTop:"30rem"}} alt="" src={"../../"+process.env.REACT_APP_BRAND+"/img/logo.png"} />

				<h2 className={"text-white"}>{moment(this.state.dateTime).format("LLL")}</h2>
			</div>
		}
		let isFullScreen = false;
		if (window.innerHeight === window.screen.height) {
			// browser is fullscreen
			isFullScreen = true;
		}

		if (this.props.sessionInfo === null) {
			return null;
		}

		if (this.props.showLoadingScreen === true) {
			return (<div id="app">
				<Toaster />
				<LocationOffline />
				<EmptyLayout />
			</div>);
		}

		return (
			<div id="app">
				<HeaderNotification />
				<Toaster />
				<LocationOffline />
				{this.props.uiOnFocusReload && this.props.uiOnFocusReload.value === true ? <WindowFocusListener timeoutInMinutes={this.props.uiOnFocusReloadTimeout.value ? Number(this.props.uiOnFocusReloadTimeout.value) : 15}/> : null}
				{this.props.uiReloadAfterInactivity && this.props.uiReloadAfterInactivity.value === true ? <InactivityReload timeoutInMinutes={this.props.uiReloadAfterInactivityTimeout.value ? Number(this.props.uiReloadAfterInactivityTimeout.value) : 14} /> : null}
				<div id="app-content-wrapper">
					<Header {...this.props} />

					<div onClick={() => actions.showMainMenu("left")} className={this.props.showMainMenu === false && this.props.renderMainMenu === true ? "side-menu-visibility btn side-menu-sticker" : "hidden"}>
						<i className="fa fa-bars"></i>
					</div>

					{this.props.renderMainMenu ? <MainMenu isFullScreen={isFullScreen} /> : null}

					<Modal />
					<div className={isFullScreen ? "content-wrapper-full" : "content-wrapper"}>
						<div className={(this.props.showMainMenu === true && this.props.renderMainMenu === true ? "content col-md-9 col-lg-9 pull-right app-page-content" : "content col-md-12 col-lg-12 app-page-content") + (isFullScreen ? " m-t-20" : "")} >
							{this.props.children}
						</div>
					</div>
					<Footer />
				</div>

			</div>);
	}
}

export default branch({
	sessionInfo: ["sessionInfo"],
	// devices: ['model', 'devices'],
	devices: ["monkeys", "accessibleDevices"],
	route: ["route"],
	showMainMenu: ["ui", "showMainMenu"],
	renderMainMenu: ["userLevelMonkeys", "renderMainMenu"],
	//	showLoadingScreen: ["monkeys", "showLoadingScreen"], passed via props
	sessionErrors: ["sessionErrors"],
	doorBellsActive: ["doorBellsActive"],
	modalDialog: ["ui", "modalDialog"],
	unfinishedScenes: ["unfinishedScenes"],
	screensaver: ["readOnlyModel", "settings", "general", "uiScreenSaver"],
	uiOnFocusReload: ["readOnlyModel", "settings", "general", "uiOnFocusReload"],
	uiOnFocusReloadTimeout: ["readOnlyModel", "settings", "general", "uiOnFocusReloadTimeout"],
	uiReloadAfterInactivity: ["readOnlyModel", "settings", "general", "uiReloadAfterInactivity"],
	uiReloadAfterInactivityTimeout: ["readOnlyModel", "settings", "general", "uiReloadAfterInactivityTimeout"],
	screensaverTimeoutInSeconds: ["readOnlyModel", "settings", "general", "uiScreenSaverTimeout", "value"]

}, MasterLayout);